<div class=" attachment" (appFileDragDrop)="onDrop($event)" height="103px">
    <div id="addAttachment" class="d-flex align-items-center">
        <span>Upload or drag-drop attachments</span>
        <img class="ml-auto" src="/assets/images_vla/Upload.svg" matTooltip="Upload"
            (click)="selectAttachment.click()"  draggable="false" alt="upload"/>
        <input type="file" class="d-none" multiple #selectAttachment (change)="GetAttachmentToUpload($event)" />
    </div>
    <div id="container" class="d-flex align-items-center" *ngFor="let documents of attachments$ | async">
        <span class="ellipsis attachment-width-11vw" [title]="documents.Name">{{documents.Name}}</span>
        <div class="ml-auto">
            <span>{{documents.DateAdded | formatDate}}</span>
            <img src="/assets/images_vla/View Eye.svg" alt="view" class="ml-3" [ngClass]="documents.disableView ? 'fa-disabled' : ''"  draggable="false"
                (click)="documents.disableView ? null : viewAttachment(documents.FileId, documents.Name)" matTooltip="View" />
            <img src="/assets/images_vla/Download.svg"  draggable="false"
                (click)="downloadAttachments(documents.FileId, documents.Name)" class="ml-3" alt="download" matTooltip="Download" />
            <img src="/assets/icons/delete-red.svg"  draggable="false"
                (click)="deleteAttachment(documents.Id, documents.Name)" class="ml-3" alt="delete" matTooltip="Delete"/>
        </div>
    </div>
</div>
<!-- <div *ngIf="url" class="show-image">
    <img src="/assets/icons/Close_black.svg" alt="image" (click)="url = ''" id="cross">
    <img [src]="url" alt="image" id="uploaded-image">
</div> -->