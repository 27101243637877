import { Component, Input } from '@angular/core';
import { LabadminService } from '../labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';


@Component({
  selector: 'app-infofax',
  templateUrl: './infofax.component.html',
  styleUrls: ['./infofax.component.scss']
})
export class InfofaxComponent {
  userid: number
  faxDetailsServices: any = [];
  faxDetailsCaseTypes: any = [];
  faxDetailsLocations: any = [];
  noDataFoundFlag: boolean;


  @Input() userDetials = {}
  npiDetails = {}
  otherIdentifiers = []
  constructor(private labadminService: LabadminService, private LabadminSessionService: LabadminSessionService) {
  }

  ngOnInit() {
    this.noDataFoundFlag = false;
    this.getFAXDetails();
  }

  getFAXDetails() {
    let userData: any;
    this.LabadminSessionService.getLabAdminSession.subscribe(Response => userData = Response);
    // let accountIds = this.userDetials['UserAccountIDs']?.map(account => account.AccountId).join(',');
    let accountIds = userData.userDetails['userAccountIDs']?.map(account => account.accountId).join(',');
    let objData = {
      LabAccountIds: accountIds,
      UserId: this.userDetials['UserID'],
      SearchValue: this.userDetials['UserID'] + '_Fax',
      Type: 'User',
      OFAccountId: this.userDetials['AccountId'],
      LoggedInUserId: userData.userDetails['userId']
    }
    this.labadminService.FaxandPrinting(objData, this.labadminService.deploymentKey).subscribe(data => {
      if (Object.values(data?.content).some((arr: any) => arr?.length > 0)) {
        this.faxDetailsLocations = data?.content?.faxDetailsLocations?.map((item: any) => item.AccountName);
        this.faxDetailsCaseTypes = data?.content?.faxDetailsCaseTypes?.map((item: any) => item.CaseTypeDisplayname);
        this.faxDetailsServices = data?.content?.faxDetailsServices?.map((item: any) => item.Services);
      }
      else {
        this.noDataFoundFlag = true;
      }
    })
  }
}
