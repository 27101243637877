<!-- Edit -->
<div *ngIf="editViewClicked || createViewClicked" class="row col-sm-12 m-0 p-0">
    <div class="col-sm-6 mt-2 mb-3 d-flex justify-content-between">
        <span *ngIf="editViewClicked" class="header-View">
            <strong>{{context}} {{ HeaderName }} </strong>
        </span>
        <span *ngIf="createViewClicked" class="header-View">
            <strong>{{context}} {{ HeaderName }} </strong>
        </span>
    </div>
    <form class="float-right col-sm-6 p-0 mt-2 mb-2">
        <div class="p-0 button-wrap align-items-center">
            <a class="reset-btn mr-3" (click)="resetDynamicForm()" *ngIf="isFormChanged">Reset</a>
            <button class="mr-3 align-center buttonHover" (click)="backView()">Return</button>
            <button class="align-center primaryButtonColor" (click)="saveDynamicForm()"
                [disabled]="isSaveDisabled">Save</button>
        </div>
    </form>
    <div autocomplete="off" class="col-sm-12 p-0">
        <form #myForm="ngForm" (ngSubmit)="saveBtn()" autocomplete="off" class="row col-sm-12 p-3 form-wrap-view">
            <div class="row col-sm-12" style="padding-left: 0px !important;padding-right: 0px !important;">
                <ng-container *ngFor="let field of dynamicFormData">

                    <div *ngIf="field.dataType === 'input'" [ngClass]="field['manageClassName']" class="viewInputContainer"
                        [ngStyle]="{'flex': field?.properties?.width ? '0 0 ' + field?.properties?.width : null}">
                        <mat-form-field class="col-sm-12 p-0" [ngClass]="field.value ? 'valuedField' : ''">
                            <mat-label> {{field['displayName']}} </mat-label>
                            <input [required]="field.inputValidations['required']"
                                [maxlength]="field.inputValidations['maxLength']"
                                [minlength]="field.inputValidations['minLength']" [(ngModel)]="field.value"
                                name="{{ field.columnName }}" (blur)="onBlurOfInput($event, field)" trim matInput
                                (input)="isFormChanged = true" (blur)="trimField(field)" />
                        </mat-form-field>
                    </div>

                    <div *ngIf="editViewClicked && field.dataType === 'inputSequence'"
                        [ngClass]="field['manageClassName']"
                        [ngStyle]="{'flex': field?.properties?.width ? '0 0 ' + field?.properties?.width : null}" class="viewInputContainer">
                        <mat-form-field class="col-sm-12 p-0" [ngClass]="field.value ? 'valuedField' : ''">
                            <mat-label> {{field['displayName']}} </mat-label>
                            <input [required]="field.inputValidations['required']"
                                [maxlength]="field.inputValidations['maxLength']"
                                [minlength]="field.inputValidations['minLength']"
                                [pattern]="fieldPatterns?.[field?.inputValidations?.['inputDataType']] || ''"
                                [(ngModel)]="field.value" name="{{ field.columnName }}"
                                (blur)="onBlurOfInput($event, field)" trim matInput (input)="isFormChanged = true" />
                        </mat-form-field>
                    </div>

                    <div *ngIf="(field.dataType === 'dropdown' && !(field['properties']?.['isDisabledInAdmin'] && isAdmin3))"
                        [ngClass]="{'hidden': (field.properties?.isHideInLabAdmin && !isAdmin3)}" [class]="field.manageClassName"
                        [ngStyle]="{'flex': field?.properties?.width ? '0 0 ' + field?.properties?.width : null}" class="viewSelectContainer">
                        <mat-form-field class="col-sm-12 p-0" [ngClass]="field.value ? 'valuedField' : ''">
                            <mat-label> {{ field?.['displayName'] }} </mat-label>
                            <mat-select [(ngModel)]="field.value" name="{{ field.columnName }}"
                                [required]="field.inputValidations['required']"
                                (selectionChange)="onSelectChange(field); isFormChanged = true"
                                [disabled]="(field['properties']?.['isDisableInEdit'] && editViewClicked && !isUserLevel) || (field?.properties?.['isDisabledInAdmin'] && isAdmin3)"
                                [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'">
                                <mat-option value="All"
                                    *ngIf="field['properties']?.['source']?.['hasAllOption']">All</mat-option>
                                <mat-option *ngFor="let dropdownOptions of field['properties']?.['source']?.['options']"
                                    [value]="dropdownOptions[field['properties']?.['source']?.['keyToBeSentToBackend']]">
                                    <div class="truncate-text">
                                        <span *ngFor="let userDisplayname of field?.properties?.source?.dataValueToDisplay; let i = index;">
                                            <span *ngIf="i === 0" matTooltip="{{ dropdownOptions[userDisplayname] }}">
                                                {{ dropdownOptions[userDisplayname] | shortentext:field?.properties?.source?.truncateLength
                                                }}
                                            </span>
                                            <span *ngIf="i !== 0">{{ '( ' + dropdownOptions[userDisplayname] + ' )'}}</span>
                                        </span>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                    <div *ngIf="field.dataType === 'staticDropdown'" [ngClass]="field['manageClassName']"
                        [ngStyle]="{'flex': field?.properties?.width ? '0 0 ' + field?.properties?.width : null}" class="viewSelectContainer">
                        <mat-form-field class="col-sm-12 p-0" [ngClass]="field.value ? 'valuedField' : ''">
                            <mat-label>{{field?.['displayName']}}</mat-label>
                            <mat-select [(ngModel)]="field.value" name="{{ field.columnName }}"
                                [required]="field.inputValidations['required']"
                                [disabled]="(field['properties']?.['isDisableInEdit'] && editViewClicked) || (field?.properties?.['isDisabledInAdmin'] && isAdmin3)"
                                [panelClass]="'customCEOverlayPanelClass'">
                                <mat-option *ngFor="let dropdownOptions of field['properties']?.['source']?.['options']"
                                    [value]="dropdownOptions[field['properties']?.['source']?.['keyToBeSentToBackend']]">
                                    <div> {{ dropdownOptions[field['properties']?.['source']?.['dataValueToDisplay']] }}
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div *ngIf="field.dataType === 'badge'" class="mt-2 badgeAlignment"
                        [ngClass]="field['manageClassName']"
                        [ngStyle]="{'flex': field?.properties?.width ? '0 0 ' + field?.properties?.width : null}">
                        <span class="mr-2"> {{field['displayName']}}: </span>
                        <label class="ct-tag-toggle">
                            <input type="checkbox" [(ngModel)]="field.value" name="{{ field.columnName }}"
                                (change)="onBadgeChange($event, field['columnName']); isFormChanged = true"
                                [value]="field['value'] == ('No' || false || 0 || '') ? 0 : 1">
                            <span class="slider"></span>
                        </label>
                    </div>

                    <div *ngIf="field.dataType === 'multiselectDropdown'" [ngClass]="field['manageClassName']">
                        <label class="" *ngIf="field['displayName']">
                            {{field['displayName']}} </label>
                        <ng-multiselect-dropdown class="col-sm-12 text-xs pr-0 p-0" [ngClass]="{'valuedDropDown': field?.value?.length}"
                            [placeholder]="field['properties']?.['placeholder']" [(ngModel)]="field.dummyValue"
                            [settings]="field['properties']?.['source']?.['dropdownSettings']"
                            [data]="field['properties']?.['source']?.['options']" [subMenuCardModel]="SubMenuCardModel"
                            [ngModelOptions]="{standalone: true}" class="col-sm-12" name="{{ field.columnName }}"
                            [disabled]="field['properties']?.['isDisableInEdit'] && editViewClicked"
                            (onSelect)="onMultiDropdownSelect($event, field)"
                            (onDeSelect)="onMultiDropdownDeSelect($event, field)"
                            (onSelectAll)="onMultiDropdownSelectAll($event, field)"
                            (onDeSelectAll)="onMultiDropdownDeSelectAll(field)">
                        </ng-multiselect-dropdown>
                    </div>

                    <div *ngIf="field.dataType === 'textarea'" [ngClass]="field['manageClassName']" class="viewTextAreaContainer">
                        <mat-form-field class="col-sm-12 p-0 ceTextArea" [ngClass]="field.value ? 'valuedField' : ''">
                            <mat-label> {{field['displayName']}} </mat-label>
                            <textarea #textareaElement type="textarea" trim matInput
                                [(ngModel)]="field.value"
                                [maxlength]="field['inputValidations']?.['maxLength']"
                                [required]="field.inputValidations['required']"
                                name="{{ field.columnName }}" (input)="isFormChanged = true" rows="5" (blur)="trimField(field)">
                                    </textarea>
                        </mat-form-field>
                    </div>

                    <div *ngIf="field.dataType === 'chip' && field['properties']?.['source']?.['options']?.length"
                        [ngClass]="field['manageClassName']" class="mt-2">
                        <div class="col-sm-12 chipsetContainer p-2">
                            <mat-chip-list aria-label="Fish selection">
                                <mat-chip class="mat-chip-border"
                                    (click)="appendToTextarea(chipValue, field); isFormChanged = true"
                                    value="{{chipValue}}"
                                    *ngFor="let chipValue of field['properties']?.['source']?.['options']">{{chipValue}}</mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>

                    <div *ngIf="field.dataType === 'autocomplete'" [ngClass]="field['manageClassName']" class="viewAutoCompleteContainer">
                        <mat-form-field class="w-100 viewAutoComplete" [ngClass]="field.value ? 'valuedField' : ''">
                            <mat-label>{{ field['displayName'] }} </mat-label>
                            <em class="fa fa-chevron-down chevron-align"></em>
                            <!-- for autocomplete as we need some value we are sorting that in default value as object from there we are taking for value field. -->
                            <input trim type="text" [(ngModel)]="field?.['dummyValue']['itemName']" maxlength="200"
                                matInput [aria-label]="field['displayName']" [matAutocomplete]="auto"
                                [ngModelOptions]="{standalone: true}" (input)="onAutoCompleteInputChange($event, field)"
                                [disabled]="field['properties']?.['isDisableInEdit'] && editViewClicked">
                            <mat-autocomplete #auto="matAutocomplete" class="customCEOverlayPanelClass listingDropdownPanel">
                                <mat-option class="mat-opt-align"
                                    *ngFor="let autoCompleteOption of  filterAutomCompleteOptions(field?.dummyValue['itemName'], field?.properties?.source?.options, field?.properties?.source?.dataValueToDisplay?.[0])"
                                    [value]="autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]]"
                                    [disabled]="field['properties']?.['isDisableInEdit'] && editViewClicked"
                                    (onSelectionChange)="onAutoCompleteSelectionChange($event, field, autoCompleteOption)">
                                    <div class="truncate-text" matTooltip="{{ autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]]}}"> {{ autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]] | shortentext:field?.properties?.source?.truncateLength }}
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div *ngIf="field.dataType === 'groupedTextArea'" [ngClass]="field['manageClassName']"
                        style="padding: 12px;">
                        <div class="groupedField" [ngClass]="field['groupedField'].value ? 'valuedField' : ''">
                            <mat-form-field class="col-sm-12 p-0 groupedTextArea" [ngClass]="field.value ? 'valuedField' : ''">
                                <mat-label> {{field?.['groupedField']?.['displayName']}} </mat-label>
                                <textarea #textareaElement type="textarea" trim matInput
                                    [(ngModel)]="field['groupedField'].value"
                                    [maxlength]="field['groupedField']?.['inputValidations']?.['maxLength']"
                                    [required]="field['groupedField']?.inputValidations['required']"
                                    name="{{ field?.['groupedField']?.columnName }}" rows="5"
                                    (input)="isFormChanged = true; onTextAreaChange(field);" (blur)="trimField(field)">
                                        </textarea>
                            </mat-form-field>

                            <div class="col-sm-12 chipsetContainer p-2">
                                <mat-label style="padding: 5px 10px; display: block; color: #00000099;">{{
                                    field['displayName']
                                    }}</mat-label>
                                <mat-chip-list aria-label="Fish selection"
                                    *ngIf="field?.['properties']?.['source']?.['options']?.length">
                                    <mat-chip class="mat-chip-border"
                                        (click)="appendToTextarea(chipValue, field); isFormChanged = true"
                                        value="{{chipValue}}"
                                        *ngFor="let chipValue of field?.['properties']?.['source']?.['options']">{{chipValue}}</mat-chip>
                                </mat-chip-list>
                                <div *ngIf="!field?.['properties']?.['source']?.['options']?.length"
                                    class="noPlaceholdersContainer"> No Placeholders Found </div>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="field.dataType === 'richTextArea'" [ngClass]="field['manageClassName']"
                        style="padding: 12px;">
                        <mat-label style="padding: 5px 0px; display: block; color: #000;">
                            {{ field['displayName'] }} <span style="color: #FF0000"> * </span>
                        </mat-label>
                        <div class="editor">
                            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                            </ngx-editor-menu>
                            <ngx-editor required class="rtf-editor" [editor]="editor" [(ngModel)]="field.value" [ngModelOptions]="{standalone: true}"
                                [disabled]="false" [required]="field.inputValidations['required']">
                            </ngx-editor>
                        </div>
                    </div>

                </ng-container>
            </div>


        </form>
    </div>
</div>

<!-- View -->
<div *ngIf="!editViewClicked && !createViewClicked" class="col-sm-12">
    <div class="d-flex mt-2">
       
        <div class="col-sm-6 mb-3 d-flex justify-content-between">
            <span class="header-View">
                <strong>View {{ HeaderName }} </strong>
            </span>
        </div>

        <div class="col-sm-6">
            <button type="button" class="btn btn-return float-right" (click)="backView()">Return</button>
            <button type="button" class="btn btn-return float-right mr-3" (click)="editDynamicForm()"
                *ngIf="!hideEdit">Edit</button>
        </div>
    </div>
    <div class="row view-Card">
        <ng-container *ngFor="let key of temp">
            <div [ngClass]="key.viewClassName">
                <span class="key-headers">
                    {{key['displayName']}}
                </span>
                <div class="key-values">
                    {{key['value'] | stripHtml }}
                </div>
            </div>
        </ng-container>
    </div>
</div>