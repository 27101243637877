import { Clipboard } from "@angular/cdk/clipboard";
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  MatChipInputEvent } from "@angular/material/chips";
import { ThemePalette } from "@angular/material/core";
import {  MatSnackBar } from '@angular/material/snack-bar';
import {  MatTabGroup } from "@angular/material/tabs";
import * as wjcCore from "@grapecity/wijmo";
import { CollectionView,addClass } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription } from "rxjs";
import { startWith } from "rxjs/internal/operators/startWith";
import { map } from "rxjs/operators";
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import { FlexGrid, CellEditEndingEventArgs } from '@grapecity/wijmo.grid';
import {  MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { cloneDeep } from 'lodash';



declare var $;

export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-setup-new-location',
  templateUrl: './setup-new-location.component.html',
  styleUrls: ['./setup-new-location.component.scss'],
})

export class SetupNewLocationComponent implements OnInit {
  files: File[] = [];

  task: Task = {
    color: 'primary',
  };

  @Input()
  searchTabContent1: any = {};
  @Input()
  mainCard: any;
  logoPath: boolean = true;
  imageSrc: string;
  filesData: any;
  filelist: any[];
  newLogoSrc: any = "";

  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @ViewChild('assocgrid') grid: WjFlexGrid;
  @ViewChild('currloc') currloc: ElementRef;
  @ViewChild('existinggrid') existGrid: WjFlexGrid;


  myForm = new FormGroup({
    logo_Upload: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required]),
    image_Sign: new FormControl(''),
  });
  public uploadbutton: boolean = true;
  public remove: boolean = true;
  public btcreate: boolean = true;
  public button: any[];
  public noLogo = true;
  public gridDisplay = false;
  public SubMenuCardModel;
  public btnCreate: boolean;
  public inptTemplateData;
  public associationModel: boolean;
  public AssociatedOrgaDetails;
  public AccountName;
  public accountName;
  public epgAccountDetails : any = [];
  public servicesList: any;
  public accError: boolean = false;
  public UpdateMultiselect: boolean;
  public AddMultiselect: boolean;
  public addeddata: any = [];
  public tabDisplayName: string;
  public selectedLevel: string;
  public associationTypeList: any;
  public usersubscript: Subscription;
  public tableGrid: boolean;
  public ifEdit: boolean = false;
  public cancelButton: boolean;
  public imageUrl: any;
  public editAssocData;
  public AssociatedOrgid: any;
  public AccountsAdded = [];
  public OldAddJson;
  public oldAsscType;
  public associateAdd: boolean;
  public UpdateMultiselectAssc: boolean;
  public tempAccId: any;
  public temporgid: any;
  public assocUsers: any;
  public assocCaseTypes: any;
  public assocAccounts: any;
  dropdownList = [];
  public associationTable: boolean;
  public ifLocChanged: boolean = false;
  public ifAsscChanged: boolean = false;
  public ifCasetypeChanged: boolean = false;
  public ifUserChanged: boolean = false;
  public ifLogoChanged: boolean = false;
  public showPop: boolean = false;
  public isDisabled: boolean = false;
  public ifLocationSaved: boolean = false;
  public ifAssociateSaved: boolean = false;
  public ifCaseTypesSaved: boolean = false;
  public ifUserSaves: boolean = false;
  public ifLogoSaved: boolean = false;
  public AsssociatedAccountName: any;
  public AsssociatedOrgName: any;
  selectedItems = [];
  public labOrganizations: any;
  public selectedValue: any;
  public selectdTab: any;
  public filteredLabAccounts: Observable<any>;
  public ExistData: any;
  public selAccountid: any;
  public bigUserArray = [];
  public ifLogoEdit: boolean = false;
  public ifAssocEdit: boolean = false;
  public userAssociateFlexGrid;
  public caseTypeAssociateFlexGrid;
  public accountid = sessionStorage.getItem('Locationid');
  public accArray = [];
  // dropdownSettings: IDropdownSettings;
  public ifNotLabEntity: boolean = true;
  @ViewChild('multiSelect') multiSelect;

  ContextType: any;
  StatesArray: any[];
  UsernameDisplayFormatArray: any[];
  UsernameReportFormatArray: any[];
  ServiceArray: any[];
  ClaimOptionsArray: any[];
  AAccountidArray: any[];
  AssociatedLabAccountidArray: any[];
  AssociatedFacilityAccountidArray: any[];
  AssociatedPathologistidArray: any[];
  AssociatedSendOutAccountidArray: any[];
  AssociatedSendInAccountidArray: any[];
  public ifAddAssociate: boolean;
  associationtypeArray: any[];
  locGridRowArray;
  displayGrid;
  intgridwidth;
  casetypelist: any;
  casetypegrid: CollectionView<any>;
  usersgrid: CollectionView<any>;

  CasetypeGridLocArray: any[];
  intMapGridData: CollectionView<any>;

  selectedItems1 = [];
  selectedItemsForUsers = [];

  selector: Selector = null;
  selectorUser: Selector = null;
  selectorCasetype: Selector = null;

  grouped = true;
  selectedCasetype: any[];
  arrUsers: any[];
  CasetypeArray = [];
  UsersArray = [];
  locationBasedCaseType: any;
  _parseAccountID: any;
  AssociatedCasetype: any;
  selectedCasetypeArray: any;

  orgid: any;

  unselectedCasetype: any = [];
  unselectedUsers: any = [];
  alertOnSave6: boolean;
  NPIWarning: boolean = false;
  refreshcardtype: any;
  tempSubmenudata: any[];
  DisplayAddUser: boolean = false;
  createFlag: boolean = false;
  updateGridFlag: boolean = false;
  showHeaderDisplayName: boolean = false;
  displaynameHeader: any;
  NPI: any;
  NPIvalidated: boolean = true;
  disableButton: boolean = true;
  export: boolean;
  public showAssociationComp: boolean = false;
  public accountId;
  public accountsOfSelectedOrg: any;
  public filterAccount: any;
  public casetyperows;
  OFORganizations: any;
  filteredOrg: Observable<any[]>;
  public isFacility: any;
  serviceDataMap: any = [];
  searchAccount: string;
  currentAssociations: boolean;

  columns
  existingData: any = [];
  assocGridData: wjcCore.CollectionView<any>;
  showPaginationNewAssocGrid: boolean;
  showPaginationExistAssocGrid: boolean;
  editableData: any = [];
  deleteData: any;
  showCasetype: boolean = false;
  categoryList: any = [];
  searchCaseType: string = '';
  checkAllCaseType: boolean;
  categoryListCopy: any = [];
  selectedAccount: string;
  labList: any = [];
  GroupCode: any;
  organizationId: any;
  organizationsList: unknown[];
  locationsList: any;
  tempAssociatedOrgaDetails: any;
  deleteAssoc: boolean = false;
  staticBackdrop: boolean = false;
  entityType: string = "";
  createMenu: boolean = false;

constructor(private ngxService: NgxUiLoaderService, private clipboard: Clipboard,
    public tabService: VitalAdminTabService, public vitalHttpServices: VitalHttpServices, public DataShare: DataShareService,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, private _fb: FormBuilder, public vaservices: VitalHttpServices) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, DataShare);
    this.columns = [
      /************* Grid Customized Column names *********/
      new ColDef('accountid', 'ID'),
      new ColDef('accountname', 'Name'),
      new ColDef('displayname', 'Display Name'),
      new ColDef('mnemonic', 'Mnemonic'),
      new ColDef('claimmnemonic', 'Claim Mnemonic'),
      new ColDef('NPI', 'NPI'),
      new ColDef('accountguid', 'GUID'),
      new ColDef('status', 'Status'),
      new ColDef('CreatedBy', 'CreatedBy'),
      new ColDef('CreatedDate', 'CreatedDate'),
      new ColDef('ModifiedBy', 'ModifiedBy'),
      new ColDef('ModifiedDate', 'ModifiedDate')
    ];
  }

  columnsAssc = [
    //Outer grid
    // new ColDef('services', 'Services'),
    // new ColDef('AllowReportViewing', 'Allow report viewing'),
    // new ColDef('isPcEnabled', 'PC Enabled'),
    new ColDef('AsssociatedAccountName', 'Associated Location Name'),
    new ColDef('AsssociatedOrgName', 'Associated Group Name'),
    new ColDef('AssociationType', 'Association Type'),
    // new ColDef('AccountName', 'Location Name'),
    new ColDef('AssociationID', 'AssociationID'),

  ];
  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  @ViewChild('RVT') RVT: ElementRef;
  @ViewChild('PDF') PDF: ElementRef;
  @ViewChild('RDF') RDF: ElementRef;
  @ViewChild('CDF') CDF: ElementRef;
  @ViewChild('UDF') UDF: ElementRef;
  @ViewChild('UPF') UPF: ElementRef;
  @ViewChild('CLM') CLM: ElementRef;
  @ViewChild('STA') STA: ElementRef;
  @ViewChild('AST') AST: ElementRef;
  columnDefs: any = [];
  gridwidth: number = 0;
  locGridWidth: number;

  gridData: CollectionView;
  existingGridData: CollectionView;
  gridDataAssc: CollectionView;

  labelFirstCount: number = 0;

  @Input()
  templateData: any;

  @Input()
  templateChangedTime: string;
  showPaginationMainGrid: boolean;
  showPaginationIntMapGrid: boolean;
  cvPaging: CollectionView;
  data: any;
  selectedIndex: number = 0;
  public ShowLocationGrid = false;
  public LabAccountDetails: any = [];
  public ShowSetupNewLocation = false;
  public NoDataSetupNewPage = true;
  public ShowGridItemDetails = true;
  public AssociationDetails: any = [];
  public GridLocArray;
  public checkCardtype = false;
  public DisplayCaseType = false;
  public DisplayLogoTab = false;
  public DisplayUsersTab: boolean = false;
  public DisplayLocationTab = false;
  public DisplayAssocLocTab = false;
  public associatedTypShow = false;
  public ifserviceShow = false;
  public oldAssJson;
  public formGroup: FormGroup;
  public formGroup1: FormGroup;
  public association: any = [];
  public association1: any = [];
  public associationAccountsArry: any = [];
  public ofAccountDetails: any = [];
  public filteredOFAccounts: Observable<any[]>;
  public epgOrgDetails: any;
  public ifLocEdit: boolean = false;
  public btnEdit: boolean = false;
  public ifCaseTypeEdit: boolean = false;
  public ifUsersEdit: boolean = false;
  public noSign = true;

  dateFormat: string[] = ['mm-dd-yyyy', 'dd-mm-yyyy'];

  gridHeader = ['status', 'NPI', 'accountguid', 'mnemonic', 'claimmnemonic','Type', 'displayname', 'accountname', 'accountid', 'CreatedBy', 'CreatedDate', 'ModifiedBy', 'ModifiedDate']
  casetypecolumnnames = [];
  userscolumnnames = [];
  casetypecolumns = [
    new ColDef('CategoryText', 'Category Text'),
    new ColDef('LogisticServices', 'Logistic Services'),
    new ColDef('CategoryValue', 'Category'),
    new ColDef('CaseTypeMnemonic', 'Mnemonic'),
    new ColDef('IssendOut', 'Send Out'),
    new ColDef('CassetteCount', 'Cassette Count'),
    new ColDef('CaseTypeDisplayname', 'Display Name'),
    new ColDef('CaseType', 'Case Type'),
  ]
  userscolumns = [
    new ColDef('userid', 'User ID'),
    new ColDef('FullName', 'Full Name'),
    new ColDef('loginname', 'Login Name'),
    new ColDef('rolename', 'Role Name'),
  ]

  //form-group for association of location page
  recipientData = this._fb.group({
    Accountname: '',
    AssociationType: '',
    AsssociatedAccountName: '',
    AssociatedAccountid: [],
    Service: '',
    IsViewReport: null,
    isPcEnabled: false,
    AllowReportViewing: false,
    Selecttype: '',
    accFilterCtrl: '',
    associateOrg: new FormControl(),
    item: [],
  });
  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  initEditGrid(grid) {
    grid.cellEditEnded.addHandler((s: FlexGrid, e: CellEditEndingEventArgs) => {

      let oldValue = e.data;
      let newValue = s.getCellData(e.row, e.col, true);
      this.editableData.length == 0 ? this.editableData.push(grid.rows[e.row]._data)
        : this.editableData.forEach(va => {
          if (va.associationid == grid.rows[e.row]._data.associationid) {
            va = grid.rows[e.row]._data;
          }
          else {
            this.editableData.push(grid.rows[e.row]._data);
          }
        })


      JSON.stringify(grid.rows[e.row]._data)
      if (oldValue !== newValue) {
      }
    });
  }

  editAssociatingTableRow(row) {
    this.ngxService.start();
    this.association = [];
    this.association.push({
      accountname: row.dataItem.AsssociatedAccountName,
      accountid: row.dataItem.associatedAccountID,
    });
    // this.selectedLevel = [];

    this.setForm();
    this.AccountsAdded = [];
    this.AccountsAdded.push(row.dataItem.associatedAccountID);
    this.selectedLevel = row.dataItem.AsssociatedAccountName;
    this.recipientData.patchValue({
      AssociationType: row.dataItem.AssociationType,
      Service: row.dataItem.services,
      AsssociatedAccountName: row.dataItem.AsssociatedAccountName,
      AssociatedAccountid: row.dataItem.AsssociatedAccountName,
      // AssociatedAccountid: tempSeletion,
      Accountname: this.accountName[0].accountname,
      AllowReportViewing: row.dataItem.AllowReportViewing,
      isPcEnabled: row.dataItem.isPcEnabled,
      Selecttype: row.dataItem.Selecttype,
      associateOrg: row.dataItem.AsssociatedOrgName,
    });
    this.delEditableRow(row);
    //this.UpdateAssociationModel = true;
    this.associationModel = true;
    this.UpdateMultiselect = true;
    this.AddMultiselect = false;
    this.ngxService.stop();
  }


  //#endregion
  delEditableRow(row) {
    this.ngxService.start();
    this.addeddata.splice(
      this.addeddata.findIndex(
        (e) => e.associatedAccountID === row.dataItem.associatedAccountID && e.AssociationType == row.dataItem.AssociationType
      ),
      1
    );
    this.AssociationDetails.splice(
      this.AssociationDetails.findIndex(
        (e) => e.associatedAccountID === row.dataItem.associatedAccountID && e.AssociationType == row.dataItem.AssociationType
      ),
      1
    );

    // this.AssociationDetails.filter((va) => va.associatedAccountID != row.dataItem.associatedAccountID)
    // if(this.AssociationDetails[i].associatedAccountID==row.dataItem.associatedAccountID){
    //   delete this.AssociationDetails[i]
    // }
    this.gridDataAssc = new CollectionView(this.addeddata, { pageSize: 10 });
    if (this.addeddata.length > 10) {
      this.showPaginationMainGrid = true;
    } else {
      this.showPaginationMainGrid = false;
    }
    this.ngxService.stop();
  }
  //#region  deleting the row of the grid

  getAssociatedAccount(value) {
    if (value.source) {
      let tempAccName = value.source.value.split('(')[0];
      this.tempAccId = value.source.value.split(')')[0].split('(')[1];
      if (this.tempAccId != 'null') {
        this.recipientData.patchValue({
          // AssociatedAccountid:value.option.value
          AssociatedAccountid: value.source.value,
        });
      }
      else {
        this.tempAccId = ''
        this.recipientData.patchValue({
          // AssociatedAccountid:value.option.value
          AssociatedAccountid: '',
        });
      }
    }
    else {
      this.tempAccId = value;
      // this.recipientData.patchValue({
      //   // AssociatedAccountid:value.option.value
      //   AssociatedAccountid: value,
      // });
    }
  }

  dropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
    selectAllText: string;
    unSelectAllText: string,

  };

  //form-group for setup-new-loc page
  AddLocRecipientData = this._fb.group({
    DropdownVal: this._fb.array([]),
    formInput_VitalAxisURL: [''],
    vitalaxiscode: [''],
    AccountName: ['', [Validators.maxLength(50)]],
    // Validators.pattern(/^(?:[a-zA-Z0-9]+(?:,[a-zA-Z0-9]+)*)*$/)]], // this validator includes comma also
    // Validators.pattern('^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$')]],
    // Validators.pattern(/^(\w+\s)*\w+|^(\s+\S+\s*)*(?!\s).$/)]],
    // Validators.pattern(/^[A-Za-z]|^(\s+\S+\s*)*(?!\s).*$/)]],
    ShortName: ['', [Validators.maxLength(10)]],
    //Validators.pattern(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)]],
    //Validators.pattern(/^(?:[a-zA-Z0-9]+(?:,[a-zA-Z0-9]+)*)*$/)]], // this validator includes comma also
    ParentAccountName: ['', Validators.maxLength(100)],
    DisplayName: ['', [Validators.maxLength(50)]],
    // Validators.pattern(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)]],
    //Validators.pattern(/^(?:[a-zA-Z0-9]+(?:,[a-zA-Z0-9]+)*)*$/)]], // this validator includes comma also
    NPI: ['', [Validators.required, Validators.maxLength(20)]],
    EIN: ['', Validators.maxLength(10)],
    CLIA: ['', Validators.maxLength(50)],
    StateLicense: ['', Validators.maxLength(50)],
    PrimaryContactname: ['', Validators.maxLength(100)],
    PrimaryContactPhone: [''],
    // PrimaryContactEmail: ['', [Validators.maxLength(50), Validators.email]],
    PrimaryContactEmail: ['', [Validators.maxLength(50), Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
    ClaimOptions: ['', Validators.maxLength(20)],
    FaxEnable: [''],///\d{3}[\ \.\-]?\d{3}[\ \.\-]?\d{4}$/
    CollectionDateFormat: ['', Validators.maxLength(10)],
    PackageDateFormat: ['', Validators.maxLength(10)],
    IsListedAsOF:null,
    GeoCoordinates: ['', Validators.maxLength(100)],
    EnableSendOutCheckBox: [''],
    ReportData: ['', Validators.maxLength(100)],
    Address1: ['', Validators.maxLength(100)],
    Address2: ['', Validators.maxLength(100)],
    Street: ['', Validators.maxLength(50)],
    City: ['', Validators.maxLength(50)],
    State: ['', Validators.maxLength(50)],
    ZIP: ['', [Validators.maxLength(15), Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]],
    Phone: [''],
    // Matches the below phone numbers
    //     123-456-7890
    // (123) 456-7890
    // 123 456 7890
    // 123.456.7890
    // +91 (123) 456-7890
    // Fax: ['', [Validators.maxLength(50), Validators.pattern(/\d{3}[\ \.]?\d{3}[\ \.]?\d{4}$/)]],
    Fax: [''],
    // Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]],
    UsernameDisplayformat: [''],
    UsernameReportformat: [''],
    Status: true,
    RegAccountName: ['', Validators.maxLength(300)],
    ClaimMnemonic: ['', Validators.maxLength(2)],
    ReceivedDateFormat: [''],
    ETADateFormat: ['', Validators.maxLength(10)],
    CLPOL: ['', Validators.maxLength(50)],
    IsSharedSurgicenter: false,
    PayToAddress1: ['', Validators.maxLength(100)],
    PayToAddress2: ['', Validators.maxLength(100)],
    PayToState: ['', Validators.maxLength(100)],
    PayToZip: ['', Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)],
    PayToCity: ['', Validators.maxLength(100)],
    OrganizationID: ['']
  });

  // --**************** Add association region starts *********************//
  recipientDataAssctn = this._fb.group({
    Accountname: '',
    AssociationType: '',
    AsssociatedAccountName: '',
    AssociatedAccountid: '',
    Service: '',
    IsViewReport: '',
    isPcEnabled: '',
    AllowReportViewing: '',
    Selecttype: '',
    accFilterCtrl: '',
    associateOrg: new FormControl(),
    item: [],
  });

  dropdownSettingsAssc: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
  };
  customOrgVal: any = [];
  public setForm() {
    this.formGroup = new FormGroup({
      id: new FormControl(this.association, Validators.required),
    });
    // this.loadContent = true;
  }
  get getformDetails() {
    return this.formGroup.controls;
  }



  public GetQuery(keyword: string) {
    // let query: string = '';
    let mainQueryIndex: number = this.vitalHttpServices.mainQueryList.findIndex(
      (mainQuery) =>
        mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase()
    );
    if (mainQueryIndex > -1)
      return this.vitalHttpServices.mainQueryList[mainQueryIndex].Query;

  }

  //#region to get the account to an organization based on the association type
  onOrganizationChange(newValue: MatChipInputEvent, item) {
      newValue = this.temporgid;
        this.accountsOfSelectedOrg = cloneDeep(this.locationsList.filter(
          (va) => va.organizationid == newValue && va.accountname != null
        ));

      this.accountsOfSelectedOrg.forEach(va => va.accountname += ' (' + va.accountid + ')')
      this.AccountsAdded = [];
      this.AddMultiselect = true;
      this.UpdateMultiselect = false;
      if (newValue) {
        this.recipientData.patchValue({
          AssociatedAccountid: ''
        }
        )
      }
  }

  changingOrganizationAndAccount(value) {
    this.locationsList=[];
    this.organizationsList=[];
    this.recipientData.patchValue({ Selecttype: value })
    if (value == 'Accounts') {
      this.recipientData.patchValue({
        AssociatedAccountid: '',
      });
    }
    else {
      this.recipientData.patchValue({
        AssociatedAccountid: '',
        associateOrg: '',
      });
    }
  }

  //#region  deleting the row of the grid
  deleteRowFromGrid(grid, row) {
    this.ngxService.start();
    this.addeddata.splice(
      this.addeddata.findIndex(
        (e) => e.associatedAccountID === row.dataItem.associatedAccountID && e.AssociationType == row.dataItem.AssociationType
      ),
      1
    );
    this.AssociationDetails.splice(
      this.AssociationDetails.findIndex(
        (e) => e.associatedAccountID === row.dataItem.associatedAccountID && e.AssociationType == row.dataItem.AssociationType
      ),
      1
    );

    // this.AssociationDetails.filter((va) => va.associatedAccountID != row.dataItem.associatedAccountID)
    // if(this.AssociationDetails[i].associatedAccountID==row.dataItem.associatedAccountID){
    //   delete this.AssociationDetails[i]
    // }
    this.assocGridData = new CollectionView(this.addeddata, { pageSize: 10 });
    if (this.addeddata.length > 10) {
      this.showPaginationMainGrid = true;
    } else {
      this.showPaginationMainGrid = false;
    }
    // this.updateButton=false;
    // this.addbutton=true;
    // this.UpdateAssociationModel = false;
    this.associationModel = true;
    if (this.recipientData.controls.AssociationType.value != '') {
      this.UpdateMultiselect = false;
      this.AddMultiselect = true;
    } else {
      this.UpdateMultiselect = true;
      this.AddMultiselect = false;
    }
    this._snackbar.open('Row deleted successfully!', "Close");
    //this.getpatchdata();
    this.ngxService.stop();
  }
  //#endregion

  getOrganizationName(value) {
    if (value.source.selected) {
      let tempAccName = value.source.value.split('(')[0];
      this.temporgid = value.source.value.split('(')[1].split(')')[0];
      if (this.temporgid != 'null') {
        this.recipientData.patchValue({
          // AssociatedAccountid:value.option.value
          associateOrg: value.source.value
        });
      }
      else {
        this.recipientData.patchValue({
          // AssociatedAccountid:value.option.value
          associateOrg: ''
        });
      }
    }
  }


  deleteAssociation(action): any {
    if (action == 'save') {
      this.existingData = this.existingData.filter(va => va.associationid != this.deleteData.associationid)
      this.existingGridData = new CollectionView(this.existingData, {
        pageSize: 10,
      });
      this.ngxService.start();
      this.vaservices.deleteAssociation(this.deleteData.associationid, sessionStorage.getItem("DeploymentKey")).subscribe(data => {
        if (data.Success) {
          this.ngxService.stop();
          this._snackbar.open(data.Message, "Close")
        }
      }, error => {
        console.error(error)
        this.ngxService.stop();
      });
    }
    this.deleteAssoc = false;

  }

  selectAssociation(newValue, item) {
    // this.updateButton=false;
    this.ngxService.start();
    // this.getpatchdata();
    // this.addbutton=true;
    this.recipientData.patchValue({
      associateOrg: '',
      Service: 'None',
      AssociatedAccountid: '',
      AssociationType: item.value,
      Accountname: this.AccountName,
      AllowReportViewing: false,
      isPcEnabled: false,
      accFilterCtrl: '',
    });
    if (newValue != null) {
      this.associationModel = true;
      this.AddMultiselect = true;
      this.UpdateMultiselect = false;
    }
    this.locationsList=[]
    this.organizationsList=[]
    this.ngxService.stop();
  }


  clearResults(value, evt: any, trigger: MatAutocompleteTrigger) {
    this.locationsList=[];
    this.organizationsList=[]
    if (value == 'searchAccount') {
      this.searchAccount = ''
    }
    else if (value == 'AssociatedAccountid') {
      this.recipientData.patchValue({
        AssociatedAccountid: ''
      })
    }
    else if (value == 'associateOrg') {
      this.recipientData.patchValue({
        associateOrg: ''
      })
    }
    // this.search.nativeElement.input();
    evt.stopPropagation();
    // if (trigger) {
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }

  public filterOFOrgByaName(name) {
    let arr = this.OFORganizations.filter(
      (oforg) =>
        oforg.organizationname != null && !(Math.sign(oforg.organizationid) == -1) &&
        oforg.organizationname.toString().toLowerCase().includes(name.toString().toLowerCase().split('(')[0].trim()));
    return arr.length ? arr.splice(0, 50) : [{ organizationname: 'No Item found', organizationid: 'null' }];
  }

  filterOfAccountByaName(name) {
    let arr = this.ofAccountDetails.filter(
      (Account) =>
        Account.accountname != null && !(Math.sign(Account.accountid) == -1) &&
        Account.accountname.toString().toLowerCase().includes(name.toString().toLowerCase().split('(')[0].trim())
    );
    return arr.length ? arr.splice(0, 50) : [{ accountname: 'No Item found', accountid: 'null' }];
  }

  public filterLabOrgByaName(name) {
    let arr = this.labOrganizations.filter(
      (laborg) =>
        laborg.organizationname != null && !(Math.sign(laborg.organizationid) == -1) &&
        laborg.organizationname.toString().toLowerCase().includes(name.toString().toLowerCase().split('(')[0].trim())
    );
    return arr.length ? arr.splice(0, 50) : [{ organizationname: 'No Item found', organizationid: 'null' }];
  }
  filterLabAccountByaName(name) {
    let arr = this.LabAccountDetails.filter(
      (Account) => Account.accountname != null && !(Math.sign(Account.accountid) == -1) &&
        Account.accountname.toString().toLowerCase().includes(name.toString().toLowerCase().split('(')[0].trim())
    );
    return arr.length ? arr.splice(0, 50) : [{ accountname: 'No Item found', accountid: 'null' }];
  }
  public filterEpgOrgByaName(name) {
    let arr = this.epgOrgDetails.filter(
      (epgorg) =>
        epgorg.organizationname != null && !(Math.sign(epgorg.organizationid) == -1) &&
        epgorg.organizationname.toString().toLowerCase().includes(name.toString().toLowerCase().split('(')[0].trim())
    );
    return arr.length ? arr.splice(0, 50) : [{ organizationname: 'No Item found', organizationid: 'null' }];
  }

  //#region the patching the form with empty values  for new Association
  addNewAssociation() {
    this.NoDataSetupNewPage = false;
    this.ShowLocationGrid = false;
    this.ngxService.start();
    this.dropdownSettingsAssc = {
      singleSelection: false,
      idField: 'accountid',
      textField: 'accountname',
      enableCheckAll: true,
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 150,
      itemsShowLimit: 44,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };
    sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
    this.ngxService.start();
    this.accountName = this.entityType ? this.accountName : this.epgAccountDetails.filter((va) => va.accountid == this.accountid);
    this.associationModel = true;
    this.UpdateMultiselect = false;
    this.AddMultiselect = true;
    this.ShowLocationGrid = false;
    this.cancelButton = true;
    this.AccountsAdded = [];
    this.recipientData.patchValue({
      AssociationType: (this.entityType && this.entityType.toString().toLowerCase() != 'lab') ? this.getAssociationType(this.entityType) : '',
      associateOrg: '',
      Service: 'None',
      AssociatedAccountid: '',
      AllowReportViewing: false,
      isPcEnabled: false,
      accFilterCtrl: '',
      Selecttype: this.entityType ? 'Accounts' : '',
    })
    if (this.ifEdit) {
      this.fetchExistingAssociations(this._parseAccountID.toString());
    }
    this.ngxService.stop();
  }


  setPathAssociations(data){
    data.forEach(val=> {
      let tempVar;
      tempVar = val.associatedAccountID
      val.accountid = val.associatedAccountID
      val.accountid = tempVar;
      
      tempVar = val.AccountName
      val.AccountName = val.AsssociatedAccountName
      val.AsssociatedAccountName = tempVar;

    })
    return data;
  }

  async fetchExistingAssociations(accountid) {
    let query = this.GetQuery('getAllAssociations');
    let queryVariable : any = {
      accid: accountid,
      associd : null
    };
    if(this.commonService.isPol && this.entityType.toString().toLowerCase() == 'rl')
    {
      queryVariable.accid = null;
      queryVariable.associd = String(this._parseAccountID);
    }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.vaservices.GetData(queryResult).toPromise().then((Resdata) => {
      if (!Resdata.errors) {
        let data = Resdata.data.submenuData;
        if(this.commonService.isPol && this.entityType.toString().toLowerCase() == 'rl')
          data = this.setPathAssociations(data);
        data = data.filter(va => va.AssociationType != 'My Organization')
        data.length > 0 ? this.showExistingAssociations(data) : null;
      }
      this.ngxService.stop();
    });
    (error) => {
      this.ngxService.stop();
      this._snackbar.open('An error occurred while processing your request', 'Failed');
    };
  }

  showExistingAssociations(data) {
    this.associationTable = true;
    this.existingData = data;
    this.existingGridData = new CollectionView(data, {
      pageSize: 10,
    });
    if (this.existingData.length > 10) {
      this.showPaginationExistAssocGrid = true;
    } else {
      this.showPaginationExistAssocGrid = false;
    }
  }

  //#region - Function to filter association types based on entity type
  setAssociationTypeList(associationTypes) {
    const filteredArray = associationTypes.filter(type => {
        const lowercaseType = type['item'].toString().toLowerCase();
        if (this.entityType.toLowerCase() === 'lab') {
          return lowercaseType !== 'laboratories';
        } else if(this.entityType.toLowerCase() === 'of'){
          this.recipientData.patchValue({
            AssociationType: 'Laboratories'
          });
          return lowercaseType === 'laboratories';
        }
        else {
          this.recipientData.patchValue({
            AssociationType: 'Pathologists'
          });
          return lowercaseType === 'pathologists';
        }
    });
    return filteredArray;
  }
  //#endregion


  //#region Dropdowns like Services and Association types
  async getAssociationTypesDropdown() {
    // let deploymentkey = sessionStorage.getItem('deploymentKey');
    let query = this.GetQuery('CategoryDropDown');
    let queryVariable = {
      keyword: 'AssociationTypes',
      context: 'AssociatedAccounts',
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.vitalHttpServices.GetData(queryResult, 'configdb').toPromise().then(async (Resdata) => {

      if (!Resdata.errors) {
        if (Resdata.data.submenuData.length > 0) {
          this.serviceDataMap = [];
          let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
          this.associationTypeList = this.entityType ? this.setAssociationTypeList(data.AssociationTypes) : data.AssociationTypes;
          this.servicesList = data.Services;
            this.servicesList.forEach(element => {
              this.serviceDataMap.push(element.item);
            });
            await this.getAccountName();
          // this.servicesList = data.Services;
        }
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        //  this.getAccountName();
      }
      this.ngxService.stop();
    }, (error) => {
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      this.ngxService.stop();
      console.error(error);
    });

  }


  //#region getting the Account details
  async getAccountName() {
    let query = this.GetQuery('accountDetails');
    let queryVariable = {
      context: 'AssociatedAccounts',
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.vitalHttpServices.GetData(queryResult).toPromise().then((Resdata) => {
      this.ngxService.start();
      if (!Resdata.errors) {
        this.ngxService.stop();
        this.epgAccountDetails = Resdata.data.submenuData;
      }
    }), (error) => {
      this.ngxService.stop();

      this.epgAccountDetails = [];
      this._snackbar.open('An error occurred while processing your request ', 'Failed');
    };

  }
  //#endregion

  //#region  validation the field
  validateDetails(associationDetails) {
    if (!associationDetails.AssociatedAccountid || associationDetails.AssociatedAccountid == '') {
      return false;
    } else if (
      !associationDetails.AssociationType || associationDetails.AssociationType == '') {
      return false;
    }
    return true;
  }

  getAssociationType(entityType){
    if(entityType.toLowerCase() == 'of')
      return 'Laboratories';
    else 
      return 'Pathologists';
  }

  //#region  function for patching the initial  value
  getpatchdata() {
    this.locationsList = [];
    this.recipientData.patchValue({
      AssociationType: (this.entityType && this.entityType.toString().toLowerCase() != 'lab') ? this.getAssociationType(this.entityType) : '',
      associateOrg: '',
      Service: 'None',
      AssociatedAccountid: '',
      Accountname: this.accountName,
      AllowReportViewing: false,
      isPcEnabled: false,
      accFilterCtrl: '',
      Selecttype: this.entityType ? 'Accounts' : '',
    });
  }

  onSelectAll(items: any) {
    this.AccountsAdded = [];
    for (let itr = 0; itr < items.length; itr++) {
      this.AccountsAdded.push(items[itr].accountid);
    }
  }

  //#region Adding to the grid
  addAssociationList(associatedData) {
    this.ngxService.start();
    this.cancelButton = false;
    // this.addeddata = [];
    associatedData.AssociatedAccountid = this.tempAccId;
    this.serviceDataMap = [];
    this.servicesList.forEach(element => {
      this.serviceDataMap.push(element.item);
    });
    associatedData.associateOrg = this.temporgid;
    if (associatedData.Selecttype == 'Organizations') {
      if (this.AccountsAdded.length !== 0) {
        for (let i = 0; i < this.AccountsAdded.length; i++) {
          associatedData.AssociatedAccountid = this.AccountsAdded[i];
          //this.validatedetails(associatedData);
          if (this.validateDetails(associatedData)) {
            this.ExistData = false;
            this.templateData.submenuData;

            let existDataInList = [];
            if (this.addeddata && this.addeddata.length > 0) {
              existDataInList = this.addeddata.filter(
                (va) =>
                  va.associatedAccountID == associatedData.AssociatedAccountid
              );
              this.AssociationDetails = [...this.addeddata];
            }

            if (existDataInList) {
              if (existDataInList.length >= 1) {
                this.ExistData = true;
              }
            }
            var filtereddata = this.epgAccountDetails.filter(
              (va) => va.accountid == associatedData.AssociatedAccountid
            );

            if (filtereddata.length >= 1) {
              this.AssociatedOrgid = filtereddata[0].organizationid;
            }

            this.AsssociatedAccountName = this.epgAccountDetails.filter(
              (va) => va.accountid == associatedData.AssociatedAccountid
            );

            this.AsssociatedOrgName = this.AssociatedOrgaDetails.filter(
              (va) => va.organizationid == this.AssociatedOrgid
            );
            //
            // OForganizationID
            var obj = {
              accountid: sessionStorage.getItem('Locationid'),
              associatedAccountID: associatedData.AssociatedAccountid.toString().split('(')[0].trim(),
              AsssociatedAccountName: this.AsssociatedAccountName[0].accountname,
              organizationID: this.orgid ? this.orgid : sessionStorage.getItem("org_id"),
              services: associatedData.Service,
              AccountName: this.accountName,
              AssociationType: (this.entityType && this.entityType.toString().toLowerCase() != 'lab') ? this.getAssociationType(this.entityType) : associatedData.AssociationType,
              AsssociatedOrgName: this.AsssociatedOrgName[0].organizationname,
              AssociatedOrgid: this.AssociatedOrgid,
              isPcEnabled: associatedData.isPcEnabled != null ? associatedData.isPcEnabled : false,
              AllowReportViewing: associatedData.AllowReportViewing != null ? associatedData.AllowReportViewing : false,
              Selecttype: associatedData.Selecttype,
              userid: sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : '-100',
            };


            // this.AssociationDetails(obj);
            if (this.ExistData != true) {
              this.addeddata = [obj, ...this.AssociationDetails];
              this.assocGridData = new CollectionView(this.addeddata, {
                pageSize: 10,
              });
              this.AssociationDetails.push(obj);
              if (i >= this.AccountsAdded.length - 1) {
                this.getpatchdata();
              }
              // this.UpdateAssociationModel = false;
              this.associationModel = true;
              this.UpdateMultiselect = false;
              this.AddMultiselect = true;
              this.associationTable = true;

              if (this.addeddata.length > 10) {
                this.showPaginationNewAssocGrid = true;
              } else {
                this.showPaginationNewAssocGrid = false;
              }
            } else {
              obj = null;
              this._snackbar.open('"' + this.AsssociatedAccountName[0].accountname + '"' + ' is already selected for association with the type: ' + '"' + existDataInList[0].AssociationType + '"', 'Close');
            }
          } else {
            this._snackbar.open('Insert valid data', 'Failed');
          }
        }
      }
      else {
        this._snackbar.open('Select atleast one account', 'Failed');
      }
      this.AccountsAdded = [];
    }
    else {
      this.ExistData = false;
      this.templateData.submenuData;
      if (this.validateDetails(associatedData)) {
        let existDataInList = [];
        if (this.addeddata && this.addeddata.length > 0) {
          existDataInList = this.addeddata.filter(
            (va) =>
              va.associatedAccountID == associatedData.AssociatedAccountid
          );
          this.AssociationDetails = [...this.addeddata];
        }

        if (existDataInList) {
          if (existDataInList.length >= 1) {
            this.ExistData = true;
          }
        }
        var filtereddata = this.epgAccountDetails.filter(
          (va) => va.accountid == associatedData.AssociatedAccountid
        );

        if (filtereddata.length >= 1) {
          this.AssociatedOrgid = filtereddata[0].organizationid;
        }

        this.AsssociatedAccountName = this.epgAccountDetails.filter(
          (va) => va.accountid == associatedData.AssociatedAccountid
        );

        this.AsssociatedOrgName = this.AssociatedOrgaDetails.filter(
          (va) => va.organizationid == this.AssociatedOrgid
        );
        //
        // OForganizationID
        if (this.templateData.GroupData.OrganizationId) {
          this.orgid = this.templateData.GroupData.OrganizationId;
        }
        else {
          this.orgid = sessionStorage.getItem('org_id');
        }
        var obj = {
          accountid: sessionStorage.getItem('Locationid'),
          associatedAccountID: associatedData.AssociatedAccountid.toString().split('(')[0].trim(),
          AsssociatedAccountName: this.AsssociatedAccountName[0].accountname,
          organizationID: this.orgid,
          services: associatedData.Service,
          AccountName: this.accountName,
          AssociationType: associatedData.AssociationType,
          AsssociatedOrgName: this.AsssociatedOrgName[0].organizationname,
          AssociatedOrgid: this.AssociatedOrgid,
          isPcEnabled: associatedData.isPcEnabled != null ? associatedData.isPcEnabled : 0,
          AllowReportViewing: associatedData.AllowReportViewing != null ? associatedData.AllowReportViewing : 0,
          Selecttype: associatedData.Selecttype,
          userid: sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : '-100',
        };

        // this.AssociationDetails(obj);
        if (this.ExistData != true) {
          this.addeddata = [obj, ...this.AssociationDetails];
          this.assocGridData = new CollectionView(this.addeddata, { pageSize: 10 });
          this.AssociationDetails.push(obj);
          this.getpatchdata();
          this.associationModel = true;
          this.UpdateMultiselect = false;
          this.AddMultiselect = true;
          this.associationTable = true;
          if (this.addeddata.length > 10) {
            this.showPaginationMainGrid = true;
          } else {
            this.showPaginationMainGrid = false;
          }
        } else {
          obj = null;
          this._snackbar.open('"' + this.AsssociatedAccountName[0].accountname + '"' + ' is already selected for association with the type: ' + '"' + existDataInList[0].AssociationType + '"', 'Close');
        }
      } else {
        this._snackbar.open('Insert valid data', 'Failed');
      }
    }
    this.ngxService.stop();
  }
  //#endregion

  backtoLocGrid() {
    this.ngxService.start();
    if (this.locGridWidth > 0) {
      this.ShowLocationGrid = true;
      this.NoDataSetupNewPage = false;
    }
    else {
      this.ShowLocationGrid = false;
      this.NoDataSetupNewPage = true;
    }
    this.ShowSetupNewLocation = false;
    this.ShowGridItemDetails = false;
    this.associationModel = false;
    this.addeddata = [];
    this.gridDataAssc = null;
    this.AssociationDetails = [];
    this.recipientData.reset();
    this.ngxService.stop();
  }

  //#region  Funtion for back button to show the initial grid
  backAssociation() {
    this.ngxService.start();
    if (this._parseAccountID) {
      if (this.locGridWidth > 0) {
        this.ShowLocationGrid = true;
        this.NoDataSetupNewPage = false;
      }
      else {
        this.ShowLocationGrid = false;
        this.NoDataSetupNewPage = true;
      }
      this.ShowSetupNewLocation = false;
      this.ShowGridItemDetails = false;
    }
    // this.UpdateAssociationModel = false;
    this.associationModel = false;
    this.addeddata = [];
    this.gridDataAssc = null;
    this.AssociationDetails = [];
    this.recipientData.reset();
    this.ngxService.stop();
  }

  removeDuplicates(arr) {
    let unique_array = []
    for (let i = 0; i < arr.length; i++) {
      if (unique_array.indexOf(arr[i]) == -1) {
        unique_array.push(arr[i])
      }
    }
    return unique_array
  }


  //#region for multislect dropdown operations
  onCheckChange(item: any) {
    this.AccountsAdded.push(item.accountid);
  }

  onItemDeSelectAssc(items: any) {
    const index: number = this.AccountsAdded.indexOf(items.accountid);
    if (index !== -1) {
      this.AccountsAdded.splice(index, 1);
    }
  }

  // --**************** Add association region ends *********************//


  ngOnDestroy() {
    this.staticBackdrop = false;
  }


  async ngOnInit() {
    try{
      this.ngxService.start('entityLoad');
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      this.ContextType = sessionStorage.getItem("context");
      this.GetButtondetails();
      this.addLocationGridData(true);
      await this.locationItemOnClick(null, null, sessionStorage.getItem('Locationid'));
      let card = sessionStorage.getItem('search_context');
      // if the context is lab the set to ifNotLabEntity , that is to hide Diagnosis Reporting done using
      //  VitalDx(VitalOffice) and Allow lab to view reports checkboxes
      if (card.toLowerCase() == 'lab') {
        this.ifNotLabEntity = false;
      } else {
        this.ifNotLabEntity = true;
      }
      let tempDropdownList = this.vitalHttpServices.locationDropdownValues;
      this.ClaimOptionsArray = tempDropdownList.ClaimOptions;
      this.StatesArray = tempDropdownList.States;
      this.UsernameDisplayFormatArray = tempDropdownList.UserNameDisplayFormat;
      this.UsernameReportFormatArray = tempDropdownList.UserNameReportFormat;
      this.ServiceArray = tempDropdownList.Service;
      this.GroupCode = (!this.tabService.tabs[0].tabData.mainCard.Group_Code) ? 'Not Specified' : this.tabService.tabs[0].tabData.mainCard.Group_Code;
      if (this.templateData.cardtype.toLowerCase() == 'facility') {
        this.AddLocRecipientData.patchValue(
          {
            State: "",
            UsernameDisplayformat: "",
            UsernameReportformat: "",
            ClaimOptions: this.ClaimOptionsArray[0].item,
            Status: true,
            NPI: 'UNKNOWN',
            IsListedAsOF: true
          });
      } else {
        this.AddLocRecipientData.patchValue(
          {
            State: "",
            UsernameDisplayformat: "",
            UsernameReportformat: "",
            ClaimOptions: this.ClaimOptionsArray[0].item,
            Status: true,
            NPI: 'UNKNOWN',
          });
      }
    }
    catch(error){
      console.error(error);
    }
    finally{
      this.ngxService.stop('entityLoad')
    }

  }




  //#region View Grid Locations for selected Organization
  addLocationGridData(init) {
    this.NoDataSetupNewPage = false;
    if (this.updateGridFlag) {
      this.templateData.submenuData = this.tempSubmenudata;
      this.templateData.submenuData.find(e => {
        if (this._parseAccountID == e.accountid) {
          this.displaynameHeader = e.displayname;
        }
      })
    }
    this.GridLocArray = [];
    let primary = {};
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            this.gridHeader.find(e => {
              if (key === e) {
                primary[key] = value.toString().trim() == '' ? 'Not Specified' : value;
              }
            });
          }
          this.GridLocArray.push(primary)
        }
        // }
        this.gridData = this.commonService.isPol ? new CollectionView(this.GridLocArray, { pageSize: 10, groupDescriptions:  ['Type'] }) : new CollectionView(this.GridLocArray, { pageSize: 10 })
        this.locGridWidth = (170 * this.gridHeader.length) + 37;
        if (this.locGridWidth > 1300)
          this.locGridWidth = 1300;
        if (this.locGridWidth != 0) {
          this.locGridWidth = this.getpercentage(this.locGridWidth)
        }
        else {
          if (!init) {
            this.NoDataSetupNewPage = true;
            this.locGridWidth = 0;
          }

        }
      }
      else {
        if (!init) {
          this.NoDataSetupNewPage = true;
          this.locGridWidth = 0;
        }
      }
    } else {
      if (!init) {
        this.NoDataSetupNewPage = true;
        this.locGridWidth = 0;
      }
    }
  }
  //#endregion


  //#region
  //Function to get AssociatedAccounts
  async getAssociatedAccounts(accId) {

    this.assocAccounts = [];
    this.assocUsers = [];
    this.assocCaseTypes = [];
    if (this.UsersArray != undefined) {
      this.UsersArray = [];
    }
    if (this.CasetypeArray != undefined) {
      this.CasetypeArray = [];
      this.bigUserArray = [];
    }
    if (this.selectedCasetype != undefined) {
      this.selectedCasetype = [];
    }

    this.arrUsers = [];
    sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
    this.ngxService.start("5");
    // this.vaservices.GetData(drpqueryRequest).subscribe(result => {
    await this.vaservices.getAssociatedDataLocation(accId.toString(), this.vitalHttpServices.deploymentKey).toPromise().then(async result => {
      this.ngxService.stop("5");
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      if (!result.errors) {
        let accArr = result[0].AssociatedAccount;
        this.accArray = accArr;
        let caseArr = result[1].AssociatedCaseTypes;
        let usrArr = result[2].AssociatedUser;
        let arr = [];
        if (accArr.length > 0) {
          for (var i = 0; i < accArr.length; i++) {
            arr.push(accArr[i].associatedaccountnames)
          }
          const unique = this.removeDuplicates(arr);
          this.assocAccounts = unique;
          if (this.assocAccounts.length > 0) {
            this.isDisabled = true;
            this.ifAssocEdit = true;
          }
          else {
            this.isDisabled = false;
            this.ifAssocEdit = false;
          }
        } else {
          this.isDisabled = false;
          this.ifAssocEdit = false;
        }
        if (usrArr.length > 0) {
          let arr = [];
          for (var i = 0; i < usrArr.length; i++) {
            arr.push(usrArr[i].username + '(' + usrArr[i].rolename + ')')
          }
          this.assocUsers = arr;
          if (this.assocUsers.length > 0) {
            this.ifUsersEdit = true;
          } else {
            this.ifUsersEdit = false;
          }
          this.UsersArray = usrArr;
          this.bigUserArray = usrArr;
        } else {
          this.ifUsersEdit = false;
        }
        if (caseArr.length > 0) {

          let csarr = [];
          for (var i = 0; i < caseArr.length; i++) {
            csarr.push(caseArr[i].casetype)
          }
          const unique = this.removeDuplicates(csarr);
          this.assocCaseTypes = unique;
          this.CasetypeArray = caseArr;
          if (this.assocCaseTypes.length > 0) {
            this.ifCaseTypeEdit = true;
            this.selectedCasetype = this.assocCaseTypes;
          } else {
            this.ifCaseTypeEdit = false;
            this.selectedCasetype = [];
          }
        } else {
          this.ifCaseTypeEdit = false;
        }
        this.existingData = [];
        await this.fetchExistingAssociations(this._parseAccountID.toString());
      }
    }, error => {
      console.error(error);
      this.ngxService.stop("5");
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
    });



  }
  //#endregion

//#region // Function to set entity type on details click 
setEntityType(dataItem) {
  let entityType = ''; // Default value

  const normalizeValue = dataItem.Type.toString().toLowerCase();
  const polAssociations = ['laboratories', 'ordering facilities', 'reading locations'];

  if (dataItem.Type) {
      if (normalizeValue === polAssociations[0]) {
          entityType = 'Lab';
      } else if (normalizeValue === polAssociations[1]) {
          entityType = 'OF';
      } else if (normalizeValue === polAssociations[2]) {
          entityType = 'RL';
      }
  }

  return entityType;
}
//#endregion

  //#region Location detailed View Page
  async locationItemOnClick(gridguid, e, parentLocationID) {
    let dataItem;
    if (gridguid != null) {
      let hti = gridguid.hitTest(e);
      if (hti.panel === gridguid.cells) {
        let row = gridguid.rows[hti.row]
        if(this.commonService.isPol){
          if(row.hasChildren) 
          return;
        }
        
        dataItem = row.dataItem;
        if (!dataItem) {
          return;
        }
        else {
          this.ShowLocationGrid = false;
          this.ShowGridItemDetails = true;
          if(this.commonService.isPol)
            this.entityType = this.setEntityType(dataItem) || "";
            this.selAccountid = dataItem.accountid;
            this._parseAccountID = this.selAccountid;
            this.locGridRowArray = [];
            this.locGridRowArray = this.templateData.submenuData.filter(
              data => data.accountid == dataItem.accountid
            );
            // await this.getAssociatedAccounts(dataItem.accountid);
            await this.getexistinglogo(dataItem.accountid);

            this.DataShare.accountMnemonic = this.locGridRowArray[0].mnemonic;
            this.locGridRowArray[0].claimmnemonic = this.locGridRowArray[0].claimmnemonic ?? this.locGridRowArray[0].claimmnemonic.trim();
            this.locGridRowArray[0].EnableSendOutCheckBox = (this.locGridRowArray[0].EnableSendOutCheckBox == true || this.locGridRowArray[0].EnableSendOutCheckBox == 'True' || this.locGridRowArray[0].EnableSendOutCheckBox == 'Active' || this.locGridRowArray[0].EnableSendOutCheckBox == 'active' || this.locGridRowArray[0].EnableSendOutCheckBox == 'Yes') ? this.locGridRowArray[0].EnableSendOutCheckBox = 'Yes' : this.locGridRowArray[0].EnableSendOutCheckBox = 'No';
            this.locGridRowArray[0].FaxEnable = (this.locGridRowArray[0].FaxEnable == true || this.locGridRowArray[0].FaxEnable == 'True' || this.locGridRowArray[0].FaxEnable == 'Active' || this.locGridRowArray[0].FaxEnable == 'active' || this.locGridRowArray[0].FaxEnable == 'Yes') ? this.locGridRowArray[0].FaxEnable = 'Yes' : this.locGridRowArray[0].FaxEnable = 'No';
            this.locGridRowArray[0].IsListedAsOF = (this.locGridRowArray[0].IsListedAsOF == true || this.locGridRowArray[0].IsListedAsOF == 'True' || this.locGridRowArray[0].IsListedAsOF == 'Active' || this.locGridRowArray[0].IsListedAsOF == 'active' || this.locGridRowArray[0].IsListedAsOF == 'Yes') ? this.locGridRowArray[0].IsListedAsOF = 'Yes' : this.locGridRowArray[0].IsListedAsOF = 'No';
            this.locGridRowArray[0].IsSharedSurgicenter = (this.locGridRowArray[0].IsSharedSurgicenter == true || this.locGridRowArray[0].IsSharedSurgicenter == 'True' || this.locGridRowArray[0].IsSharedSurgicenter == 'active' || this.locGridRowArray[0].IsSharedSurgicenter == 'Active' || this.locGridRowArray[0].IsSharedSurgicenter == 'Yes') ? this.locGridRowArray[0].IsSharedSurgicenter = 'Yes' : this.locGridRowArray[0].IsSharedSurgicenter = 'No';
            this.locGridRowArray[0].status = (this.locGridRowArray[0].status == 'Yes' || this.locGridRowArray[0].status == 'active' || this.locGridRowArray[0].status == 'Active' || this.locGridRowArray[0].status == 'True' || this.locGridRowArray[0].status == true) ? this.locGridRowArray[0].status = 'Active' : this.locGridRowArray[0].status = 'InActive';

            Object.keys(this.locGridRowArray[0]).forEach(key => {
              if (!this.locGridRowArray[0][key] || this.locGridRowArray[0][key].toString().trim() == '') {
                this.locGridRowArray[0][key] = 'Not Specified';
              }
            });
        }
      }
    } else {
      this.ShowLocationGrid = false;
      this.ShowGridItemDetails = true;
      this.locGridRowArray = [];
      if(!this.templateData.submenuData){
        this.back();
      }
      this.locGridRowArray = this.templateData.submenuData.filter(
        data => data.accountid == parentLocationID
      );
      if(this.commonService.isPol)
        this.entityType = this.setEntityType(this.locGridRowArray[0]) || "";
      if (parentLocationID != undefined) {
        this.selAccountid = parentLocationID;
        this._parseAccountID = this.selAccountid;
        // await this.getAssociatedAccounts(parentLocationID);
        await this.getexistinglogo(parentLocationID);
        this.DataShare.accountMnemonic = this.locGridRowArray[0].mnemonic;
        this.locGridRowArray[0].claimmnemonic = this.locGridRowArray[0].claimmnemonic ?? this.locGridRowArray[0].claimmnemonic.trim();
        this.locGridRowArray[0].EnableSendOutCheckBox = (this.locGridRowArray[0].EnableSendOutCheckBox == true || this.locGridRowArray[0].EnableSendOutCheckBox == 'True' || this.locGridRowArray[0].EnableSendOutCheckBox == 'Active' || this.locGridRowArray[0].EnableSendOutCheckBox == 'active' || this.locGridRowArray[0].EnableSendOutCheckBox == 'Yes') ? this.locGridRowArray[0].EnableSendOutCheckBox = 'Yes' : this.locGridRowArray[0].EnableSendOutCheckBox = 'No';
        this.locGridRowArray[0].FaxEnable = (this.locGridRowArray[0].FaxEnable == true || this.locGridRowArray[0].FaxEnable == 'True' || this.locGridRowArray[0].FaxEnable == 'Active' || this.locGridRowArray[0].FaxEnable == 'active' || this.locGridRowArray[0].FaxEnable == 'Yes') ? this.locGridRowArray[0].FaxEnable = 'Yes' : this.locGridRowArray[0].FaxEnable = 'No';
        this.locGridRowArray[0].IsListedAsOF = (this.locGridRowArray[0].IsListedAsOF == true || this.locGridRowArray[0].IsListedAsOF == 'True' || this.locGridRowArray[0].IsListedAsOF == 'Active' || this.locGridRowArray[0].IsListedAsOF == 'active' || this.locGridRowArray[0].IsListedAsOF == 'Yes') ? this.locGridRowArray[0].IsListedAsOF = 'Yes' : this.locGridRowArray[0].IsListedAsOF = 'No';
        this.locGridRowArray[0].IsSharedSurgicenter = (this.locGridRowArray[0].IsSharedSurgicenter == true || this.locGridRowArray[0].IsSharedSurgicenter == 'True' || this.locGridRowArray[0].IsSharedSurgicenter == 'active' || this.locGridRowArray[0].IsSharedSurgicenter == 'Active' || this.locGridRowArray[0].IsSharedSurgicenter == 'Yes') ? this.locGridRowArray[0].IsSharedSurgicenter = 'Yes' : this.locGridRowArray[0].IsSharedSurgicenter = 'No';
        this.locGridRowArray[0].status = (this.locGridRowArray[0].status == 'Yes' || this.locGridRowArray[0].status == 'active' || this.locGridRowArray[0].status == 'Active' || this.locGridRowArray[0].status == 'True' || this.locGridRowArray[0].status == true) ? this.locGridRowArray[0].status = 'Active' : this.locGridRowArray[0].status = 'InActive';

        Object.keys(this.locGridRowArray[0]).forEach(key => {
          if (!this.locGridRowArray[0][key] || this.locGridRowArray[0][key].toString().trim() == '') {
            this.locGridRowArray[0][key] = 'Not Specified';
          }
        });

      } else {
        console.log('Data does not exist!')
      }
    }
    this.ngxService.start();
    await this.getCustomorgAttribute();
    await this.getCustomAttrValues(undefined, this._parseAccountID, undefined);
  }
  //#endregion

  //#region detailed view page for created location
  async viewLocation() {
    await this.getCustomorgAttribute();
    await this.getCustomAttrValues(undefined, this._parseAccountID.toString(), undefined);
    this.addeddata.length = 0;
    this.AssociationDetails.length = 0;
    if (!this.ifEdit) {
      if (!this._parseAccountID) {
        if (this.locGridWidth > 0) {
          this.ShowLocationGrid = true;
          this.NoDataSetupNewPage = false;
        }
        else {
          this.ShowLocationGrid = false;
          this.NoDataSetupNewPage = true;
        }
        this.ShowSetupNewLocation = false;
        this.ShowGridItemDetails = false;
      }
      else {
        this.locGridRowArray = [];
        this.locGridRowArray = this.templateData.submenuData.filter(
          data => data.accountid == this._parseAccountID
        );
        this.selAccountid = this._parseAccountID;
        this.getexistinglogo(this._parseAccountID);
        if (this.locGridRowArray.length) {
          this.locGridRowArray[0].claimmnemonic = this.locGridRowArray[0].claimmnemonic ?? this.locGridRowArray[0].claimmnemonic.trim();
          this.locGridRowArray[0].EnableSendOutCheckBox = (this.locGridRowArray[0].EnableSendOutCheckBox == true || this.locGridRowArray[0].EnableSendOutCheckBox == 'True' || this.locGridRowArray[0].EnableSendOutCheckBox == 'Active' || this.locGridRowArray[0].EnableSendOutCheckBox == 'active' || this.locGridRowArray[0].EnableSendOutCheckBox == 'Yes') ? this.locGridRowArray[0].EnableSendOutCheckBox = 'Yes' : this.locGridRowArray[0].EnableSendOutCheckBox = 'No';
          this.locGridRowArray[0].FaxEnable = (this.locGridRowArray[0].FaxEnable == true || this.locGridRowArray[0].FaxEnable == 'True' || this.locGridRowArray[0].FaxEnable == 'Active' || this.locGridRowArray[0].FaxEnable == 'active' || this.locGridRowArray[0].FaxEnable == 'Yes') ? this.locGridRowArray[0].FaxEnable = 'Yes' : this.locGridRowArray[0].FaxEnable = 'No';
          this.locGridRowArray[0].IsListedAsOF = (this.locGridRowArray[0].IsListedAsOF == true || this.locGridRowArray[0].IsListedAsOF == 'True' || this.locGridRowArray[0].IsListedAsOF == 'Active' || this.locGridRowArray[0].IsListedAsOF == 'active' || this.locGridRowArray[0].IsListedAsOF == 'Yes') ? this.locGridRowArray[0].IsListedAsOF = 'Yes' : this.locGridRowArray[0].IsListedAsOF = 'No';
          this.locGridRowArray[0].IsSharedSurgicenter = (this.locGridRowArray[0].IsSharedSurgicenter == true || this.locGridRowArray[0].IsSharedSurgicenter == 'True' || this.locGridRowArray[0].IsSharedSurgicenter == 'active' || this.locGridRowArray[0].IsSharedSurgicenter == 'Active' || this.locGridRowArray[0].IsSharedSurgicenter == 'Yes') ? this.locGridRowArray[0].IsSharedSurgicenter = 'Yes' : this.locGridRowArray[0].IsSharedSurgicenter = 'No';
          this.locGridRowArray[0].status = (this.locGridRowArray[0].status == 'Yes' || this.locGridRowArray[0].status == 'active' || this.locGridRowArray[0].status == 'Active' || this.locGridRowArray[0].status == 'True' || this.locGridRowArray[0].status == true) ? this.locGridRowArray[0].status = 'Active' : this.locGridRowArray[0].status = 'InActive';
          Object.keys(this.locGridRowArray[0]).forEach(key => {
            if (!this.locGridRowArray[0][key] || this.locGridRowArray[0][key].toString().trim() == '') {
              this.locGridRowArray[0][key] = 'Not Specified';
            }
          });
          this.getAssociatedAccounts(this._parseAccountID);
          this.ShowLocationGrid = false;
          this.ShowSetupNewLocation = false;
          this.ShowGridItemDetails = true;
        }
        else {
          this.ShowLocationGrid = true;
          this.ShowSetupNewLocation = false;
          this.ShowGridItemDetails = false;
        }
      }
    } else {
      this.ifEdit = false;

      this.selAccountid = this._parseAccountID;
      this.locGridRowArray = [];
      this.locGridRowArray = this.templateData.submenuData.filter(
        data => data.accountid == this._parseAccountID
      );
      this.getexistinglogo(this._parseAccountID);
      if (this.locGridRowArray) {

        this.locGridRowArray[0].claimmnemonic = this.locGridRowArray[0].claimmnemonic ?? this.locGridRowArray[0].claimmnemonic.trim();
        this.locGridRowArray[0].EnableSendOutCheckBox = (this.locGridRowArray[0].EnableSendOutCheckBox == true || this.locGridRowArray[0].EnableSendOutCheckBox == 'True' || this.locGridRowArray[0].EnableSendOutCheckBox == 'Active' || this.locGridRowArray[0].EnableSendOutCheckBox == 'active' || this.locGridRowArray[0].EnableSendOutCheckBox == 'Yes') ? this.locGridRowArray[0].EnableSendOutCheckBox = 'Yes' : this.locGridRowArray[0].EnableSendOutCheckBox = 'No';
        this.locGridRowArray[0].FaxEnable = (this.locGridRowArray[0].FaxEnable == true || this.locGridRowArray[0].FaxEnable == 'True' || this.locGridRowArray[0].FaxEnable == 'Active' || this.locGridRowArray[0].FaxEnable == 'active' || this.locGridRowArray[0].FaxEnable == 'Yes') ? this.locGridRowArray[0].FaxEnable = 'Yes' : this.locGridRowArray[0].FaxEnable = 'No';
        this.locGridRowArray[0].IsListedAsOF = (this.locGridRowArray[0].IsListedAsOF == true || this.locGridRowArray[0].IsListedAsOF == 'True' || this.locGridRowArray[0].IsListedAsOF == 'Active' || this.locGridRowArray[0].IsListedAsOF == 'active' || this.locGridRowArray[0].IsListedAsOF == 'Yes') ? this.locGridRowArray[0].IsListedAsOF = 'Yes' : this.locGridRowArray[0].IsListedAsOF = 'No';
        this.locGridRowArray[0].IsSharedSurgicenter = (this.locGridRowArray[0].IsSharedSurgicenter == true || this.locGridRowArray[0].IsSharedSurgicenter == 'True' || this.locGridRowArray[0].IsSharedSurgicenter == 'active' || this.locGridRowArray[0].IsSharedSurgicenter == 'Active' || this.locGridRowArray[0].IsSharedSurgicenter == 'Yes') ? this.locGridRowArray[0].IsSharedSurgicenter = 'Yes' : this.locGridRowArray[0].IsSharedSurgicenter = 'No';
        this.locGridRowArray[0].status = (this.locGridRowArray[0].status == 'Yes' || this.locGridRowArray[0].status == 'active' || this.locGridRowArray[0].status == 'Active' || this.locGridRowArray[0].status == 'True' || this.locGridRowArray[0].status == true) ? this.locGridRowArray[0].status = 'Active' : this.locGridRowArray[0].status = 'InActive';
        Object.keys(this.locGridRowArray[0]).forEach(key => {
          if (!this.locGridRowArray[0][key] || this.locGridRowArray[0][key].toString().trim() == '') {
            this.locGridRowArray[0][key] = 'Not Specified';
          }
        });
        this.getAssociatedAccounts(this._parseAccountID);
        this.ShowLocationGrid = false;
        this.ShowSetupNewLocation = false;
        this.ShowGridItemDetails = true;
      }
      else {
        this.ShowLocationGrid = true;
        this.ShowSetupNewLocation = false;
        this.ShowGridItemDetails = false;
      }
    }

  }
  //#endregion

  //#region on create button click execute the following fun()
  /* get all the dropdown datas used in Setup new Location Page */
  async createBtnClick(entity?) {
    if(entity)
      this.entityType = entity;   
    this.ifLocationSaved = false; 
    // if(this.vitalHttpServices.deploymentKey.toString().match(/thx/i)){
    //   this._snackbar.open('This feature is temporarily disabled.','Close');
    //   return;
    // }
    this.ifEdit = false;
    this.removeNewLogo();
    this.getCasetype();
    this.getUsers();
    this.getCustomorgAttribute();
    await this.getAssociatedLocationsForOrg();

    this._parseAccountID = 0;
    this.selAccountid = 0;
    this.CasetypeArray = [];
    this.ifLocEdit = false;
    this.ifCaseTypeEdit = false;
    this.NPIWarning = false;
    this.isDisabled = false;
    this.ifAssocEdit = false;
    this.ifUsersEdit = false;
    this.ifLogoEdit = false;
    this.recipientData.reset();
    this.AddLocRecipientData.reset();
    if (this.templateData.cardtype.toLowerCase() == 'facility') {
      this.AddLocRecipientData.patchValue(
        {
          Status: true,
          NPI: 'UNKNOWN',
          IsListedAsOF: true
        });
    } else {
      this.AddLocRecipientData.patchValue(
        {
          Status: true,
          NPI: 'UNKNOWN',
        });
    }
    this.assocCaseTypes = [];
    this.UsersArray = [];
    this.assocUsers = [];
    this.assocAccounts = [];
    this.selectedItems = [];
    this.oldAsscType = '';
    this.editAssocData = [];
    this.NoDataSetupNewPage = false;
    this.ShowGridItemDetails = false;
    this.ShowLocationGrid = false;
    this.ShowSetupNewLocation = true;
    this.DisplayLocationTab = false
    this.DisplayCaseType = true;
    this.DisplayLogoTab = true;
    this.DisplayUsersTab = true;
    this.DisplayAssocLocTab = true;
    this.showHeaderDisplayName = false;
    this.selectedIndex = 0;
    this.getAssociationTypesDropdown();

  }


  //#region dropdown data for associate location page
  /****To Associate Location get associationType and accounts to select in dropdown */
  async getAssociatedLocationsForOrg() {
    this.checkCardtype = this.templateData.cardtype
    // let orgid = parseInt(this.commonService.orgid);
    let accid = parseInt(this.templateData.cardIdentifier);
    // let card = sessionStorage.getItem('Context');
    let card = sessionStorage.getItem('search_context');
    let AssociationType;
    if (this.templateData.cardtype || card) {
      if (this.templateData.cardtype.toLowerCase() == 'lab') {
        AssociationType = 'Laboratories';
      }
      else if (this.templateData.cardtype.toLowerCase() == 'facility') {
        AssociationType = 'Ordering Facilities';
      }
      else {
        // let card = sessionStorage.getItem('search_context');
        if (card) {
          if (card.toLowerCase() == 'lab') {
            AssociationType = 'Laboratories';
          }
          else if (card.toLowerCase() == 'facility') {
            AssociationType = 'Ordering Facilities';
          }
        }
      }
      this.ngxService.start();
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      await this.vaservices.GetAssociatedAccountsForOrg(accid, AssociationType).toPromise().then(result => {
        // this.getDropdownValues();
        if (!result.errors) {
          let res = result;
          this.associationtypeArray = [];
          this.associationtypeArray = [...new Set(res.map(item => item.associationtype))];
          this.AssociatedLabAccountidArray = res.filter(obj => obj.associationtype === 'Laboratories');
          this.AssociatedSendInAccountidArray = res.filter(obj => obj.associationtype === 'Send In Lab');
          this.AssociatedSendOutAccountidArray = res.filter(obj => obj.associationtype === 'Send-out Laboratories');
          this.AssociatedFacilityAccountidArray = res.filter(obj => obj.associationtype === 'Ordering Facilities');
          this.AssociatedPathologistidArray = res.filter(obj => obj.associationtype === 'Pathologists');
          this.dropdownSettings = {
            singleSelection: false,
            idField: 'accountid',
            textField: 'accountname',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            // itemsShowLimit: 3,
            allowSearchFilter: true,
            enableCheckAll: true,
            limitSelection: -1,
            clearSearchFilter: true,
            maxHeight: 150,
            itemsShowLimit: 44,
            closeDropDownOnSelection: false,
            showSelectedItemsAtTop: false,
            defaultOpen: false,

          };
          this.recipientData.patchValue(
            {
              AssociatedAccountid: "",
              AssociationType: "",
              Service: "None"
            });
          this.recipientData.patchValue({
            AssociatedAccountid: this.AssociatedFacilityAccountidArray,
          });
          this.recipientData.patchValue({
            AssociatedAccountid: this.AssociatedSendOutAccountidArray,
          });
          this.recipientData.patchValue({
            AssociatedAccountid: this.AssociatedSendInAccountidArray,
          });
          this.recipientData.patchValue({
            AssociatedAccountid: this.AssociatedLabAccountidArray,
          });
          this.recipientData.patchValue({
            AssociatedAccountid: this.AssociatedPathologistidArray,
          });
        }
        else {
          console.log(result);
        }
      }, error => {
        this.ngxService.stop();
        console.error(error);
      })
    }
    else {
      this._snackbar.open("Failed to get data", "Failure");
    }
    this.ngxService.stop();
  }
  //#endregion

  captureNPI(NPI) {
    this.NPI = NPI;
    if (NPI.trim() != "" && NPI.toLowerCase() != "unknown") {
      this.NPIvalidated = false;
      this.disableButton = false;
      this.NPIWarning = false;
    }
    else {
      this.disableButton = true;
      this.NPIWarning = false;
      this.NPIvalidated = true;
    }
  }

  //#region NPI Validation
  NPIValidation() {
    this.ngxService.start();
    let NPIstring = this.NPI.toString();
    if (NPIstring.trim() != "" && NPIstring.toLowerCase() != "unknown") {
      if (NPIstring.length == 10) {
        this.NPIvalidated = true;
        this.NPIWarning = false;
      }
      else {
        this.NPIvalidated = false;
        this.NPIWarning = true;
      }
      this.ngxService.stop();
      // let value = {
      //   "npi": NPIstring,
      // }
      // this.vaservices.NpiValidation(value).subscribe((Info) => {
      //   this.NPIWarning = Info.result_count == 0 ? true : false;
      //   this.NPIvalidated = !this.NPIWarning;
      // }, (error) => {
      //   this.NPIWarning = true;
      //   this.NPIvalidated = !this.NPIWarning;
      // });
      // this.ngxService.stop();
    }
    else {
      this.ngxService.stop();
      this.NPIvalidated = true;
      this.NPIWarning = false;
    }
  }
  //#endregion
  // async addNewLocation(addNewLocationData, NPIvalid,accValid,dispValid,shortValid, pop)
  //#region Setup New Location
  async addNewLocation(addNewLocationData, valid, pop) {
    // if (this.ifEdit) {
    //   if (addNewLocationData.NPI.trim() != "")
    //     this.captureNPI(addNewLocationData.NPI);
    // } //
    this.captureNPI(addNewLocationData.NPI);
    this.NPIValidation();
    if (!valid && (this.NPIWarning == false && this.NPIvalidated == true && addNewLocationData.AccountName != null && addNewLocationData.ShortName != null && addNewLocationData.DisplayName != null)) {
      if (addNewLocationData.AccountName.trim() != "" && addNewLocationData.ShortName.trim() != "" && addNewLocationData.DisplayName.trim() != "" && addNewLocationData.NPI.trim() != "") {

        let orgid = this.commonService.orgid;
        let AssociationType;
        let loggedIn_userID: any = this.getLoggedInUserID();
        if (loggedIn_userID == '' || loggedIn_userID == undefined || loggedIn_userID == null) {
          loggedIn_userID = '-100';
        }
        // let card = sessionStorage.getItem('Context');
        let card = sessionStorage.getItem('search_context');
        let usernamereportformat, UsernameDisplayformat
        if (this.templateData.cardtype || card) {
          if (this.templateData.cardtype.toLowerCase() == 'lab') {
            AssociationType = 'Laboratories';
          }
          else if (this.templateData.cardtype.toLowerCase() == 'facility') {
            AssociationType = 'Ordering Facilities';
          }
          else if (card.toLowerCase() == 'lab') {
            AssociationType = 'Laboratories';
          }
          else if (card.toLowerCase() == 'facility') {
            AssociationType = 'Ordering Facilities';
          }
          if (this.selAccountid == undefined || this.selAccountid == 0) {
            this._parseAccountID = 0;
          } else {
            this._parseAccountID = this.selAccountid;
          }

          addNewLocationData.UsernameDisplayformat = this.AddLocRecipientData.get('UsernameDisplayformat').value;
          addNewLocationData.UsernameReportformat = this.AddLocRecipientData.get('UsernameReportformat').value;
          UsernameDisplayformat = this.convertUsernameFormat(addNewLocationData.UsernameDisplayformat);
          usernamereportformat = this.convertUsernameFormat(addNewLocationData.UsernameReportformat);
          let matches = addNewLocationData.DisplayName.match(/\b(\w)/g);
          let mnemonic_name = matches.join('');
          addNewLocationData.ReceivedDateFormat = this.AddLocRecipientData.get('ReceivedDateFormat').value;
          addNewLocationData.CollectionDateFormat = this.AddLocRecipientData.get('CollectionDateFormat').value;
          addNewLocationData.State = this.AddLocRecipientData.get('State').value;
          addNewLocationData.ReceivedDateFormat = this.AddLocRecipientData.get('ReceivedDateFormat').value;
          addNewLocationData.ETADateFormat = this.AddLocRecipientData.get('ETADateFormat').value;
          addNewLocationData.PackageDateFormat = this.AddLocRecipientData.get('PackageDateFormat').value;
          addNewLocationData.ClaimOptions = this.AddLocRecipientData.get('ClaimOptions').value;
          addNewLocationData.ClaimMnemonic = this.AddLocRecipientData.get('ClaimMnemonic').value;
          let AddJson = {
            "accountId": this._parseAccountID,
            "accountname": addNewLocationData.AccountName
            , "ShortName": addNewLocationData.ShortName
            , "ParentAccountName": addNewLocationData.ParentAccountName
            , "displayname": addNewLocationData.DisplayName
            , "mnemonic": mnemonic_name.toUpperCase()
            , "npi": addNewLocationData.NPI
            , "ein": addNewLocationData.EIN
            , "clia": addNewLocationData.CLIA
            , "statelicensenumber": addNewLocationData.StateLicense
            , "primarycontactname": addNewLocationData.PrimaryContactname
            , "primarycontactphone": addNewLocationData.PrimaryContactPhone
            , "primarycontactemail": addNewLocationData.PrimaryContactEmail
            , "claimoptions": addNewLocationData.ClaimOptions
            , "FaxEnable": addNewLocationData.FaxEnable == true ? 1 : 0
            , "CollectionDateFormat": addNewLocationData.CollectionDateFormat
            , "PackageDateFormat": addNewLocationData.PackageDateFormat
            , "IsListedAsOF": this.entityType.toLowerCase() == 'of' ? 1 : 0
            , "GeoCoordinates": addNewLocationData.GeoCoordinates
            , "EnableSendOutCheckBox": this.entityType != 'Lab' ? 0 : addNewLocationData.EnableSendOutCheckBox ? 1 : 0
            , "ReportData": addNewLocationData.ReportData
            , "address1": addNewLocationData.Address1
            , "address2": addNewLocationData.Address2
            , "street": addNewLocationData.Street
            , "city": addNewLocationData.City
            , "STATE": addNewLocationData.State
            , "zip": addNewLocationData.ZIP
            , "phone": addNewLocationData.Phone
            , "fax": addNewLocationData.Fax
            , "usernamedisplayformat": UsernameDisplayformat
            , "usernamereportformat": usernamereportformat
            , "STATUS": addNewLocationData.Status == true ? "Active" : "Inactive"
            , "RegAccountName": addNewLocationData.RegAccountName
            , "claimmnemonic": addNewLocationData.ClaimMnemonic
            //  ? addNewLocationData.ClaimMnemonic : ''
            , "ReceivedDateFormat": addNewLocationData.ReceivedDateFormat
            , "ETADateFormat": addNewLocationData.ETADateFormat
            , "CLPOL": addNewLocationData.CLPOL
            , "IsSharedSurgicenter":  addNewLocationData.IsSharedSurgicenter == true ? 1 : 0
            , "PayToAddress1": addNewLocationData.PayToAddress1
            , "PayToAddress2": addNewLocationData.PayToAddress2
            , "PayToState": addNewLocationData.PayToState
            , "PayToZip": addNewLocationData.PayToZip
            , "PayToCity": addNewLocationData.PayToCity
            , "organizationid": orgid.toString()
            , "userid": loggedIn_userID
          };
          this.ngxService.start();
          this.vaservices.addOrgLocation(AddJson).subscribe(async result => {
            this.ngxService.stop();
            if (!result.errors) {
              if (result[1].Success) {
                if (!pop) {
                  this.ifLocationSaved = true;
                }
                if(this.entityType){
                  this.accountName = addNewLocationData.AccountName;
                  this.displaynameHeader =  addNewLocationData.DisplayName;
                }
                if (!this.ifLocEdit) {
                  this._parseAccountID = result[0].accountid;
                  await this.saveCustomorgAttribute();
                  this.selAccountid = result[0].accountid;
                  this._snackbar.open("Saved successfully", "Success");
                  if (this._parseAccountID) {
                    this.refreshLocGrid();
                    this.showHeaderDisplayName = true;
                    this.getAssociatedAccounts(this._parseAccountID);
                    this.getexistinglogo(this._parseAccountID);
                    if (pop) {
                      this.ifLocationSaved = false;
                      this.ifLocChanged = false;
                      if (this.selectedValue == 'nxt') {
                        this.selectedIndex = 1;
                        this.DisplayLocationTab = true;
                        this.DisplayAssocLocTab = false;
                        this.DisplayCaseType = true;
                        this.DisplayUsersTab = true;
                        this.DisplayLogoTab = true;
                        this.addNewAssociation()

                      } else if (this.selectedValue == 'prev' || this.selectedValue == 'can') {
                        this.viewLocation();
                      }

                    }
                  }
                  else {
                    this.selectedIndex = 0;
                    this.DisplayCaseType = true;
                    this.DisplayLogoTab = true;
                    this.DisplayUsersTab = true;
                    this.DisplayAssocLocTab = true;
                    this.DisplayLocationTab = false;
                    this.showHeaderDisplayName = false;
                  }
                  this.ifLocEdit = true;
                }
                else {
                  this._parseAccountID = result[0].accountid;
                  this.selAccountid = result[0].accountid;
                  let matches = this.OldAddJson.DisplayName.match(/\b(\w)/g);
                  let mnemonic_name = matches.join('');
                  let auditJson = {
                    "accountId": this._parseAccountID,
                    "accountname": this.OldAddJson.AccountName
                    , "ShortName": this.OldAddJson.ShortName
                    , "ParentAccountName": this.OldAddJson.ParentAccountName
                    , "displayname": this.OldAddJson.DisplayName
                    , "mnemonic": mnemonic_name.toUpperCase()
                    , "npi": this.OldAddJson.NPI
                    , "ein": this.OldAddJson.EIN
                    , "clia": this.OldAddJson.CLIA
                    , "statelicensenumber": this.OldAddJson.StateLicense
                    , "primarycontactname": this.OldAddJson.PrimaryContactname
                    , "primarycontactphone": this.OldAddJson.PrimaryContactPhone
                    , "primarycontactemail": this.OldAddJson.PrimaryContactEmail
                    , "claimoptions": this.OldAddJson.ClaimOptions
                    , "FaxEnable": this.OldAddJson.FaxEnable == true ? 1 : 0
                    , "CollectionDateFormat": this.OldAddJson.CollectionDateFormat
                    , "PackageDateFormat": this.OldAddJson.PackageDateFormat
                    , "IsListedAsOF": this.OldAddJson.IsListedAsOF == true ? 1 : 0
                    , "GeoCoordinates": this.OldAddJson.GeoCoordinates
                    , "EnableSendOutCheckBox": this.OldAddJson.EnableSendOutCheckBox == true ? 1 : 0
                    , "ReportData": this.OldAddJson.ReportData
                    , "address1": this.OldAddJson.Address1
                    , "address2": this.OldAddJson.Address2
                    , "street": this.OldAddJson.Street
                    , "city": this.OldAddJson.City
                    , "STATE": this.OldAddJson.State
                    , "zip": this.OldAddJson.ZIP
                    , "phone": this.OldAddJson.Phone
                    , "fax": this.OldAddJson.Fax
                    , "usernamedisplayformat": this.OldAddJson.UsernameDisplayformat
                    , "usernamereportformat": this.OldAddJson.UsernameReportformat
                    , "STATUS": this.OldAddJson.Status == true ? "Active" : "Inactive"
                    , "RegAccountName": this.OldAddJson.RegAccountName
                    , "claimmnemonic": this.OldAddJson.ClaimMnemonic
                    , "ReceivedDateFormat": this.OldAddJson.ReceivedDateFormat
                    , "ETADateFormat": this.OldAddJson.ETADateFormat
                    , "CLPOL": this.OldAddJson.CLPOL
                    , "IsSharedSurgicenter": this.OldAddJson.IsSharedSurgicenter == true ? 1 : 0
                    , "PayToAddress1": this.OldAddJson.PayToAddress1
                    , "PayToAddress2": this.OldAddJson.PayToAddress2
                    , "PayToState": this.OldAddJson.PayToState
                    , "PayToZip": this.OldAddJson.PayToZip
                    , "PayToCity": this.OldAddJson.PayToCity
                    , "organizationid": orgid.toString()
                    , "userid": loggedIn_userID
                  };
                  this.commonService.createActivityTracker('Location Update', this._parseAccountID, 'Location', 'Audit', AddJson, auditJson);
                  this.refreshLocGrid();
                  // this.selectedIndex = 1;
                  if (pop) {
                    this.ifLocationSaved = false;
                    this.ifLocChanged = false;
                    if (this.selectedValue == 'nxt') {
                      this.selectedIndex = 1;
                      this.DisplayLocationTab = true;
                      this.DisplayAssocLocTab = false;
                      this.DisplayCaseType = true;
                      this.DisplayUsersTab = true;
                      this.DisplayLogoTab = true;
                    } else if (this.selectedValue == 'prev' || this.selectedValue == 'can') {
                      this.viewLocation();
                    }
                  }
                  //await this.saveCustomorgAttribute();
                  this.addNewAssociation();
                  await this.saveCustomorgAttribute();
                  this._snackbar.open("Updated successfully", "Success");
                }
              }
              else {
                this._snackbar.open("Failed to save data.", "failed");
              }
            }
          }, error => {
            this.ngxService.stop();
            this._snackbar.open("Failed to save data.", "failed");
          });
        } else {
          this._snackbar.open("Failed to save data.", "failed");
        }
      }
      else {
        if (this.AddLocRecipientData.controls.AccountName.status == 'INVALID' || addNewLocationData.AccountName.trim() == "") {
          this._snackbar.open("Please enter the Location Name upto 50 characters!", "Alert!");
        }
        if (this.AddLocRecipientData.controls.ShortName.status == 'INVALID' || addNewLocationData.ShortName.trim() == "") {
          this._snackbar.open("Please enter the Short Name upto 10 characters!", "Alert!");
        }

        if (this.AddLocRecipientData.controls.DisplayName.status == 'INVALID' || addNewLocationData.DisplayName.trim() == "") {
          this._snackbar.open("Please enter the Display Name upto 50 characters!", "Alert!");
        }
        if (this.AddLocRecipientData.controls.NPI.status == 'INVALID' || addNewLocationData.NPI.trim() == "") {
          this._snackbar.open("Please enter a valid NPI or UNKNOWN value!", "Alert!");
        }
      }
    } else {
      //this._snackbar.open("Please enter the Required Fields!", "Alert!");
      if (this.AddLocRecipientData.controls.DisplayName.status == 'INVALID' || addNewLocationData.DisplayName == null) {
        this._snackbar.open("Please enter the Display Name upto 50 characters!", "Alert!");
      }

      if (this.AddLocRecipientData.controls.ShortName.status == 'INVALID' || addNewLocationData.ShortName == null) {
        this._snackbar.open("Please enter the Short Name upto 10 characters!", "Alert!");
      }
      if (this.AddLocRecipientData.controls.AccountName.status == 'INVALID' || addNewLocationData.AccountName == null) {
        this._snackbar.open("Please enter the Location Name upto 50 characters!", "Alert!");
      }

      if (this.AddLocRecipientData.controls.NPI.status == 'INVALID' || addNewLocationData.NPI == null || (this.NPIWarning == true && this.NPIvalidated == false)) {
        this._snackbar.open("Please enter a valid NPI or UNKNOWN value!", "Alert!");
      }
      if (this.AddLocRecipientData.controls.PrimaryContactEmail.status == 'INVALID') {
        this._snackbar.open("Please enter a valid email id!", "Alert!");
      }
      if (this.AddLocRecipientData.controls.ZIP.status == 'INVALID') {
        this._snackbar.open("Please enter a valid ZIP!", "Alert!");
      }
      if (this.AddLocRecipientData.controls.Fax.status == 'INVALID') {
        this._snackbar.open("Please enter a valid Fax!", "Alert!");
      }
      if (this.AddLocRecipientData.controls.PayToZip.status == 'INVALID') {
        this._snackbar.open("Please enter a valid PayToZip!", "Alert!");
      }
      if (this.AddLocRecipientData.controls.Street.status == 'INVALID') {
        this._snackbar.open("Please enter the Street value upto 50 characters!", "Alert!");
      }
      if (this.AddLocRecipientData.controls.City.status == 'INVALID') {
        this._snackbar.open("Please enter the City value upto 50 characters!", "Alert!");
      }
      if (this.AddLocRecipientData.controls.ClaimMnemonic.status == 'INVALID') {
        this._snackbar.open("Please enter the City value upto 2 characters!", "Alert!");
      }
      if (this.AddLocRecipientData.controls.CLPOL.status == 'INVALID') {
        this._snackbar.open("Please enter the CLPOL value upto 50 characters!", "Alert!");
      }
      if (this.AddLocRecipientData.controls.PrimaryContactPhone.status == 'INVALID') {
        this._snackbar.open("Please enter the valid Primary Contact Phone!", "Alert!");
      }
      if (this.AddLocRecipientData.controls.Phone.status == 'INVALID') {
        this._snackbar.open("Please enter a valid Phone!", "Alert!");
      }

    }

  }

  //#endregion

  convertUsernameFormat(nameDisplayFormat) {
    switch (nameDisplayFormat) {
      case 'Mr. Lastname, Firstname': return 'S.L,F';
      case 'Lastname, Firstname Ini.': return 'L,F,I';
      case 'Firstname, Lastname': return 'F,L';
      case 'Mr. Firstname, Lastname': return 'S.F,L';
      case 'Firstname, Lastname MBBS': return 'F,L,D';
      case 'Mr. Lastname, Firstname MBBS': return 'S.L,F,D';
      case 'Mr. Firstname, Lastname MBBS': return 'S.F,L,D';
      case 'Lastname, Firstname': return 'L,F';
      case 'Mr. Lastname, Firstname Ini.': return 'S.L,F,I';
    }
  }

  convertUsernameFormatToOriginalFormat(nameDisplayFormat) {
    switch (nameDisplayFormat) {
      case 'S.L,F': return 'Mr. Lastname, Firstname';
      case 'L,F,I': return 'Lastname, Firstname Ini.';
      case 'F,L': return 'Firstname, Lastname';
      case 'S.F,L': return 'Mr. Firstname, Lastname';
      case 'F,L,D': return 'Firstname, Lastname MBBS';
      case 'S.L,F,D': return 'Mr. Lastname, Firstname MBBS';
      case 'S.F,L,D': return 'Mr. Firstname, Lastname MBBS';
      case 'L,F': return 'Lastname, Firstname';
      case 'S.L,F,I': return 'Mr. Lastname, Firstname Ini.';
      case undefined: return '';
      case null: return '';
    }
  }



  redirectTab() {
    if (!this.DisplayAssocLocTab) {
      this.tabGroup.selectedIndex = 1;
    }
    else if (!this.DisplayCaseType) {
      this.tabGroup.selectedIndex = 2;
    }
    else if (!this.DisplayUsersTab) {
      this.tabGroup.selectedIndex = 3;
    }
    else if (!this.DisplayLogoTab) {
      this.tabGroup.selectedIndex = 4;
    }
    else {
      this.ShowSetupNewLocation = false;
      this.viewLocation();
    }
  }



  //#region calling the Api to Associate
  AddAssociation() {
    // this.ngxService.start();
    let associatedData = [];
    if (this.grid && this.grid.rows.length > 0) {
      this.grid.rows.forEach((va: any) => associatedData.push(va._data))
    }
    if (this.existingData.length == 0) {
      if (associatedData.length == 0) {
        this._snackbar.open("Please associate with at least one location!", "Close");
        return;
      }
    }
    associatedData = this.getFinalData(associatedData, [])
    associatedData.forEach(va => {
      if (va.AssociationType.toString().match(/my organization/i)) {
        va.associatedAccountID = va.accountid
      }
    })

    this.ngxService.start();
    if (associatedData.length > 0) {
      this.vaservices.associateAccounts(associatedData).subscribe((res: any) => {
        if (res.Success) {
          this.currentAssociations = false;
          this.ngxService.stop();
          this._snackbar.open(res.Message, 'Success');
          this.commonService.setBooleanToTrue(true);
          this.addeddata = [];
          this.AssociationDetails = [];
          if(this.currloc)
          this.currloc["checked"] = false;
          //this.UpdateAssociationModel = false;
          this.fetchExistingAssociations(this._parseAccountID.toString());
        } else {
          this._snackbar.open(res.Message, 'Failed');
        }
      });
    }
    else if (this.editableData.length > 0) {
      this.editAssociations(this.editableData)
      this.ngxService.stop();

    }
    else {
      this.ngxService.stop();
      this._snackbar.open("Saved successfully", "Close");
    }
  }

  editAssociations(editData, Message?, newAssociation?) {
    this.ngxService.start();
    this.vaservices.editAssociations(editData).subscribe((res: any) => {
      if (res.Success) {
        this.currentAssociations = false;
        // this._snackbar.open(Message ? Message : res.Message, 'Success');
        this.commonService.setBooleanToTrue(true);
        this.addeddata = [];
        this.AssociationDetails = [];
        this._snackbar.open("Saved successfully", "Close");
        //this.UpdateAssociationModel = false;
      }
      else {
        this._snackbar.open("Association Failed!", "Close");
      }
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this._snackbar.open('An error occurred while processing your request', 'Failed');
      this.ngxService.stop();
    });
  }

  getFinalData(associatedData, finalData): any {
    for (let j = 0; j < associatedData.length; j++) {
      // let object: any = {}
      // object = associatedData[j]
      let { accountid: _, ...rest } = associatedData[j];
      if(this.entityType.toLowerCase() == 'rl'){
        rest.accountid = rest.associatedAccountID;
        rest.associatedAccountID =  this._parseAccountID;
      }
      else {
        rest.accountid = this._parseAccountID

      }
      rest.userid = rest.userid ? rest.userid : sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100
      finalData.push(rest);
    }
    return finalData;
  }
  //#region Associating Locations
  addAssociationToLocation(associatedData, pop) {
    if (associatedData.AssociationType != null && this.selectedItems.length > 0) {
      if (associatedData.Service == null) {
        associatedData.Service = 'None';
      }
      let updateObj;
      let orgid = parseInt(this.commonService.orgid);
      let sendArrayParam = [];
      let userid = sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100;

      for (let i = 0; i < this.selectedItems.length; i++) {
        let obj = {
          accountid: this._parseAccountID.toString(),
          AssociationType: associatedData.AssociationType,
          associatedAccountID: this.selectedItems[i].accountid.toString(),
          services: associatedData.Service,
          isPcEnabled: associatedData.isPcEnabled == true ? true : false,
          AllowReportViewing: associatedData.IsViewReport == true ? true : false,
          organizationID: orgid,
          AssociatedOrgid: orgid,
          userid: userid.toString(),
        };
        sendArrayParam.push(obj)
      }
      if (this.ifAssocEdit) {
        updateObj = {
          accountid: this._parseAccountID.toString(),
          AssociationType: associatedData.AssociationType,
          services: associatedData.Service,
          isPcEnabled: associatedData.isPcEnabled == true ? true : false,
          AllowReportViewing: associatedData.IsViewReport == true ? true : false,
          userid: userid.toString(),
        };
      }
      this.ngxService.start();
      if (this._parseAccountID && this._parseAccountID.toString() != '0') {
        if (!this.ifAssocEdit) {
          // Add Association Part
          this.vaservices.associateAccounts(sendArrayParam).subscribe((res) => {
            if (!res.errors) {
              if (res.Success) {
                if (!pop) {
                  this.ifAssociateSaved = true;
                }
                this.ngxService.stop();
                this._snackbar.open(res.Message, 'Success');
                this.DisplayAssocLocTab = false;
                this.commonService.setBooleanToTrue(true);
                this.getAssociatedAccounts(this._parseAccountID);
                this.ifAssocEdit = true;
                if (pop) {
                  this.ifAssociateSaved = false;
                  this.ifAsscChanged = false;
                  if (this.selectedValue == 'nxt') {
                    this.selectedIndex = 2;
                    this.DisplayLocationTab = true;
                    this.DisplayAssocLocTab = true;
                    this.DisplayCaseType = false;
                    this.DisplayUsersTab = true;
                    this.DisplayLogoTab = true;
                  } else if (this.selectedValue == 'prev') {
                    this.selectedIndex = 0;
                    this.DisplayLocationTab = false;
                    this.DisplayAssocLocTab = true;
                    this.DisplayCaseType = true;
                    this.DisplayUsersTab = true;
                    this.DisplayLogoTab = true;
                  } else if (this.selectedValue == 'can') {
                    this.viewLocation();
                  }
                }

              }
              else {
                this.ngxService.stop();
                this._snackbar.open("Association failed", 'Failed');
                this.DisplayAssocLocTab = false;
                this.selectedIndex = 1;
              }
            } else {
              this.ngxService.stop();
              this._snackbar.open("Association failed", 'Failed');
              this.DisplayAssocLocTab = false;
              this.selectedIndex = 1;
            }
          });
        } else {
          //Edit Association Part
          this.vaservices.updateassociateAccounts(updateObj).subscribe((res) => {
            if (!res.errors) {
              if (res.Success) {
                if (!pop) {
                  this.ifAssociateSaved = true;
                }
                this.ifAssocEdit = true;
                let selarr = [];
                for (var i = 0; i < this.selectedItems.length; i++) {
                  let arr;
                  arr = this.selectedItems[i].accountname;
                  selarr.push(arr)
                }
                var selc = selarr.toString();
                //New JSON to audit
                let obj = {
                  accountid: this._parseAccountID.toString(),
                  AssociationType: associatedData.AssociationType,
                  associatedAccountID: selc,
                  services: associatedData.Service,
                  isPcEnabled: associatedData.isPcEnabled == true ? true : false,
                  AllowReportViewing: associatedData.IsViewReport == true ? true : false,
                  organizationID: orgid,
                  AssociatedOrgid: orgid,
                  userid: userid.toString(),
                };

                //Old json for Audit in edit mode
                let Arr = this.assocAccounts.toString();
                let Updatobj = {
                  accountid: this._parseAccountID.toString(),
                  AssociationType: this.oldAssJson.AssociationType,
                  associatedAccountID: Arr,
                  services: this.oldAssJson.Service,
                  isPcEnabled: this.oldAssJson.isPcEnabled == true ? true : false,
                  AllowReportViewing: this.oldAssJson.IsViewReport == true ? true : false,
                  organizationID: orgid,
                  AssociatedOrgid: orgid,
                  userid: userid.toString(),
                };


                this.commonService.createActivityTracker('Association Update', this._parseAccountID, 'Association', 'Audit', obj, Updatobj)
                this.getAssociatedAccounts(this._parseAccountID);
                this.ngxService.stop();
                this._snackbar.open("Updated Successfully", 'Success');
                if (pop) {
                  this.ifAssociateSaved = false;
                  this.ifAsscChanged = false;
                  if (this.selectedValue == 'nxt') {
                    this.selectedIndex = 2;
                    this.DisplayLocationTab = true;
                    this.DisplayAssocLocTab = true;
                    this.DisplayCaseType = false;
                    this.DisplayUsersTab = true;
                    this.DisplayLogoTab = true;
                  } else if (this.selectedValue == 'prev') {
                    this.selectedIndex = 0;
                    this.DisplayLocationTab = false;
                    this.DisplayAssocLocTab = true;
                    this.DisplayCaseType = true;
                    this.DisplayUsersTab = true;
                    this.DisplayLogoTab = true;
                  } else if (this.selectedValue == 'can') {
                    this.viewLocation();
                  }
                }

                // this.DisplayAssocLocTab = true;
                this.commonService.setBooleanToTrue(true);

                //
              }
              else {
                this.ngxService.stop();
                this._snackbar.open("Association failed", 'Failed');
                this.DisplayAssocLocTab = false;
                this.selectedIndex = 1;
              }
            } else {
              this.ngxService.stop();
              this._snackbar.open("Association failed", 'Failed');
              this.DisplayAssocLocTab = false;
              this.selectedIndex = 1;
            }
          });
        }
      }
      else {
        this.ngxService.stop();
        this._snackbar.open("Please create an location first", "Failed");
        this.DisplayAssocLocTab = false;
        this.selectedIndex = 1;
      }
    }
    else {
      this._snackbar.open("Please select Required fields", "Alert");
      this.DisplayAssocLocTab = false;
      this.selectedIndex = 1;
    }
  }
  //#endregion

  onItemDeSelect(items: any) {
    //   const index: number = this.selectedItems.indexOf(items.accountid);
    const index: number = this.selectedItems.indexOf(items);
    if (index !== -1) {
      this.selectedItems.splice(index, 1);
    }
  }

  //#region Grid selector CaseType Tab
  initializeCaseTypesGrid(flexgrid) {
    let tempSelectedCasetypeArray = [];
    tempSelectedCasetypeArray = this.CasetypeArray;
    this.casetyperows = flexgrid;
    this.selectorCasetype = null;
    this.selectorCasetype = new Selector(flexgrid, {
      itemChecked: () => {
        this.selectedItems1 = [];
        this.selectedItems1 = flexgrid.rows.filter((r) => r.isSelected);
        this.selectedCasetype = [];
        this.unselectedCasetype = [];
        for (let i = 0; i < this.selectedItems1.length; i++) {
          this.selectedCasetype.push(this.selectedItems1[i]._data.CaseType);
        }
        this.unselectedCasetype = tempSelectedCasetypeArray.filter(item => !this.selectedCasetype.includes(item.casetype))
      }
    })
    // ****** Show Saved casetypes for existing AccountID */

    flexgrid.rows.filter((r, index) => {
      this.CasetypeArray.find(e => {
        if (e.casetype == r._data.CaseType) {
          r.isSelected = true;
        }
      });
      if (index == (flexgrid.rows.length - 1)) {
        this.CasetypeArray = [];
      }
    });


  }
  //#endregion

  //#region Get Casetype grid
  getCasetype() {
    let AssociationType;
    if (this.casetypegrid != undefined) {
      this.casetypegrid = undefined;
    }
    //  let card = sessionStorage.getItem('Context');
    let card = sessionStorage.getItem('search_context');
    if (this.templateData.cardtype || card) {
      if (this.templateData.cardtype.toLowerCase() == 'lab') {
        AssociationType = 'Laboratories';
      }
      else if (this.templateData.cardtype.toLowerCase() == 'facility') {
        AssociationType = 'Ordering Facilities';
      }
      else {
        if (card.toLowerCase() == 'lab') {
          AssociationType = 'Laboratories';
        }
        else if (card.toLowerCase() == 'facility') {
          AssociationType = 'Ordering Facilities';
        }
      }
      let orgid = parseInt(this.commonService.orgid);
      //  sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      let gridarray = [];
      this.ngxService.start();
      this.vaservices.GetCaseTypesForSetupNewAccount(orgid, AssociationType, this._parseAccountID).subscribe(result => {
        if (!result.errors) {
          if (result.length > 0) {
            this.casetypecolumnnames = ['CaseType', 'CaseTypeMnemonic', 'CassetteCount', 'CategoryText', 'CategoryValue', 'IssendOut', 'CaseTypeDisplayname', 'LogisticServices'];
            for (let i = 0; i < result.length; i++) {
              let primary = {}
              for (let [key, value] of Object.entries(result[i])) {
                this.casetypecolumnnames.find(e => {
                  if (key === e) {
                    primary[key] = value
                  }
                });
              }
              gridarray.push(primary);
            }
            this.casetypegrid = new CollectionView(gridarray, {
              groupDescriptions: ['CategoryValue']
            })
            this.gridwidth = 500 * ((this.casetypecolumnnames.length - 1) + 37);
            if (this.gridwidth > 1300) {
              this.gridwidth = 665;
            }
          }
          else {
            this.casetypecolumnnames = [];
          }
        }
      }, error => {
        this.casetypecolumnnames = [];
        console.error(error);
      });
    }
    else {
      this.casetypecolumnnames = [];
    }
    this.ngxService.stop();

  }
  //#endregion
  public userFlexGrid: any;
  //#region Grid selector for Users tab
  initializeUsersGrid(grid) {
    this.userFlexGrid = grid;
    let tempUsersArray = [];
    tempUsersArray = this.UsersArray;
    this.userAssociateFlexGrid = grid;
    this.selectorUser = null;
    this.selectorUser = new Selector(grid, {
      itemChecked: () => {
        this.selectedItemsForUsers = [];
        this.selectedItemsForUsers = grid.rows.filter((r) => r.isSelected);
        let UserListArray = {};
        this.arrUsers = [];
        let arrUserObj = ['userid', 'rolename'];
        for (let i = 0; i < this.selectedItemsForUsers.length; i++) {
          UserListArray = {};
          for (let [key, value] of Object.entries(this.selectedItemsForUsers[i]._data)) {
            arrUserObj.find(e => {
              if (key === e) {
                UserListArray[key] = value
              }
            });
          }
          this.arrUsers.push(UserListArray);
        }
        let _userid = [];
        for (let i = 0; i < this.arrUsers.length; i++) {
          _userid.push(this.arrUsers[i].userid)
        }
        this.unselectedUsers = [];
        this.unselectedUsers = tempUsersArray.filter(item => !_userid.includes(item.userid))
      }
    });

    //************Show Saved AssociatedUsers for the AccountID */
    grid.rows.filter((r, index) => {
      this.UsersArray.find(e => {
        if (e.userid == r._data.userid) {
          r.isSelected = true
        }
      });
      if (index == (grid.rows.length - 1)) {
        this.UsersArray = [];
      }
    });


      grid.formatItem.addHandler((s, e) => {
        if (e.panel.cellType == wjcGrid.CellType.RowHeader || e.panel.cellType == wjcGrid.CellType.TopLeft) {
          addClass(e.cell, 'wj-state-disabled');
        }
      });
  }
  //#endregion

  //#region Get Users grid
  /* Lab: get users based on selected organization
     Facility: get users that are associated to the accountid which
     is newly created. Since there will not be any users associated for the
     accountid(newly created), AddUser flag is set to true */
  getUsers() {
    let accid;
    let AssociationType;
    if (this.usersgrid != undefined) {
      this.usersgrid = undefined;
    }
    let gridarray = [];
    //  let card = sessionStorage.getItem('Context');
    let card = sessionStorage.getItem('search_context');
    if (this.templateData.cardtype || card) {
      if (this.templateData.cardtype.toLowerCase() == 'lab') {
        AssociationType = 'Laboratories';
        accid = parseInt(this.templateData.cardIdentifier);
      }
      else if (this.templateData.cardtype.toLowerCase() == 'facility') {
        this.DisplayAddUser = true;
        this.DisplayUsersTab = false;
      }
      else {
        if (card) {
          if (card.toLowerCase() == 'lab') {
            AssociationType = 'Laboratories';
            accid = parseInt(this.templateData.cardIdentifier);
          }
          else if (card.toLowerCase() == 'facility') {
            this.DisplayAddUser = true;
            this.DisplayUsersTab = false;
          }
        }
      }
      if (this.DisplayAddUser == false) {
        if (accid && accid.toString() != '0') {
          this.ngxService.start();
          // sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
          this.vaservices.GetUsersForSetupNewAccount(accid, AssociationType).subscribe(result => {
            if (!result.errors) {
              if (result.length > 0) {

                this.userscolumnnames = ['rolename', 'loginname', 'FullName', 'userid'];
                var finalArr = [];
                let card = sessionStorage.getItem('search_context');
                //if Cardtype is lab, then roles like Pathologist,pathologist assistant,cytotechnologist,cytotechnologist  supervisor should not be displayed in user role grid
                if (card.toLowerCase() == 'lab') {
                  for (var j = 0; j < result.length; j++) {
                    if (result[j].rolename.toLowerCase() != 'pathologist' && result[j].rolename.toLowerCase() != 'pathologist assistant' && result[j].rolename.toLowerCase() != 'cytotechnologist' && result[j].rolename.toLowerCase() != 'cytotechnologist supervisor') {
                      finalArr.push(result[j]);
                    }
                  }
                } else {
                  finalArr = result;
                }

                for (let i = 0; i < finalArr.length; i++) {
                  let primary = {}
                  for (let [key, value] of Object.entries(finalArr[i])) {
                    this.userscolumnnames.find(e => {
                      if (key === e) {
                        primary[key] = value;
                      }
                    });
                  }
                  gridarray.push(primary);
                }
                this.usersgrid = new CollectionView(gridarray, { groupDescriptions: ['rolename'] });
                this.gridwidth = 500 * ((this.userscolumnnames.length - 1) + 37);
                if (this.gridwidth > 1300)
                  this.gridwidth = 665;
              }
              else {
                this.userscolumnnames = [];
                this.usersgrid = new CollectionView([], {});
              }
            }
          }, error => {
            this.userscolumnnames = [];
            // this.usersgrid = new CollectionView([], {});
            console.error(error);
          });
          this.ngxService.start();
        }
      }
      else {
        this.ngxService.start();
        this.userscolumnnames = [];
        console.log('Parent location ID does not exist')
      }
    }
  }
  //#endregion

  enableBtn(val) {
    console.log(val)

  }

  //#region associateUsers function
  associateUsers(pop) {
    this.ShowLocationGrid = false;
    this.ShowGridItemDetails = false;
    this.ShowSetupNewLocation = true;
    if (this._parseAccountID && this._parseAccountID.toString() != '0') {
      if (this.ifEdit) {
        if (this.arrUsers == undefined || this.arrUsers.length == 0) {
          this.arrUsers = this.bigUserArray;
        }

      }
      if (this.arrUsers && this.arrUsers.length > 0) {
        let rolename = [];
        let userid = [];
        let checkbox = [];
        if (this.ifUsersEdit) {
          for (let i = 0; i < this.unselectedUsers.length; i++) {
            rolename.push(this.unselectedUsers[i].rolename)
            userid.push(this.unselectedUsers[i].userid)
            checkbox.push(false);
          }
        }

        for (let i = 0; i < this.arrUsers.length; i++) {
          rolename.push(this.arrUsers[i].rolename)
          userid.push(this.arrUsers[i].userid)
          checkbox.push(true);
        }


        let sendJson = {
          "accountid": this._parseAccountID,
          "rolename": rolename,
          "userid": userid,
          "CheckboxStatus": checkbox
        }


        if (this.arrUsers.length > 0) {
          this.ngxService.start();
          this.vaservices.SaveAssociatedUsers(sendJson).subscribe((result) => {
            if (!result.Success) {
              if (typeof (result) == 'object')
                this._snackbar.open('Something went wrong. Please try again', "Failed");
              else
                this._snackbar.open(result, "Failed");
            }
            else {
              if (!pop) {
                this.ifUserSaves = true;
              }
              let oldrole = [];
              let oldcheck = [];
              let olduser = [];
              for (let i = 0; i < this.bigUserArray.length; i++) {
                oldrole.push(this.bigUserArray[i].rolename)
                olduser.push(this.bigUserArray[i].userid)
                oldcheck.push(false);
              }
              let oldJson = {
                "accountid": this._parseAccountID,
                "rolename": oldrole,
                "userid": olduser,
                "CheckboxStatus": oldcheck
              }
              this.commonService.setBooleanToTrue(true);
              if (this.ifUsersEdit) {
                this.commonService.createActivityTracker('Update Users under Location', this._parseAccountID, 'Users Associate', 'Audit', sendJson, oldJson);
                this.bigUserArray = [];
                this._snackbar.open("Updated successfully", "Success");

                //
              } else {
                this._snackbar.open("Saved successfully", "Success");
                this.DisplayUsersTab = false;
                // this.redirectTab();
              }
              this.ifUsersEdit = true;
              if (pop) {
                this.ifUserSaves = false;
                this.ifUserChanged = false;
                if (this.selectedValue == 'nxt') {
                  this.selectedIndex = 4;
                  this.DisplayLocationTab = true;
                  this.DisplayAssocLocTab = true;
                  this.DisplayCaseType = true;
                  this.DisplayUsersTab = true;
                  this.DisplayLogoTab = false;
                } else if (this.selectedValue == 'prev') {
                  this.selectedIndex = 2;
                  this.DisplayLocationTab = true;
                  this.DisplayAssocLocTab = true;
                  this.DisplayCaseType = false;
                  this.DisplayUsersTab = true;
                  this.DisplayLogoTab = true;
                } else if (this.selectedValue == 'can') {
                  this.viewLocation();
                }
              }
              this.getAssociatedAccounts(this._parseAccountID);;

              this.ngxService.stop();

            }
            this.ngxService.stop();

          }, error => {
            this._snackbar.open('Something went wrong. Please try again', 'Close');
            console.error(error);
          });
        } else {
          this.ngxService.stop();
          // this._snackbar.open("Select atleast one user", "Failed");
          // this.DisplayUsersTab = false;
          this.DisplayLocationTab = true;
                  this.DisplayAssocLocTab = true;
                  this.DisplayCaseType = false;
                  this.DisplayUsersTab = true;
                  this.DisplayLogoTab = true;
          this.selectedIndex = 3;
        }
      }
      else {
        this.ngxService.stop();
        // if (this.userscolumnnames.length > 0) {
          // this.selectedIndex = 4;
          // this._snackbar.open('Select atleast one user', 'Close');
        // }
        // else {
          if (this.selectedValue == 'nxt') {
            this.DisplayUsersTab = true;
            this.DisplayLogoTab = false;
            this.selectedIndex = 4;
          }
          else if (this.selectedValue == 'prev'){
            this.DisplayLocationTab = true;
            this.DisplayAssocLocTab = true;
            this.DisplayCaseType = false;
            this.DisplayUsersTab = true;
            this.DisplayLogoTab = true;
            this.selectedIndex = 2;
          }
          else if (this.selectedValue == 'can') {
            this.viewLocation();
          }
        // }
      }
    }
    else {
      this.ngxService.stop();
      this._snackbar.open("Please create an location first", "Failed");
      this.DisplayUsersTab = false;
      this.selectedIndex = 3;
    }
  }
  //#endregion

  //#region associateCaseType function
  associateCaseTypes(pop) {
    this.ShowLocationGrid = false;
    this.ShowGridItemDetails = false;
    this.ShowSetupNewLocation = true;
    if (this.selectedCasetype && this.selectedCasetype.length > 0) {
      let checkedCaseTypes = [];
      let statusArr = [];
      if (this.ifCaseTypeEdit) {
        for (var i = 0; i < this.unselectedCasetype.length; i++) {
          checkedCaseTypes.push(this.unselectedCasetype[i].casetype);
          statusArr.push(0);
        }
      }

      for (var i = 0; i < this.selectedCasetype.length; i++) {
        checkedCaseTypes.push(this.selectedCasetype[i]);
        statusArr.push(1);
      }
      let AddJson = {
        "accountid": this._parseAccountID.toString()
        , "casetype": checkedCaseTypes
        , "status": statusArr
        , "issendout": 0
        , "cassettecount": ""
        , "magazineid": ""
        , "AccountMnemonic": ""
        , "LogisticServices": ""
      }



      this.ngxService.start();
      if (this._parseAccountID && this._parseAccountID.toString() != '0') {
        this.vaservices.AddAssociatedAccountCaseType(AddJson).subscribe(result => {
          if (!result.errors) {
            if (result.Success) {
              if (!pop) {
                this.ifCaseTypesSaved = true;
              }


              this.ngxService.stop();
              this.commonService.setBooleanToTrue(true)
              if (!this.ifCaseTypeEdit) {
                this._snackbar.open("Saved successfully", "Success");
                this.DisplayCaseType = false;
                // this.redirectTab();
              } else {
                let oldcasetype = [];
                let oldstatus = [];


                for (var i = 0; i < this.assocCaseTypes.length; i++) {
                  oldcasetype.push(this.assocCaseTypes[i]);
                  oldstatus.push(0);
                }
                let OldAsscAddJson = {
                  "accountid": this._parseAccountID.toString()
                  , "casetype": oldcasetype
                  , "status": oldstatus
                  , "issendout": 0
                  , "cassettecount": ""
                  , "magazineid": ""
                  , "AccountMnemonic": ""
                  , "LogisticServices": ""
                }
                this.commonService.createActivityTracker('Update Association of Casetypes', this._parseAccountID, 'CaseTypes', 'Audit', AddJson, OldAsscAddJson)
                this._snackbar.open("Updated successfully", "Success");
                // this.selectedIndex =3;
              }
              this.ifCaseTypeEdit = true;
              if (pop) {
                this.ifCaseTypesSaved = false;
                this.ifCasetypeChanged = false;
                if (this.selectedValue == 'nxt') {
                  this.selectedIndex = 3;
                  this.DisplayLocationTab = true;
                  this.DisplayAssocLocTab = true;
                  this.DisplayCaseType = true;
                  this.DisplayUsersTab = false;
                  this.DisplayLogoTab = true;
                } else if (this.selectedValue == 'prev') {
                  this.selectedIndex = 1;
                  this.DisplayLocationTab = true;
                  this.DisplayAssocLocTab = false;
                  this.DisplayCaseType = true;
                  this.DisplayUsersTab = true;
                  this.DisplayLogoTab = true;
                } else if (this.selectedValue == 'can') {
                  this.viewLocation();
                }
              }
              this.getAssociatedAccounts(this._parseAccountID);

            }
            else {
              this.ngxService.stop();
              this._snackbar.open("Failed to save", "Failed");
              this.DisplayCaseType = false;
              this.selectedIndex = 2;
            }
          } else {
            this.ngxService.stop();
            this._snackbar.open("Failed to save", "Failed");
            this.DisplayCaseType = false;
            this.selectedIndex = 2;
          }
        });
      }
      else {
        this.ngxService.stop();
        this._snackbar.open("Please create an location first", "Failed");
        this.DisplayCaseType = false;
        this.selectedIndex = 2;
      }
    }
    else {
      this._snackbar.open("Select atleast one casetype", "Failed");
    }
  }
  //#endregion

  //#region Copy function
  copy(value: string) {
    this.clipboard.copy(value);
    this._snackbar.open("", "Copied");
  }
  //#endregion

  //#region set screen width for grid
  getpercentage(pixel: number) {
    let screenWidth = window.screen.width;
    let per = (pixel / screenWidth) * 100
    if (per < 80) {
      per = per + 8
    }
    return per;
  }
  //#endregion

  //#region redirect to Location Grid
  back() {
    // this.ifEdit=false;
    this.ShowSetupNewLocation = false;
    this.ShowGridItemDetails = false;
    if (this.locGridWidth > 0) {
      this.ShowLocationGrid = true;
      this.NoDataSetupNewPage = false;
    }
    else {
      this.ShowLocationGrid = false;
      this.NoDataSetupNewPage = true;
    }
    this.locGridRowArray = [];
    this.assocCaseTypes = [];
    this.assocUsers = [];
    this.assocAccounts = [];
  }
  //#endregion

  //#region Export Details of selected Grid item
  exportdata() {
    let excel = []
    let primary = {}
    let obj = ['accountid', 'accounttype', 'displayname', 'status', 'mnemonic', 'NPI'
      , 'ein', 'clia', 'statelicensenumber', 'primarycontactname', 'primarycontactphone'
      , 'primarycontactemail', 'claimoptions', 'FaxEnable', 'IsListedAsOF', 'GeoCoordinates', 'EnableSendOutCheckBox'
      , 'address1', 'street', 'city', 'STATE', 'zip', 'phone', 'fax', 'RegAccountName', 'claimmnemonic', 'CLPOL'
      , 'IsSharedSurgicenter', 'PayToAddress1', 'PayToState', 'PayToZip', 'PayToCity', 'AssociatedAccounts', 'AssociatedCaseTypes', 'AssociatedUsers', 'CreatedBy', 'CreatedDate', 'ModifiedBy', 'ModifiedDate']

    for (let [key, value] of Object.entries(this.locGridRowArray[0])) {
      obj.find((e): any => {
        if (key === e) {
          primary[key] = value;
        }
      });
    }
    // primary['AssociatedAccounts'] = this.assocAccounts.toString();
    // primary['AssociatedCaseTypes'] = this.assocCaseTypes.toString();
    // primary['AssociatedUsers'] = this.assocUsers.toString();
    excel.push(primary);

    let file_name = 'Location Details_';
    let LabName = sessionStorage.getItem("LabName");
    if (this.locGridRowArray[0].displayname) {
      file_name = file_name + this.locGridRowArray[0].displayname;
      if (file_name.length > 31) {
        let fileName = file_name.split('_')[0] + '_';
        let orgName = file_name.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgName.length > 31) {
          fileName = file_name.split('_')[0].replace(' ','') +'_';
          file_name = fileName + orgName +'_';
        }
        else {
          file_name = fileName + orgName + '_';
        }
      }
      else {
        file_name = file_name + '_';
      }
    }
    else if (LabName != 'undefined' && LabName) {
      file_name = file_name + LabName;
      if (file_name.length > 31) {
        let fileName = file_name.split('_')[0] + '_';
        let orgName = file_name.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
          if (orgName.length > 31) {
            fileName = file_name.split('_')[0].replace(' ','') + '_';
            file_name = fileName + orgName +'_';
          }
          else {
            file_name = fileName + orgName + '_';
          }
      }
      else{
        file_name =file_name + '_';
      }
    }
    if (!this.templateData.secondarykeys) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = file_name + this.organizationId + '.xlsx';
    if(filename.length > 31){
      let fileName = filename.split('_')[0] + '_';
      let orgid = filename.split('_')[2];
      filename = fileName  +orgid;
    }
    let ws = XLSX.utils.json_to_sheet(excel);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + "Location Details");
    XLSX.writeFile(wb, filename);
  }
  //#endregion

  //#region Export Grid data
  ExportExcel(flex) {
    let LabName = sessionStorage.getItem("LabName");
    let file_name = 'Location_';
    if (LabName != 'undefined' && LabName) {
      file_name =file_name + LabName;
      if (file_name.length > 31) {
        let fileName = file_name.split('_')[0] + '_';
        let orgName = file_name.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
          if (orgName.length > 31) {
            fileName = file_name.split('_')[0].replace(' ','') + '_';
            file_name = fileName + orgName +'_';
          }
          else {
            file_name = fileName + orgName + '_';
          }
      }
      else{
        file_name =file_name + '_';
      }
    }
    if (!this.templateData.secondarykeys) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    file_name = file_name +  this.organizationId + '.xlsx';
    if(file_name.length > 31){
      let fileName = file_name.split('_')[0] + '_';
      let orgid = file_name.split('_')[2];
      file_name = fileName +orgid;
    }
    let excel = [];
    const view = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate();
    flex.rows.find(e => {
      if(!e._data._gd){
        delete e._data["Type"];
        excel.push(e._data);
      }
    });
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Location');
    XLSX.writeFile(wb, file_name);
    view.pageSize = oldPgSize;
    flex.endUpdate()
  }
  //#endregion

  //#region get updated location details
  async refreshLocGrid() {
    let associationType;
    let orgid = parseInt(this.commonService.orgid);
    this.ngxService.start();
    if (this.templateData.cardtype.toLowerCase() == 'lab') {
      associationType = 'Laboratories';
    }
    else if (this.templateData.cardtype.toLowerCase() == 'facility') {
      associationType = 'Ordering Facilities';
    }
    else {
      //  let card = sessionStorage.getItem('Context');
      let card = sessionStorage.getItem('search_context');
      if (card.toLowerCase() == 'lab') {
        associationType = 'Laboratories';
      }
      else if (card.toLowerCase() == 'facility') {
        associationType = 'Ordering Facilities';
      }
    }
    if(this.commonService.isPol){
      associationType = 'POL';
    }
    await this.vitalHttpServices.GetOrgLocations(orgid, this.templateData.cardIdentifier, associationType).toPromise().then(async (resData) => {
      if (!resData.errors) {
        if (resData) {
          this.tempSubmenudata = [];
          if (resData) {
            if (resData.length > 0) {
              for (let i = 0; i < resData.length; i++) {
                this.tempSubmenudata.push(resData[i]);
              }
              this.updateGridFlag = true;
              await this.addLocationGridData(false);
            }
          }
        }
      }
      this.ngxService.stop();
    }, (error) => {
      console.error(error);
      this.ngxService.stop();
    });
  }
  //#endregion


  /*********************** UPLOAD LOGO ****************************/
  //#region for drag and drop logo
  onFileDropped($event) {
    this.onFileChange($event);
  }
  //#endregion

  //#region on logo upload
  onFileChange(event) {
    this.onRemove(event);

    let tempArrData = event.target ? event.target.files[0] : event[0];
    if (tempArrData.type.toString().toLowerCase() == "image/png" ||
      tempArrData.type == "image/jpeg" || tempArrData.type == "image/jpg") {
      event.addedFiles = [];
      event.addedFiles.push(tempArrData);
      this.files.push(...event.addedFiles);
      const reader = new FileReader();
      if (this.files && this.files.length) {
        const [logo_Upload] = this.files;
        reader.readAsDataURL(logo_Upload);
        reader.onload = () => {
          this.newLogoSrc = reader.result as string;
          this.logoPath = false;
        };
      } else {
        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
    } else {
      this._snackbar.open("Please upload image file only", "Failed");
    }
  }
  //#endregion

  //#region reset the logo
  removeNewLogo() {
    this.newLogoSrc = "";
    this.logoPath = true;
  }
  //#endregion

  //#region Call API to save logo
  async ConfirmUploadLogo(pop) {
    if (this._parseAccountID && this._parseAccountID.toString() != '0') {
      if (this.newLogoSrc !== "") {
        this.ngxService.start();
        let loggedIn_userID: any = this.getLoggedInUserID();
        let imageSrcString = await this.toDataURL(this.newLogoSrc)
        this.imageUrl = imageSrcString;
        imageSrcString = imageSrcString.toString().split(",")[1];
        let data = {
          Logo: imageSrcString,
          AccountID: this._parseAccountID.toString(),
          userObjectId: loggedIn_userID
        };
        this.ngxService.start();
        this.vitalHttpServices.ConfirmLogoUpload(data).subscribe(res => {
          if (res.Success) {
            this.ngxService.stop();
            if (!pop) {
              this.ifLogoSaved = true;
            }
            if (this.ifLogoEdit) {
              this._snackbar.open("Logo Updated successfully", "Success");
              let oldLogo = {
                "Logo": imageSrcString
              }

              let newLogo = {
                "Logo": this.imageUrl
              }
              this.commonService.createActivityTracker("Logo Update under Location", this._parseAccountID, "Logo Update", "Audit", newLogo, oldLogo);

            } else {
              this._snackbar.open("Logo saved successfully", "Success");
              // this.DisplayLogoTab = false;
              //  this.redirectTab();
            }
            this.ifLogoEdit = true;
            if (pop) {
              this.ifLogoSaved = false;
              this.ifLogoChanged = false;
              if (this.selectedValue == 'nxt' || this.selectedValue == 'can') {
                this.refreshLocGrid();
                this.viewLocation();
              } else if (this.selectedValue == 'prev') {
                this.selectedIndex = 2;
                this.DisplayLocationTab = true;
                this.DisplayAssocLocTab = true;
                this.DisplayCaseType = false;
                this.DisplayUsersTab = true;
                this.DisplayLogoTab = true;
              }
            }
            this.getexistinglogo(this._parseAccountID);

          } else {
            this._snackbar.open("Failed to save the logo", "Failed");
            // this.DisplayLogoTab = false;
            this.selectedIndex = 3;
          }
          this.ngxService.stop();
        }, error => {

          console.error(error);
          this._snackbar.open("An error occurred while processing your request", "Failed");
          this.ngxService.stop();
          this.DisplayLogoTab = false;
          this.selectedIndex = 3;
        })
      } else {
        if (pop) {
          this.ifLogoSaved = false;
          this.ifLogoChanged = false;
          if (this.selectedValue == 'nxt' || this.selectedValue == 'can') {
            this.refreshLocGrid();
            this.viewLocation();
          } else if (this.selectedValue == 'prev') {
            this.selectedIndex = 2;
            this.DisplayLocationTab = true;
            this.DisplayAssocLocTab = true;
            this.DisplayCaseType = false;
            this.DisplayUsersTab = true;
            this.DisplayLogoTab = true;
          }
        } else {
          this._snackbar.open("Please Upload Logo");
        }

      }
    }
    else {
      this._snackbar.open("Please create an location first", "Failed");
    }
  }
  //#endregion
   //#endregion

  //#region RBAC
  GetButtondetails() {
    this.GetButtonAccess(this.vaservices.SubmenuAction);
  }
  //#endregion

  //#region get button access
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadbutton = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Remove":
          this.remove = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.btcreate = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          this.btnCreate = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.btnEdit = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.export = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }
  //#endregion





  //#region get the location logo based on accid
  async getexistinglogo(dataItemAccid) {
    let data = {
      accid: dataItemAccid
    };
    await this.vitalHttpServices.GetLogoFile(data).toPromise().then(res => {
      this.ngxService.start("6");
      if (res.Success) {
        if (res.Message && res.Message != "") {
          this.imageSrc = "data:image/jpg;base64," + res.Message;
          this.noLogo = false;
          this.logoPath = true;
        }
        else {
          this.imageSrc = "";
          this.noLogo = true;
        }
      }
      this.ngxService.stop("6");
    }, error => {
      this.noLogo = true;
      this._snackbar.open("An error occurred while processing your request", "Failed");
      this.ngxService.stop("6");
    })
  }
  //#endregion

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.myForm.patchValue({
      logo_Upload: "",
      fileSource: "",
    });
  }

  //#region returns blob
  toDataURL = async (url) => {
    let res = await fetch(url);
    let blob = await res.blob();
    const result = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
    return result
  };
  //#endregion

  //#region get userID
  getLoggedInUserID() {
    let loggedIn_userID = sessionStorage.getItem('Userid');
    return loggedIn_userID;
  }
  //#endregion

  public onDeSelectAll(items: any) {
    this.AccountsAdded = [];
  }

  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /^[0-9*#+. -]+$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  //*-------------------------------------EDIT PART--------------------*//
  editData() {
    // this.AddLocRecipientData.reset();
    // if (this.vitalHttpServices.deploymentKey.toString().match(/thx/i)) {
    //   this._snackbar.open('This feature is temporarily disabled.', 'Close');
    //   return;
    // }

    this.ngxService.start();
    this.getAssociatedLocationsForOrg();
    this.getCasetype();
    this.getUsers();

    this.ifLocationSaved = false;
    this.ifLocEdit = true;
    this.oldAsscType = '';
    this.getpatchdata();
    this.getAssociationTypesDropdown();

    this.ifAssociateSaved = false;
    this.ifCaseTypesSaved = false;
    this.ifUserSaves = false;
    this.ifLogoSaved = false;
    this.ifEdit = true;
    this.DisplayCaseType = true;
    this.DisplayLogoTab = true;
    this.DisplayUsersTab = true;
    this.DisplayAssocLocTab = true;
    this.DisplayLocationTab = false;
    this.showHeaderDisplayName = true;
    this.ShowSetupNewLocation = true;
    this.selectedIndex = 0;
    this.ShowLocationGrid = false;
    this.associationModel = false;
    this.ShowGridItemDetails = false;
    this.NoDataSetupNewPage = false;
    this.UpdateMultiselectAssc = true;
    Object.keys(this.locGridRowArray[0]).forEach(key => {
      if (!this.locGridRowArray[0][key] || this.locGridRowArray[0][key].toString().trim() == '' || this.locGridRowArray[0][key].toString().trim() == 'Not Specified' || this.locGridRowArray[0][key].toString().trim() == undefined || this.locGridRowArray[0][key].toString().trim() == null || this.locGridRowArray[0][key].toString().trim() == 'undefined') {
        this.locGridRowArray[0][key] = '';
      }
    });
    this.displaynameHeader = this.locGridRowArray[0].displayname;
    //Populating data of Location Tab

    this.AddLocRecipientData.controls['ETADateFormat'].setValue(this.locGridRowArray[0].ETADateFormat);
    // this.AddLocRecipientData.get("ETADateFormat").patchValue(this.locGridRowArray[0].ETADateFormat);
    // this.AddLocRecipientData.get('ETADateFormat').patchValue(this.locGridRowArray[0].ETADateFormat);
    this.locGridRowArray[0].usernamedisplayformat = this.convertUsernameFormatToOriginalFormat(this.locGridRowArray[0].usernamedisplayformat);
    this.locGridRowArray[0].usernamereportformat = this.convertUsernameFormatToOriginalFormat(this.locGridRowArray[0].usernamereportformat);
    
    setTimeout(() => {
      this.AddLocRecipientData.patchValue(
        {
          AccountName: this.locGridRowArray[0].accountname,
          ShortName: this.locGridRowArray[0].ShortName,
          ParentAccountName: this.locGridRowArray[0].ParentAccountName,
          DisplayName: this.locGridRowArray[0].displayname,
          EIN: this.locGridRowArray[0].ein,
          NPI: this.locGridRowArray[0].NPI,
          CLIA: this.locGridRowArray[0].clia,
          StateLicense: this.locGridRowArray[0].statelicensenumber,
          PrimaryContactname: this.locGridRowArray[0].primarycontactname,
          PrimaryContactPhone: this.locGridRowArray[0].primarycontactphone,
          PrimaryContactEmail: this.locGridRowArray[0].primarycontactemail,
          // FaxEnable: (this.locGridRowArray[0].FaxEnable == 'Yes' || this.locGridRowArray[0].FaxEnable == 'active' || this.locGridRowArray[0].FaxEnable == 'Active' || this.locGridRowArray[0].FaxEnable == 'True' || this.locGridRowArray[0].FaxEnable == true) ? this.locGridRowArray[0].FaxEnable = true : this.locGridRowArray[0].FaxEnable = false,
          IsListedAsOF: (this.locGridRowArray[0].IsListedAsOF == 'Yes' || this.locGridRowArray[0].IsListedAsOF == 'active' || this.locGridRowArray[0].IsListedAsOF == 'Active' || this.locGridRowArray[0].IsListedAsOF == 'True' || this.locGridRowArray[0].IsListedAsOF == true) ? this.locGridRowArray[0].IsListedAsOF = true : this.locGridRowArray[0].IsListedAsOF = false,
          GeoCoordinates: this.locGridRowArray[0].GeoCoordinates,
          ReportData: this.locGridRowArray[0].ReportData,
          // EnableSendOutCheckBox: (this.locGridRowArray[0].EnableSendOutCheckBox == 'Yes' || this.locGridRowArray[0].EnableSendOutCheckBox == 'active' || this.locGridRowArray[0].EnableSendOutCheckBox == 'Active' || this.locGridRowArray[0].EnableSendOutCheckBox == 'True' || this.locGridRowArray[0].EnableSendOutCheckBox == true) ? this.locGridRowArray[0].EnableSendOutCheckBox = true : this.locGridRowArray[0].EnableSendOutCheckBox = false,
          Address1: this.locGridRowArray[0].address1,
          Address2: this.locGridRowArray[0].address2,
          Street: this.locGridRowArray[0].street,
          City: this.locGridRowArray[0].city,
          ZIP: this.locGridRowArray[0].zip,
          Phone: this.locGridRowArray[0].phone,
          Fax: this.locGridRowArray[0].fax,
          Status: (this.locGridRowArray[0].status == 'Yes' || this.locGridRowArray[0].status == 'active' || this.locGridRowArray[0].status == 'Active') ? this.locGridRowArray[0].status = true : this.locGridRowArray[0].status = false,
          RegAccountName: this.locGridRowArray[0].RegAccountName,
          ClaimMnemonic: this.locGridRowArray[0].claimmnemonic,
          CLPOL: this.locGridRowArray[0].CLPOL,
          IsSharedSurgicenter: this.locGridRowArray[0].IsSharedSurgicenter == 'Yes' ? this.locGridRowArray[0].IsSharedSurgicenter = true : this.locGridRowArray[0].IsSharedSurgicenter = false,
          PayToAddress1: this.locGridRowArray[0].PayToAddress1,
          PayToAddress2: this.locGridRowArray[0].PayToAddress2,
          PayToState: this.locGridRowArray[0].PayToState,
          PayToZip: this.locGridRowArray[0].PayToZip,
          PayToCity: this.locGridRowArray[0].PayToCity
        });
      this.AddLocRecipientData.get("ETADateFormat").setValue(this.locGridRowArray[0].ETADateFormat);
      this.AddLocRecipientData.get("CollectionDateFormat").setValue(this.locGridRowArray[0].CollectionDateFormat);
      this.AddLocRecipientData.get("ReceivedDateFormat").setValue(this.locGridRowArray[0].ReceivedDateFormat);
      this.AddLocRecipientData.get("PackageDateFormat").setValue(this.locGridRowArray[0].PackageDateFormat);
      this.AddLocRecipientData.get("State").setValue(this.locGridRowArray[0].STATE);
      this.AddLocRecipientData.get("ClaimOptions").setValue(this.locGridRowArray[0].claimoptions);
      this.AddLocRecipientData.get("UsernameDisplayformat").setValue(this.locGridRowArray[0].usernamedisplayformat);
      this.AddLocRecipientData.get("UsernameReportformat").setValue(this.locGridRowArray[0].usernamereportformat);
      this.OldAddJson = this.AddLocRecipientData.value;

      //populating and binding data to associate  tab in edit mode

    }, 2000);
    sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
    let queryVariable1 = { "accid": this._parseAccountID.toString() };
    let query1 = this.SubMenuCardModel.GetQuery("associatedaccounts");
    let queryResult1 = this.commonService.GetCardRequest(queryVariable1, query1);
    let res;


    // if (this.accArray.length > 0) {
      if (this.selectedItems != undefined) {
        this.selectedItems = [];
      }
      let dbname = sessionStorage.getItem('deploymentKey').toUpperCase();
      this.vitalHttpServices.GetData(queryResult1, dbname).subscribe(data => {
        if (!data.errors) {
          if (data.data.submenuData.length > 0) {
            res = data.data.submenuData;
            let arrDrp = [];
            for (var i = 0; i < res.length; i++) {
              let arr = {};
              this.recipientData.patchValue({
                isPcEnabled: res[i].Is_Pc_Enabled == 'true' ? res[i].Is_Pc_Enabled = true : res[i].Is_Pc_Enabled = false,
                IsViewReport: res[i].Allow_Report_Viewing == 'true' ?  true :  false,
              })
              if(!this.commonService.isPol)
              this.recipientData.get("AssociationType").setValue(res[i].Association_Type) 
              // this.AST.nativeElement.value = res[i].Association_Type;
              this.recipientData.get("Service").setValue(res[i].Services);
              this.oldAsscType = res[i].Association_Type;
              this.oldAssJson = this.recipientData.value;
              let assocname = res[i].Associated_Account_Name?.replace(/ *\([^)]*\) */g, "");
              let tempObj = {
                accountid: res[i].Associated_Account_Id,
                accountname: assocname,
                associationtype: res[i].Association_Type
              }
              arrDrp.push(tempObj);
            }

            if (arrDrp.length > 0) {
              this.isDisabled = true;
            } else {
              this.isDisabled = false;
            }
            this.selectedItems = arrDrp;
            this.editAssocData = this.selectedItems;
          }
          if (sessionStorage.getItem('context') == 'Facility') {
            this.associationTypeList = this.associationTypeList.filter(va => va.item != 'Ordering Facilities')
          }
          else if (sessionStorage.getItem('context') == 'Laboratory') {
            this.associationTypeList = this.associationTypeList.filter(va => va.item != 'Laboratories')
          }
          else if (sessionStorage.getItem('context') == 'Send Out') {
            this.associationTypeList = this.associationTypeList.filter(va => va.item != 'Send-out Laboratories')
          }
          else if (sessionStorage.getItem('context') == 'Send In') {
            this.associationTypeList = this.associationTypeList.filter(va => va.item != 'Send In Lab')
          }
          else if (sessionStorage.getItem('context') == 'EPG') {
            this.associationTypeList = this.associationTypeList.filter(va => va.item != 'Patologists')
          }

          this.addNewAssociation();
        }
        this.ngxService.stop();
      }, error => {
        console.log(error);
        this.ngxService.stop();
      });
    // }
  }

  public selectedSaveVal;
  saveNotSave(val) {
    this.selectedSaveVal = val;
    if (this.selectdTab == 'Location') {
      if (val == 'save') {
        this.OldAddJson = this.AddLocRecipientData.value;
        //this.getCustomorgAttribute();
        //await this.saveCustomorgAttribute();
        this.getpatchdata();
        this.addNewLocation(this.AddLocRecipientData.value, this.AddLocRecipientData.invalid, true);
      } else {
        if (val == 'notsave') {
          if (!this.ifLocEdit) {
            this.ngxService.start();
            if (this.selectedValue == 'nxt') {
              this._snackbar.open("Enter all the mandatory fields under Location tab to proceed further")
              this.ngxService.stop();
            } else if (this.selectedValue == 'prev' || this.selectedValue == 'can') {
              this.ngxService.start();
              this.viewLocation();
              this.ngxService.stop();
            }
          } else if (this.selectedValue == 'nxt') {
            this.selectedIndex = 1;
            this.DisplayLocationTab = true;
            this.DisplayAssocLocTab = false;
            this.DisplayCaseType = true;
            this.DisplayUsersTab = true;
            this.DisplayLogoTab = true;
            this.ifLocationSaved = false;
            this.ifLocChanged = false;
            this.getpatchdata();
            this.fetchExistingAssociations(this._parseAccountID.toString());
          } else if (this.selectedValue == 'prev') {
            this.viewLocation();
          } else if (this.selectedValue == 'can') {
            this.viewLocation();
          }
        }
      }

    }
    if (this.selectdTab == 'Associate') {
      if (val == 'save') {
        if (!this.grid && this.existingData.length == 0) {
          this._snackbar.open("Please associate with at least one location!", "Close");
          this.staticBackdrop = false;
          return;
        }
        this.getpatchdata();
        this.DisplayLocationTab = true;
        this.DisplayAssocLocTab = true;
        this.DisplayCaseType = false;
        this.DisplayUsersTab = true;
        this.DisplayLogoTab = true;
        this.AddAssociation()
        this.selectedIndex = 2;
      } else {
        this.ifAssociateSaved = false;
        this.ifAsscChanged = false;
        if (val == 'notsave') {
          if (this.selectedValue == 'nxt') {
            if (!this.ifAssocEdit) {
              this.recipientData.reset();
            }
            this.getpatchdata();
            this.addeddata = [];
            this.DisplayLocationTab = true;
            this.DisplayAssocLocTab = true;
            this.DisplayCaseType = false;
            this.DisplayUsersTab = true;
            this.DisplayLogoTab = true;
            this.staticBackdrop = false;
            this.selectedIndex = 2;
          } else if (this.selectedValue == 'prev') {
            this.selectedIndex = 0;
            this.DisplayLocationTab = false;
            this.DisplayAssocLocTab = true;
            this.DisplayCaseType = true;
            this.DisplayUsersTab = true;
            this.DisplayLogoTab = true;
          } else if (this.selectedValue == 'can') {
            this.getpatchdata();
            this.addeddata = [];
            this.AssociationDetails = [];
            this.existingData = [];
            this.recipientData.reset();
            this.viewLocation();
          }

        }
      }

    }
    if (this.selectdTab == 'CaseTypes') {
      if (val == 'save') {
        this.associateCaseTypes(true);
      } else {
        this.ifCaseTypesSaved = false;
        this.ifCasetypeChanged = false;
        if (val == 'notsave') {
          if (this.selectedValue == 'nxt') {
            this.selectedIndex = 3;
            this.DisplayLocationTab = true;
            this.DisplayAssocLocTab = true;
            this.DisplayCaseType = true;
            this.DisplayUsersTab = false;
            this.DisplayLogoTab = true;
          } else if (this.selectedValue == 'prev') {
            this.selectedIndex = 1;
            this.DisplayLocationTab = true;
            this.DisplayAssocLocTab = false;
            this.DisplayCaseType = true;
            this.DisplayUsersTab = true;
            this.DisplayLogoTab = true;
            this.getpatchdata();
          } else if (this.selectedValue == 'can') {
            this.viewLocation();
          }

        }
      }
      this.showCasetype = true
    }
    if (this.selectdTab == 'Users') {
      if (val == 'save') {
        this.associateUsers(true);

      } else {
        this.ifUserSaves = false;
        this.ifUserChanged = false;
        if (val == 'notsave') {
          if (this.selectedValue == 'nxt') {
            this.selectedIndex = 4;
            this.DisplayLocationTab = true;
            this.DisplayAssocLocTab = true;
            this.DisplayCaseType = true;
            this.DisplayUsersTab = true;
            this.DisplayLogoTab = false;
          } else if (this.selectedValue == 'prev') {
            this.selectedIndex = 2;
            this.DisplayLocationTab = true;
            this.DisplayAssocLocTab = true;
            this.DisplayCaseType = false;
            this.DisplayUsersTab = true;
            this.DisplayLogoTab = true;
          } else if (this.selectedValue == 'can') {
            this.viewLocation();
          }

        }
      }

    }
    if (this.selectdTab == 'Logo') {
      if (val == 'save') {
        if (this.existingData.length == 0) {
          this._snackbar.open("Associate with at least one location!", "Close");
          this.selectedIndex = 1
          this.DisplayLocationTab = true;
          this.DisplayAssocLocTab = false;
          this.DisplayCaseType = true;
          this.DisplayUsersTab = true;
          this.DisplayLogoTab = true;
          this.staticBackdrop = false;
          return;
        }
        this.ConfirmUploadLogo(true);
      } else {
        this.ifLogoSaved = false;
        this.ifLogoChanged = false;
        if (val == 'notsave') {
          if (this.selectedValue == 'nxt') {
            if (this.existingData.length == 0) {
              this._snackbar.open("Associate with at least one location!", "Close");
              this.selectedIndex = 1
              this.DisplayLocationTab = true;
              this.DisplayAssocLocTab = false;
              this.DisplayCaseType = true;
              this.DisplayUsersTab = true;
              this.DisplayLogoTab = true;
              this.staticBackdrop = false;
              return
            }
            this.viewLocation();
          } else if (this.selectedValue == 'prev') {
            this.selectedIndex = 2;
            this.DisplayLocationTab = true;
            this.DisplayAssocLocTab = true;
            this.DisplayCaseType = false;
            this.DisplayUsersTab = true;
            this.DisplayLogoTab = true;
          } else if (this.selectedValue == 'can') {
            this.viewLocation();
          }

        }
      }

    }
    this.staticBackdrop = false;
  }

  // close Pop up and remove from body
  closeModal() {
    this.showPop = false;
    this.staticBackdrop = false;
  }

  async saveCaseTypes(copyAllConfig : boolean = true) {
    let obj = {
      LabAccountID: this._parseAccountID,
      LabOrgID: sessionStorage.getItem('org_id'),
      CreatedBy: sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100,
      CaseTypes: this.DataShare.savedCaseTypes,
      CaseTypeOrderCode: this.DataShare.savedOrderCodes,
      Instruments: this.DataShare.savedInstruments,
      copyAllConfig : copyAllConfig
    }
    this.ngxService.start();
    let res: any

    try {
      res = await this.vitalHttpServices.configureCaseTypes(obj, sessionStorage.getItem('DeploymentKey')).toPromise();
    }
    catch {
      res = { Success: false };
      this._snackbar.open('Case type configuration failed', 'Close')
    }
    this.ngxService.stop();
    return res;

  }

  previousNxtBtn(tab, val) {
    this.selectdTab = tab;
    this.selectedValue = val;
    if (this.selectdTab == 'Location') {
      if (!this.ifLocationSaved) {
        this.tabDisplayName = 'Location';
        this.staticBackdrop = true;
      } else {
        ///  if(this.ifLocEdit){
        if (val == 'prev') {
          this.refreshLocGrid();
          this.viewLocation();
          this.ngxService.stop();
        } else if (val == 'nxt') {
          this.ngxService.start();
          this.selectedIndex = 1;
          this.DisplayLocationTab = true;
          this.DisplayAssocLocTab = false;
          this.DisplayCaseType = true;
          this.DisplayUsersTab = true;
          this.DisplayLogoTab = true;
          this.ngxService.stop();
        } else if (val == 'can') {
          this.refreshLocGrid();
          this.viewLocation();
          this.ngxService.stop();
        }
      }
      return 
    }
    if (this.selectdTab == 'Associate') {
        this.tabDisplayName = 'Associate';
        this.staticBackdrop = true;
    }
    // if (this.selectdTab == 'Users') {
    //   if (!this.ifUserSaves) {
    //     this.tabDisplayName = 'Users';
    //     this.staticBackdrop = true;
    //   } else {
    //     if (this.selectedValue == 'prev') {
    //       this.selectedIndex = 2;
    //       this.DisplayLocationTab = true;
    //       this.DisplayAssocLocTab = true;
    //       this.DisplayCaseType = false;
    //       this.DisplayUsersTab = true;
    //       this.DisplayLogoTab = true;
    //     } else if (this.selectedValue == 'nxt') {
    //       this.selectedIndex = 4;
    //       this.DisplayLocationTab = true;
    //       this.DisplayAssocLocTab = true;
    //       this.DisplayCaseType = true;
    //       this.DisplayUsersTab = true;
    //       this.DisplayLogoTab = false;
    //     } else if (val == 'can') {
    //       this.refreshLocGrid();
    //       this.viewLocation();
    //     }
    //   }
    // }

    if (this.selectdTab == 'Logo') {
      if (!this.ifLogoSaved) {
        this.tabDisplayName = 'Logo';
        this.staticBackdrop = true;
      } else {
        if (this.selectedValue == 'prev') {
          this.selectedIndex = 2;
          this.DisplayLocationTab = true;
          this.DisplayAssocLocTab = true;
          this.DisplayCaseType = false;
          this.DisplayUsersTab = true;
          this.DisplayLogoTab = true;
        } else if (this.selectedValue == 'nxt') {
          if(!this.existingData.length){
            this.selectedIndex = 1;
            this.DisplayLocationTab = true;
            this.DisplayAssocLocTab = false;
            this.DisplayCaseType = true;
            this.DisplayUsersTab = true;
            this.DisplayLogoTab = true;
            return;
          }
          this.refreshLocGrid();
          this.viewLocation();
        } else if (val == 'can') {
          this.refreshLocGrid();
          this.viewLocation();
        }
        // this.showPop = false;

      }
    }
  }

  fetchCurrentLocationAssociations(checked) {
    if (!checked) {
      this.addeddata = [];
      this.cancelButton = true;
      this.AssociationDetails = []
      this.associationTable = false
      this.currentAssociations = false;
    }
    else {
      this.getpatchdata()
      this.cancelButton = false;
      this.currentAssociations = true;
      this.getCurrentAssociations(sessionStorage.getItem("AccountID"));
    }
  }

  getCurrentAssociations(accountID) {
    let query = this.GetQuery('getAllAssociations');
    let queryVariable = {
      accid: accountID,
      associd : null
    };
    if(this.commonService.isPol && this.entityType.toString().toLowerCase() == 'rl')
      {
        queryVariable.accid = null;
        queryVariable.associd = String(this._parseAccountID);
      }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vaservices.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        let data = Resdata.data.submenuData;
        data = data.filter(va => va.AssociationType != 'My Organization')
        this.addAssociationToList(data)
      }
      this.ngxService.stop();
    });
    (error) => {
      this.ngxService.stop();
      this._snackbar.open('An error occurred while processing your request', 'Failed');
    };
  }

  addAssociationToList(data): any {
    this.associationTable = true;
    this.addeddata = data;
    this.serviceDataMap = [];
    this.servicesList.forEach(element => {
      this.serviceDataMap.push(element.item);
    });
    this.assocGridData = new CollectionView(data, {
      pageSize: 10,
    });
    if (this.addeddata.length > 10) {
      this.showPaginationNewAssocGrid = true;
    } else {
      this.showPaginationNewAssocGrid = false;
    }
  }

  //#region Tooltip for Grid
  initLocationGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }
  //#endregion
  openDelete(rowData) {
    this.deleteData = rowData
    // $('#').modal('show');
    this.deleteAssoc = true;

  }
  nextFromCasetype() {
    this.selectedIndex++;
    this.DisplayLocationTab = true;
    this.DisplayAssocLocTab = true;
    this.DisplayCaseType = true;
    this.DisplayUsersTab = true;
    this.DisplayLogoTab = false;
  }

  prevFromCasetype() {
    this.selectedIndex--;
    this.DisplayLocationTab = true;
    this.DisplayAssocLocTab = false;
    this.DisplayCaseType = true;
    this.DisplayUsersTab = true;
    this.DisplayLogoTab = true;
    this.getpatchdata();
  }




  updateCustomorgAttributeArray(arrtdata: any, event) {
    arrtdata.Value = event.currentTarget.value;
    arrtdata.EntityID = "";
    arrtdata.EntityType = "";
    if (arrtdata.Value != '' || arrtdata.Value != null) {
      if (this.customorgattributrlist.length > 0) {
        const exists = this.customorgattributrlist.some(item => item.Attribute === arrtdata.Attribute &&
          item.LaborgId === arrtdata.LaborgId &&
          item.CAID === arrtdata.CAID &&
          item.EntityID === arrtdata.EntityID &&
          item.EntityType === arrtdata.EntityType &&
          item.Value === arrtdata.Value);
        if (!exists) {
          this.customorgattributrlist.push(arrtdata);
        }
      } else {
        this.customorgattributrlist.push(arrtdata);
      }

    }

  }

  async saveCustomorgAttribute() {
    let dbName = sessionStorage.getItem('deploymentKey');
    for (let i = 0; i < this.customorgattributrlist.length; i++) {
      this.customorgattributrlist[i].EntityID = this._parseAccountID;
      this.customorgattributrlist[i].EntityType = 3;
    }
    await this.vaservices.saveCustomOrgvalues(this.customorgattributrlist, dbName).toPromise();
  }

  customorgattributrlist: any = [];
  customorgvalueslist: any = [];
  async getCustomorgAttribute() {
    let query = this.vaservices.GetQuery('getCustomOrgAttributes');
    this.ngxService.start();
    try {
      let orgArr = [];
      let queryVariable = { id: sessionStorage.getItem('org_id').toString() }
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      let res = await this.vaservices.GetData(queryResult).toPromise();
      let result = res.data.CustomOrgAttributes_list;
      this.customorgattributrlist = result;
    }
    catch(e) {
      console.error(e);
      this._snackbar.open('Error processing your request!', 'Close');
    }
    finally {
      this.ngxService.stop();
    }
  }


  async getCustomAttrValues(orgid?: any, EntityID?, EntityType?) {
    try {
      let orgidt = orgid ? orgid : sessionStorage.getItem('org_id').toString();
      let EntityIDt = EntityID ? EntityID : null;
      let EntityTypet = EntityType;
      let query = this.vaservices.GetQuery('getCustomOrgvalues');
      this.ngxService.start();
      let orgArr = [];
      let queryVariable = { laborgid: orgidt, entityid: EntityIDt.toString() }
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      let res = await this.vaservices.GetData(queryResult).toPromise();
      this.customorgvalueslist = res
      if (this.customorgvalueslist.data.values && this.customorgvalueslist.data.values.length > 0) {
        this.mapCustumAttributes(this.customorgvalueslist)
      }
    }
    catch (e) {
      console.error(e);
      this._snackbar.open('Error processing your request!', 'Close');
    }
    finally {
      this.ngxService.stop();

    }
  }

  mapCustumAttributes(recevedata: any) {
    const joinedData = [];
    recevedata.data.values.forEach(value => {
      let matchingAttribute = recevedata.data.attributes.find(attribute =>
        value.LaborgId === attribute.LaborgId
        && value.CAID === attribute.CAID
      );

      if (matchingAttribute) {
        const joinedEntry = {
          LaborgId: value.LaborgId,
          CAID: value.CAID,
          EntityType: value.EntityType,
          EntityID: value.EntityID,
          Value: value.Value,
          Attribute: matchingAttribute.Attribute
        };
        joinedData.push(joinedEntry);
      }
    });
    this.finalMapAttributes(joinedData, this.customorgattributrlist)
  }

  finalMapAttributes(joinedData: any, customorgattributrlist: any) {
    if (customorgattributrlist.length > 0 && joinedData.length > 0) {
      for (let i = 0; i < customorgattributrlist.length; i++) {
        customorgattributrlist[i].EntityID = '';
        customorgattributrlist[i].EntityType = '';
        customorgattributrlist[i].Value = '';
      }
      let newwarr = [];

      for (let i = 0; i < customorgattributrlist.length; i++) {
        let foundMatch = false;

        for (let j = 0; j < joinedData.length; j++) {
          if (customorgattributrlist[i].CAID === joinedData[j].CAID &&
            customorgattributrlist[i].LaborgId === joinedData[j].LaborgId &&
            customorgattributrlist[i].Attribute === joinedData[j].Attribute) {

            if (customorgattributrlist[i].Value !== '' &&
              customorgattributrlist[i].EntityID !== '' &&
              customorgattributrlist[i].EntityType !== '') {
              newwarr.push(joinedData[j]);
            } else {
              customorgattributrlist[i].EntityID = joinedData[j].EntityID;
              customorgattributrlist[i].EntityType = joinedData[j].EntityType;
              customorgattributrlist[i].Value = joinedData[j].Value;
              newwarr.push(customorgattributrlist[i]);
            }

            foundMatch = true;
            break; // No need to continue searching once a match is found
          }
        }

        // If no match was found in joinedData, push the current item from customorgattributrlist
        if (!foundMatch) {
          newwarr.push(customorgattributrlist[i]);
        }
        this.customorgattributrlist = newwarr
      }
    }

  }
  async locationFilter(event = null) {
    this.temporgid = null;
    var RegExp = /^[0-9]+$/
    let value = event && event.target.value ? event.target.value.trim() : ''
    let idCheck = RegExp.test(value)
    if (value.length >= 3 || idCheck) {
      let item = this.recipientData.controls.AssociationType.value
      if (value.length == 3 || RegExp.test(value)) {
        let associationtype = item == 'Ordering Facilities' ? 'Ordering Facilities' : item == 'Pathologists' ? 'epg' : 'Laboratories'
        var obj;

        if (this.recipientData.controls.Selecttype.value == 'Accounts') {
          obj = {
            loggedInAccountId: this._parseAccountID,
            associationType: associationtype,
            accountName: !idCheck ? value : null,
            accountId: idCheck ? value : null
          };
          if(this.commonService.isPol && (item.match(/Ordering Facilities/i) || item == "Laboratories")) obj.organizationid = this.commonService.orgid;
          if(this.entityType.match(/rl/i) && item.match(/patho/i)) obj.organizationid = this.commonService.orgid;
        }
        else if (this.recipientData.controls.Selecttype.value == 'Organizations') {
          obj = {
            loggedInAccountId:this.templateData.cardIdentifier,
            associationType: associationtype,
            organizationName: !idCheck ? value : null,
            organizationid: idCheck ? value : null
          };
        }
        await this.vitalHttpServices.getAssociatedOrganizations(obj).toPromise().then(
          (res) => {
            if (res.Success) {
              this.currentAssociations = false;
              this.AssociatedOrgaDetails = JSON.parse(res.Message);
              this.tempAssociatedOrgaDetails = this.AssociatedOrgaDetails;
            }
            else {
              this.AssociatedOrgaDetails = [];
            }
          }), error => {
            console.log(error)
          }
      }
      else if (event.target.value.length > 3) {
        this.AssociatedOrgaDetails = this.tempAssociatedOrgaDetails.filter(va => va.accountname.toLowerCase().includes(event.target.value))
      }
      let tempAssociationArray = this.templateData.submenuData && this.templateData.submenuData.filter(va => va.Association_Type == item)
      if (tempAssociationArray && tempAssociationArray.length > 0) {
        // Create a Set of unique Associated_Account_Id values
        const associatedAccountIdsSet = new Set(tempAssociationArray.map(element => element.Associated_Account_Id.toString()));

        // Filter out elements that have a matching Associated_Account_Id in tempAssociationArray
        this.AssociatedOrgaDetails = this.AssociatedOrgaDetails.filter(element1 =>
          !associatedAccountIdsSet.has(element1.accountid)
        );
      }

      this.ofAccountDetails = this.AssociatedOrgaDetails.filter(
        (va) =>
          va.organizationid != null && !(Math.sign(va.organizationid) == -1)
      );
      this.organizationsList = [...new Map(this.ofAccountDetails.map((item) => [item['organizationid'], item])).values(),];
      this.locationsList = this.AssociatedOrgaDetails;
    }
    else {
      this.organizationsList = [];
      this.locationsList = []
    }
  }

  locListVisible(header){
    let hiddenColumns = ['CreatedBy','CreatedDate','ModifiedBy','ModifiedDate','Type'];
    return !hiddenColumns.some(column=> column == header);
  }
}


class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}
