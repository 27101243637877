import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { CellEditEndingEventArgs, FlexGrid } from "@grapecity/wijmo.grid";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
@Component({
  selector: 'app-case-profiles',
  templateUrl: './case-profiles.component.html',
  styleUrls: ['./case-profiles.component.scss']
})
export class CaseProfilesComponent implements OnInit {
  hideExportBtn: boolean;
  hideCreateBtn: boolean;
  hideEditBtn: boolean;
  enablecreate : boolean = false
  gridheader = [
    'CaseProfileId',
    'accountid',
    'IsActive',
    'Modified_by',
    'Modified_date',
    'Bill_To',
    'ICD10',
    'caseid',
    'No_of_jars',
    'Services',
    'Extraction_procedure',
    'Order_code',
    'Case_type',
    'Display_name',
    'Sequence',
  ];
  // columns = [
  //   new ColDef('CaseProfileId', 'CaseProfileId'),
  //   new ColDef('caseid', 'Case id'),
  //   new ColDef('accountid', 'Account ID'),
  //   new ColDef('Modified_by', 'Modified by'),
  //   new ColDef('Modified_date', 'Modified date'),
  //   new ColDef('Bill_To', 'Bill to'),
  //   new ColDef('ICD10', 'ICD10'),
  //   new ColDef('No_of_jars', 'No of jars'),
  //   new ColDef('Services', 'Services'),
  //   new ColDef('Extruction_procedure', 'Extruction procedure'),
  //   new ColDef('Order_code', 'Order code'),
  //   new ColDef('Case_type', 'Case type'),

  // ];
  @Input() templateData: any;
  gridData: any;
  private dragIndex: number;
  hideDragnDropBtn: any;
  changeToTrue: boolean;
  subMenuCardModel: SubMenuCardModel;
  tempExport: any[];

  constructor(private vitalservice: VitalHttpServices, private dialog: MatDialog, private datashare: DataShareService, private ngxService: NgxUiLoaderService, private commonService: CommonService, private _snackbar: MatSnackBar) {
    this.subMenuCardModel = new SubMenuCardModel(commonService, vitalservice, datashare);

  }

  async ngOnInit() {
    await this.CreateConfig();
    this.GetButtondetails();
    this.AddGridData();
    

  }

  // for RBAC
  GetButtondetails() {
    this.GetButtonAccess(this.vitalservice.SubmenuAction);
    // this.DataShare.SubmenuAction.subscribe((data) => {
    //   if (data) {
    //     // this.actionButtonDetails = data;
    //     this.GetButtonAccess(data);
    //   }
    // });
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Create':
          this.hideCreateBtn = (seletedMenuPermissions[i].IsPermitted  == "true" && this.enablecreate == true) ? false : true;
          break;
        case 'Edit':
          this.hideEditBtn = (seletedMenuPermissions[i].IsPermitted == "true" && this.enablecreate == true)? false : true;
          break;
        case 'DragnDrop':
          this.hideDragnDropBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }

  async CreateConfig(){
    let obj = {
      entityid: sessionStorage.getItem('org_id'),
      entytitype: 2,
      ConfigName: "IsCaseProfileEnabled",
      tableName: "VaEntityExtAttributes",
      scope: 3
    }
    this.ngxService.start();
    await this.vitalservice.getVAEntityAttribute(obj, sessionStorage.getItem('deploymentKey')).toPromise().then((res) => {
      this.ngxService.stop();
      if(res && res.Success){
        let data = JSON.parse(res.Message);
        if(data[0].All_Locations_Value_1.toLowerCase()=='true'){
         this.enablecreate = true;
        }else{
          this.enablecreate = false;
        }
      }
    },  error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
    });
  }

  AddGridData() {
    let gridarray = [];
    let primary = {};

    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      this.tempExport = [...this.templateData.submenuData];
      for (let i = 0; i < this.templateData.submenuData.length; i++) {
        primary = {};
        for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
          for (let j = 0; j < this.gridheader.length; j++) {
            if (key.toLowerCase() === this.gridheader[j].toLowerCase()) {
              if (value == null || value.toString() == 'null' || value.toString().trim() == '') {
                value = 'Not Specified';
              }
              primary[key] = value;
            }
          }
        }
        gridarray.push(primary);
      }
      gridarray.sort((a, b) => a.Sequence > b.Sequence ? 1 : -1)
      this.gridData = new CollectionView(gridarray)
    }

    // this.roleArray = this.templateData.submenuData.submenuData2;
    //this.UserDetails = this.templateData.submenuData.submenuData4;
    // this.gridData = new CollectionView(gridarray, { pageSize: 10 });
    // this.gridwidth = 120 * (this.gridheader.length - 1 + 37);
    // if (this.gridwidth > 1300) this.gridwidth = 760;
  }
  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell)
        return;
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
    grid.beginningEdit.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.CellRangeEventArgs) => {
      let col = s.columns[e.col];

      if (col.binding == 'Display_name') {
        let item = s.rows[e.row].dataItem;
      }
    });
    grid.cellEditEnded.addHandler((s: FlexGrid, e: CellEditEndingEventArgs) => {

      let oldValue = e.data;
      let newValue = s.getCellData(e.row, e.col, true);
      if (oldValue !== newValue) {
        if (!this.hideEditBtn) {
          let col = s.columns[e.col];
          if (col.binding == 'IsActive') {
            let obj = {
              isactive: newValue,
              caseProfileId: s.rows[e.row].dataItem.CaseProfileId,
              userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid')
            }
            this.ngxService.start();
            this.vitalservice.UpdateActiveStatusforCaseProfile(obj)
              .subscribe((res) => {
                if (res && res.Success == true) {
                  this.getUpdatedGrid();
                  this.ngxService.stop();
                  this._snackbar.open(res.Message, 'Close');
                  this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Case Profiles', 'Audit', obj, { isactive: oldValue })
                }
              }, error => {
                // Change position in view level if update fail
                this.ngxService.stop();
                this._snackbar.open("An error occurred while processing your request", "Failed");
              });

          }
          if (col.binding == 'Display_name') {
            let obj = {
              Display_name: newValue,
              caseProfileId: s.rows[e.row].dataItem.CaseProfileId,
              userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid')
            }
              // for controlling unwanted edit
              let value = this.templateData.submenuData.find(va => va.CaseProfileId == s.rows[e.row].dataItem.CaseProfileId)
              if (value.Display_name == newValue) { // checking if nothing is updated it will return back.
                this.getUpdatedGrid();
              return
              }
            this.ngxService.start();
            this.vitalservice.UpdateCaseProfiledisplyname(obj)
              .subscribe((res) => {
                if (res && res.Success == true) {
                  this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Case Profiles', 'Audit', obj, { Display_name: oldValue })
                  this.getUpdatedGrid();
                  this.ngxService.stop();
                  this._snackbar.open(res.Message, 'Close');
                }
              }, error => {
                // Change position in view level if update fail
                this.ngxService.stop();
                this._snackbar.open("An error occurred while processing your request", "Failed");
              });
          }
        }
        else {
          this._snackbar.open('User is not authorized to perform this action.', 'Close');
          this.getUpdatedGrid();
        }
      }

    });
    //   grid.cellEditEnding.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.CellEditEndingEventArgs) => {
    //     let col = s.columns[e.col];
    //     if (col.binding == 'IsActive') {
    //       let item = s.rows[e.row].dataItem;
    //       if (item.IsActive) {
    //         item.IsActive=false;
    //       }
    //       else{
    //         item.IsActive=true
    //       }
    //       let obj={
    //         isactive:item.IsActive,
    //         caseProfileId:item.CaseProfileId,
    //         userid:sessionStorage.getItem('Userid')
    //       }
    //       this.ngxService.start();
    //       this.vitalservice.UpdateActiveStatusforCaseProfile(obj)
    //         .subscribe((res) => {
    //           if (res && res.Success == true) {
    //             this.getUpdatedGrid();
    //             this.ngxService.stop();
    //             this._snackbar.open(res.Message, 'Close');
    //           }
    //         }, error => {
    //           // Change position in view level if update fail
    //           this.ngxService.stop();
    //           this._snackbar.open("An error occurred while processing your request", "Failed");
    //         });
    //       //
    //   }
    //   else if (col.binding == 'Display_name'){
    //     let item = s.rows[e.row].dataItem;
    //   }
    //     // let item = s.rows[e.row].dataItem;
    //     // if (col.binding == 'IsActive' || col.binding == 'IsActive') {
    //     //     let value = wjcCore.changeType(s.activeEditor.value, wjcCore.DataType.Number, col.format);
    //     //     if (!wjcCore.isNumber(value) || value < 0) { // prevent negative sales/expenses
    //     //         e.cancel = true;
    //     //     }
    //     // }
    // });

    //   grid.selectionChanged.addHandler((s,e) => {
    //     this.ngxService.start();
    //     if (grid.selection.isSingleCell) {
    //        alert(s.getCellData(e.row,e.col))
    //   }
    //   this.ngxService.stop();
    // });
  }
  onRowDragging(s: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    this.dragIndex = e.row;
    s.collectionView.moveCurrentToPosition(this.dragIndex);
  }
  onRowDragged(s: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    let dropIndex = e.row,
      arr = s.collectionView.sourceCollection;
    let dragId: number;
    let dropId: number;
    let dragItem = arr[this.dragIndex];
    let dropItem = arr[dropIndex];
    s.collectionView.deferUpdate(() => {
      dragId = dragItem.CaseProfileId;
      dropId = dropItem.CaseProfileId;
      arr.splice(this.dragIndex, 1);
      arr.splice(dropIndex, 0, dragItem);
      s.collectionView.moveCurrentToPosition(dropIndex);
    });
    if (!this.hideDragnDropBtn) {
      // drag and drop site is not matching, not allowed to switch
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "", message: "", alert: "The order in which the Case Profile are listing will be changed. Do you want to continue?", continue: "yes", cancel: "no" }
      });
      return dialogRef.afterClosed().toPromise().then((result) => {
        if (result) {  //Change position in view level if we want/(yes)
          if (dragId && dropId) {
            let obj = {
              dragId: dragId,
              dropId: dropId,
              orgid: sessionStorage.getItem('org_id'),
              userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid')

            }
            this.ngxService.start();
            this.vitalservice.updateSequenceCaseProfiles(obj)
              .subscribe((res) => {
                if (res && res.Success == true) {
                  this.getUpdatedGrid();
                  this.ngxService.stop();
                  this._snackbar.open(res.Message, 'Close');
                } else {

                  // Change position in view level if update fails
                  s.collectionView.deferUpdate(() => {
                    arr.splice(dropIndex, 1);
                    arr.splice(this.dragIndex, 0, dragItem);
                    s.collectionView.moveCurrentToPosition(this.dragIndex);
                  });
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                }
              }, error => {
                // Change position in view level if update fails
                s.collectionView.deferUpdate(() => {
                  arr.splice(dropIndex, 1);
                  arr.splice(this.dragIndex, 0, dragItem);
                  s.collectionView.moveCurrentToPosition(this.dragIndex);
                });
                this.ngxService.stop();
                this._snackbar.open("An error occurred while processing your request", "Failed");
              });
          }
          else {
            // Change position in view level if undefined
            s.collectionView.deferUpdate(() => {
              arr.splice(dropIndex, 1);
              arr.splice(this.dragIndex, 0, dragItem);
              s.collectionView.moveCurrentToPosition(this.dragIndex);
            });
            this._snackbar.open("An error occurred while processing your request", "Failed");
          }
        }
        else {
          // Change position in view level if we don't want/(no)
          s.collectionView.deferUpdate(() => {
            arr.splice(dropIndex, 1);
            arr.splice(this.dragIndex, 0, dragItem);
            s.collectionView.moveCurrentToPosition(this.dragIndex);
          });
        }
      });
    }
    else {
      this._snackbar.open('User is not authorized to perform this action.', 'Close')
    }
  }
  editCaseProfile(row) {
    let deployid = sessionStorage.getItem('deploymentKey');
    let dxurl=this.vitalservice.OrgaAppUrl == "" ? this.vitalservice.GetDxurlBasedOnDeployment(deployid) : this.vitalservice.OrgaAppUrl; 
    var queryString = 'VACreateCaseProfile.aspx?caseid={0}&from=admin&organizationid={1}&userid={2}&accountid={3}';
    let addedCaseID = queryString.replace('{0}', row.dataItem.caseid);
    let addedOrgId = addedCaseID.replace('{1}', sessionStorage.getItem('org_id'))
    let addedUserId = addedOrgId.replace('{2}', sessionStorage.getItem('Userid'))
    let addedaccountid = addedUserId.replace('{3}', row.dataItem.accountid)
    var newWindow = window.open(dxurl + addedaccountid);

  }
  createCaseProfile() {
    let deployid = sessionStorage.getItem('deploymentKey'); 
    let dxurl=this.vitalservice.OrgaAppUrl == "" ? this.vitalservice.GetDxurlBasedOnDeployment(deployid) : this.vitalservice.OrgaAppUrl;   
    var queryString = 'VACreateCaseProfile.aspx?caseid={0}&from=admin&organizationid={1}&userid={2}&accountid={3}';
    let addedCaseID = queryString.replace('{0}', '0');
    let addedOrgId = addedCaseID.replace('{1}', sessionStorage.getItem('org_id'))
    let addedUserId = addedOrgId.replace('{2}', sessionStorage.getItem('Userid'))
    let addedaccountid = addedUserId.replace('{3}', sessionStorage.getItem('Locationid'))
    var newWindow = window.open(dxurl + addedaccountid);
  }
  getUpdatedGrid() {
    let queryVariable = { orgid: this.templateData.secondarykeys.OrganizationId.toString() };
    let query = this.subMenuCardModel.GetQuery("managecaseprofile");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalservice.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        this.templateData.submenuData = [];
        this.templateData.submenuData = data.data.submenuData;
        this.AddGridData();
      }
    },error => {
        console.error(error);
      });
  }
  exportCaseProfile(flex) {
    let orgname = sessionStorage.getItem('Org_Name');
    let filename = 'Case Profiles_';

    if (orgname != 'undefined' && orgname) {
      filename = filename + orgname;
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
      let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
          if (orgName.length > 31) {
            fileName = filename.split('_')[0].replace(' ','') + '_';
            filename = fileName + orgName + '_';
          }
          else {
            filename = fileName + orgName + '_';
          }
        }
        else {
          filename = filename + '_';
        }
      }
    var sheet = XLSX.utils.book_new();
    let array = [];
    for (let itr = 0; itr < flex.length; itr++) {
      array.push(flex[itr]);
    }
    filename = filename +  this.templateData.secondarykeys.OrganizationId.toString()+'.xlsx';
    if(filename.length > 31){
      let fileName = filename.split('_')[0] + '_';
      if(fileName.length>31){
        filename = fileName.split('_')[0] +'.xlsx';
      }else{
         if(this.templateData.secondarykeys){
        filename = fileName  + this.templateData.secondarykeys.OrganizationId.toString()+'.xlsx';
      }
      else{
        filename = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    let sheetData = XLSX.utils.json_to_sheet(array);
    XLSX.utils.book_append_sheet(sheet, sheetData, 'caseprofiles');
    XLSX.writeFile(sheet, filename);
  }



  // get statistics for the current selection


}
class ColDef {
  constructor(public binding?: string, public header?: string) { }
}
