<div *ngIf="sourceApp != 'VitalDx'" class="modal-content bordernone ml-2" style="width: calc(100vw - 19vw);">
    <div class="col-md-12 m-0 p-0">
        <div class="modal-header admin-section-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong>Custom Listing</strong>
            </h3>
        </div>
    </div>
</div>
<!-- <div class="row col-sm-12 m-0 p-0" *ngIf="sourceApp == 'VitalDx' && !customListingEditMode">
    <div class="col-sm-8 m-0 p-0 mt-2">
        <strong class="menu-title">Custom Listing</strong>
    </div>
</div> -->
<div class="custom-listing-container p-0" style="width: calc(100vw - 19vw);">
    <div class="ml-4 mr-2" [style]="!customListingEditMode ? 'display: block': 'display: none'">
        <div class="custom-listing-container-header mx-3" *ngIf="rolesData.length && usersData.length">
            <ng-container >
                <app-auto-complete-select placeholder="Role" [dropDownValue]="rolesData" [defaultValue]="selectedRole" key="SearchableText"
                    value="Role" (onSelection)="roleSelection($event)"></app-auto-complete-select>
                <app-auto-complete-select placeholder="User" [dropDownValue]="usersData"
                    (onSelection)="userSelection($event)" [defaultValue]="selectedUser.userName" key="username"
                    value="username" [isDisabled]="selectedRole==''"></app-auto-complete-select>
                <app-auto-complete-select placeholder="Tab" [dropDownValue]="tabList" key="TabDisplayName"
                    value="TabDisplayName" (onSelection)="tabSelection($event)" [defaultValue]="selectedTab"
                    [isDisabled]="selectedRole==''"></app-auto-complete-select>
                <app-auto-complete-select placeholder="Worklist" [dropDownValue]="workList" key="DisplayName"
                    value="DisplayName" (onSelection)="workListSelection($event)"
                    [isDisabled]="selectedRole == '' || selectedTab == ''" [defaultValue]="selectedWorkList"></app-auto-complete-select>
            </ng-container>
            <app-search-box class="ml-2" placeHolder="Search Column Name" [searchText]="searchText"
                (enteredValue)="filterStatus({searchtext:$event})"
                [isDisabled]="!customWorkListData?.currentData || customListingSequenceEditMode"></app-search-box>
            <ng-container *ngIf="!customListingSequenceEditMode; else saveOrReturn">
                <app-button class="ml-auto" *ngIf="customWorkListData?.currentData"  matTooltip="Reorder" [matTooltipDisabled]="isworklistChanged() || isEditDisabled"
                    [isDisabled]="isworklistChanged() || isEditDisabled" image="icons/Reorder_Blue.svg"
                    (click)="openSequenceEdit()"></app-button>
                <app-button class="ml-auto" (click)="openManageByUserDialog()" [isDisabled]="isEditDisabled">Manage By
                    User</app-button>
            </ng-container>
            <ng-template #saveOrReturn>
                <app-button buttonclass="secondary" class="edit-page-button" (click)="showAlert()">Return</app-button>
                <app-button buttonclass="primary" class="edit-page-button" (click)="saveSequence()"
                    [isDisabled]="!isDataChanged">Save</app-button>
            </ng-template>
        </div>
        <div *ngIf="rolesDataNotFound"
            class="col-sm-12 text-center mt-4">
                    <span class="w-50 text-center mt-4" class="nodata-design">
                        No Records Found To Display
                    </span>
        </div>
        <div *ngIf="selectedWorkList && workListFilteredData$ | async as customListingData; else loading">
            <ng-container *ngIf="!customListingData.isNoDataPresent">
            <div class="custom-listing-header" [class.edit-sequence]="customListingSequenceEditMode"
                *ngIf="searchSubject$ | async as search">
                <span *ngIf="customListingSequenceEditMode" id="swap"></span>
                <span id="sequence">Seq</span>
                <span class="columnName">Column Name</span>
                <span id="columnWidth">Column Width (in PX)</span>
                <span id="columnVisi">Column Visibility
                    <img src="../../../../assets/icons/Filter.svg" *ngIf="!customListingSequenceEditMode" alt="filter"
                        class="ml-2 cusor-pointer" width="14px"
                        [class.filter-select-color]="t1.menuOpen || search.colVisible != 'all'"
                        [matMenuTriggerFor]="filter" matTooltip="Filter" #t1="matMenuTrigger">
                    <mat-menu #filter="matMenu" xPosition="before">
                        <button mat-menu-item class="filter-menu-option"
                            [appMarkAsChecked]="search.colVisible === 'all'"
                            (click)="filterStatus({colVisible:'all'})">All</button>
                        <button mat-menu-item class="filter-menu-option" [appMarkAsChecked]="search.colVisible === '1'"
                            (click)="filterStatus({colVisible:'1'})">Yes</button>
                        <button mat-menu-item class="filter-menu-option" [appMarkAsChecked]="search.colVisible === '0'"
                            (click)="filterStatus({colVisible:'0'})">No</button>
                        <button mat-menu-item class="filter-menu-option" [appMarkAsChecked]="search.colVisible === '-2'"
                            (click)="filterStatus({colVisible:'-2'})">Default Sort</button>
                    </mat-menu>
                </span>
                <span id="addInSearch">Add In Search
                    <img src="../../../../assets/icons/Filter.svg" *ngIf="!customListingSequenceEditMode" alt="filter"
                        class="ml-2 cusor-pointer" width="14px"
                        [class.filter-select-color]="t2.menuOpen || search.addInSearch != 'all'"
                        [matMenuTriggerFor]="filter2" matTooltip="Filter" #t2="matMenuTrigger">
                    <mat-menu #filter2="matMenu" xPosition="before">
                        <button mat-menu-item class="filter-menu-option"
                            [appMarkAsChecked]="search.addInSearch === 'all'"
                            (click)="filterStatus({addInSearch:'all'})">All</button>
                        <button mat-menu-item class="filter-menu-option" [appMarkAsChecked]="search.addInSearch === '1'"
                            (click)="filterStatus({addInSearch:'1'})">Yes</button>
                        <button mat-menu-item class="filter-menu-option" [appMarkAsChecked]="search.addInSearch === '0'"
                            (click)="filterStatus({addInSearch:'0'})">No</button>
                    </mat-menu>
                </span>
                <span id="export">Add In Export
                    <img src="../../../../assets/icons/Filter.svg" *ngIf="!customListingSequenceEditMode" alt="filter"
                        class="ml-2 cusor-pointer" width="14px"
                        [class.filter-select-color]="t3.menuOpen || search.addInExport != 'all'"
                        [matMenuTriggerFor]="filter3" matTooltip="Filter" #t3="matMenuTrigger">
                    <mat-menu #filter3="matMenu" xPosition="before">
                        <button mat-menu-item class="filter-menu-option"
                            [appMarkAsChecked]="search.addInExport === 'all'"
                            (click)="filterStatus({addInExport:'all'})">All</button>
                        <button mat-menu-item class="filter-menu-option" [appMarkAsChecked]="search.addInExport === '1'"
                            (click)="filterStatus({addInExport:'1'})">Yes</button>
                        <button mat-menu-item class="filter-menu-option" [appMarkAsChecked]="search.addInExport === '0'"
                            (click)="filterStatus({addInExport:'0'})">No</button>
                    </mat-menu>
                </span>
                <span id="addInGroup">Add In Group By
                    <img src="../../../../assets/icons/Filter.svg" *ngIf="!customListingSequenceEditMode" alt="filter"
                        class="ml-2 cusor-pointer" width="14px"
                        [class.filter-select-color]="t4.menuOpen || search.addInGroupBy != 'all'"
                        [matMenuTriggerFor]="filter4" matTooltip="Filter" #t4="matMenuTrigger">
                    <mat-menu #filter4="matMenu" xPosition="before">
                        <button mat-menu-item class="filter-menu-option"
                            [appMarkAsChecked]="search.addInGroupBy === 'all'"
                            (click)="filterStatus({addInGroupBy:'all'})">All</button>
                        <button mat-menu-item class="filter-menu-option"
                            [appMarkAsChecked]="search.addInGroupBy === '1'"
                            (click)="filterStatus({addInGroupBy:'1'})">Yes</button>
                        <button mat-menu-item class="filter-menu-option"
                            [appMarkAsChecked]="search.addInGroupBy === '0'"
                            (click)="filterStatus({addInGroupBy:'0'})">No</button>
                    </mat-menu>
                </span>
                <span id="edit" *ngIf="!customListingSequenceEditMode"></span>
            </div>
            <div class="cl-overflow" #scrollableDiv (scroll)="onScroll($event)" *ngIf="customListingData.data.swapableData.length || customListingData.data.otherData.length">
                <div class="custom-listing-body mt-2" cdkDropList [cdkDropListData]="customListingData.data.swapableData"
                    (cdkDropListDropped)="drop($event)">
                    <div *ngFor="let cl of customListingData.data.swapableData" cdkDragBoundary=".custom-listing-body"
                        cdkDragLockAxis="y" cdkDragPreviewContainer="parent" cdkDrag
                        [cdkDragDisabled]="!customListingSequenceEditMode || cl.SeqNumber === ''">
                        <div class="drag-handle" cdkDragHandle *ngIf="customListingSequenceEditMode">
                            <div class="drag-handle" *ngIf="cl.SeqNumber != ''" cdkDragHandle>
                                <img src="/assets/icons/Drag_handle.svg" alt="Edit" width="14px" />
                            </div>
                        </div>
                        <span *ngIf="!customListingSequenceEditMode; else editSequenceNumber"
                            id="sequence">{{cl.SeqNumber}}</span>
                        <ng-template #editSequenceNumber>
                            <span>
                                <input *ngIf="cl.SeqNumber != ''" #seqInp type="text" maxlength="2"
                                    (focusout)="onFocusOut(cl.SeqNumber, seqInp, customListingData.data.swapableData)"
                                    (keyup.enter)="onFocusOut(cl.SeqNumber, seqInp, customListingData.data.swapableData)"
                                    [value]="cl.SeqNumber" class="text-center seq-input" appOnlyNumber />
                            </span>
                        </ng-template>
                        <span class="column-name">{{cl.DisplayName}}
                            <span *ngIf="cl.OrderType">
                                <img class="ml-2" *ngIf="cl.OrderType =='desc'" width="14px"
                                    src="../../../../assets/icons/Reorder_Descending.svg" alt="Ascending">
                                <img class="ml-2" *ngIf="cl.OrderType =='asc'" width="14px"
                                    src="../../../../assets/icons/Reorder_Asceding.svg" alt="Ascending">
                            </span>
                        </span>
                        <span id="columnWidth">{{cl.Width}}</span>
                        <span id="columnVisi" [appAddCheckOrCross]="cl.uiIsvisible"></span>
                        <span id="addInSearch" [appAddCheckOrCross]="cl.uiIsSearchable"></span>
                        <span id="export" [appAddCheckOrCross]="cl.uiDefaultExport"></span>
                        <span id="addInGroup" [appAddCheckOrCross]="cl.uiIsGroupby"></span>
                        <span id="edit" *ngIf="!customListingSequenceEditMode">
                            <img src="/assets/icons/Edit-fill.svg" [class.disabled]="isEditDisabled"
                                class="cusor-pointer" alt="Edit" width="14px" [matTooltipDisabled]="isEditDisabled"
                                matTooltip="Edit" (click)="isEditDisabled ? null : onEdited(cl)">
                        </span>
                    </div>
                </div>
                <div class="custom-listing-body">
                    <div *ngFor="let cl of customListingData.data.otherData">
                        <div *ngIf="customListingSequenceEditMode"></div>
                        <span *ngIf="!customListingSequenceEditMode; else editSequenceNumber"
                            id="sequence">{{cl.SeqNumber}}</span>
                        <ng-template #editSequenceNumber>
                            <span></span>
                        </ng-template>
                        <span class="column-name">{{cl.DisplayName}}
                            <span *ngIf="cl.OrderType">
                                <img class="ml-2" *ngIf="cl.OrderType =='desc'" width="14px"
                                    src="../../../../assets/icons/Reorder_Descending.svg" alt="Ascending">
                                <img class="ml-2" *ngIf="cl.OrderType =='asc'" width="14px"
                                    src="../../../../assets/icons/Reorder_Asceding.svg" alt="Ascending">
                            </span>
                        </span>
                        <span id="columnWidth">{{cl.Width}}</span>
                        <span id="columnVisi" [appAddCheckOrCross]="cl.uiIsvisible"></span>
                        <span id="addInSearch" [appAddCheckOrCross]="cl.uiIsSearchable"></span>
                        <span id="export" [appAddCheckOrCross]="cl.uiDefaultExport"></span>
                        <span id="addInGroup" [appAddCheckOrCross]="cl.uiIsGroupby"></span>
                        <span id="edit" *ngIf="!customListingSequenceEditMode">
                            <img src="/assets/icons/Edit-fill.svg" [class.disabled]="isEditDisabled"
                                class="cusor-pointer" alt="Edit" width="14px" [matTooltipDisabled]="isEditDisabled"
                                matTooltip="Edit" (click)="isEditDisabled ? null : onEdited(cl)">
                        </span>
                    </div>
                </div>
            </div>
            <div  *ngIf="customListingData.data.swapableData.length === 0 && customListingData.data.otherData.length === 0">
            <div class="col-sm-12 text-center mt-4">
                <span class="w-50 text-center mt-4" class="nodata-design">
                    No Data Found
                </span>
            </div>
        </div>
            </ng-container>
        <ng-container *ngIf="customListingData.isNoDataPresent">
            <div class="col-sm-12 text-center mt-4">
                <span class="w-50 text-center mt-4" class="nodata-design">
                    No Records Found To Display
                </span>
            </div>
        </ng-container>

        </div>
        <ng-template #loading>
            <mat-spinner class="labadmin-spinner" *ngIf="isDataLoading && sourceApp == 'VitalDx'" style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50"></mat-spinner>
        </ng-template>
    </div>
    <div class="ml-4 mr-2" *ngIf="customListingEditMode" [formGroup]="formCustomListingDetails">
        <div class="custom-listing-container-header">
            <div class="heading">
                <b>Edit Custom Listing</b>
            </div>
            <span class="ml-auto"></span>
            <span class="ml-auto cusor-pointer form-reset" *ngIf="isCustomListingDataChanged()"
                (click)="onReset()">Reset</span>
            <app-button buttonclass="secondary" class="edit-page-button" (click)="returnToList()">Return</app-button>
            <app-button buttonclass="primary" class="edit-page-button" (click)="onSave()"
                [isDisabled]="!isCustomListingDataChanged()">Save</app-button>
        </div>
        <div class="d-flex align-items-center edit-border" id="editBorder">
            <div class="edit-column-name">
                <span>Column Name</span>
                <b class="font-size-15 " id="txt-wrap">{{displayName}}</b>
            </div>
            <div class="ml-auto d-flex align-items-center">
                <div class="edit-custom-listing-input">
                    <app-input labelValue="Column Width" mask="000" formcontrolname="Width" subscript="PX"></app-input>
                </div>
                <div class="edit-custom-listing">
                    <div class="firstChild" *ngIf="currentItem?.uiIsvisible != -1">
                        <div class="edit-column-visibility align-self-center">
                            <b>Column Visibility:</b>
                            <app-toggle-switch formcontrolname="IsVisible"></app-toggle-switch>
                        </div>
                        <div *ngIf="formCustomListingDetails.get('IsVisible').value">
                            <div *ngIf="formCustomListingDetails.get('IsVisible').value"
                                class="default-input-width">
                                <b>Set as Default Sort</b>
                                <input #setAsDefault type="checkbox" [checked]="isSetSortDefaultChecked"
                                    [disabled]="currentItem.OrderType != ''"
                                    (click)="onSetDefaultOrder(setAsDefault.checked)" />
                            </div>
                            <span *ngIf="formCustomListingDetails.get('IsVisible').value && isSetSortDefaultChecked"
                                class="mt-2">
                                <mat-button-toggle-group class="toggleButton mt-2" formControlName="DefaultSort"
                                    aria-label="Font Style">
                                    <mat-button-toggle value="asc">Ascending</mat-button-toggle>
                                    <mat-button-toggle value="desc">Descending</mat-button-toggle>
                                </mat-button-toggle-group>
                            </span>
                        </div>
                    </div>
                    <div class="lastChild">
                        <div *ngIf="currentItem?.uiIsSearchable != -1" class="default-input-width">
                            <b>Add in Search:</b>
                            <app-toggle-switch formcontrolname="IsSearchable"></app-toggle-switch>
                        </div>
                        <div *ngIf="currentItem?.uiDefaultExport != -1" class="default-input-width">
                            <b>Add in Export:</b>
                            <app-toggle-switch formcontrolname="DefaultExport"></app-toggle-switch>
                        </div>
                        <div *ngIf="currentItem?.uiIsGroupby != -1" class="default-input-width">
                            <b>Add in Group By:</b>
                            <app-toggle-switch formcontrolname="IsGroupby"></app-toggle-switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="false" class="col-sm-12 text-center mt-4">
            <span class="w-50 text-center mt-4" class="nodata-design">
                No Data Found
            </span>
        </div>
    </div>

