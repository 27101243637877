<div class="modal-body set-location-wrap" [ngClass]="{'vitalDx': sourceApp === 'VitalDx'}">
  <div class="row">
    <div class="col-sm-12 p-0">
      <div class="row" *ngIf="sourceApp==''">
        <div class="col-md-12">
          <div class="modal-header admin-section-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
              <strong>Manage Case Profiles</strong>
            </h3>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="sourceApp!=''" class="mt-3">
        <strong class="caseprofile-main-header">Manage Case Profiles</strong>
      </div> -->
      
      <ng-container *ngIf="caseProfilesFitered$|async as profiles">
        <div class="modal-body">
            <div *ngIf="!sequenceEditMode" class="d-flex align-items-center mt-3 mb-2 filters">
            <span class="not-selectable" tabindex="0" [ngClass]="[filterCondition.value.status.toLowerCase()=='all' ? 'filterselected' : '',profiles.isNoDataPresent?'disabled':'']"
            (click)="!profiles.isNoDataPresent?filterStatus({status:'all'}):null">All ({{profiles.totalCount}})</span>
            <span id="seperator"></span>
                <div class="filters d-flex not-selectable flex-wrap">
                    <span class="not-selectable" tabindex="0"
                        [ngClass]="[filterCondition.value.status.toLowerCase()=='active'? 'filterselected' : '',profiles.isNoDataPresent?'disabled':'']"
                        (click)="!profiles.isNoDataPresent?filterStatus({status:'active'}):null" >
                        Active ({{profiles.activeCount}})
                    </span>
                    <span class="not-selectable" tabindex="0"
                        [ngClass]="[filterCondition.value.status.toLowerCase()=='inactive'? 'filterselected' : '',profiles.isNoDataPresent?'disabled':'']"
                        (click)="!profiles.isNoDataPresent?filterStatus({status:'inactive'}):null">
                        Inactive ({{profiles.totalCount-profiles.activeCount}})
                    </span>

              </div>
              <app-search-box placeHolder="Search" class="ml-auto search-box-width" (enteredValue)="filterStatus({searchText:$event})" [isDisabled]="profiles.isNoDataPresent"></app-search-box>
              <app-button matTooltip="Reorder" [matTooltipDisabled]="hideEditBtn" [isDisabled]="hideEditBtn"  image="icons/Reorder_Blue.svg" (click)="enableSequenceEditMode()" class="ml-2"></app-button>
              <app-button buttonclass="primary" class="ml-2" [isDisabled]="hideCreateBtn"
                (click)="createCaseProfile()">Create</app-button>

            </div>
            <div *ngIf="sequenceEditMode" class="mt-3 mb-2 d-flex">
              <app-button buttonclass="secondary" class = "ml-auto edit-page-button" (click)="returnToList()">Return</app-button>
              <app-button buttonclass="primary" class = "edit-page-button ml-2" [isDisabled]="!isSortDataChanged" (click)="saveSequence()" >Save</app-button>
            </div>
            <div *ngIf="profiles.isNoDataPresent" class="col-sm-12 text-center mt-4">
              <span class="w-50 text-center mt-4" [ngClass]="sourceApp!='VitalDx'?'nodata-design':''">
                No Results
              </span>
            </div>
            <div *ngIf="!profiles.isNoDataPresent">
              <div class="case-profiles-header">
                <div class="case-profiles-table-header m-1" [class]="sequenceEditMode ? 'case-profiles-sort-table-header' : ''">
                  <div class="col" *ngIf="sequenceEditMode"></div>
                  <div class="col">#</div>
                  <div class="col">Display Name</div>
                  <div class="col">Order Code</div>
                  <div class="col">Case Type</div>
                  <div class="col">Extraction Procedure</div>
                  <div class="col">Services</div>
                  <div class="col">Jars</div>
                  <div class="col">ICD-10</div>
                  <div class="col mr-1">Bill To</div>
                  <div class="col" *ngIf="!sequenceEditMode">Status</div>
                  <div class="justify-self-end" *ngIf="!sequenceEditMode">
                    <span>
                      <img src="/assets/icons/Icon_Excel-Download.svg"
                        class="cusor-pointer" alt="Export" matTooltip="Export" [class.disabled]="hideExportBtn || profiles.data.length === 0" [matTooltipDisabled]="hideExportBtn || profiles.data.length === 0"
                        (click)="hideExportBtn || profiles.data.length === 0 ? null : exportexcel(profiles.data)">
                    </span>
                  </div>

                </div>
              </div>
              <div class="case-profiles-table-body case-profiles-max-height item mt-2" *ngIf="profiles.data.length" [class]="sequenceEditMode ? 'case-profiles-sort-table-body' : ''" cdkDropList [cdkDropListData]="profiles.data" (cdkDropListDropped)="drop($event)" #scrollableDiv>

                <div class="row matelevation each-item mb-2 m-1 align-items-center" [class.inactive-rule]="!i.IsActive" *ngFor="let i of profiles.data; let j = index" cdkDragBoundary=".case-profiles-table-body" cdkDragLockAxis="y" cdkDragPreviewContainer="parent" cdkDrag>
                  <div class="drag-handle" cdkDragHandle *ngIf="sequenceEditMode">
                    <img src="/assets/icons/Drag_handle.svg" alt="drag" width="14px" />
                  </div>
                  <div *ngIf="sequenceEditMode" class="col ellipsis">
                      <input class="input-width" #input type="text" (focusout)="onFocusOut(j+1, input, profiles.data)" [value]="j+1"/>
                  </div>
                  <div *ngIf="!sequenceEditMode" class="col ellipsis " [matTooltip]="tooTipText" #sequence (mouseover)="toolTipshow(sequence)">
                    {{i.Sequence}}</div>
                  <div class="col ellipsis " [matTooltip]="tooTipText" #displayName
                    (mouseover)="toolTipshow(displayName)">{{i.Display_name}}</div>
                  <div class="col ellipsis  " [matTooltip]="tooTipText" #orderCode (mouseover)="toolTipshow(orderCode)">
                    {{i.Order_code ? i.Order_code:'-' }}</div>
                  <div class="col ellipsis " [matTooltip]="tooTipText " #caseType (mouseover)="toolTipshow(caseType)">
                    {{i.Case_type}} </div>
                  <div class="col ellipsis " [matTooltip]="tooTipText" #extractionProcedure
                    (mouseover)="toolTipshow(extractionProcedure)">{{i.Extraction_procedure? i.Extraction_procedure
                    :'-'}}</div>
                  <div class="col ellipsis ml-1 " [matTooltip]="tooTipText" #services
                    (mouseover)="toolTipshow(services)"><img class="ml" width="20px" height="20px"
                      src="../../../../assets/images/{{i.Services}}.png" /> </div>
                  <div class="col ellipsis ml-3 " [matTooltip]="tooTipText" #jars (mouseover)="toolTipshow(jars)">
                    {{i.No_of_jars}} </div>
                  <div class="col ellipsis ml-2
               " [matTooltip]="tooTipText" #icd (mouseover)="toolTipshow(icd)">
                    {{i.ICD10}} <span *ngIf="i.ICD10.trim('')==''">-</span> </div>
                  <div class="col ellipsis ml-2 " [matTooltip]="tooTipText" #billto (mouseover)="toolTipshow(billto)">
                    {{i.Bill_To ? i.Bill_To : '-'}}</div>
                  <div class="col ellipsis ml-2 cursor-pointer" *ngIf="!sequenceEditMode">
                    <app-toggle-switch type="secondary" (clicked)="[i.IsActive = $event,
                (hideEditBtn)?null:UpdateStatusOfCaseprofile(i)]" [isChecked]="i.IsActive" [isDisable]="hideEditBtn">
                    </app-toggle-switch>
                  </div>
                  <div class="justify-self-center" *ngIf="!sequenceEditMode">
                    <span>
                      <img src="/assets/icons/Edit-fill.svg" [class.disabled]="hideEditBtn" class="cusor-pointer"
                        alt="Edit" width="14px" [matTooltipDisabled]="hideEditBtn" matTooltip="Edit"
                        (click)="hideEditBtn ? null : editCaseProfile(i)">
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 text-center mt-4 " *ngIf="profiles.data.length==0">
                <span class="w-50 text-center mt-4" [ngClass]="sourceApp!='VitalDx'?'nodata-design':''">
                  No Results
                </span>
              </div>

            </div>
        </div>
      </ng-container>
      <div class="px-4" *ngIf="showloader&&sourceApp!=''">
        <app-shimmerui></app-shimmerui>
      </div>
    </div>
  </div>
</div>
