<div *ngIf="gridPage && !sourceApp" class="row">
    <div class="col-md-12 p-0">
        <div class="modal-header admin-section-header mt-2 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong class="header-View">Quality Attributes</strong>
            </h3>
        </div>
    </div>
</div>

<mat-spinner class="labadmin-spinner" *ngIf="sourceApp && !(gridPage && (qualityAttributeFilter$|async)?.['data']) && !uploadClicked"
    style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50"></mat-spinner>

<section class="ang-mat-section" *ngIf="gridPage && qualityAttributeFilter$ | async as qualityAttribueList">
    <div>
        <div *ngIf="!sequenceEditMode" class="d-flex align-items-center mt-3 mb-2 filters gap-4px">
            <span class="not-selectable text-center" tabindex="0" [ngClass]="[filtercondition.value.status=='all' ? 'filterselected' : '',qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length?'disabled':'']"
            (click)="!qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length?Filter$({status:'all'}):null">All ({{qualityAttribueList.statusCount.totalCount}})</span>
            <span id="seperator"></span>
                <div class="filters d-flex not-selectable flex-wrap gap-4px">
                    <span class="not-selectable text-center" tabindex="0"
                        [ngClass]="[filtercondition.value.status=='active'? 'filterselected' : '',qualityAttribueList.isNoDataPresent?'disabled':'']"
                        (click)="!qualityAttribueList.isNoDataPresent?Filter$({status:'active'}):null" [class.filterDisabled]="(qualityAttribueList.statusCount.activeCount <= 0)">
                        Active ({{qualityAttribueList.statusCount.activeCount}})
                    </span>
                    <span class="not-selectable text-center" tabindex="0"
                        [ngClass]="[filtercondition.value.status=='inactive'? 'filterselected' : '',qualityAttribueList.isNoDataPresent?'disabled':'']"
                        (click)="!qualityAttribueList.isNoDataPresent?Filter$({status:'inactive'}):null" [class.filterDisabled]="(qualityAttribueList.statusCount.totalCount-qualityAttribueList.statusCount.activeCount <= 0)">
                        Inactive ({{qualityAttribueList.statusCount.totalCount-qualityAttribueList.statusCount.activeCount}})
                    </span>

                </div>
            <span id="miniseperator"></span>

            <app-auto-complete-select class="ml-auto" placeholder="Select Role" [dropDownValue]="RoleList"
                (onSelection)="RoleStatusSelection($event)"
                [isDisabled]="qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length"></app-auto-complete-select>

            <app-auto-complete-select placeholder="Select Case Status" [dropDownValue]="caseStatus"
                (onSelection)="caseStatusSelection($event)"
                [isDisabled]="qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length"></app-auto-complete-select>

            <app-search-box placeHolder="Search Quality Attribute" (enteredValue)="filterStatus({searchText:$event})"
                class="flex-grow-1 mr-1" [isDisabled]="qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length"></app-search-box>

            <app-button class="mr-1" matTooltip="Copy" [isDisabled]="!copyBtn || qualityAttributeSelectionModel.selected.length > 0" [matTooltipDisabled]="!editBtn || qualityAttributeSelectionModel.selected.length > 0" image="icons/Global_Icon.svg" (click)="copyOrg()" class=""></app-button>

            <app-button class="mr-1" matTooltip="Reorder" [matTooltipDisabled]="!editBtn || qualityAttributeSelectionModel.selected.length > 0 || isFilterEnabledChanged()"  image="icons/Reorder_Blue.svg" class="" [isDisabled]="qualityAttribueList.isNoDataPresent || !editBtn || qualityAttributeSelectionModel.selected.length > 0  || isFilterEnabledChanged()"
                (click)="enableSortEdit()"></app-button>

            <app-button buttonHoverText=":Bulk Upload" [isDisabled]="!createBtn" [isRightButtonDisabled]="!uploadBtn || qualityAttributeSelectionModel.selected.length > 0" [multiple]="true" image=":icons/Excel-Bulk Upload_white.svg" buttonText="Create" (leftBtnClick)="openCreateGroup()" (rightBtnClick)="loadUploadScreen()" class=""></app-button>
        </div>
        <div *ngIf="sequenceEditMode" class="mt-3 mb-2 d-flex">
            <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                (click)="returnToList()">Return</app-button>
            <app-button buttonclass="primary" class="edit-page-button ml-2" (click)="saveSequence()"
                [isDisabled]="!isSortDataChanged">Save</app-button>
        </div>
        <div class="content-slab">
            <span>QA Group Name</span>
            <img *ngIf="sequenceEditMode && isGroupAscending === 'DESC'"
                (click)="sequenceTracking('group','ASC', qualityAttribueList.data)" matTooltip="Sort" matTooltipPosition="right" class="ml-2 cursor-pointer" width="14px"
                src="/assets/icons/Reorder_Descending.svg" alt="Descending">
            <img *ngIf="sequenceEditMode && isGroupAscending === 'ASC'"
                (click)="sequenceTracking('group','DESC', qualityAttribueList.data)" matTooltip="Sort" matTooltipPosition="right" class="ml-2 cursor-pointer" width="14px"
                src="/assets/icons/Reorder_Asceding.svg" alt="Ascending">
            <img *ngIf="sequenceEditMode && isGroupAscending === 'UNS'"
                (click)="sequenceTracking('group','ASC', qualityAttribueList.data)" matTooltip="Sort" matTooltipPosition="right" class="ml-2 cursor-pointer" width="14px"
                src="/assets/icons/Mix - Sort.svg" alt="Un-Ordered">
            <span *ngIf="!sequenceEditMode" class="right-elements pointer" (click)="!qualityAttribueList.isNoDataPresent || qualityAttribueList.data.length === 0 || qualityAttributeSelectionModel.selected.length ?openPreview():null" [class.disabled]=" qualityAttribueList.isNoDataPresent || qualityAttribueList.data.length === 0 || qualityAttributeSelectionModel.selected.length">Preview</span>

            <img *ngIf="!sequenceEditMode" class="excel" src="/assets/icons/Excel_Download.svg" matTooltip="Export" alt="Download As Excel"  (click)="!qualityAttribueList.isNoDataPresent && qualityAttribueList.data.length > 0 && qualityAttributeSelectionModel.selected.length === 0 ?exportexcel(qualityAttribueList.data):null" [class.disabled]="qualityAttribueList.data.length === 0 || qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length > 0" [matTooltipDisabled]="qualityAttribueList.data.length === 0 || qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length > 0">
        </div>

        <div class="quality-attributes">
            <div class="mb-2 qa-creation" *ngIf="qualityAttributeSelectionModel.isSelected(-1)">
                <div class="heading p-1 px-3 f-12 d-flex align-items-center">
                    <span>Create Group</span>
                    <div class="ml-auto d-flex align-items-center">
                        <app-toggle-switch [isChecked]="frmIsActive" labelActivetext='Active'
                            labelInactivetext="Inactive" (clicked)="frmIsActive = $event"
                            type="secondary"></app-toggle-switch>
                        <!-- <app-switch class="d-flex align-items-center mr-3" [value]="frmIsActive"
                            (clicked)="frmIsActive = $event"></app-switch> -->
                        <!-- <img (click)="qualityAttributeSelectionModel.clear()" src="../../../../assets/images/close.png"
                            class="close" alt="Close" matTooltip="Close"> -->
                    </div>
                </div>
                <div class="form-body  pr-3 py-2">
                    <!-- create group region -->
                    <div class="mt-2">
                        <app-input [selectedValue]="frmGpName" class="f-12 mb-3" placeholder="Enter Group Name"
                            labelValue="Group Name " [isFormValid]="QualityAttributeObject.frmGpName.isValid"
                            (onFocusOut)="[frmGpName=$event,validateQualityfrm('frmGpName')]" [required]="true">
                        </app-input>

                        <app-input [selectedValue]="frmname" class="f-12 mb-3" placeholder="Enter Name" labelValue="Name"
                            [isFormValid]="QualityAttributeObject.frmName.isValid"
                            (onFocusOut)="[frmname=$event,validateQualityfrm('frmname')]" [required]="true">
                        </app-input>

                        <app-input [selectedValue]="frmDescription" class="f-12 mb-3" placeholder="Enter Description"
                            labelValue="Description " (onFocusOut)="[frmDescription=$event]">
                        </app-input>

                        <app-multi-select-checkbox [listData]="RoleList" class="mb-3" (onFocusOut)="validateQualityfrm('frmRole')"
                            [selectionModel]="RolesSelectionModel"
                            [isFormValid]="QualityAttributeObject.frmRole.isValid" [labelValue]="'Role'"
                            [required]="true"></app-multi-select-checkbox>

                        <app-multi-select-checkbox [listData]="caseStatus" class="mb-3" [selectionModel]="CaseStatusSelectionModel"
                            [labelValue]="'Case Status'"
                            [palceholder]="'Select Case Sequence'"></app-multi-select-checkbox>

                        <app-multi-select-checkbox class="mb-3" [listData]="ReportTypes" [selectionModel]="ReportTypesSelectionModel"
                            labelValue="Report Type" [palceholder]="'Select Report Type'"></app-multi-select-checkbox>
                        <mat-checkbox id="qualityAttribute" class=" f-12  mt-1 custom-matcheckbox" color="primary"
                            [(ngModel)]="frmComments">Comments</mat-checkbox>
                    </div>
                    <div class="d-flex">
                        <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                            (click)="onCreateAttributeReturn()">Return</app-button>
                        <app-button buttonclass="primary" class="edit-page-button ml-2"
                            (click)="createNewAttribute(true)">Save</app-button>
                    </div>
                    <!-- <div class="d-flex">
                        <button type="button" class="ml-auto btn f-12" (click)="createNewAttribute(true)">Save</button>
                    </div> -->
                    <!-- end create group region -->
                </div>

            </div>
            <div *ngIf="!qualityAttribueList.isNoDataPresent" [ngClass]="{'vitalDx': sourceApp === 'VitalDx'}">
                <div *ngIf="qualityAttribueList.data.length == 0 && filtercondition.value.searchText.length"
                    class="col-sm-12 text-center mt-4">
                    <span class="w-50 text-center mt-4" class="nodata-design">
                        No Data Found
                    </span>
                </div>
                <div #scrollableDiv cdkDropList [cdkDropListData]="qualityAttribueList.data"
                    *ngIf="qualityAttribueList.data.length > 0" (cdkDropListDropped)="drop($event,'group')"
                    cdkDropListGroup class="search-results main-list" [alwaysCallback]="true"
                    (scroll)="onScroll($event)">
                    <div class="quality-attribute-main" *ngFor="let item of qualityAttribueList.data; let i = index"
                        cdkDragBoundary=".main-list" cdkDragLockAxis="y" cdkDrag
                        [cdkDragDisabled]="!editBtn ||qualityAttributeSelectionModel.selected.length>0 || !sequenceEditMode">
                        <div class="d-flex" (click)="!editBtn?openEditGroup(item.attrGroupName):null" [class.cursor-pointer]="!editBtn"
                            *ngIf="!qualityAttributeSelectionModel.isSelected(item.attrGroupName.attrid*-1); else editGroup">
                            <div *ngIf="sequenceEditMode" class="drag-handle margin-left-10" cdkDragHandle>
                                <img src="/assets/icons/Drag_handle.svg" alt="drag" width="14px" />
                            </div>
                            <!-- <div class="seperator"></div> -->
                            <span class="ml-1 mr-2">{{ item.attrGroupName.category }}
                                <img class="ml-2" matTooltip="Sort" matTooltipPosition="right" (click)="sequenceTracking(item.attrGroupName.attrid,'ASC', item)"  [class]="sequenceEditMode && item.attrGroupName.currentChildSortOrder === 'DESC' ? 'show-sort' : 'hide-sort'" width="14px" src="/assets/icons/Reorder_Descending.svg" alt="Descending">

                                <img class="ml-2" width="14px" matTooltip="Sort" matTooltipPosition="right" (click)="sequenceTracking(item.attrGroupName.attrid,'DESC', item)" [class]="sequenceEditMode && item.attrGroupName.currentChildSortOrder === 'ASC' ? 'show-sort' : 'hide-sort'" src="/assets/icons/Reorder_Asceding.svg" alt="Ascending">

                                <img class="ml-2" width="14px" matTooltip="Sort" matTooltipPosition="right" (click)="sequenceTracking(item.attrGroupName.attrid,'ASC', item)" [class]="sequenceEditMode && item.attrGroupName.currentChildSortOrder === 'UNS' ? 'show-sort' : 'hide-sort'" src="/assets/icons/Mix - Sort.svg" alt="Un-Ordered">
                            </span><span class="f-12"></span>
                            <div *ngIf="!sequenceEditMode"
                                class="ml-auto mr-2 d-flex align-items-center justify-content-center gap-20">
                                <app-toggle-switch [isChecked]="item.attrGroupName.isAllAttrActive" *ngIf="editBtn"
                                    [id]="'status'+i"
                                    labelActivetext='Active' labelInactivetext="Inactive"
                                    (clicked)="setAttributeStatus($event,item, 'status'+i)" [isDisable]="qualityAttributeSelectionModel.selected.length"
                                    type="secondary"></app-toggle-switch>

                                <div>
                                    <button class="card-action-button" [matMenuTriggerFor]="create"
                                         [matTooltipDisabled]="!createBtn" [disabled]="!createBtn"
                                        matTooltip="Add Attribute">
                                        <img [class.disabled]="!createBtn" src="../../../../assets/images/add_circle.png" class="img-width"
                                            alt="Add" />
                                    </button>
                                    <mat-menu #create="matMenu" xPosition="before">
                                        <button mat-menu-item class="f-12 sort-menu-option"
                                            (click)="openAddAttribute('Add Quality Attribute',item.attrGroupName)">
                                            <span>Add Quality Attribute</span>
                                        </button>
                                        <button mat-menu-item class="f-12 sort-menu-option"
                                            [disabled]="item.attrGroupName.isOthersPresent"  [disabled]="!createBtn"
                                            (click)="openAddAttribute('Others',item.attrGroupName)">
                                            <span>Add Others</span>
                                        </button>
                                    </mat-menu>
                                </div>
                                <img src="../../../../assets/images/edit.png" [attr.disabled]="" (click)="editBtn?openEditGroup(item.attrGroupName) : null" class="img-width"
                                [matTooltip]=" 'Edit' " alt="edit"
                                [matTooltipDisabled]="!editBtn"
                                [class.disabled]="!editBtn" />
                            </div>
                        </div>
                        <ng-template #editGroup>
                            <div class="d-flex" >
                                <span class="ml-1">{{editBtn ?'Edit Group' :'View Group'}}</span>
                                <div class="ml-auto d-flex align-items-center">
                                </div>
                            </div>
                        </ng-template>
                        <!-- edit group region -->
                        <div cdkDropList [cdkDropListData]="item.attrList" class="items-list"
                            (cdkDropListDropped)="drop($event, 'attr', item)"
                            [style]="item.attrList.length > 0? 'min-height: 60px; padding: 10px;':''">
                            <div *ngIf="qualityAttributeSelectionModel.isSelected(item.attrGroupName.attrid*-1) && editBtn "
                                class="form-body p-1 py-2 mb-2 border-0"
                                [style]="item.attrList.length == 0? 'padding: 10px; !important':''">
                                <div class="mt-2">
                                    <app-input [selectedValue]="frmGpName" [placeholder]="" labelValue="Name"
                                        [isFormValid]="QualityAttributeObject.frmGpName.isValid"
                                        (onFocusOut)="[frmGpName=$event,validateQualityfrm('frmGpName')]"
                                        [required]="true">
                                    </app-input>
                                    <app-input   [selectedValue]="frmGpSquence" [placeholder]=""
                                        labelValue="Group Sequence" mask="000000"
                                        [isFormValid]="QualityAttributeObject.frmGpSquence.isValid"
                                        (onFocusOut)="[frmGpSquence=$event,validateQualityfrm('frmGpSquence')]"
                                        [required]="true">
                                    </app-input>
                                </div>
                                <div class="d-flex">
                                    <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                                        (click)="OnReturnGroup()">Return</app-button>
                                    <app-button buttonclass="primary" class="edit-page-button ml-2"
                                        (click)="editGroupName(item.attrGroupName)">Save</app-button>
                                </div>
                            
                            </div>
                            <div
                                *ngIf="qualityAttributeSelectionModel.isSelected(item.attrGroupName.attrid*-1) && !editBtn ">
                                <div class="m-3 p-1 d-flex group-gap">
                                    <div>
                                        <span class="sub-heading-border">
                                            Group Name
                                        </span> <br>
                                        <p class="mt-1">{{frmGpName}}
                                        </p>
                                    </div>
                                    <div>
                                        <span class="sub-heading-border">
                                            Group Sequence
                                        </span> <br>
                                        <p class="mt-1">{{frmGpSquence}}
                                        </p>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <app-button buttonclass="secondary" class="ml-auto edit-page-button mb-3"
                                        (click)="this.qualityAttributeSelectionModel.clear()">Return</app-button>
                                </div>
                            </div>
                            <div class="mb-2" *ngIf="qualityAttributeSelectionModel.isSelected(item.attrGroupName.attrid)"
                                [style]="item.attrList.length == 0? 'padding: 10px;':''">
                                <div class="heading p-1 px-3 f-12 d-flex align-items-center">
                                    <span>{{creationHeading}}</span>
                                    <div class="ml-auto d-flex align-items-center">
                                        <app-toggle-switch [isChecked]="frmIsActive" labelActivetext='Active'
                                            labelInactivetext="Inactive" (clicked)="frmIsActive = $event"
                                            type="secondary"></app-toggle-switch>
                                    </div>
                                </div>
                                <div class="form-body  pr-3 py-2">
                                    <!-- ceration of new quality attribute region -->
                                    <div class="mt-2">
                                        <app-input [selectedValue]="frmname" class=" f-12 mb-3" placeholder="Enter Name"
                                            labelValue="Name " [isFormValid]="QualityAttributeObject.frmName.isValid"
                                            (onFocusOut)="[frmname=$event,validateQualityfrm('frmname')]"
                                            [required]="true" [isDisabled]="isOthersPress">
                                        </app-input>

                                        <app-input [selectedValue]="frmDescription" class="f-12 mb-3"
                                            placeholder="Enter Description" labelValue="Description "
                                            (onFocusOut)="[frmDescription=$event]" [isDisabled]="isOthersPress">
                                        </app-input>
                                        <app-multi-select-checkbox [listData]="RoleList" class="mb-3"
                                            (onFocusOut)="validateQualityfrm('frmRole')"
                                            [selectionModel]="RolesSelectionModel"
                                            [isFormValid]="QualityAttributeObject.frmRole.isValid" [labelValue]="'Role'"
                                            [required]="true"></app-multi-select-checkbox>

                                        <app-multi-select-checkbox [listData]="caseStatus" class="mb-3"
                                            [selectionModel]="CaseStatusSelectionModel" [labelValue]="'Case Status'"
                                            [palceholder]="'Select Case Status'"></app-multi-select-checkbox>

                                        <app-multi-select-checkbox [listData]="ReportTypes" class="mb-3"
                                            [selectionModel]="ReportTypesSelectionModel" labelValue="Report Type"
                                            [palceholder]="'Select Report Type'"></app-multi-select-checkbox>

                                        <mat-checkbox *ngIf="!isOthersPress" id="qualityAttribute" color="primary"
                                            class=" f-12  mt-1 custom-matcheckbox"
                                            [(ngModel)]="frmComments">Comments</mat-checkbox>
                                    </div>
                                    <div class="d-flex">
                                        <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                                            (click)="onCreateAttributeReturn()">Return</app-button>
                                        <app-button buttonclass="primary" class="edit-page-button ml-2"
                                            (click)="createNewAttribute()">Save</app-button>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="item.attrList.length > 0">
                                <div class="items" *ngFor="let childItem of item.attrList" cdkDragBoundary=".items-list"
                                    cdkDragLockAxis="y" cdkDrag
                                    [cdkDragDisabled]="!editBtn ||qualityAttributeSelectionModel.selected.length>0 || !sequenceEditMode">
                                    <div (click)="!editBtn?openEditAttribute(childItem):null"  [class.cursor-pointer]="!editBtn" [ngClass]="childItem.isactive.toLowerCase()"
                                        *ngIf="!qualityAttributeSelectionModel.isSelected(childItem.attrid);else editqualityAttribute">
                                        <div *ngIf="sequenceEditMode" class="drag-handle" cdkDragHandle>
                                            <img src="/assets/icons/Drag_handle.svg" alt="drag" width="14px" />
                                        </div>
                                        <!-- <div class="seperator"></div> -->
                                        <span class="ml-1">{{ childItem.attrname }}</span>
                                        <img [ngClass]="'opacity-'+childItem.isactive.toLowerCase()+' ml-2'"
                                            *ngIf="childItem.iscomment" src="../../../../assets/images/comment.png"
                                            class="img-width" alt="comments" matTooltip="Comments" matTooltipPosition="right"/>
                                        <div *ngIf="!sequenceEditMode" class="ml-auto"
                                            (click)="editBtn?openEditAttribute(childItem):null"><img matTooltipPosition="left"
                                            [matTooltipDisabled]="!editBtn"
                                            [class.disabled]="!editBtn"
                                            [disabled]="!editBtn"
                                                src=" ../../../../assets/images/edit.png"
                                                class="img-width" alt="edit" [matTooltip]="editBtn? 'Edit' :'View'" />
                                        </div>
                                    </div>
                                    <!-- edit quality attribute region -->
                                    <ng-template #editqualityAttribute>
                                        <div class="mb-2">
                                            <div class="heading p-1 px-3 f-12 d-flex align-items-center">
                                                <span>{{editBtn ?'Edit Quality Attribute' :'View Quality Attribute'}}</span>
                                                <div class="ml-auto d-flex align-items-center">
                                                    <app-toggle-switch [isChecked]="frmIsActive" *ngIf="editBtn"
                                                        labelActivetext='Active' labelInactivetext="Inactive"
                                                        (clicked)="frmIsActive = $event"
                                                        type="secondary"></app-toggle-switch>
                                                </div>
                                            </div>
                                            <div class="form-body  pr-3 py-2" *ngIf="editBtn">
                                                <div class="mt-2">
                                                    <app-input [selectedValue]="frmname" class=" f-12 mb-3"
                                                        placeholder="Enter Name" labelValue="Name"
                                                        [isFormValid]="QualityAttributeObject.frmName.isValid"
                                                        (onFocusOut)="[frmname=$event,validateQualityfrm('frmname')]"
                                                        [required]="true" [isDisabled]="isOthersPress">
                                                    </app-input>
                                                    <app-input [selectedValue]="frmDescription" class=" f-12 mb-3"
                                                        placeholder="Enter Description" labelValue="Description"
                                                        (onFocusOut)="[frmDescription=$event]"
                                                        [isDisabled]="isOthersPress">
                                                    </app-input>

                                                    <app-input [selectedValue]="frmSequence" class=" f-12 mb-3"
                                                        placeholder="Enter Attribute Sequence"
                                                        labelValue="Attribute Sequence" mask="000000000"
                                                        [isFormValid]="QualityAttributeObject.frmSequence.isValid"
                                                        (onFocusOut)="[frmSequence=$event,validateQualityfrm('frmSequence')]"
                                                        [required]="true" [isDisabled]="isOthersPress">
                                                    </app-input>
                                                    <app-multi-select-checkbox [listData]="RoleList" class="mb-3"
                                                        (onFocusOut)="validateQualityfrm('frmRole')"
                                                        [selectionModel]="RolesSelectionModel"
                                                        [isFormValid]="QualityAttributeObject.frmRole.isValid"
                                                        [labelValue]="'Role'"
                                                        [required]="true"></app-multi-select-checkbox>

                                                    <app-multi-select-checkbox [listData]="caseStatus" class="mb-3"
                                                        [selectionModel]="CaseStatusSelectionModel"
                                                        [labelValue]="'Case Status'"
                                                        [palceholder]="'Select Case Status'"></app-multi-select-checkbox>

                                                    <app-multi-select-checkbox [listData]="ReportTypes" class="mb-3"
                                                        [selectionModel]="ReportTypesSelectionModel"
                                                        labelValue="Report Type"
                                                        [palceholder]="'Select Report Type'"></app-multi-select-checkbox>

                                                    <mat-checkbox color="primary" id="qualityAttribute"
                                                        *ngIf="!(childItem.attrname.toLowerCase() === 'others')"
                                                        class=" custom-matcheckbox f-12  mt-1"
                                                        [(ngModel)]="frmComments">Comments</mat-checkbox>
                                                </div>
                                                <div class="d-flex">

                                                    <span class="ml-auto"></span>
                                                    <span class="ml-auto reset-btn align-self-end mr-2" *ngIf=" onEditAttributeReturn()"
                                                        (click)="onReset()">Reset</span>
                                                    <app-button buttonclass="secondary" class=" edit-page-button" (click)="onAttributeReturn()">Return</app-button>
                                                    <app-button buttonclass="primary" class="edit-page-button ml-2" (click)="updateAttribute()">Save</app-button>
                                                </div>
                                            </div>
                                            <div class="qa-view-border" *ngIf="!editBtn">
                                                <div class="m-3 p-1 qa-view ">
                                                    <div>
                                                        <span class="sub-heading-border">
                                                            Attribute Name
                                                        </span> <br>
                                                        <p class="mt-1">{{frmname}}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <span class="sub-heading-border">
                                                            Description
                                                        </span> <br>
                                                        <p class="mt-1">{{frmDescription}}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <span class="sub-heading-border">
                                                            Attribute Sequence
                                                        </span> <br>
                                                        <p class="mt-1">{{frmSequence}}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <span class="sub-heading-border">
                                                            Roles
                                                        </span> <br>
                                                        <p class="mt-1">{{RolesSelectionModel.selected.join(',')}}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <span class="sub-heading-border">
                                                            Case Status
                                                        </span> <br>
                                                        <p class="mt-1">{{CaseStatusSelectionModel.selected ?
                                                            CaseStatusSelectionModel.selected.join(',') : 'N/A' }}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <span class="sub-heading-border">
                                                            Report Type
                                                        </span> <br>
                                                        <p class="mt-1">{{ReportTypesSelectionModel.selected ?
                                                            ReportTypesSelectionModel.selected.join(',') : 'N/A'}}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <span class="sub-heading-border">
                                                            Comments
                                                        </span> <br>
                                                        <p class="mt-1">{{frmComments ? 'yes' :'No'}}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <span class="sub-heading-border">
                                                            Status
                                                        </span> <br>
                                                        <p class="mt-1">{{frmIsActive ? 'Active' :'Inactive'}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex m-3">
                                                    <app-button buttonclass="secondary" class="ml-auto edit-page-button "
                                                        (click)="this.qualityAttributeSelectionModel.clear()">Return</app-button>
                                                </div>

                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </ng-container>
                            <!-- <div *ngIf="qualityAttributeSelectionModel.isSelected(item.attrGroupName.attrid)"
                                [style]="item.attrList.length == 0? 'padding: 10px;':''">
                                <div class="heading p-1 px-3 f-12 d-flex align-items-center">
                                    <span>{{creationHeading}}</span>
                                    <div class="ml-auto d-flex align-items-center">
                                        <app-toggle-switch [isChecked]="frmIsActive" labelActivetext='Active'
                                            labelInactivetext="Inactive" (clicked)="frmIsActive = $event"
                                            type="secondary"></app-toggle-switch>
                                    </div>
                                </div>
                                <div class="form-body  pr-3 py-2">
                                    ceration of new quality attribute region
                                    <div class="mt-2">
                                        <app-input [selectedValue]="frmname" class=" f-12 mb-3" placeholder="Enter Name"
                                            labelValue="Name " [isFormValid]="QualityAttributeObject.frmName.isValid"
                                            (onFocusOut)="[frmname=$event,validateQualityfrm('frmname')]"
                                            [required]="true" [isDisabled]="isOthersPress">
                                        </app-input>

                                        <app-input [selectedValue]="frmDescription" class="f-12 mb-3"
                                            placeholder="Enter Description" labelValue="Description "
                                            (onFocusOut)="[frmDescription=$event]" [isDisabled]="isOthersPress">
                                        </app-input>
                                        <app-multi-select-checkbox [listData]="RoleList" class="mb-3"
                                            (onFocusOut)="validateQualityfrm('frmRole')"
                                            [selectionModel]="RolesSelectionModel"
                                            [isFormValid]="QualityAttributeObject.frmRole.isValid" [labelValue]="'Role'"
                                            [required]="true"></app-multi-select-checkbox>

                                        <app-multi-select-checkbox [listData]="caseStatus" class="mb-3"
                                            [selectionModel]="CaseStatusSelectionModel" [labelValue]="'Case Status'"
                                            [palceholder]="'Select Case Status'"></app-multi-select-checkbox>

                                        <app-multi-select-checkbox [listData]="ReportTypes" class="mb-3"
                                            [selectionModel]="ReportTypesSelectionModel" labelValue="Report Type"
                                            [palceholder]="'Select Report Type'"></app-multi-select-checkbox>

                                        <mat-checkbox *ngIf="!isOthersPress" id="qualityAttribute" color="primary"
                                            class=" f-12  mt-1 custom-matcheckbox"
                                            [(ngModel)]="frmComments">Comments</mat-checkbox>
                                    </div>
                                    <div class="d-flex">
                                        <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                                            (click)="onCreateAttributeReturn()">Return</app-button>
                                        <app-button buttonclass="primary" class="edit-page-button ml-2"
                                            (click)="createNewAttribute()">Save</app-button>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="qualityAttribueList.isNoDataPresent&&!qualityAttributeSelectionModel.isSelected(-1)"
            class="col-sm-12 text-center mt-4">
            <span class="w-50 text-center mt-4" class="nodata-design">
                No Records to display
            </span>
        </div>
    </div>

</section>
<section *ngIf="!gridPage">
    <app-export-upload-copy (uploadBack)=backClicked($event) [templateData]="templateData"
        [copyDataClicked]="copyDataClicked" [uploadClicked]="uploadClicked">{{templateData}}</app-export-upload-copy>
</section>
