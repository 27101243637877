import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { qualityAttributeAndFlags } from 'src/app/common/constants/qualityAttributeAndFlags';
import { catchError, concatMap, map, take, tap } from 'rxjs/operators';
import { DialogComponent } from 'src/app/base/popup/dialog/dialog.component';
import { DialogService } from 'src/app/core/services/dialog.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';

@Component({
  selector: 'app-new-case-profiles',
  templateUrl: './new-case-profiles.component.html',
  styleUrls: ['./new-case-profiles.component.scss']
})
export class NewCaseProfilesComponent implements AfterViewInit,OnDestroy {

  hideExportBtn: boolean;
  hideCreateBtn: boolean;
  hideEditBtn: boolean;
  gridheader = [
    'CaseProfileId',
    'accountid',
    'IsActive',
    'Modified_by',
    'Modified_date',
    'Bill_To',
    'ICD10',
    'caseid',
    'No_of_jars',
    'Services',
    'Extraction_procedure',
    'Order_code',
    'Case_type',
    'Display_name',
    'Sequence',
  ];
  @Input() templateData: any;
  gridData: any;
  private dragIndex: number;
  hideDragnDropBtn: any;
  changeToTrue: boolean;
  subMenuCardModel: SubMenuCardModel;
  tempExport: any[];
  profilesSelectionModel = new SelectionModel(false, [])
  isactive: Boolean = true
  tooTipText = ''
  profiles = [];
  filterCondition = new BehaviorSubject({ status: 'all', searchText: ''})
  filterCondition$ = this.filterCondition.asObservable();
  caseProfiles$: any;
  caseProfilesFitered$: any;
  sequenceEditMode: boolean = false
  isSortDataChanged : boolean = false
  caseProfileSortData : { currentData : any, previousData : any}
  dbName;
  scrollHistory: number = 0
  caseprofilesessionUnsubscribe
  @ViewChildren('scrollableDiv') maindiv
  maxSequence: number = 0;
  userID: any
  orgId:any;
  locationId:any
  activityEntity: any;


  flagsSelectionModel = new SelectionModel(false, [])
  auditableColumns: { status : boolean };

  constructor(private vitalservice: VitalHttpServices, private _dialog: DialogService, private datashare: DataShareService,
    private ngxService: NgxUiLoaderService, private commonService: CommonService, private _snackbar: MatSnackBar,
    private labAdminSessionService : LabadminSessionService,
    private store : Store<{breadcrum : []}>,
    private labAdminService : LabadminService,
    private activityService : ActivityTrackerService
  ) {
    this.subMenuCardModel = new SubMenuCardModel(commonService, vitalservice, datashare);

  }
  ngOnDestroy(): void {
    this.sourceApp=='VitalDx'?this.caseprofilesessionUnsubscribe.unsubscribe():''

  }

  toolTipshow(element) {
    this.tooTipText = element.scrollWidth > element.clientWidth ? element.textContent : '';
  }
  userSessionDetails: any;
  sourceApp: string='';
  destDeployment: string;
  showloader=true;
   ngOnInit() {
    let checkLaunchUrl=window.location.href.split('/#/')[1];
    if (checkLaunchUrl.toString().toLowerCase() !== "home") {
      this.caseprofilesessionUnsubscribe=this.labAdminSessionService.getLabAdminSession
      .pipe(
        concatMap((session:any)=>(
            this.store.select("breadcrum").pipe(take(1),
            concatMap(i=>{
              this.userSessionDetails = session["userDetails"];
              this.sourceApp = 'VitalDx';
              return this.commonService.getCommmonTemplateData(i[i.length-1], this.userSessionDetails)
            })
          )
        )

       )
      )
      .subscribe((data)=>{
              this.templateData=this.labAdminService.templateData
              this.dbName=this.labAdminService.deploymentKey
            this.userID=this.userSessionDetails.userId
              this.locationId=this.userSessionDetails.userAccountIDs[0].accountId
            this.getCaseProfileData()
            this.GetButtondetails(this.labAdminService.subMenuPermissions);
      })
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': 'Case profiles', 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':'Case profiles'}] });
          this.getAuditableDetails('Case profiles');
    }
    else{
              this.dbName=sessionStorage.getItem('deploymentKey')
              // this.orgId=sessionStorage.getItem('org_id')
              this.userID=sessionStorage.getItem('Userid')
              this.locationId=sessionStorage.getItem('Locationid')
      this.getCaseProfileData()
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL}] });
      this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
      this.GetButtondetails(this.vitalservice.SubmenuAction);
      this.getAuditableDetails(this.templateData.menuURL);

    }




  }
  //filter Functionality
  filterStatus(updateObj:any) {
    this.filterCondition.next({ ...this.filterCondition.value,...updateObj });
  }
  getCaseProfileData() {
    this.showloader=true
    this.caseProfiles$ = this.vitalservice.GetData(
      {
        OperationName: null,
        Query: this.vitalservice.GetQuery(this.templateData.Keyword),
        Variables: { orgid: this.templateData.secondarykeys.OrganizationId.toString() }
      },this.dbName).pipe(
        tap(()=>this.showloader=true),
        map((d: any) => d.data.submenuData),

        catchError((err)=>{
        this._snackbar.open('Unable to process request','close')
        console.error(err)
        this.showloader=false
        return err
        }
        )
      );
    this.caseProfilesFitered$ = combineLatest([this.caseProfiles$, this.filterCondition$]).pipe(
      map(([data, filterValue]: any) => {
        if (data && data.length > 0) {
          let res = []
           res=filterValue.searchText != '' ? data.filter(i => this.filterCaseProfiles(i,filterValue.searchText)) : data
          const totalCount=res.length
          const activeCount=res.filter(v => v.IsActive).length
           res = filterValue.status.toLowerCase() != 'all' ? filterValue.status.toLowerCase() == 'active' ? res.filter(v => v.IsActive) : res.filter(v => !v.IsActive) : res;

           return {data:res,totalCount,activeCount,isNoDataPreseant:false}
        }
        return  {data:[],totalCount:0,activeCount:0,isNoDataPreseant:true}
      }),
      tap((res : any) => {
        this.caseProfileSortData = { currentData : res.data, previousData : res.data.map((j) => ({...j}))}
        this.maxSequence = res.data.length
        this.showloader=false
      })
    )
  }
  stringNormalize(str: any) {
    return str != null ? str.toString().toLowerCase().replace(/\s/g, "") : '';
  }
  filterCaseProfiles(obj,text:string)
  { let txt=this.stringNormalize(text);
    return this.stringNormalize(obj.Case_type).includes(txt) ||
    this.stringNormalize(obj.Display_name).includes(txt) ||
    this.stringNormalize(obj.Order_code).includes(txt)||
    this.stringNormalize(obj.Extraction_procedure).includes(txt)||
    this.stringNormalize(obj.No_of_jars).includes(txt)||
    this.stringNormalize(obj.ICD10).includes(txt)||
    this.stringNormalize(obj.Bill_To).includes(txt)
   }

  // for RBAC
  GetButtondetails(data:any) {
    this.GetButtonAccess(data);
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext  == this.labAdminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.hideExportBtn = (seletedMenuPermissions[i].IsPermitted == "true") ? false : true;
          break;
        case 'Create':
          this.hideCreateBtn = (seletedMenuPermissions[i].IsPermitted == "true") ? false : true;
          break;
        case 'Edit':
          this.hideEditBtn = (seletedMenuPermissions[i].IsPermitted == "true") ? false : true;
          break;
        case 'DragnDrop':
          this.hideDragnDropBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }

  editCaseProfile(row) {
    if (!this.userID ||  this.userID == '') {
      this._snackbar.open("Userid does not exist in the deployment", "Close");
      return;
    }
    let deployid = this.dbName;
    let dxurl = this.vitalservice.GetDxurlBasedOnDeployment(deployid);
    var queryString = '/VACreateCaseProfile.aspx?caseid={0}&from=admin&organizationid={1}&userid={2}&accountid={3}';
    let addedCaseID = queryString.replace('{0}', row.caseid);
    let addedOrgId = addedCaseID.replace('{1}', this.templateData.secondarykeys.OrganizationId)
    let addedUserId = addedOrgId.replace('{2}',  this.userID)
    let addedaccountid = addedUserId.replace('{3}', row.accountid)
    var newWindow = window.open(dxurl + addedaccountid);




  }

  createCaseProfile() {
    if (!this.userID ||  this.userID == '') { // checking the userid exist or not
      this._snackbar.open("Userid does not exist in the deployment", "Close");
      return;
    }
    let deployid = this.dbName;
    let dxurl = this.vitalservice.GetDxurlBasedOnDeployment(deployid);
    var queryString = '/VACreateCaseProfile.aspx?caseid={0}&from=admin&organizationid={1}&userid={2}&accountid={3}';
    let addedCaseID = queryString.replace('{0}', '0');
    let addedOrgId = addedCaseID.replace('{1}', this.templateData.secondarykeys.OrganizationId)
    let addedUserId = addedOrgId.replace('{2}',  this.userID)
    let addedaccountid = addedUserId.replace('{3}', this.locationId)
    var newWindow = window.open(dxurl + addedaccountid);
  }

  // saves the drag and droped data
  drop(event: CdkDragDrop<any>) {
    if (event.previousContainer === event.container && event.currentIndex != event.previousIndex) {

      // let previousIndex = event.previousIndex
      // let currentIndex = event.currentIndex

      // if (event.previousIndex > event.currentIndex) {
      //   previousIndex = event.currentIndex
      //   currentIndex = event.previousIndex
      // }

      //let previousData = event.previousContainer.data.slice(previousIndex, currentIndex + 1).map((i) => i.Sequence)

      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.checkSequenceChanged()
      // let currentChangedData = event.container.data.slice(previousIndex, currentIndex + 1)

      // if (currentChangedData.length > 1) {
      //   let modifiedAttributes = []

      //   for (let index = 0; index < currentChangedData.length; index++) {
      //     const element = { ...currentChangedData[index] };
      //     element.Sequence = previousData[index]
      //     modifiedAttributes.push(element)
      //   }

      //   let data = modifiedAttributes.map((i) => (
      //     {
      //       id: i.CaseProfileId,
      //       sequence: i.Sequence
      //     }
      //   ))

      //   this.ngxService.start()
      //   this.vitalservice.SwapSequence(qualityAttributeAndFlags.Swap_CaseProfiles, data)
      //     .subscribe({
      //       next: () => {
      //         this._snackbar.open('Sequence updated successfully', 'Close')
      //         this.getCaseProfileData()
      //       },
      //       error: () => {
      //         this._snackbar.open('Something went wrong. Please try again', 'Close')
      //         moveItemInArray(
      //           event.container.data,
      //           event.currentIndex,
      //           event.previousIndex
      //         );
      //         this.ngxService.stop()
      //       },
      //       complete: () => this.ngxService.stop()
      //     })
      // }
    }
  }

  UpdateStatusOfCaseprofile(i) {
    let ref = this._dialog.openDialog('Alert', `Are you sure you want to continue?`, '', 'Proceed', 'Cancel')
    ref.afterClosed().subscribe((res) => {
      if (res) {
        let obj = {
          isactive: i.IsActive,
          caseProfileId: i.CaseProfileId,
          userid: !this.userID ||  this.userID == '' ? -100 :  this.userID
        }
        this.ngxService.start();
        this.vitalservice.UpdateActiveStatusforCaseProfile(obj)
          .subscribe((res) => {
            if (res && res.Success == true) {
              //this.getUpdatedGrid();
              this.ngxService.stop();
              this._snackbar.open(res.Message, 'Close');
              this.getCaseProfileData()
	            this.commonService.createActivityObject(i.CaseProfileId,'Status',this.templateData.menuURL,'Edit', { status : i.IsActive}, { status : !i.IsActive}, this.commonService.generateGuid(), this.auditableColumns)

              //this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Case Profiles', 'Audit', obj, { isactive: oldValue })
            }
          }, error => {
            // Change position in view level if update fail
            i.IsActive = !i.IsActive
            this.ngxService.stop();
            this._snackbar.open("An error occurred while processing your request", "Failed");
          });
      }
      else {
        i.IsActive = !i.IsActive
      }
    })
  }

  UpdateVAEntityAttribute(value: boolean) {
    let obj = {
      entityid: this.templateData.secondarykeys.OrganizationId,
      configid: this.templateData.secondarykeys.OrganizationId,
      entytitype: 2,
      AttributeName: 'IsCaseProfileEnabled',
      ConfigName: "IsCaseProfileEnabled",
      tableName: "VaEntityExtAttributes",
      scope: 3,
      ExtAttrValue1: value,
      id: -1,
      description: "Update IsCaseProfileEnabled Flag"
    }

    this.ngxService.start();
    this.vitalservice.UpdateVAEntityAttribute(obj, this.dbName).subscribe((res) => {
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
    });
  }

  ngAfterViewInit(): void {
    this.maindiv.changes.subscribe((comps: QueryList<any>) => {
      if (comps.first) comps.first.nativeElement.scrollTop = this.scrollHistory
    });
  }

  // export the list of case profile data in Excel format
  exportexcel(profiles) {
    const fileName = 'CaseProfilesList.xlsx';
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(profiles);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Case Profiles');
    XLSX.writeFile(wb, fileName);
  }

  onScroll = (e: any) => this.scrollHistory = e.target.scrollTop

  enableSequenceEditMode(){
    this.filterCondition.next({ status: 'Active', searchText: '' });
    this.sequenceEditMode = !this.sequenceEditMode
    this.isSortDataChanged = false
  }

  returnToList(){
    if(this.isSortDataChanged){
      let ref = this._dialog.openDialog('Alert', `All your changes will be lost. Are you sure to continue?`, '', 'Continue', 'Cancel')
      ref.afterClosed().subscribe((res) => {
        if(res){
          this.filterCondition.next({ status:'All', searchText: '' })
          this.sequenceEditMode = !this.sequenceEditMode
        }
      })
    }
    else{
        this.filterCondition.next({ status:'All', searchText: '' })
        this.sequenceEditMode = !this.sequenceEditMode
    }
  }

  onFocusOut(actualValue: number, changedValue: HTMLInputElement, container: any) {
    if (actualValue != +changedValue.value) {
      if (+changedValue.value > this.maxSequence) {
        this._dialog.openDialog('Alert', `Sequence cannot be greater than maximum sequence(${this.maxSequence})`, '', 'Ok','Cancel')
        changedValue.value = String(actualValue)
      }
      else {
        moveItemInArray(
          container,
          actualValue - 1,
          +changedValue.value - 1
        );
        this.checkSequenceChanged()
      }
    }
  }

    //calculates the sequence and updates accordingly.
    checkSequenceChanged() {
      // list.map((CL: any, i: number) => {
      //   if (CL.Sequence != '') CL.Sequence = i + 1
      // })
      //this.caseProfileSortData.currentData = [...list]
      this.isSortDataChanged = this.caseProfileSortData.currentData.map((i) => i.CaseProfileId).join(',') != this.caseProfileSortData.previousData.map((i) => i.CaseProfileId).join(',')
    }

    saveSequence(){
      this.ngxService.start()
      let caseProfilesSequence = []

      for (let index = 0; index < this.caseProfileSortData.currentData.length; index++) {
        const currentElement = this.caseProfileSortData.currentData[index];
        const previousElement = this.caseProfileSortData.previousData[index];

        if(currentElement.CaseProfileId != previousElement.CaseProfileId){
          caseProfilesSequence.push({ id: currentElement.CaseProfileId, sequence: previousElement.CaseProfileSequence, to: qualityAttributeAndFlags.Swap_CaseProfiles })
        }
      }
      console.log(caseProfilesSequence)

      this.vitalservice.SwapSequence2({ UpdateSequences: caseProfilesSequence, SortItems: [] }).subscribe({
        next: (res) => {
          this._snackbar.open('Sequence updated successfully', 'Close')
          this.sequenceEditMode = !this.sequenceEditMode
          this.filterCondition.next({ status:'All', searchText: '' })
          this.getCaseProfileData()
          this.ngxService.stop()

        },
        error: (err) => this.handleError(err)
      })
    }

    handleError(err){
      this.ngxService.stop()
      this._snackbar.open('Something went wrong. Please try again', 'Close')
      console.error(err)
    }

    private getAuditableDetails(location: any) {
      this.vitalservice.getDisplayColumns({ "TableName": location }).subscribe({
        next: (res) => {
          try {
            this.auditableColumns = JSON.parse(res.content.JsonData);
          } catch (error) {
            console.error(error)
          }
        },
        error: (err) => console.error(err)
      })
    }
}
