<div class="modal-dialog JsonFormat">
  <div class="modal-content header-wraps">
    <!-- Modal Header -->
    <form [formGroup]="JsonFormat">
      <!-- Header and Close button -->
      <div class="modal-header admin-model-header header-freeze">
        <h3 class="mainTitle modal-title w-100 admin-model-header-txt">
          User Role Management 
        </h3>
        <span type="button" data-dismiss="modal" (click)="JsonformatClose()" class="mr-2">
          <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
            title="Close">
        </span>

      </div>
      <!-- Role Name in list to select -->
      <div class="modal-body mt-4">
        <!-- *ngIf="UserEnteredDataDiv" -->
        <div *ngIf="showGrid" class="col-lg-12">
          <!-- the grid -->
          <wj-flex-grid #flex [headersVisibility]="'Column'" [(itemsSource)]="gridData"
            [allowDragging]="false" (initialized)="initializeGrid(flex)">
            <wj-flex-grid-column [binding]="'RoleId'" [header]="'Id'" [visible]="false" [isRequired]="true"
              [width]="200" [minWidth]="40"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'RoleName'" [header]="'Roles'" [width]="210" [minWidth]="40">
            </wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'Description'" [header]="'Description'" [isRequired]="true" [width]="200"
              [minWidth]="40"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'PriorityOrder'" [header]="'Priority Order'" [isReadOnly]="true"
              [width]="120" [minWidth]="40"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'Status'" [selectionMode]="'None'" [header]="'Status'"
              [isReadOnly]="statusread" [width]="140" [minWidth]="40" [format]="'n2'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'IsAllowAllUsers'" [selectionMode]="'None'" [header]="'All Lab Access'"
              [isReadOnly]="isAllowAllusersRead" [width]="140" [minWidth]="40" [format]="'n2'"></wj-flex-grid-column>
            <wj-flex-grid-column [width]="140" [minWidth]="40" [binding]="'buttons'" [header]="'Action'" class="cursor"
              align="center"></wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
          </wj-flex-grid>
          <div class="align-center">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
              headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
            <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
              <wj-menu-item [value]="0">No Paging</wj-menu-item>
              <wj-menu-item [value]="10">10</wj-menu-item>
              <wj-menu-item [value]="15">15</wj-menu-item>
              <wj-menu-item [value]="30">30</wj-menu-item>
              <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
          </div>
          <div class="col-sm-12 mt-4 mb-2 align-center">
            <div class="row p-0 m-0">
              <button mat-raised-button class="admin-btn-success mr-4" (click)="showPermission($event)"
                title="Permission">
                Permission
              </button>
              <button mat-raised-button class="admin-btn-success" (click)="InsertRoleForm()" title="Add Role">
                Create
              </button>
            </div>
          </div>
          <!-- template for buttons on items in view mode -->
          <div id="tplBtnViewMode" style="display:none">
            <button id="btnEdit" class="btn btn-sm background-trans cursor">
              <i class="fa fa-edit" aria-hidden="true"></i>
            </button>
          </div>

          <!-- template for buttons on items in edit mode -->
          <div id="tplBtnEditMode" style="display:none">
            <button id="btnOK" class="btn btn-success btn-sm mr-3 cursor"><i class="fa fa-check" id="btnOK"
                aria-hidden="true"></i></button>
            <button id="btnCancel" class="btn btn-danger btn-sm cursor"><i class="fa fa-times" id="btnCancel"
                aria-hidden="true"></i></button>
          </div>
        </div>
        <!-- permission Update -->
        <div *ngIf="PermissionUpdating">
          <div>
            <div class="row col-sm-12 d-flex align-center p-0 m-0">
              <div class="col-md-4">
                <!-- Role Name -->
                <mat-form-field class="col-md-12" appearance="outline">
                  <mat-label><span class="error-msg">*</span>Roles</mat-label>
                  <mat-select disableOptionCentering formControlName="RoleNameGroup">
                    <mat-option *ngFor="let i of RoleList" disableOptionCentering
                      (onSelectionChange)="cardTypeSuggestion(i,true)" [value]="i">
                      {{i}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
              <!-- Card type to select  -->
              <div class="col-md-4">
                <mat-form-field class="col-md-12" appearance="outline">
                  <mat-label>Card Type</mat-label>
                  <mat-select formControlName="CardTypeGroup" (selectionChange)="permissionSuggestion($event)" disableOptionCentering>
                    <div *ngFor="let item of cardTypeSuggestionList">
                      <mat-option *ngIf="JsonFormat.value.RoleNameGroup" disableOptionCentering
                        value="{{ item.CardType }}">
                        <span>{{item.CardType}}</span>
                      </mat-option>
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>

            </div>
          </div>
          <!-- Permission of json to edit and save -->
          <div class="row p-0 mt-2" *ngIf="JsonFormat.value.permissionGroup">
            <div class="col-12 p-0 m-0 text-center">
              <strong>Permissions</strong>
            </div>
            <div class="row col-12 p-0 m-0 box-wrap">
                <textarea [ngStyle]="{'color': !jsonValid ? 'red' :  'green'}"  (ngModelChange)="JsonValidate($event)"
                  style="width:100%; height: 100%; min-height: 17rem;border: none;"
                  formControlName="permissionGroup"></textarea>
              <!-- <div class="col-md-6 m-0 box-wrap">
                <json-editor [options]="editorOptions" [data]="data"></json-editor>
              </div> -->
              <!-- <div class="col-sm-12 align-center mt-2">
                <button mat-raised-button class="admin-btn-success" (click)="PermissionUpdate()" [disabled]="disableSave(JsonFormat.value.permissionGroup)">Update</button>
              </div> -->
              <div class="col-sm-12 mt-4 mb-2 align-center">
                <div class="row p-0 m-0">
                  <!-- <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">Back</button> -->

                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 mt-4 mb-2 align-center">
            <div class="row p-0 m-0">
              <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">Back</button>
              <button mat-raised-button class="admin-btn-success" (click)="PermissionUpdate()"
                [disabled]="updateBtn">Update</button>
            </div>
          </div>
        </div>

      </div>
      <!-- Adding a new role -->
      <div class="row col-sm-12 m-0 p-0" *ngIf="RoleAdding">
        <div class="col-sm-12 m-0 p-0 align-center">
          <div class="col-md-4 m-0 p-0">
            <mat-form-field appearance="outline" class="col-sm-12">
              <mat-label><span class="error-msg">*</span>Roles</mat-label>
              <input matInput formControlName="RoleName" name="RoleName" type="RoleName">
            </mat-form-field>
          </div>

          <div class="col-md-4 m-0 p-0">
            <mat-form-field appearance="outline" class="col-sm-12">
              <mat-label><span class="error-msg">*</span>Description</mat-label>
              <input matInput formControlName="Description" name="Description" type="Description">
            </mat-form-field>
          </div>

          <!-- <div class="col-md-4 m-0 p-0 mb-2">
            <mat-checkbox [color]="task.color" formControlName="IsAllowAllUsers" class="ml-3" name="IsAllowAllUsers"
              type="IsAllowAllUsers">IsAllowAllUsers</mat-checkbox>
          </div> -->
        </div>

        <div class="row col-sm-12 m-0">
          <div class="col-sm-12 mt-4 mb-2 align-center">
            <div class="row p-0 m-0">
              <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">Back</button>
              <button mat-raised-button class="admin-btn-success" (click)="InsertNewRole()"
                [disabled]="false">Create</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>