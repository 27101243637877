import { Injectable } from '@angular/core';
import {  MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/base/popup/dialog/dialog.component';
import { PreviewDialogComponent } from './../../base/popup/preview-dialog/preview-dialog.component';
import { NpiComponent } from 'src/app/labadmin/components/share/components/npi/npi.component';
import { FaxComponent } from './../../labadmin/components/share/components/fax/fax.component';
import { ViewAttachmentComponent } from 'src/app/labadmin/components/share/components/view-attachment/view-attachment.component';
import { LabAdminDailogComponent } from 'src/app/base/popup/lab-admin-dailog/lab-admin-dailog.component';
import { BulkUploadPopUpComponent } from 'src/app/labadmin/components/share/components/bulk-upload-pop-up/bulk-upload-pop-up.component';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }
  //common alert
  openDialog(heading:string,message:string,subMessage:string,continueBtn:string,cancelBtn :string) {
   return this.dialog.open(DialogComponent, {
      disableClose: true,
      width: '500px',
      data: { dailogHeading: heading, message: message, alert: subMessage, continue: continueBtn, cancel: cancelBtn }
    });
  }

  openLargeDialog(heading: string, qualityattributes : any, preview : string, width = '72vw', height = '82vh'){
    return this.dialog.open(PreviewDialogComponent, {
      disableClose: true,
      width:width,
      height : height,
      data:{header : heading,qualityAttribueList :qualityattributes,preview : preview}
    });
  }

  openNPIDailog(heading: string, organizationid : string, isDataPopulated : boolean, prefilledData : any, stateList : Lookup[]= [], mode : 'search' | 'view' = 'search', NPIFor : 'user' | 'location' = 'location', height = '82vh', width='80vw'){
    return this.dialog.open(NpiComponent, {
      disableClose: true,
      width:width,
      minHeight: height,
      maxHeight: '80vh',
      data:{header : heading, stateList, organizationid : organizationid, mode : mode, NPIFor : NPIFor, isDataPopulated : isDataPopulated, prefilledData : prefilledData}
    });
  }

  openFaxDailog(heading: string, services: any,value:string,accountid:number,labAccountDetails:any,loggedInUserId:any,labOrgid:number,width = '72vw') {
    return this.dialog.open(FaxComponent, {
      disableClose: true,
      width: width,
      data: { header: heading, Services: services,ruleName:value,accountId:accountid,labAccountDetails:labAccountDetails,loggedInUserId }
    });
  }

  openAttachmentsViewDailog(file : any, fileName : string, width='72vw'){
    return this.dialog.open(ViewAttachmentComponent, {
      disableClose: true,
      width:width,
      height : '80vh',
      data:{file : file, fileName : fileName}
    });
  }

  openCommonDialog(heading:string,message:string,subMessage:string,continueBtn:string,cancelBtn :string) {
    return this.dialog.open(LabAdminDailogComponent, {
       disableClose: true,
       width: '500px',
       data: { dailogHeading: heading, message: message, alert: subMessage, continue: continueBtn, cancel: cancelBtn }
     });
   }

   openUserBulkUploadDailog(heading: string, type: 'users' | 'labusers' = 'users', height = 'auto', width='auto'){
    return this.dialog.open(BulkUploadPopUpComponent, {
      disableClose: true,
      width:width,
      minHeight: height,
      maxHeight: '80vh',
      data:{header : heading, type: type}
    });
  }

  openLabAdminconfirmDialog(heading:string,message:string,continueText:string,cancelText:string) {
    return this.dialog.open(ConfirmLabadminComponent, {
       disableClose: true,
       width: '360px',
       data: { dailogHeading: heading, message: message, continue: continueText, cancel: cancelText }
     });
   }



}
export interface Lookup {
  LookupID: string;
  LookupValue: string;
  Description: string;
  SequenceOrder: number;
}
