<div class="row container-fluid py-2 px-3">

  <div class="col-sm-2 pr-0">
    <div *ngIf="logo==''" class="col-sm-9 no_logo">
        <img src="../../../../assets/icons/NoLogo.svg" alt="" width="100" height="70">
    </div>

    <div *ngIf="logo!=''" class="col-sm-9 brand_logo">
        <img [src]="logo" alt="" width="100" height="70">
        <div class="action_btns">
            <div class="actions_icon" (click)="downloadLogo()"> <i class="fa fa-download" aria-hidden="true"></i> </div>
        </div>
    </div>
</div>


  <div class="col-sm-10">
    <div class="row">
      <div class="col-sm-7 tool-tip">
        <span class="roleborder mx-2"></span>
        <span class="username" matTooltip="{{ locationDetails?.DisplayName | uppercase }}">
          {{ locationDetails?.DisplayName | uppercase }}
        </span>
      </div>
      <div
        class="col-sm-5 d-flex justify-content-end slide"
        style="width: calc(100% - 70%)"
      >
 <!-- Edit -->
 <button class="Vdm-btn mr-3 cursor" disabled="isEditDisabled" (click)="EditLocation()"> <span> <i class="fa fa-pencil mr-2" aria-hidden="true"></i></span> Edit Location</button>
        <span
          >Active: <app-toggle-switch class="ml-auto mr-4" [isDisable]="isStatusDisabled" [isChecked] = "isChecked" (clicked)="toggleChange($event)"></app-toggle-switch
        ></span>
      </div>
    </div>
    <div class="ml-4">
      <span
        class="text-uppercase _600 tool-tip"
        style="font-size: 11px"
        matTooltip="{{ locationDetails?.Address | uppercase }}"
        >{{ locationDetails?.Address }}</span
      >
    </div>

    <div class="row pl-2">
      <div class="col-sm-8 d-flex">
        <div class="row d-block justify-content-between px-4 mt-1">
          <div class="icon_label" *ngIf="locationDetails?.Phone != '' && locationDetails?.Phone != null && locationDetails?.Phone != undefined">
            <mat-icon class="vdm_icon">phone</mat-icon>
            {{ locationDetails?.Phone }}
          </div>
          <div class="icon_label" *ngIf="locationDetails?.Fax != '' && locationDetails?.Fax != null && locationDetails?.Fax != undefined">
            <mat-icon class="vdm_icon">contact_phone</mat-icon>
            {{ locationDetails?.Fax }}
          </div>
        </div>
        <div class="username surgicenter" *ngIf="locationDetails?.IsSharedSurgicenter">SURGICENTER</div>
      </div>
    </div>
  </div>
</div>
