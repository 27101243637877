import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, VERSION, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { parse, areIntervalsOverlapping } from "date-fns";
import * as lodash from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { CommonService } from '../../../core/services/commonservices';
import { DataShareService } from '../../../core/services/datashare.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { ActionbtnComponent } from '../actionbtn/actionbtn.component';
import { AddUserToOrgComponent } from '../AddUserToOrg/AddUserToOrg.component';
import { AllUsersComponent } from '../allusers/allusers.component';
import { UserPersonalInfoComponent } from '../UserPersonalInfo/UserPersonalInfo.component';

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit {

  task: Task = {
    color: 'primary'
  };

  [x: string]: any;
  @Input() userData: any;
  name = 'Angular ' + VERSION.major;

  @Input()
  organizationLoginType: any;

  @Input()
  userLoginType: any;

  @Input()
  fromComponent: any;

  @Input()
  DeploymentKeys

  @Input()
  editMode: boolean = false;

  @Input()
  organizationID: any;

  @Input() creatLiveFlag;
  @Output()
  sendCommonKey: EventEmitter<any> = new EventEmitter();

  @ViewChild('userTabs') userTabs: MatTabGroup

  rolechecklist: any;
  locationchecklist: any;
  masterSelected = false;

  gridRolesData: any = [];
  gridLocationsData: any = [];

  selectedTab = new FormControl(0);
  SubMenuCardModel: any;

  columnRoles: any = ['Hold Duration (In minutes)', 'Primary Roles?', 'Roles'];
  columnLocations: any = ['Locations List'];
  selector: any;
  selectedItems: any;
  saveState: boolean = false;
  alertPopUp: boolean = false;

  minDate = new Date();

  zip_pattern = "/^\d{5}([\-]\d{4})?$/";
  frm_AddUsergroup = this._fb.group({
    frmDeploymentKey: ['', Validators.required],
    frmOrganization: ['', Validators.required],
    frmAccountName: '',
    frmInp_title: 'Dr.',
    frmInp_Address1: '',
    frmInp_FirstName: ['', Validators.required],
    frmInp_Address2: '',
    frmInp_LastName: ['', Validators.required],
    frmInp_Street: '',
    frmInp_MiddleInitial: '',
    frmInp_City: '',
    frmInp_Qualification: '',
    frmInp_State: '',
    frmInp_LoginName: [''],
    frmInp_Zip: '',
    frmInp_Gender: "1",
    frmInp_Country: '',
    frmInp_NPI: ['UNKNOWN', Validators.required],
    frmInp_Phone: '',
    frmInp_DisplayFormatWebpage: "F L D",
    frmInp_Fax: '',
    frmInp_DisplayFormatDescription: "F L D",
    frmInp_DisplayFormatReports: "S. F L D",
    frmInp_Email: ['', Validators.required],
    frmInp_SSN: '',
    frmInp_Status: true,
    frmInp_UserCode: '',
    frmInp_UPIN: '',
    frmInp_ShowMyCaseOnly: false,
    frmInp_ExternalUserGUID: '',
    //frmInp_Region: ['', Validators.required],
    frmInp_US: false,
    frmInp_ST: false,
    frmInp_AllowReviewPathologist: false,
    frmInp_AllowtoeditAccession: false,
    frmInp_Threshold: '',
    frmInp_EditSite: false,
    frmDrp_ThresholdDuration: 'Select',
    frmDrp_ReadComputeOn: "Sign-out",
    frmInp_ReviewThreshold: '',
    frmDrp_ReviewDuration: 'Select',
    frmDrp_ReviewComputeOn: 'Sign-out',
    frmInp_ScreeningThreshold: '',
    frmDrp_ScreeningDuration: 'Select',
    frmDrp_ScreeningComputeOn: 'Sign-out',
    frmInp_AllowPathologistDiagnosisTemplate: false,
    frmChk_EnableSignOutCorrection: false,
    frmChk_IsPathologistPrivileges: false,
    frmInp_PopulateDiag: false,
    frmInp_Notifyusers: true,
    frmArruserRoles: this._fb.array([]),
    frmArruserAccounts: this._fb.array([]),
    frmDrp_OrgSendforReview: 'true',
    frmDrp_OrgManages: 'true',
    frmDrp_OrgWorks: 'true',
    frmchk_OrgLocation: false,
    formLicensure: this._fb.array([]),
    frmPhysician: this._fb.array([]),
    frmOutOfOffice: this._fb.array([]),
    frmPathologist: this._fb.array([]),
    frmLblchk_OrgLocation: '',
    frmChk_RoleCheckboxValidator: false,
    frmInp_AllowLogin: true,
    frmChk_IsMedicalDirector: false,
    frmEditInp_State: null
  });
  StateList: any = [];
  files: any = [];
  imagePath: boolean = false;
  frm_sign: string = null;
  clone_sign: string;
  userTabDisable: boolean = false;
  roleTabDisable: boolean = true;
  LocTabDisable: boolean = true;
  configTabDisable: boolean = true;
  summaryTabDisable: boolean = true;
  allowLogin: boolean = false;
  organizationList: any;
  LoginTypesflag: boolean = false;
  noOrgData: boolean;
  SelectedOrgID: any;
  holdReleaseDuration: any;
  DiagICDcodeReminder: any;
  ArrOrgType: any;
  UserType: any;
  IsOrderingFacility: boolean;
  isICDdisplay: any;
  ArrEPGOrgType: any;
  context: string;
  card: any;
  EPGorgType: any;
  ArrThresholdDuration: any;
  CommonKey: any;
  ArrSavedRoles: any = [];
  associationData: any;
  cardtype: string;
  rolesdata: any[];
  ShowMedicalDirector: boolean = false;
  ShoHoldReleaseDuration: boolean = false;
  hidIsCytoSelected: boolean = false;
  hidIsCytoSupervisiorSelected: boolean = false;
  hidPrimaryRole: any;
  hidIsPathlogistSelected: boolean = false;
  showfrmInp_AllowReviewPathologist: boolean = false;
  showfrmInp_AllowtoeditAccession: boolean = false;
  ShowLicensureDetails: boolean = false;
  showfrmInp_Threshold: boolean = false;
  showfrmInp_EditSite: boolean = false;
  showfrmDrp_ThresholdDuration: boolean = false;
  showfrmInp_AllowPathologistDiagnosisTemplate: boolean = false;
  showfrmInp_PopulateDiag: boolean = false;
  IsMedDirectorExistsForCommon: boolean = false;
  MD_KValue: number;
  HRD_KValue: number;
  rolelablistcount: any;
  rolelablistcytcount: any;
  rolelablistcyscount: any;
  rolelistcount: any;
  hidIsChecked: boolean = false;
  counts: any = -1;
  ClickFrom: string = 'UI';
  hidIsPhysicianSelected: boolean = false;
  HRD_value: number = 0;
  hidIsMedicalDirector: boolean = false;
  showfrmInp_Notifyusers: boolean = true;
  showfrmInp_DisplayFormatReports: boolean = false;
  accountSearch: string = ''
  roleSearch: string = ''
  clonedUserForm: any;
  loginNamesList: any;
  NPIvalidated: boolean = false;
  moveTo: string;
  StateDiscription: string = "";
  usersdeatils: any;
  EditCommonKey: any;
  Editpassword: any;
  EditholdReleaseDuration: any;
  EditDiagICDcodeReminder: any;
  pathuserid: any;
  rolename: any;
  Status_value: number;
  userstatus: string;
  statuslist: any = [];
  associateSurgiCenter: boolean = false;
  surgicenterList: Array<object> = [];
  selectedSurgiCenters: Array<object> = [];
  clonedsurgicenterList: any = [];
  backupsurgicenterList: any = [];
  clonedselectedSurgiCenters: Array<object> = [];
  backupselectedSurgiCenters: Array<object> = [];
  primaryLocation: any;
  clonedPrimaryLocation: any;
  backupPrimaryLocation: any;
  surgiAssociationData: any = [];
  locationsList: any = [];
  editOrgLocList: any[];
  summaryData: any = {};
  roleList: any = [];
  accounts: any = [];
  userThreshold: any = {};
  existingAssociations: any = []
  existingOutOfOfficeDetails: any = []
  userAssocFlag: boolean = false;
  pathFilter: string = '';
  physFilter: string = '';
  IsMedicalDirectorExists: any = false
  worksAccount: any;
  assocChecked: boolean = false;
  clonedAssocChecked: boolean = false;
  POLOrg: boolean = false;
  IsPOL: boolean = false;
  searchContext: string
  editConfigRoles: string[] = [];
  ascPresent: boolean = false;

  constructor(private _fb: FormBuilder,
    private ngxService: NgxUiLoaderService, public _snackBar: MatSnackBar, public datepipe: DatePipe, private user: AllUsersComponent, private commonService: CommonService, public vaservices: VitalHttpServices, private datashare: DataShareService, public actionBtn: ActionbtnComponent, public UserPersonalInfoComponent: UserPersonalInfoComponent
    , public AllUsersComponent: AllUsersComponent, public homepage: AddUserToOrgComponent, private dialog: MatDialog) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, vaservices, datashare);
  }

  async ngOnInit() {
    this.DeploymentKeys = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    this.ngxService.start('loadInitialData');
    try {
      this.organizationID = this.organizationID ? this.organizationID.toString() : sessionStorage.getItem('org_id');
      if (this.editMode) {
        //  = true;
        this.EditCommonKey = this.userData.CommonKey;
        await this.getOrganizationLoginType();
        await this.editMethod();
        // this.frm_AddUsergroup.controls['frmInp_LoginName'].disable();
      }
      else {

        await this.SearchOrg();
        // await this.getIsMedicalDirectorvalue();
      }

      if (this.organizationLoginType.toString().toLowerCase().trim() != 'email') {
        // this.frm_AddUsergroup.controls.frmInp_LoginName = new FormControl(this.editMode ? this.usersdeatils.loginname : '', Validators.required);
        this.frm_AddUsergroup.controls['frmInp_LoginName'].setValidators([Validators.required]);
        this.frm_AddUsergroup.controls.frmInp_Email.clearValidators();
        this.frm_AddUsergroup.controls.frmInp_Email.updateValueAndValidity();
      }
    }
    catch (e) {
      console.error(e);
      this._snackBar.open('Something went wrong.', 'Close');
      this.user.back();
    }
    finally {
      this.ngxService.stop('loadInitialData');
    }
  }

  async getIsMedicalRolePresent(OrgId, commonKey?, formGroup?: FormGroup): Promise<boolean> {
    let returnValue = false;
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetismedicaldirector");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "orgid": OrgId }
    };
    await this.vaservices.GetData(drpqueryRequest).toPromise().then(result => {
      if (!result.errors) {
        this.IsMedicalDirector = false;
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.IsMedicalDirector = true;
          }
          else {
            this.IsMedicalDirector = false;
          }
        }
        else {
          this.IsMedicalDirector = false;
        }


        if (commonKey) {
          if (this.IsMedicalDirector == true && result.data.submenudata[0].CommonKey != commonKey) {
            formGroup.controls.frmChk_IsMedicalDirector.disable();
          }
          else if (result.data.submenudata[0] && result.data.submenudata[0].CommonKey == commonKey) {
            returnValue = true;
          }

        }
        else if (this.IsMedicalDirector) {
          formGroup.controls.frmChk_IsMedicalDirector.disable();
        }

      }
    });
    return returnValue;
  }

  isPathologistActive() {
    return this.frm_AddUsergroup.get('frmArruserRoles').value.some((va: any) => va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'pathologist' && va.frmLbl_usrStatus == true)
  }

  async getOrganizationLoginType() {
    let queryVariable = { "Orgid": this.organizationID };
    let query = this.vaservices.GetQuery("getorganizationname");
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    if (!this.organizationLoginType) {
      await this.vaservices.GetData(queryResult, depKey).toPromise().then(res => {
        if (res) {
          this.organizationLoginType = (res.data.submenuData[0].LoginType == null || res.data.submenuData[0].LoginType == '' || res.data.submenuData[0].LoginType.toLowerCase() == 'loginname') ? 'LoginName' : 'Email';

        }
      }, error => {
        console.error(error);
      })
    }
  }


  async editMethod() {
    this.ngxService.start();
    this.SelectedOrgID = this.userData.Organizationid;
    this.usersdeatils = this.userData;
    await this.editGetIsMedicalDirectorvalue();
    await this.searchOrgType();
    await this.editSavedRoleList(this.userData.userid, this.userData.Organizationid);
    this.ngxService.stop();
  }

  async editSavedRoleList(userid, OrgId) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetcommonkey");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "userid": userid.toString() }
    };
    this.ngxService.start();
    await this.vaservices.GetData(drpqueryRequest).toPromise().then(async result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.CommonKey = result.data.submenudata[0].CommonKey;
            if (this.CommonKey == null) {
              this.CommonKey = "";
            }
          }
          else {
            this.CommonKey = "";
          }
        }
        else {
          this.CommonKey = "";
        }
        //get getSavedRoleList
        drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetsavedrole");
        drpqueryRequest = {
          "OperationName": null,
          "Query": drpquery[0].Query,
          "Variables": { "Commonkey": this.CommonKey }
        };

        await this.vaservices.GetData(drpqueryRequest).toPromise().then(async result => {
          if (!result.errors) {
            if (result.data.submenudata != undefined && result.data.submenudata != null) {
              if (result.data.submenudata.length > 0) {
                this.ArrSavedRoles = result.data.submenudata
                this.ArrSavedRoles.forEach(element => {
                  element.IsPrimary = !element.IsPrimary || element.IsPrimary == '0' ? false : true;
                  element.status = element.status == 1 ? true : false;
                  element.ModifyTechnicalData = !element.ModifyTechnicalData || element.ModifyTechnicalData == '0' ? false : true;
                  element.IsModifyTechnicalDataAccession = !element.IsModifyTechnicalDataAccession || element.IsModifyTechnicalDataAccession == '0' ? false : true;
                  element.ReviewPathCanSignout = !element.ReviewPathCanSignout || element.ReviewPathCanSignout == '0' ? false : true;
                  element.isMedicalDirector = !element.isMedicalDirector || element.isMedicalDirector == '0' ? false : true;
                  element.IsUserLevelDiagnosisTemplateEnabled = !element.IsUserLevelDiagnosisTemplateEnabled || element.IsUserLevelDiagnosisTemplateEnabled == '0' ? false : true;
                  element.ShowMyCaseOnly = !element.ShowMyCaseOnly || element.ShowMyCaseOnly == '0' ? false : true;
                  element.DiagICDcodeReminder = !element.DiagICDcodeReminder || element.DiagICDcodeReminder == '0' ? false : true;

                  if (element.rolename.toString().toLowerCase().trim() == 'physician' && element.isPrimaryLocation) {
                    this.primaryLocation = String(element.accountid)
                  }
                });
                let i;
                for (i = 0; i < this.ArrSavedRoles.length; i++) {
                  if (this.ArrSavedRoles[i].rolename === 'Pathologist') {
                    this.pathuserid = this.ArrSavedRoles[i].userid;
                    this.frm_AddUsergroup.patchValue(
                      {
                        frmInp_AllowPathologistDiagnosisTemplate: this.ArrSavedRoles[i].IsUserLevelDiagnosisTemplateEnabled,
                        frmInp_ShowMyCaseOnly: this.ArrSavedRoles[i].ShowMyCaseOnly,
                        frmChk_IsMedicalDirector: this.ArrSavedRoles[i].isMedicalDirector ? true : false
                      });
                    break;
                  }
                }
              }
            }
          }
          await this.editLocationDetails(OrgId);
        }, error => {
          console.info(error);
        });
      }
    }, error => {
      this.ngxService.stop();
      console.info(error);
    });
  }

  async editLocationDetails(OrgIDValue: string) {
    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.GetQuery('adduserlocations');
    queryVariable = { "orgid": OrgIDValue ? OrgIDValue.toString() : this.userData.Organizationid.toString() };
    let accIds: any[];
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    let res = await this.vaservices.GetData(queryResult, depKey).toPromise();
    this.ngxService.stop();
    if (!res.errors) {
      let templateData = res.data.vwGetAccountAssociationForUser_list;
      this.editOrgLocList =  res.data.vwGetAccountAssociationForUser_list;
      // this.organizationList = res.data.vwGetAccountAssociationForUser_list;
      let checkedLoction = this.userData.Userlocation.split(',');
      let worksLocation = this.userData.Works;
      if (templateData.length > 0) {
        (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).clear();
        let m;
        let tempValue = false;
        for (m = 0; m < templateData.length; m++) {
          let frmAccounts;
          frmAccounts as FormGroup;
          frmAccounts = "userAccounts_" + m;
          for (let j = 0; j < checkedLoction.length; j++) {
            //let templocation=checkedLoction[j];
            let tempArrayData = templateData[m].Account_ID == checkedLoction[j];

            if (tempArrayData == true) {
              frmAccounts = this._fb.group({
                frmchk_Location: new FormControl(true),
                frmLblchk_Location: new FormControl(templateData[m].Account_ID),
                frmDrp_Works: worksLocation && worksLocation == templateData[m].Account_ID ? new FormControl(true) : new FormControl(false),
                frmDrp_Manages: new FormControl('true'),
                frmDrp_SendforReview: new FormControl('true'),
                frmLbl_Location: new FormControl(templateData[m].Display_Name),
                frmLbl_LocationContext: new FormControl(templateData[m].context)
              });

              break;
            }
            else {
              frmAccounts = this._fb.group({
                frmchk_Location: new FormControl(false),
                frmLblchk_Location: new FormControl(templateData[m].Account_ID),
                frmDrp_Works: worksLocation && worksLocation == templateData[m].Account_ID ? new FormControl(true) : new FormControl(false),
                frmDrp_Manages: new FormControl('true'),
                frmDrp_SendforReview: new FormControl('true'),
                frmLbl_Location: new FormControl(templateData[m].Display_Name),
                frmLbl_LocationContext: new FormControl(templateData[m].context)
              });
            }
          }
          (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).push(frmAccounts);
        }
        this.locationsList = [];
        for (let k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
          if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true &&
            (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmLbl_LocationContext'].value.toString().toLowerCase().trim() == 'of') {
            this.locationsList.push({ accountid: (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmLblchk_Location'].value, accountname: (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmLbl_Location'].value })
          }
        }
        if (templateData.length == checkedLoction.length) {
          this.frm_AddUsergroup.patchValue({
            frmDrp_OrgSendforReview: 'true',
            frmDrp_OrgManages: 'true',
            frmDrp_OrgWorks: 'true',
            frmchk_OrgLocation: true,
          });
        }
        else {
          this.frm_AddUsergroup.patchValue({
            frmDrp_OrgSendforReview: 'true',
            frmDrp_OrgManages: 'true',
            frmDrp_OrgWorks: 'true',
            frmchk_OrgLocation: false,
          });
        }

        accIds = [];
        templateData.forEach(element => {
          accIds.push(element.Account_ID);
        });
        await this.editAssociationTypes(accIds);

      }
      else {
        templateData = "";
      }
    }
    else {
      console.error(res.error);
    }
  }

  async editAssociationTypes(accIDs) {

    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.GetQuery('getassociationtypeforaccid');
    let i, Qstr = "";
    for (i = 0; i < accIDs.length; i++) {
      if (i == accIDs.length - 1) {
        Qstr += "accountid = \"" + accIDs[i] + "\"";
      }
      else {
        Qstr += "accountid = \"" + accIDs[i] + "\" or ";
      }
    }
    queryVariable = { "accid": Qstr };
    let associationTypes: any[];
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.vaservices.GetData(queryResult, depKey).toPromise().then(async res => {
      this.ngxService.stop();
      if (!res.errors) {
        if (res.data.vwGetAssociationtypeByOrgID_list.length > 0) {
          this.associationData = res.data.vwGetAssociationtypeByOrgID_list;
          associationTypes = [];
          this.associationData.forEach(element => {
            if (associationTypes.indexOf(element.associationtype) == -1) {
              //to get unique associations from arrayF
              associationTypes.push(element.associationtype)
            }
          });
          await this.editRoles(associationTypes);
        }
        else {
          this.associationData = "";
        }
      }
      else {
        console.error(res.error);
      }
    }, error => {
      console.error(error);
    });
  }

  async editRoles(AssociationTypes) {
    if (sessionStorage.getItem("contextdata") == 'users' || sessionStorage.getItem("contextdata") == 'physician' || sessionStorage.getItem("contextdata") == 'pathologist') {
      await this.getAccountType();
    }
    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.GetQuery('getuserrolesforassociationtype');
    let i, Qstr = "";
    let medicalDirectorvalue;
    for (i = 0; i < AssociationTypes.length; i++) {
      if (i == AssociationTypes.length - 1) {
        Qstr += "associationtype = \"" + AssociationTypes[i] + "\"";
      }
      else {
        Qstr += "associationtype = \"" + AssociationTypes[i] + "\" or ";
      }
    }
    if(this.commonService.isPol){
      this.IsPOL = true
      //this.POLOrg = true;
    }

    // let index = AssociationTypes.findIndex(va=> va.toString().toLowerCase().trim() == 'both')
    // if(index != -1){
    //   this.POLOrg = true
    // }
    queryVariable = { "associationtype": Qstr };
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let res: any = await this.vaservices.GetData(queryResult, depKey).toPromise();
    this.ngxService.stop();
    if (!res.errors) {
      if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
        this.rolesdata = [];
        this.rolesdata = res.data.vwGetRolesBasedOnAccountAssociation_list; //unsorted
        //to get unique roles from array
        let rolesarray = [];
        this.rolesdata.forEach(async (element) => {
          if (rolesarray.length > 0) {
            let foundRoleDuplicate = false;
            for (let kk = 0; kk < rolesarray.length; kk++) {
              let role: string = element.rolename;
              if (rolesarray[kk].rolename === role) {
                foundRoleDuplicate = true;
                break;
              }
            }
            if (foundRoleDuplicate == false) {
              //to get unique roles from array
              rolesarray.push(element);
            }
          }
          else {
            rolesarray.push(element);
          }
        });
        this.rolesdata = rolesarray; // edit roles
        if (this.editMode && (this.commonService.isLiveAccount || this.isliveAccount) && !this.creatLiveFlag) {
          await this.getRolesConfiguredForEdit();// editConfigRoles
        }
        else if((this.editMode && this.commonService.isLiveAccount && this.creatLiveFlag) || !this.commonService.isLiveAccount ) {
          this.editConfigRoles =[]
           this.rolesdata.forEach(element => {this.editConfigRoles.push(element.displayname.toLowerCase())});
        }  
        if (this.IsPOL) {
          let roleType = this.rolesdata.find(va => va.rolename === this.ArrSavedRoles[0].rolename);
          if (roleType?.Associationtype !== undefined) {
            if (roleType?.roletype?.toLowerCase() != 'both') {
            this.rolesdata = this.rolesdata.filter(ele => ele.roletype === roleType?.roletype || ele.roletype?.toLowerCase() == 'both');
            }
            this.hideLocations(roleType?.roletype?.toLowerCase());
          }
        }       
        (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).clear();
        let k;
        for (k = 0; k < this.rolesdata.length; k++) {
          let frm;
          frm as FormGroup;
          frm = "userRole_" + k;
          let IsPathologistActive: boolean = false;
          if (this.rolesdata[k].rolename == "Medical Staff")
            this.rolesdata[k].rolename = "Medical Assistant";
          else if (this.rolesdata[k].rolename == "Transcriptionist")
            this.rolesdata[k].rolename = "Pathologist Assistant";
          if (this.rolesdata[k].rolename.toLowerCase() == "pathologist") {
            this.ShowMedicalDirector = false;
            this.ShoHoldReleaseDuration = true;
            frm = this._fb.group({
              frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: (this.editConfigRoles && this.editConfigRoles.length && this.editConfigRoles.includes('pathologist') ? false : true) }),
              frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
              frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
              frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
              frmLbl_userID: new FormControl({ value: '', disabled: true }),
              frmLbl_usrStatus: new FormControl({ value: false, disabled: true }),
              frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
              frmChk_IsMedicalDirector: false,
              frmInp_RoleHoldreleaseDuration: new FormControl({ value: this.rolesdata[k].HoldReleaseDuration, disabled: this.editConfigRoles && this.editConfigRoles.length && this.editConfigRoles.includes('pathologist') ? false : true }),
              frm_RoleType: this.rolesdata[k].roletype
            });
          }
          else if (this.rolesdata[k].rolename.toLowerCase() == "lab manager") {
            this.ShowMedicalDirector = false;
            this.ShoHoldReleaseDuration = true;
            frm = this._fb.group({
              frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: (this.editConfigRoles && this.editConfigRoles.length && this.editConfigRoles.includes('lab manager') ? false : true) }),
              frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
              frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
              frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
              frmInp_RoleHoldreleaseDuration: new FormControl({ value: this.rolesdata[k].HoldReleaseDuration, disabled: this.editConfigRoles && this.editConfigRoles.length && this.editConfigRoles.includes('lab manager') ? false : true }),
              frmLbl_userID: new FormControl({ value: '', disabled: true }),
              frmLbl_usrStatus: new FormControl({ value: false, disabled: true }),
              frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
              frmChk_IsMedicalDirector: false,
              frm_RoleType: this.rolesdata[k].roletype
            });
          }
          else {
            this.ShowMedicalDirector = false;
            this.ShoHoldReleaseDuration = false;
            frm = this._fb.group({
              frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: ((this.rolesdata[k].rolename.toLowerCase() == "physician" && this.editConfigRoles && this.editConfigRoles.length && this.editConfigRoles.includes('physician')) ? this.checkAscPhysician(this.editOrgLocList) : (this.editConfigRoles && this.editConfigRoles.length && this.editConfigRoles.some(va => va == this.rolesdata[k].rolename.toLowerCase())) ? false : true) }),
              frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
              frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
              frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
              frmLbl_userID: new FormControl({ value: '', disabled: true }),
              frmLbl_usrStatus: new FormControl({ value: false, disabled: true }),
              frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
              frmInp_RoleHoldreleaseDuration: new FormControl({ value: this.rolesdata[k].HoldReleaseDuration, disabled: this.editConfigRoles && this.editConfigRoles.length > 0 && this.editConfigRoles.some(va => va == this.rolesdata[k].rolename.toLowerCase()) ? false : true }),
              frmChk_IsMedicalDirector: false,
              frm_RoleType: this.rolesdata[k].roletype
            });

          }
          this.ArrSavedRoles.forEach(async (e, row_index) => {
            if (frm.controls.frmlbl_DisplayName.value == e.rolename) {
              frm.controls.frmChk_UserRoles_RoleName.value = true;
              frm.controls.frmChk_UserRoles_RoleName.disable(); //for enable checkBox
              if (this.editConfigRoles && this.editConfigRoles.some(val => val.toString().toLowerCase() == frm.controls.frmlbl_DisplayName.value.toString().toLowerCase())) {
                frm.controls.frmRd_UserRoles_RoleName.value = '';
                frm.controls.frmRd_UserRoles_RoleName.enable();
              }
              if (frm.controls.frmLblChk_UserRoles_RoleName.value == "cytotechnologist") {
                this.hidIsCytoSelected = true

              }
              if (frm.controls.frmLblChk_UserRoles_RoleName.value == "cytotechnologist supervisor") {
                this.hidIsCytoSupervisiorSelected = true

              }
              if ((e.HoldReleaseDuration.toString() != "" && e.HoldReleaseDuration.toString() != null)) {
                if (frm.controls.frmChk_UserRoles_RoleName.value == true) {
                  if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "lab manager" && (this.editConfigRoles && this.editConfigRoles.length && this.editConfigRoles.includes('lab manager'))) {
                    frm.controls.frmInp_RoleHoldreleaseDuration.value = e.HoldReleaseDuration as string;
                    frm.controls.frmInp_RoleHoldreleaseDuration.enable();
                  }
                  else if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "pathologist" && (this.editConfigRoles && this.editConfigRoles.length && this.editConfigRoles.includes('pathologist'))) {
                    frm.controls.frmInp_RoleHoldreleaseDuration.value = e.HoldReleaseDuration as string;
                    frm.controls.frmInp_RoleHoldreleaseDuration.enable();
                  }
                  else if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "cytotechnologist" && (this.editConfigRoles && this.editConfigRoles.length && this.editConfigRoles.includes('cytotechnologist'))) {
                    frm.controls.frmInp_RoleHoldreleaseDuration.value = e.HoldReleaseDuration as string;
                    frm.controls.frmInp_RoleHoldreleaseDuration.enable();
                  }
                  else if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "cytotechnologist supervisor" && (this.editConfigRoles && this.editConfigRoles.length && this.editConfigRoles.includes('cytotechnologist supervisor'))) {
                    frm.controls.frmInp_RoleHoldreleaseDuration.value = e.HoldReleaseDuration as string;
                    frm.controls.frmInp_RoleHoldreleaseDuration.enable();
                  }
                }
              }


              if (e.IsPrimary == true) {
                this.rolename = e.rolename;
                this.hidPrimaryRole = e.rolename;
                frm.controls.frmRd_UserRoles_RoleName.value = frm.controls.frmLblChk_UserRoles_RoleName.value;
                this.selectedPrimaryRole = frm.controls.frmLblChk_UserRoles_RoleName.value;
              }
              if (frm.controls.frmChk_UserRoles_RoleName.value == true) {
                this.Status_value = 1;
                frm.controls.frmLbl_usrStatus.enable();
                frm.controls.frmLbl_userID.value = e.userid;
                if (e.usrStatus.toLowerCase() == 'active') {
                  frm.controls.frmLbl_usrStatus.value = true
                  //  frm.controls.frmImg_UserIDnUserStatus.src = 'activestatus';
                  // frm.controls.frmImg_UserIDnUserStatus.title = 'Active';
                }
                else {
                  frm.controls.frmLbl_usrStatus.value = false;
                  // frm.controls.frmImg_UserIDnUserStatus.src = 'inactivestatus';
                  //frm.controls.frmImg_UserIDnUserStatus.title = 'InActive';
                }
              }
            }
            if (frm.controls.frmlbl_DisplayName.value == "Pathologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.frm_AddUsergroup.patchValue(
                {
                  frmInp_AllowtoeditAccession: e.IsModifyTechnicalDataAccession as boolean,
                  frmInp_EditSite: e.ModifyTechnicalData as boolean,
                  frmInp_AllowReviewPathologist: e.ReviewPathCanSignout as boolean
                });

              if (e.DiagICDcodeReminder == null) {
                // if (this.UserType == "Laboratories" && this.IsOrderingFacility as string != "True") {
                this.frm_AddUsergroup.patchValue(
                  {
                    frmInp_Notifyusers: this.DiagICDcodeReminder as boolean
                  }
                );
              }
              else {
                this.frm_AddUsergroup.patchValue(
                  {
                    frmInp_Notifyusers: e.DiagICDcodeReminder as boolean
                  }
                );
              }
            }
            if ((e.rolename == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) || e.rolename == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              if (e.DiagICDcodeReminder as string != "" && e.DiagICDcodeReminder as string != null) {
                this.frm_AddUsergroup.patchValue(
                  {
                    frmInp_Notifyusers: this.DiagICDcodeReminder as boolean
                  }
                );
              }
              else {
                this.frm_AddUsergroup.patchValue(
                  {
                    frmInp_Notifyusers: e.DiagICDcodeReminder as boolean
                  }
                );
              }
            }

          });

          if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
            this.hidIsCytoSelected = true;
            this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
          }

          if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
            this.hidIsCytoSupervisiorSelected = true;
            this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
          }
          let isPatholigistEnabled = false;

          if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
            this.hidIsPathlogistSelected = true;
            isPatholigistEnabled = true;
            this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].enable();
            this.showfrmInp_AllowReviewPathologist = true;
            this.ShowLicensureDetails = true;

            this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].enable();
            this.showfrmInp_AllowtoeditAccession = true;
            this.showfrmInp_Threshold = true;

            this.frm_AddUsergroup.controls['frmInp_EditSite'].enable();
            this.showfrmInp_EditSite = true;
            this.showfrmDrp_ThresholdDuration = true;

            this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].enable();
            this.showfrmInp_AllowPathologistDiagnosisTemplate = true;
            this.showfrmInp_PopulateDiag = true;

            this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();

            await this.setMedicalDirectorRole(frm);

          }


          if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist" || frm.controls.frmLblChk_UserRoles_RoleName.value == "Physician") {
            this.frm_AddUsergroup.controls['frmInp_NPI'].enable();
          }
          if (isPatholigistEnabled) {
            let k;
            for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {

              (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].enable();
            }
            this.editSendforReview(true);
          }
          if (this.usersdeatils.Status.toString().toLowerCase().trim() !== "active") {
            frm.controls['frmLbl_usrStatus'].disable()
          }
          (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).push(frm);
        }
        this.editSendforReview(false);
        //to display hold release duration and  medical director
        this.MD_KValue = -1;
        this.HRD_KValue = -1;
        for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; k++) {
          if (this.HRD_KValue == -1 && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmInp_RoleHoldreleaseDuration').enabled == true) {
            this.HRD_KValue = k;
          }
          if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'lab manager') {
            this.rolelablistcount = k
          }
          if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'cytotechnologist') {
            this.rolelablistcytcount = k
          }
          if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'cytotechnologist supervisor') {
            this.rolelablistcyscount = k
          }
          if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value == 'Pathologist') {
            this.rolelistcount = k;
            if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
              this.MD_KValue = k;
              if (this.EPGorgType == 'Pathologists') {
                this.ShowLicensureDetails = true;
              }
              else {
                this.ShowLicensureDetails = true;
              }
            } else {
              this.ShowLicensureDetails = false;
            }
          } else {
            this.ShowLicensureDetails = false;
          }
        }
        // this.showRoles = true;
      }
    }
    else {
      this.rolesdata = [];
      let k;
      for (k = 0; k < this.rolesdata.length; k++) {
        this.ShowMedicalDirector = false;
        this.ShoHoldReleaseDuration = false;
        let frm;
        frm as FormGroup;
        frm = "userRole_" + k;
        frm = this._fb.group({
          frmChk_UserRoles_RoleName: [{ value: false, disabled: false }],
          frmLblChk_UserRoles_RoleName: new FormControl(''),
          frmRd_UserRoles_RoleName: [{ value: '', disabled: false }],
          frmlbl_DisplayName: ''

        });
        (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).clear();
        (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).push(frm);
      }
      // this.showAccounts = false;
      // this.showRoles = false;
    }
    this.enabledRoles = this.frm_AddUsergroup.controls.frmArruserRoles["controls"].filter(va=> va["controls"].frmChk_UserRoles_RoleName.disabled != true ) 
    await this.editLicensureDetails();
    await this.editGetOutofOffice()
    await this.editGetLiscensureInfo();
    await this.editSurgiCenterDetails();
  }


  async setMedicalDirectorRole(formGrp) {
    let value = await this.getIsMedicalRolePresent(this.organizationID, this.EditCommonKey || null, formGrp);
    if (value)
      formGrp.controls.frmChk_IsMedicalDirector.value = true;

  }

  async getRolesConfiguredForEdit() {
    let obj = {
      entityid: sessionStorage.getItem('org_id'),
      entytitype: 2,
      ConfigName: "AdminApp.LiveCustomerRoles",
      tableName: "VaEntityExtAttributes",
      scope: 3
    }
    this.ngxService.start();
    await this.vaservices.getVAEntityAttribute(obj, sessionStorage.getItem('deploymentKey')).toPromise().then(async (res) => {
      this.ngxService.stop();
      if (res && res.Success) {
        let data = JSON.parse(res.Message);
        this.editConfigRoles = data[0].All_Locations_Value_1 != 'N/A' ? data[0].All_Locations_Value_1.toLowerCase().split(',') : null;
        if (!this.editConfigRoles) {
          this.editConfigRoles = data[0].Deployment_Level__Value_1 != 'N/A' ? data[0].Deployment_Level__Value_1.toLowerCase().split(',') : null;
        }
      }
      else {
        this.editConfigRoles = [];
      }
    }), error => {
      console.error(error)
    }
  }
  async editSurgiCenterDetails() {
    await this.fetchSurgicenters();
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "getsurgicenterdata");
    // this.addloginname = form.frmInp_LoginName;
    let drpqueryRequest = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "commonkey": this.CommonKey.toString() }
    };
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    let result: any
    try {
      result = await this.vaservices.GetData(drpqueryRequest, depKey).toPromise();
    }
    catch {
      console.log('Error fetching surgicenters', 'Close');
    }
    this.selectedSurgiCenters = [];
    if (result && result.data.submenuDataAccount && result.data.submenuDataAccount.length > 0) {
      this.selectedSurgiCenters = result.data.submenuDataAccount;
      let primaryLocation: any = this.selectedSurgiCenters.filter((va: any) => va.isprimarylocation)
      this.primaryLocation = primaryLocation && primaryLocation.length > 0 ? String(primaryLocation[0].isprimarylocation) : ''
      this.clonedPrimaryLocation = lodash.cloneDeep(this.primaryLocation)
      this.backupPrimaryLocation = lodash.cloneDeep(this.primaryLocation)
      this.surgicenterList = this.surgicenterList.filter((va: any) => this.locationsList.length > 0 && this.locationsList.some(val => val.accountid != va.accountid));
      this.selectedSurgiCenters = this.selectedSurgiCenters.filter((va: any) => this.locationsList.length > 0 && this.locationsList.some(val => val.accountid != va.accountid));
      this.surgicenterList.forEach((va: any) => {
        if (this.selectedSurgiCenters.some((val: any) => va.accountid == val.accountid)) {
          va.selected = true;
          va.ishidden = true;
        }
      })
      this.associateSurgiCenter = false;
      this.assocChecked = false;
      if (this.selectedSurgiCenters && this.selectedSurgiCenters.length > 0) {
        this.associateSurgiCenter = true;
        this.assocChecked = true;
        this.clonedAssocChecked = lodash.cloneDeep(this.assocChecked)
      }
      this.clonedselectedSurgiCenters = lodash.cloneDeep(this.selectedSurgiCenters);
      this.backupselectedSurgiCenters = lodash.cloneDeep(this.selectedSurgiCenters);
    }
  }

  async editGetOutofOffice() {
    try {
      var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "getexistingoutofofficeinfo");
      let drpqueryRequest: any = {
        "OperationName": null,
        "Query": drpquery[0].Query,
        "Variables": { "commonkey": this.CommonKey.toString() }
      };
      this.ngxService.start('getexistingoutofofficeinfo');
      let result = await this.vaservices.GetData(drpqueryRequest).toPromise();

      if (!result.errors) {
        this.existingOutOfOfficeDetails = result.data.submenuData;
        if (this.existingOutOfOfficeDetails.length > 0) {
          this.mapOutOfOfficeDetails(this.existingOutOfOfficeDetails)
        }
        else {
          this.setInitialOutofOfficeDetails();
        }
      }
      this.ngxService.stop('getexistingoutofofficeinfo');
    }
    catch {

      this.ngxService.stop('getexistingoutofofficeinfo');
    }
  }

  setInitialOutofOfficeDetails() {
    (<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).clear();
    let frm;
    frm as FormGroup;
    frm = this._fb.group({
      frm_startdate: '',
      frm_enddate: '',
      frm_isdeleted: 0
    });

    if (!(this.frm_AddUsergroup.get('frmOutOfOffice').value.some((va: any) => va.frm_startdate == '' && va.frm_enddate == ''))) {
      (<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).push(frm);
    }
  }



  mapOutOfOfficeDetails(existingData) {
    (<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).clear();
    for (let i = 0; i < existingData.length; i++) {
      let frm;
      frm as FormGroup;
      frm = this._fb.group({
        frm_id: existingData[i].id,
        frm_userid: existingData[i].userid,
        frm_startdate: parse(this.datepipe.transform(existingData[i].startdate, 'MM/dd/yyyy'), 'MM/dd/yyyy', new Date()),
        frm_enddate: parse(this.datepipe.transform(existingData[i].enddate, 'MM/dd/yyyy'), 'MM/dd/yyyy', new Date()),
        frm_isdeleted: existingData[i].isdeleted
      });


      if (!existingData[i].isdeleted) {
        (<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).push(frm);
      }
    }
  }

  async editGetLiscensureInfo() {
    if (this.pathuserid != undefined) {
      var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "vwGetLicensureInfo");
      let drpqueryRequest: any = {
        "OperationName": null,
        "Query": drpquery[0].Query,
        "Variables": { "userid": this.pathuserid.toString() }
      };
      this.ngxService.start('vwGetLicensureInfo');
      let result: any = await this.vaservices.GetData(drpqueryRequest).toPromise();
      this.ngxService.stop('vwGetLicensureInfo');
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          this.ShowLicensureDetails = true;
          this.StateDiscription = this.StateList[0].LookUpValue + " (" + this.StateList[0].Description + ")";
          (<FormArray>this.frm_AddUsergroup.get('formLicensure')).clear();
          for (let item of result.data.submenudata) {
            if (item.Expirydate && item.Expirydate.toString().toLowerCase() != 'null') {
              let frm;
              frm as FormGroup;
              frm = this._fb.group({
                frmDrp_LicsenceStates: item.Statefullname,
                frmDrp_LicsenceDate: parse(this.datepipe.transform(item.Expirydate, 'MM/dd/yyyy'), 'MM/dd/yyyy', new Date())
              });
              (<FormArray>this.frm_AddUsergroup.get('formLicensure')).push(frm);
            }
          }
          //this.ShowLicensureDetails = false;
        }
      }
    }
    else {
      this.ShowLicensureDetails = false
    }
    if ((<FormArray>this.frm_AddUsergroup.get('formLicensure')).length == 0) {
      let frm;
      frm as FormGroup;
      frm = this._fb.group({
        frmDrp_LicsenceStates: new FormControl("Select"),
        frmDrp_LicsenceDate: ""
      });

      (<FormArray>this.frm_AddUsergroup.get('formLicensure')).push(frm);
    }

    Object.keys(this.usersdeatils).forEach(key => {
      if (key != 'AllowLogin') {
        if (!this.usersdeatils[key] || this.usersdeatils[key] == 'Not Specified'
          || this.usersdeatils[key] == '') {
          this.usersdeatils[key] = '';
        }
      }
    });
    var chkUS = false;
    var chkST = false;
    if (this.usersdeatils.Region != null) {
      if (this.usersdeatils.Region.length > 0) {
        if (this.usersdeatils.Region.toLowerCase().includes('us')) {
          chkUS = true;
        } else {
          chkUS = false;
        }
        if (this.usersdeatils.Region.toLowerCase().includes('st')) {
          chkST = true;
        } else {
          chkST = false;
        }
      }
    }
    this.EditholdReleaseDuration = this.userData.HoldReleaseDuration == "" ? 5 : this.userData.HoldReleaseDuration;
    this.Editpassword = this.usersdeatils.password;


    await this.getExistingThresholdDetails(this.usersdeatils.CommonKey);

    this.frm_AddUsergroup.patchValue({
      frmInp_title: this.usersdeatils.title,
      frmInp_FirstName: this.usersdeatils.firstname,
      frmInp_LastName: this.usersdeatils.lastname,
      frmInp_MiddleInitial: this.usersdeatils.middleinitials,
      frmInp_LoginName: this.usersdeatils.loginname,
      frmInp_Qualification: this.usersdeatils.Qualification,
      frmInp_NPI: this.usersdeatils.npi,
      frmInp_Gender: this.usersdeatils.gender != "" ? (this.usersdeatils.gender === "Male" ? "1" : "2") : this.usersdeatils.gender,
      frmInp_Status: this.usersdeatils.Status.toString().toLowerCase().trim() === "active" ? true : false,
      frmInp_Address1: this.usersdeatils.address1,
      frmInp_Address2: this.usersdeatils.address2,
      frmInp_Street: this.usersdeatils.street,
      frmInp_City: this.usersdeatils.city,
      frmEditInp_State: this.usersdeatils.state,
      frmInp_State: this.usersdeatils.state,
      frmInp_Country: this.usersdeatils.country,
      frmInp_Zip: this.usersdeatils.zip,
      frmInp_Phone: this.usersdeatils.phone,
      frmInp_Fax: this.usersdeatils.fax,
      frmInp_Email: this.usersdeatils.email,
      frmInp_SSN: this.usersdeatils.ssn,
      frmInp_UserCode: this.usersdeatils.usercode,
      frmInp_UPIN: this.usersdeatils.upin,
      frmInp_ExternalUserGUID: this.usersdeatils.ExternalUserGUID,
      frmInp_US: chkUS,
      frmInp_ST: chkST,
      frmInp_ShowMyCaseOnly: !this.usersdeatils.ShowMyCaseOnly || this.usersdeatils.ShowMyCaseOnly == 'false' ? false : this.usersdeatils.ShowMyCaseOnly,
      frmInp_DisplayFormatWebpage: this.usersdeatils.usernamedisplayformat,
      frmInp_DisplayFormatDescription: this.usersdeatils.Usernamegrossformat,
      frmInp_DisplayFormatReports: this.usersdeatils.usernamereportformat,
      frmInp_Threshold: this.userThreshold && this.userThreshold.Threshold ? String(this.userThreshold.Threshold) : '',
      frmDrp_ThresholdDuration: this.userThreshold && this.userThreshold.ThresholdDuration ? this.userThreshold.ThresholdDuration : "Select",
      frmDrp_ReadComputeOn: this.userThreshold && this.userThreshold.ReadComputeOn ? this.userThreshold.ReadComputeOn : "Sign-out",
      frmInp_ReviewThreshold: this.userThreshold && this.userThreshold.ReviewThreshold ? String(this.userThreshold.ReviewThreshold) : '',
      frmDrp_ReviewDuration: this.userThreshold && this.userThreshold.ReviewDuration ? this.userThreshold.ReviewDuration : 'Select',
      frmDrp_ReviewComputeOn: this.userThreshold && this.userThreshold.ReviewComputeOn ? this.userThreshold.ReviewComputeOn : 'Sign-out',
      frmInp_ScreeningThreshold: this.userThreshold && this.userThreshold.ScreeningThreshold ? String(this.userThreshold.ScreeningThreshold) : '',
      frmDrp_ScreeningDuration: this.userThreshold && this.userThreshold.ScreeningDuration ? this.userThreshold.ScreeningDuration : 'Select',
      frmDrp_ScreeningComputeOn: this.userThreshold && this.userThreshold.ScreeningComputeOn ? this.userThreshold.ScreeningComputeOn : 'Sign-out',
      frmInp_AllowPathologistDiagnosisTemplate: !this.usersdeatils.IsUserLevelDiagnosisTemplateEnabled ? false : this.usersdeatils.IsUserLevelDiagnosisTemplateEnabled == 'true' ? true : false,
      frmInp_PopulateDiag: !this.usersdeatils.autoPopulateDiagICD9Codes ? false : this.usersdeatils.autoPopulateDiagICD9Codes == 'true' ? true : false,
      frmInp_Notifyusers: !this.usersdeatils.diagICDcodeReminder ? false : this.usersdeatils.diagICDcodeReminder == 'true' ? true : false,
      frmInp_AllowLogin: this.usersdeatils.AllowLogin == 0 ? false : true,
      frmChk_EnableSignOutCorrection: !this.usersdeatils.EnableSignOutCorrection ? false : this.usersdeatils.EnableSignOutCorrection == "1" ? true : false,
      frmChk_IsPathologistPrivileges: !this.usersdeatils.IsPathologistPrivileges ? false : this.usersdeatils.IsPathologistPrivileges == "1" ? true : false
    });


    await this.getExistingSign();
    this.clonedUserForm = lodash.cloneDeep(this.frm_AddUsergroup);
  }

  async fetchExistingUserAssociations() {
    var queryList = this.vaservices.mainQueryList.filter(x => x.Keyword == "getexistingassociations");
    // let queryString = ''

    // queryString = "accountid in \"" + accountSelection + "\"";
    let queryVariable = { "commonkey": this.EditCommonKey };

    let drpqueryRequest = {
      "OperationName": null,
      "Query": queryList[0].Query,
      "Variables": queryVariable
    };

    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    let result

    try {
      result = await this.vaservices.GetData(drpqueryRequest, depKey).toPromise();
      return [...new Map(result.data.submenuData.map(item => [item['associationid'], item])).values()];
    }
    catch {
      console.error('Fetching existing user association failed');
      return result;
    }
  }

  async getExistingSign() {
    let data = {
      userid: this.usersdeatils.userid.toString()
    };
    let res: any = await this.vaservices.GetSignFile(data).toPromise()
    if (res.Success) {
      if (res.Message != undefined && res.Message != "" && res.Message != null) {
        this.frm_sign = "data:image/jpg;base64," + res.Message;

      }
    }
    this.clone_sign = lodash.cloneDeep(this.frm_sign);
  }




  async getExistingThresholdDetails(commonKey) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "userthresholddeatils");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "commonkey": this.EditCommonKey }
    };
    this.ngxService.start();
    let result
    try {
      result = await this.vaservices.GetData(drpqueryRequest).toPromise();
      this.ngxService.stop();
      if (!result.errors) {
        if (result.data.submenuData) {
          let cytoData = result.data.submenuData.filter(va => va.rolename.toString().toLowerCase().trim() == 'cytotechnologist')
          let cytoSuperData = result.data.submenuData.filter(va => va.rolename.toString().match(/cytotechnologist supervisor/i))
          let pathData = result.data.submenuData.filter(va => va.rolename.toString().match(/pathologist/i))
          this.userThreshold.Threshold = pathData[0] ? pathData[0].Threshold : ''
          this.userThreshold.ThresholdDuration = (pathData && pathData[0]) ? pathData[0].ThresholdDuration : 'Select'
          this.userThreshold.ReadComputeOn = (pathData && pathData[0]) ? pathData[0].ReadComputeOn : 'Sign-out'
          this.userThreshold.ReviewThreshold = (pathData && pathData[0]) ? pathData[0].ReviewThreshold : ''
          this.userThreshold.ReviewDuration = (pathData && pathData[0]) ? pathData[0].ReviewDuration : 'Select'
          this.userThreshold.ReviewComputeOn = (pathData && pathData[0]) ? pathData[0].ReviewComputeOn : 'Sign-out'
          this.userThreshold.ScreeningThreshold = (cytoData && cytoData[0]) ? cytoData[0].Threshold : (cytoSuperData && cytoSuperData[0]) ? cytoSuperData[0].Threshold : ''
          this.userThreshold.ScreeningDuration = (cytoData && cytoData[0]) ? cytoData[0].ThresholdDuration : (cytoSuperData && cytoSuperData[0]) ? cytoSuperData[0].ThresholdDuration : 'Select'
          this.userThreshold.ScreeningComputeOn = (cytoData && cytoData[0]) ? cytoData[0].ReadComputeOn : (cytoSuperData && cytoSuperData[0]) ? cytoSuperData[0].ReadComputeOn : 'Sign-out'
        }
      }
    }
    catch {
      this.ngxService.stop();
      console.error('Fetching Existing Threshold details failed');
    }
  }
  editSendforReview(show: boolean) {
    if (show) {
      this.frm_AddUsergroup.controls['frmDrp_OrgSendforReview'].enable();
      let k;
      for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].enable();
      }
    }
    else {
      this.frm_AddUsergroup.controls['frmDrp_OrgSendforReview'].disable();
      let k;
      for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].disable();
      }
    }
  }

  async editLicensureDetails() {
    // call set change status dropdown
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "licensurestates");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": {}
    };
    this.ngxService.start();
    let result = await this.vaservices.GetData(drpqueryRequest).toPromise();
    this.ngxService.stop();
    if (!result.errors) {
      this.frm_AddUsergroup.patchValue(
        {
          frmEditInp_State: this.userData.state,
        });
      this.ShowLicensureDetails = true;
      this.StateList = [];
      this.StateList = result.data.submenudata;
      this.StateDiscription = this.StateList[0].LookUpValue + " (" + this.StateList[0].Description + ")";
      (<FormArray>this.frm_AddUsergroup.get('formLicensure')).clear();
      let frm;
      frm as FormGroup;
      frm = this._fb.group({
        frmDrp_LicsenceStates: new FormControl("Select"),
        frmDrp_LicsenceDate: ""
      });

      (<FormArray>this.frm_AddUsergroup.get('formLicensure')).push(frm);
      this.ShowLicensureDetails = false;
    }
    this.editThresholdDuration();

  }

  async editThresholdDuration() {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "adduserthresholdduration");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": {}
    };
    this.ngxService.start();
    let result = await this.vaservices.GetData(drpqueryRequest).toPromise()
    this.ngxService.stop();
    if (!result.errors) {
      if (result.data.submenudata != null && result.data.submenudata != undefined) {
        if (result.data.submenudata.length > 0) {
          this.ArrThresholdDuration = result.data.submenudata;
        }
        else {
          this.frm_AddUsergroup.patchValue({
            frmDrp_ThresholdDuration: this.usersdeatils.ThresholdDuration
          });
          this.ArrThresholdDuration = [];
        }
      }
      else {
        this.frm_AddUsergroup.patchValue({
          frmDrp_ThresholdDuration: this.usersdeatils.ThresholdDuration
        });
        this.ArrThresholdDuration = [];
      }
    }
  }
  roleCheckUncheckAll(event) {
    // this.rolechecklist.forEach((c) => c.isSelected = evt.target.checked)
    for (let i = 0; i < this.frm_AddUsergroup.controls.frmArruserRoles['controls'].length; i++) {
      if (!this.frm_AddUsergroup.controls.frmArruserRoles.value[i]['frmChk_UserRoles_RoleName'].disabled) {
        this.enableRadioBtnForRoleNameChk(this.frm_AddUsergroup.controls.frmArruserRoles['controls'][i], i, event)
      }
    }
  }

  isAllSelectedRole(evt, index) {
    this.rolechecklist[index].isSelected = evt.target.checked
    this.masterSelected = this.rolechecklist.every((item) => item.isSelected == true);
  }

  LocationCheckUncheckAll(evt) {
    this.locationchecklist.forEach((c) => c.isSelected = evt.target.checked)
  }

  isAllSelectedLocation(evt, index) {
    this.locationchecklist[index].isSelected = evt.target.checked
    this.masterSelected = this.locationchecklist.every((item) => item.isSelected == true);
  }

  initRoleGrid(grid) {
    this.selector = new Selector(grid, {
      itemChecked: () => {
        this.selectedItems = grid.rows.filter(r => r.isSelected);
      }
    });
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  initLocaGrid(grid) {
    this.selector = new Selector(grid, {
      itemChecked: () => {
        this.selectedItems = grid.rows.filter(r => r.isSelected);
      }
    });
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }


  onChangefrmchk_OrgLocation(eventValue) {
    if (eventValue == null && eventValue == undefined) {
      eventValue = true;
    }
    let m;
    for (m = 0; m < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; m++) {
      if (this.POLOrg && this.searchContext) {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).patchValue(
          {
            frmchk_Location: (this.searchContext.toString().toLowerCase().trim() == this.frm_AddUsergroup.controls.frmArruserAccounts[m].controls.frmLbl_LocationContext.value.toString().toLowerCase().trim()) ? eventValue : false,
            frmDrp_Works: !eventValue ? false : this.checkIfWorksSelected(m)
          });
      }
      else {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).patchValue(
          {
            frmchk_Location: eventValue,
            frmDrp_Works: !eventValue ? false : this.checkIfWorksSelected(m)
          });
      }
    }
  }

  checkIfWorksSelected(index) {
    return this.frm_AddUsergroup.controls.frmArruserAccounts["controls"][index]["controls"].frmDrp_Works.value;
  }


  get formcontrol() {
    return this.frm_AddUsergroup.controls;
  }

  populateNPIDetails(Info) {
    let state
    if (Info.addresses && Info.addresses.length > 0) {
      if (this.StateList && Info.addresses[0].state) {
        let stateData = this.StateList.filter(va => va.LookUpValue.toString().toLowerCase().trim() == Info.addresses[0].state.toString().toLowerCase().trim())
        state = stateData && stateData.length > 0 ? stateData[0].LookUpValue + ' (' + stateData[0].Description + ')' : '';
      }
      else {
        state = '';
      }
    }
    else {
      state = '';
    }
    this.frm_AddUsergroup.patchValue({
      frmInp_title: !Info.basic.name_prefix ? '' : Info.basic.name_prefix,
      frmInp_Address1: Info.addresses && Info.addresses.length > 0 ? Info.addresses[0].address_1 : '',
      frmInp_FirstName: !Info.basic.first_name ? '' : Info.basic.first_name,
      frmInp_Address2: '',
      frmInp_LastName: !Info.basic.last_name ? '' : Info.basic.last_name,
      frmInp_Street: '',
      frmInp_MiddleInitial: !Info.basic.middle_name ? '' : Info.basic.middle_name,
      frmInp_City: Info.addresses && Info.addresses.length > 0 ? Info.addresses[0].city : '',
      frmInp_Qualification: !Info.basic.credential ? '' : Info.basic.credential,
      frmInp_State: state,
      frmInp_Zip: '',
      frmInp_Gender: Info.basic.gender ? Info.basic.gender == "M" ? '1' : '2' : null,
      frmInp_Country: Info.addresses && Info.addresses.length > 0 ? Info.addresses[0].country_name : '',
      frmInp_Phone: Info.addresses && Info.addresses.length > 0 ? Info.addresses[0].telephone_number : '',
      frmInp_DisplayFormatWebpage: "F L D",
      frmInp_DisplayFormatDescription: "F L D",
      frmInp_Fax: Info.addresses && Info.addresses.length > 0 ? Info.addresses[0].fax_number : '',
      frmInp_DisplayFormatReports: "S. F L D",
      frmInp_Email: '',
      frmInp_SSN: '',
      frmInp_Status: true,
      frmInp_UserCode: '',
      frmInp_UPIN: '',
      frmInp_ShowMyCaseOnly: false,
      frmInp_ExternalUserGUID: '',
      //frmInp_Region: ['', Validators.required],
      frmInp_US: false,
      frmInp_ST: false,
      frmInp_AllowReviewPathologist: false,
      frmInp_AllowtoeditAccession: false,
      frmInp_Threshold: '',
      frmInp_EditSite: false,
      frmDrp_ReadComputeOn: "Sign-out",
      frmInp_ReviewThreshold: '',
      frmDrp_ReviewDuration: 'Select',
      frmDrp_ReviewComputeOn: 'Sign-out',
      frmInp_ScreeningThreshold: '',
      frmDrp_ScreeningDuration: 'Select',
      frmDrp_ScreeningComputeOn: 'Sign-out',
      frmDrp_ThresholdDuration: 'Select',
      frmInp_AllowPathologistDiagnosisTemplate: false,
      frmChk_EnableSignOutCorrection: false,
      frmChk_IsPathologistPrivileges: false,
      frmInp_PopulateDiag: false,
      frmInp_Notifyusers: true,
      //  frmArruserRoles: this._fb.array([]),
      // frmArruserAccounts: this._fb.array([]),
      frmDrp_OrgSendforReview: 'true',
      frmDrp_OrgManages: 'true',
      frmDrp_OrgWorks: 'true',
      frmchk_OrgLocation: false,
      // formLicensure: this._fb.array([]),
      frmLblchk_OrgLocation: '',
      frmChk_RoleCheckboxValidator: false,
      frmInp_AllowLogin: true
    })
  }


  disableNPI() {
    return !this.frm_AddUsergroup.value.frmInp_NPI.toString().trim() || this.frm_AddUsergroup.value.frmInp_NPI.length != 10 || this.frm_AddUsergroup.value.frmInp_NPI.toString().toLowerCase().trim() == 'unknown' || !/[0-9]/.test(this.frm_AddUsergroup.value.frmInp_NPI)
  }

  async getLicensureDetails() {
    // if (this.templateData.data.submenuData.length > 0) {
    // call set change status dropdown
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "licensurestates");
    // sessionStorage.setItem( 'deploymentKey', this.vitalHttpServices.deploymentKey);
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": {}
    };
    this.ngxService.start();
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    try {
      let result = await this.vaservices.GetData(drpqueryRequest, depKey).toPromise();
      if (!result.errors) {
        // this.frm_AddUsergroup.value.formLicensure.value.frmDrp_LicsenceStates = "";
        this.frm_AddUsergroup.patchValue(
          {
            frmInp_State: "Select"
          });

        this.ShowLicensureDetails = true;

        this.StateList = [];
        this.StateList = result.data.submenudata;
        this.StateDiscription = this.StateList[0].LookUpValue + " (" + this.StateList[0].Description + ")";

        (<FormArray>this.frm_AddUsergroup.get('formLicensure')).clear();
        let frm;
        frm as FormGroup;
        frm = this._fb.group({
          frmDrp_LicsenceStates: new FormControl("Select"),
          frmDrp_LicsenceDate: ""
        });

        (<FormArray>this.frm_AddUsergroup.get('formLicensure')).push(frm);
        this.ShowLicensureDetails = false;
        this.frm_AddUsergroup.patchValue({
          frmInp_State: "Select"
        });
        this.setInitialOutofOfficeDetails();
        await this.fetchSurgicenters();
        this.clonedUserForm = lodash.cloneDeep(this.frm_AddUsergroup);
        this.clone_sign = lodash.cloneDeep(this.frm_sign);
      }
      else {
        console.error('Something went wrong!');
      }
    }
    catch {
      console.error('Something went wrong!');
    }
    this.ngxService.stop();
  }


  async fetchSurgicenters(checked?) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "getsurgicenters");
    // this.addloginname = form.frmInp_LoginName;
    this.organizationID = this.organizationID ? this.organizationID : sessionStorage.getItem('org_id');
    let drpqueryRequest = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "orgid": this.organizationID.toString() }
    };
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    let result: any
    try {
      result = await this.vaservices.GetData(drpqueryRequest, depKey).toPromise();
    }
    catch {
      console.log('Error fetching surgicenters', 'Close');
    }
    this.surgicenterList = [];
    if (result && result.data.submenuDataAccount && result.data.submenuDataAccount.length > 0) {
      this.surgicenterList = result.data.submenuDataAccount;
      if (checked && this.locationsList.length > 0) {
        this.surgicenterList = this.surgicenterList.filter((va: any) => this.locationsList.some(val => val.accountid != va.accountid));
      }
      this.clonedsurgicenterlist = lodash.cloneDeep(this.surgicenterList)
      this.backupsurgicenterList = lodash.cloneDeep(this.surgicenterList)
      if (this.surgicenterList.length == 0) {
        if (checked == true) {
          this._snackBar.open('No surgicenters found!', 'Close');
          checked = false;
        }
        this.associateSurgiCenter = false;
      }
    }
    else {
      if (checked == true) {
        this._snackBar.open('No surgicenters found!', 'Close');
        checked = false;
        this.associateSurgiCenter = false;
      }
    }
  }

  GenerateLoginID() {
    var loginID = "";
    var firstname: string = this.frm_AddUsergroup.value.frmInp_FirstName;
    var lastname: string = this.frm_AddUsergroup.value.frmInp_LastName;
    let re = /\@/gi;
    firstname = firstname.replace(re, '')
    lastname = lastname.replace(re, '')
    if (firstname.trim() != "")
      loginID = firstname.substring(0, 1);

    if (lastname.trim() != "")
      loginID += lastname;

    //to make sure loginID length should not exceed 25
    if (loginID.length > 20)
      loginID = loginID.substring(0, 20);


    if (loginID != '')
      loginID += Math.floor((Math.random() * 1000) + 1)
    // if (this.frm_AddUsergroup.value.frmInp_LoginName == '') {
    this.frm_AddUsergroup.patchValue({
      frmInp_LoginName: loginID
    });
    // }
  }


  onUserImageUpload(event) {
    // this.onRemove();
    let tempArrData: any
    if (event.target) {
      tempArrData = event.target.files[0];
    } else {
      tempArrData = event[0];
    }
    if (tempArrData.type.toString().toLowerCase() == "image/png" || tempArrData.type == "image/jpeg" || tempArrData.type == "image/jpg") {
      event.addedFiles = [];
      event.addedFiles.push(tempArrData);
      this.files.push(...event.addedFiles);
      const reader = new FileReader();
      if (this.files && this.files.length) {
        const [logoUpload] = this.files;
        reader.readAsDataURL(logoUpload);
        reader.onload = () => {
          this.frm_sign = reader.result as string;
          this.imagePath = true;
        };
      } else {
        this._snackBar.open("An error occurred while processing your request", "Failed");
      }
    } else {
      this._snackBar.open("Please upload image file only", "Failed");
    }
  }

  onRemove() {
    this.files = []
    this.frm_sign = null;
    this.imagePath = false;
  }

  async SearchOrg() {
    this.ngxService.start();
    this.context = sessionStorage.getItem('search_context');
    let searchDrpValue;
    let query;
    // let depKey: string = this.DeploymentKeys;
    let queryResult: any;
    let queryVariable;
    let searchInpValue;
    let queryString: string = "";
    searchDrpValue = 'Organization ID';
    searchInpValue = this.organizationID;
    query = this.vaservices.GetQuery('adduserfilterorg');
    this.card = this.context;
    // this.vaservices.deploymentKey = depKey.toLowerCase();
    // sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    switch (searchDrpValue) {
      case 'Organization ID':
        queryString = "organizationid >= \"1\":decimal and organizationid in \"" + searchInpValue + "\"";
        queryVariable = { "filterParam": queryString };
        break;
      case 'Account ID':
        queryString = "accountid >= \"1\":decimal and accountid in \"" + searchInpValue + "\"";
        queryVariable = { "filterParam": queryString };
        break;
      default:
        queryVariable = '';
        break;
    }
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vaservices.GetData(queryResult, depKey).toPromise().then(async res => {
      if (!res.errors) {
        this.organizationList = res.data.Organizations_list;
        if (this.organizationList[0].LoginType != null) {
          this.LoginTypesflag = this.organizationList[0].LoginType.toLowerCase() != 'email' ? true : false;
        }
        else {
          this.LoginTypesflag = true;
        }
        if (this.organizationList == undefined) {
          this.organizationList = res.data.Accounts_list;
        }
        if (this.organizationList.length > 0) {

          this.noOrgData = false;
        }
        else {
          this.organizationList = []
          this.noOrgData = true;
        }
      }
      else {
        this.organizationList = [];
        this.noOrgData = false;
        this.ngxService.stop();
      }
      await this.setOrganization(searchInpValue)
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this.organizationList = [];
      this.noOrgData = false;
      this.ngxService.stop();
    });
  }

  async searchOrgType() {
    let searchDrpValue = "Organization ID"
    let searchInpValue = this.userData.Organizationid;
    let templateData
    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.mainQueryList.filter(x => x.Keyword == "adduserfilterorg");
    let queryString: string = "";
    switch (searchDrpValue) {
      case 'Organization ID':
        queryString = "organizationid >= \"1\":decimal and organizationid in \"" + searchInpValue + "\"";
        queryVariable = { "filterParam": queryString };
        break;
      case 'Organization Name':
        queryString = "organizationid >= \"1\":decimal and organizationname like \"%" + searchInpValue + "%\"";
        queryVariable = { "filterParam": queryString }
        break;
      default:
        queryVariable = '';
        break;
    }
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    queryResult = this.commonService.GetCardRequest(queryVariable, query[0].Query);

    await this.vaservices.GetData(queryResult, depKey).toPromise().then(res => {

      if (!res.errors) {
        templateData = res.data.Organizations_list;
        if (templateData[0].LoginType != null) {
          this.LoginTypesflag = templateData[0].LoginType.toLowerCase() != 'email' ? true : false;
        }
        else {
          this.LoginTypesflag = true;
        }
      }
      // this.setOrganization(searchInpValue)
    }, error => {
      console.error(error);
      templateData = [];

    });
  }

  async setOrganization(OrgValue) {
    this.frm_AddUsergroup.patchValue({
      frmOrganization: OrgValue,
    });
    this.SelectedOrgID = OrgValue;
    await this.showAddUserDetailsFunc(OrgValue);
  }

  async showAddUserDetailsFunc(OrgId) {
    OrgId = this.organizationID;
    // this.ShowFrmAddUserDetails = true;
    await this.getHoldReleaseDuration(OrgId);
  }

  async getHoldReleaseDuration(OrgID) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "adduserholdreleaseduration");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "filterparameter": "organizationid > \"1\" and organizationid = \"" + OrgID + "\"" }
    };
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    await this.vaservices.GetData(drpqueryRequest, depKey).toPromise().then(async result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.holdReleaseDuration = result.data.submenudata[0].HoldReleaseDuration;
            this.EditholdReleaseDuration = this.holdReleaseDuration;
            this.DiagICDcodeReminder = result.data.submenudata[0].DiagICDcodeReminder;
            this.EditDiagICDcodeReminder = this.DiagICDcodeReminder;
            if (this.holdReleaseDuration == null) {
              this.holdReleaseDuration = "5";
            }
            if (this.DiagICDcodeReminder == null) {
              this.DiagICDcodeReminder = false;
            }
            this.isICDdisplay = this.DiagICDcodeReminder;
          }
          else {
            this.holdReleaseDuration = "5";
            this.DiagICDcodeReminder = false;
          }
        }
        else {
          this.holdReleaseDuration = "5";
          this.DiagICDcodeReminder = false;
        }
      }
      await this.getGetOrganizationType(OrgID);
    }, error => {
      console.info(error);
    });
  }


  async getGetOrganizationType(OrgId) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetorgtype");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "orgid": OrgId }
    };
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    await this.vaservices.GetData(drpqueryRequest, depKey).toPromise().then(async result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.ArrOrgType = result.data.submenudata;
            this.UserType = this.ArrOrgType[0].associationtype;
            if (this.ArrOrgType[0].IsListedAsOF.toLowerCase == "true") {
              this.IsOrderingFacility = true;
            }
            else {
              this.IsOrderingFacility = false;
            }
          }
          else {
            this.UserType = "";
            this.IsOrderingFacility = false;
          }
        }
        else {
          this.UserType = "";
          this.IsOrderingFacility = false;
        }
      }
      if (this.UserType == "Laboratories" && this.IsOrderingFacility != true) {
        this.frm_AddUsergroup.patchValue({
          frmInp_AllowtoeditAccession: true,
          frmInp_EditSite: true,
          frmInp_Notifyusers: this.isICDdisplay,
          frmInp_ExternalUserGUID: this.generateNewGuid()
        });
      }
      else {
        this.frm_AddUsergroup.patchValue({
          frmInp_AllowtoeditAccession: false,
          frmInp_EditSite: false,
          frmInp_Notifyusers: this.isICDdisplay,
          frmInp_ExternalUserGUID: this.generateNewGuid()
        });
      }
      await this.getGetEPGOrganizationType(OrgId);

    }, error => {
      console.info(error);
    });
  }
  generateNewGuid() {
    var d = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  async getGetEPGOrganizationType(OrgId) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetepgorgtype");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "orgid": OrgId }
    };
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    await this.vaservices.GetData(drpqueryRequest, depKey).toPromise().then(async result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.ArrEPGOrgType = result.data.submenudata[0].associationtype;
            if (this.ArrEPGOrgType != "") {
              this.EPGorgType = this.ArrEPGOrgType;
            }
            else {
              this.EPGorgType = "";
            }
          } else {
            this.EPGorgType = "";
          }
        } else {
          this.EPGorgType = "";
        }
      }
      await this.getThresholdDuration(OrgId);
    }, error => {
      console.info(error);
    });
  }


  async getThresholdDuration(OrgId) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "adduserthresholdduration");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": {}
    };
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    await this.vaservices.GetData(drpqueryRequest, depKey).toPromise().then(async result => {
      if (!result.errors) {
        if (result.data.submenudata != null && result.data.submenudata != undefined) {
          if (result.data.submenudata.length > 0) {
            this.ArrThresholdDuration = result.data.submenudata;
            this.frm_AddUsergroup.patchValue({
              frmDrp_ThresholdDuration: "Select",
              frmDrp_ReviewDuration: "Select",
              frmDrp_ReviewComputeOn: "Sign-out",
              frmDrp_ReadComputeOn: "Sign-out",
              frmDrp_ScreeningDuration: 'Select',
              frmDrp_ScreeningComputeOn: 'Sign-out'
            });
          }
          else {
            this.ArrThresholdDuration = [];
          }
        }
        else {
          this.ArrThresholdDuration = [];
        }
      }
      // if(this.editMode == true ){
      await this.getSavedRoleList(OrgId); // userid for add user is 0 by default
      // }
    }, error => {
      console.info(error);
    });
  }

  async getSavedRoleList(OrgId) {

    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    //get getSavedRoleList
    let drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetsavedrole");
    let drpqueryRequest = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "Commonkey": "" }
    };
    await this.vaservices.GetData(drpqueryRequest, depKey).toPromise().then(async result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.ArrSavedRoles = result.data.submenudata
            this.ArrSavedRoles.forEach(element => {
              element.IsPrimary = element.IsPrimary == null ? false : element.IsPrimary;
              element.status = element.status == 1 ? true : false;
              element.ModifyTechnicalData = element.ModifyTechnicalData == null ? false : element.ModifyTechnicalData;
              element.IsModifyTechnicalDataAccession = element.IsModifyTechnicalDataAccession == null ? false : element.IsModifyTechnicalDataAccession;
              element.ReviewPathCanSignout = element.ReviewPathCanSignout == null ? false : element.ReviewPathCanSignout;
              element.isMedicalDirector = element.isMedicalDirector == null ? false : element.isMedicalDirector;
              element.IsUserLevelDiagnosisTemplateEnabled = element.IsUserLevelDiagnosisTemplateEnabled == null ? false : element.IsUserLevelDiagnosisTemplateEnabled;
              element.ShowMyCaseOnly = element.ShowMyCaseOnly == null ? false : element.ShowMyCaseOnly;
              element.DiagICDcodeReminder = element.DiagICDcodeReminder == null ? false : element.DiagICDcodeReminder;
            });
            let i;
            for (i = 0; i < this.ArrSavedRoles.length; i++) {
              if (this.ArrSavedRoles[i].rolename === 'Pathologist') {
                this.frm_AddUsergroup.patchValue(
                  {
                    frmInp_AllowPathologistDiagnosisTemplate: this.ArrSavedRoles[i].IsUserLevelDiagnosisTemplateEnabled,
                    frmInp_ShowMyCaseOnly: this.ArrSavedRoles[i].ShowMyCaseOnly
                  });
                break;
              }
            }
          }
        }
      }
      await this.getLocationDetails(OrgId);
    }, error => {
      console.info(error);
    });
  }

  async getLocationDetails(OrgIDValue: string) {
    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.GetQuery('adduserlocations');
    queryVariable = { "orgid": OrgIDValue };
    let accIds: any[];
    //let labname = sessionStorage.getItem('LabName');
    // let flabname = sessionStorage.getItem('FlabName');
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    await this.vaservices.GetData(queryResult, depKey).toPromise().then(async res => {
      if (!res.errors) {
        // let loctaiondta=res.data.vwGetAccountAssociationForUser_list
        // this.frm_AddUserSelectOrgGroup.reset();
        //to get one location
        // if (this.context.toLowerCase() == 'lab') {
        //   if (labname === 'undefined') {
        //     labname = sessionStorage.getItem('Org_Name');
        //     this.templateData = res.data.vwGetAccountAssociationForUser_list.filter(
        //       data => data.Display_Name === labname
        //     );
        //   }
        //   else {
        //     this.templateData = res.data.vwGetAccountAssociationForUser_list.filter(
        //       data => data.Display_Name === labname
        //     );
        //     if (this.templateData.length === 0) {
        //       let labname = sessionStorage.getItem('locationName');
        //       this.templateData = res.data.vwGetAccountAssociationForUser_list.filter(
        //         data => data.Display_Name === labname
        //       );
        //     }
        //   }
        // }

        // else if (this.context.toLowerCase() == 'facility' || this.context!) {
        //   if (flabname === 'undefined') {
        //     let fname = sessionStorage.getItem('Org_Name');
        //     this.templateData = res.data.vwGetAccountAssociationForUser_list.filter(
        //       data => data.Display_Name === fname
        //     );
        //   }
        //   else {
        //     this.templateData = res.data.vwGetAccountAssociationForUser_list.filter(
        //       data => data.Display_Name == flabname
        //     );
        //     if (this.templateData.length === 0) {
        //       let fname = sessionStorage.getItem('locationName');
        //       this.templateData = res.data.vwGetAccountAssociationForUser_list.filter(
        //         data => data.Display_Name === fname
        //       );
        //     }
        //   }
        // }
        //multiple location

        this.organizationList = res.data.vwGetAccountAssociationForUser_list;

        if (this.organizationList.length > 0) {
          let frmAccounts;
          frmAccounts as FormGroup;
          // frmAccounts = "userAccounts_" + m;
          (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).clear();


          if (this.organizationList.some(va => va.Account_ID == sessionStorage.getItem('AccountID')) && this.fromComponent != 'homepage') {
            let account = this.organizationList.filter(va => va.Account_ID == sessionStorage.getItem('AccountID'))
            frmAccounts = this._fb.group({
              frmchk_Location: new FormControl(true),
              frmLblchk_Location: new FormControl(account[0].Account_ID),
              frmDrp_Works: new FormControl(true),
              frmDrp_Manages: new FormControl('true'),
              frmDrp_SendforReview: new FormControl('true'),
              frmLbl_Location: new FormControl(account[0].Display_Name),
              frmLbl_LocationContext: new FormControl(account[0].context)
            });
            (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).push(frmAccounts);


          }
          // this.organizationList = this.organizationList;
          // this.showAccounts = true;
          let m;
          for (m = 0; m < this.organizationList.length; m++) {
            let frmAccounts;
            frmAccounts as FormGroup;
            frmAccounts = "userAccounts_" + m;
            if (this.fromComponent != 'homepage') {
              if (this.organizationList[m].Account_ID != sessionStorage.getItem('AccountID')) {
                frmAccounts = this._fb.group({
                  frmchk_Location: new FormControl(false),
                  frmLblchk_Location: new FormControl(this.organizationList[m].Account_ID),
                  frmDrp_Works: new FormControl(false),
                  frmDrp_Manages: new FormControl('true'),
                  frmDrp_SendforReview: new FormControl('true'),
                  frmLbl_Location: new FormControl(this.organizationList[m].Display_Name),
                  frmLbl_LocationContext: new FormControl(this.organizationList[m].context)
                });

                (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).push(frmAccounts);
              }
            }
            else {
              frmAccounts = this._fb.group({
                frmchk_Location: new FormControl(false),
                frmLblchk_Location: new FormControl(this.organizationList[m].Account_ID),
                frmDrp_Works: new FormControl(false),
                frmDrp_Manages: new FormControl('true'),
                frmDrp_SendforReview: new FormControl('true'),
                frmLbl_Location: new FormControl(this.organizationList[m].Display_Name),
                frmLbl_LocationContext: new FormControl(this.organizationList[m].context)
              });

              (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).push(frmAccounts);

            }

          }
          if (this.organizationList.length === 1) {
            this.frm_AddUsergroup.patchValue({
              frmDrp_OrgSendforReview: 'true',
              frmDrp_OrgManages: 'true',
              frmDrp_OrgWorks: 'true',
              frmchk_OrgLocation: true
            });
          }
          else {
            this.frm_AddUsergroup.patchValue({
              frmDrp_OrgSendforReview: 'true',
              frmDrp_OrgManages: 'true',
              frmDrp_OrgWorks: 'true',
              frmchk_OrgLocation: false
            });
          }


          accIds = [];
          this.organizationList.forEach(element => {
            accIds.push(element.Account_ID);
          });
          await this.getAssociationTypes(accIds, OrgIDValue);
        }
        else {
          this.organizationList = "";
          // this.showAccounts = false;
        }
      }
      else {
        console.error(res.error);
      }
    }, error => {
      console.error(error);
    });
  }

  async getAssociationTypes(accIDs, OrgId) {
    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.GetQuery('getassociationtypeforaccid');
    let i, Qstr = "";
    for (i = 0; i < accIDs.length; i++) {
      if (i == accIDs.length - 1) {
        Qstr += "accountid = \"" + accIDs[i] + "\"";
      }
      else {
        Qstr += "accountid = \"" + accIDs[i] + "\" or ";
      }
    }
    queryVariable = { "accid": Qstr };
    let associationTypes: any[];
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vaservices.GetData(queryResult, depKey).toPromise().then(async res => {
      if (!res.errors) {
        if (res.data.vwGetAssociationtypeByOrgID_list.length > 0) {
          this.associationData = res.data.vwGetAssociationtypeByOrgID_list;
          associationTypes = [];
          this.associationData.forEach(element => {
            if (associationTypes.indexOf(element.associationtype) == -1) {
              associationTypes.push(element.associationtype)
            }
          });
          for (i = 0; i < associationTypes.length; i++) {
            if (associationTypes[i] === 'Both') {
              this.cardtype = 'Both';
              // sessionStorage.setItem('Contexttype', this.cardtype);
            }
            if (associationTypes[i].toLowerCase() === 'laboratory' || associationTypes[i].toLowerCase() === 'pathologists') {
              this.cardtype = 'Lab';
              // sessionStorage.setItem('Contexttype', cardtype);
            }
            else if (associationTypes[i].toLowerCase() === 'ordering facility') {
              this.cardtype = 'facility';
              // sessionStorage.setItem('Contextype', cardtype);
            }
          }
          if (!this.editMode && this.commonService.isLiveAccount && !this.creatLiveFlag) {
             await this.compareRoles(associationTypes)

          }
          else {
            await this.getRoles(associationTypes, OrgId);
          }
        }
        else {
          this.associationData = "";
        }
      }
      else {
        console.error(res.error);
      }
    }, error => {
      console.error(error);
    });
  }

  async getConfiguredRolesforLiveOrg(configuredRoles) {
    let obj = {
      entityid: this.organizationID || sessionStorage.getItem('org_id'),
      entytitype: 2,
      ConfigName: "AdminApp.LiveCustomerRoles",
      tableName: "VaEntityExtAttributes",
      scope: 3
    }
    this.ngxService.start();
    await this.vaservices.getVAEntityAttribute(obj, this.DeploymentKeys).toPromise().then(async (res) => {
      this.ngxService.stop();
      if (res && res.Success) {
        let data = JSON.parse(res.Message);
        let tempRoleData = data[0].All_Locations_Value_1 != 'N/A' ? data[0].All_Locations_Value_1.split(',') : null;
        this.rolesdata = [];
        if (!tempRoleData) {
          tempRoleData = data[0].Deployment_Level__Value_1 != 'N/A' ? data[0].Deployment_Level__Value_1.split(',') : null;
        }
        this.rolesdata = tempRoleData.map(str => ({ rolename: str }));
        //to get unique roles from array
        let rolesarray = [];
        this.rolesdata.forEach(element => {
          if (rolesarray.length > 0) {
            let foundRoleDuplicate = false;
            for (let kk = 0; kk < rolesarray.length; kk++) {
              let role: string = element.rolename;
              if (rolesarray[kk] === role) {
                foundRoleDuplicate = true;
                break;
              }
            }
            if (foundRoleDuplicate == false) {
              //to get unique roles from array
              rolesarray.push(element);
            }
          }
          else {
            rolesarray.push(element);
          }
        });
        this.rolesdata = configuredRoles.filter(configuredRole => {
          return rolesarray.some(role =>
            role.rolename.toLowerCase() === configuredRole.rolename.toLowerCase()
          );
        });

        (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).clear();

        let k;
        for (k = 0; k < this.rolesdata.length; k++) {
          let frm;
          frm as FormGroup;
          frm = "userRole_" + k;
          let IsPathologistActive: boolean = false;
          if (this.rolesdata[k].rolename == "Medical Staff")
            this.rolesdata[k].rolename = "Medical Assistant";
          else if (this.rolesdata[k].rolename == "Transcriptionist")
            this.rolesdata[k].rolename = "Pathologist Assistant";
          if (this.rolesdata[k].rolename == "Pathologist") {
            this.ShowMedicalDirector = true;
            this.ShoHoldReleaseDuration = false;
            frm = this._fb.group({
              frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: false }),
              frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
              frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
              frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
              frmChk_IsMedicalDirector: false,
              frmLbl_userID: new FormControl({ value: '', disabled: true }),
              frmLbl_usrStatus: new FormControl({ value: false, disabled: true }),
              frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
              frmInp_RoleHoldreleaseDuration: '0',
              frm_RoleType: this.rolesdata[k].roletype
            });
          }
          else if (this.rolesdata[k].rolename.toLowerCase() == "lab manager") {
            this.ShowMedicalDirector = false;
            this.ShoHoldReleaseDuration = true;
            frm = this._fb.group({
              frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: false }),
              frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
              frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
              frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
              frmInp_RoleHoldreleaseDuration: '0',
              frmLbl_userID: new FormControl({ value: '', disabled: true }),
              frmLbl_usrStatus: new FormControl({ value: false, disabled: true }),
              frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
              frmChk_IsMedicalDirector: false,
              frm_RoleType: this.rolesdata[k].roletype
            });
          }
          else {
            this.ShowMedicalDirector = false;
            this.ShoHoldReleaseDuration = false;
            frm = this._fb.group({
              frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: false }),
              frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
              frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
              frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
              frmLbl_userID: new FormControl({ value: '', disabled: true }),
              frmLbl_usrStatus: new FormControl({ value: false, disabled: true }),
              frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
              frmInp_RoleHoldreleaseDuration: '0',
              frmChk_IsMedicalDirector: false,
              frm_RoleType: this.rolesdata[k].roletype
            });
          }
          this.ArrSavedRoles.forEach((e, row_index) => {
            if (frm.controls.frmlbl_DisplayName.value == e.rolename) {
              frm.controls.frmChk_UserRoles_RoleName.value = true;
              frm.controls.frmChk_UserRoles_RoleName.disable();
              frm.controls.frmRd_UserRoles_RoleName.value = '';
              frm.controls.frmRd_UserRoles_RoleName.enable();

              if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "cytotechnologist") {
                this.hidIsCytoSelected = true
              }
              if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "cytotechnologist supervisor") {
                this.hidIsCytoSupervisiorSelected = true
              }
              if ((e.HoldReleaseDuration.toString() != "" && e.HoldReleaseDuration.toString() != null)) {
                if (frm.controls.frmChk_UserRoles_RoleName.value == true) {
                  if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "lab manager" || frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "pathologist") {
                  }
                }
              }
              if (e.IsPrimary == true) {
                this.hidPrimaryRole = e.rolename;
                frm.controls.frmRd_UserRoles_RoleName.value = frm.controls.frmLblChk_UserRoles_RoleName.value;
              }
              if (frm.controls.frmChk_UserRoles_RoleName.value == true) {
                frm.controls.frmLbl_userID.value = e.userid;
                if (e.Status == true) {
                  frm.controls.frmLbl_usrStatus.value = e.usrStatus && e.usrStatus.toString() == 'active' ? true : false;
                  frm.controls.frmImg_UserIDnUserStatus.src = 'activestatus';
                  frm.controls.frmImg_UserIDnUserStatus.title = 'Active';
                }
                else {
                  frm.controls.frmLbl_usrStatus.value = e.usrStatus && e.usrStatus.toString() == 'active' ? true : false;
                  frm.controls.frmImg_UserIDnUserStatus.src = 'inactivestatus';
                  frm.controls.frmImg_UserIDnUserStatus.title = 'InActive';
                }
              }
            }
            if (e.rolename == "Pathologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.frm_AddUsergroup.patchValue(
                {
                  frmInp_AllowtoeditAccession: e.IsModifyTechnicalDataAccession as boolean,
                  frmInp_EditSite: e.ModifyTechnicalData as boolean,
                  frmInp_AllowReviewPathologist: e.ReviewPathCanSignout as boolean
                });

              if (e.DiagICDcodeReminder == null) {
                // if (this.UserType == "Laboratories" && this.IsOrderingFacility as string != "True") {
                this.frm_AddUsergroup.patchValue(
                  {
                    frmInp_Notifyusers: this.DiagICDcodeReminder as boolean
                  }
                );
              }
              else {
                this.frm_AddUsergroup.patchValue(
                  {
                    frmInp_Notifyusers: e.DiagICDcodeReminder as boolean
                  }
                );
              }
            }
            if ((e.rolename == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) || e.rolename == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              if (e.DiagICDcodeReminder as string != "" && e.DiagICDcodeReminder as string != null) {
                // if (this.UserType == "Laboratories" && this.IsOrderingFacility != "True") {
                this.frm_AddUsergroup.patchValue(
                  {
                    frmInp_Notifyusers: this.DiagICDcodeReminder as boolean
                  }
                );
              }
              else {
                this.frm_AddUsergroup.patchValue(
                  {
                    frmInp_Notifyusers: e.DiagICDcodeReminder as boolean
                  }
                );
              }
            }
            if (e.rolename == "Pathologist") {
              frm.controls.IsPathologistActive = e.STATUS as boolean;
              frm.controls.frmChk_IsMedicalDirector.value = e.isMedicalDirector as boolean;

            }
          });

          if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
            this.hidIsCytoSelected = true;
            this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
          }

          if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
            this.hidIsCytoSupervisiorSelected = true;
            this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
          }
          let isPatholigistEnabled = false;

          if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
            this.hidIsPathlogistSelected = true;
            isPatholigistEnabled = true;
            this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].enable();
            this.showfrmInp_AllowReviewPathologist = true;
            this.ShowLicensureDetails = true;

            this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].enable();
            this.showfrmInp_AllowtoeditAccession = true;
            this.showfrmInp_Threshold = true;

            this.frm_AddUsergroup.controls['frmInp_EditSite'].enable();
            this.showfrmInp_EditSite = true;
            this.showfrmDrp_ThresholdDuration = true;

            this.frm_AddUsergroup.controls['frmChk_IsMedicalDirector'].enable();

            this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].enable();
            this.showfrmInp_AllowPathologistDiagnosisTemplate = true;
            this.showfrmInp_PopulateDiag = true;

            this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
          }
          /*Flag as Medical Director*/
          if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist") {
            if (this.ShowMedicalDirector == true && this.IsMedDirectorExistsForCommon == false)
              this.ShowMedicalDirector = true;
            if ((this.ShowMedicalDirector == true && this.IsMedDirectorExistsForCommon == false) || !IsPathologistActive) {
              frm.controls.frmChk_IsMedicalDirector.disable();
            }
            else {
              frm.controls.frmChk_IsMedicalDirector.enable();
            }
          }
          if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist" || frm.controls.frmLblChk_UserRoles_RoleName.value == "Physician") {

            this.frm_AddUsergroup.controls['frmInp_NPI'].enable();
          }
          if (isPatholigistEnabled) {
            let k;
            for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {

              (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].enable();
            }
            this.ShowSendforReview(true);
          }
          (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).push(frm);
        }
        this.ShowSendforReview(false);

        //to display hold release duration and  medical director
        this.MD_KValue = -1;
        this.HRD_KValue = -1;
        for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; k++) {
          if (this.HRD_KValue == -1 && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmInp_RoleHoldreleaseDuration').enabled == true) {
            this.HRD_KValue = k;
          }
          if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'lab manager') {
            this.rolelablistcount = k

          }
          if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'cytotechnologist') {
            this.rolelablistcytcount = k

          }
          if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'cytotechnologist supervisor') {
            this.rolelablistcyscount = k

          }

          if (this.MD_KValue == -1 && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_IsMedicalDirector').enabled == true) {
            this.MD_KValue = k;
          }

          if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value == 'Pathologist') {
            this.rolelistcount = k;
            if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
              this.MD_KValue = k;
              if (this.EPGorgType == 'Pathologists') {
                this.ShowLicensureDetails = true;
              }
              else {
                this.ShowLicensureDetails = false;
              }
            } else {
              this.ShowLicensureDetails = false;
            }
          } else {
            this.ShowLicensureDetails = false;
          }
        }
        await this.getIsMedicalDirectorvalue();
      }

    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
    });
  }

  async getRoles(AssociationTypes, OrgId) {
    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.GetQuery('getuserrolesforassociationtype');
    let i, Qstr = "";
    for (i = 0; i < AssociationTypes.length; i++) {
      if (i == AssociationTypes.length - 1) {
        Qstr += "associationtype = \"" + AssociationTypes[i] + "\"";
      }
      else {
        Qstr += "associationtype = \"" + AssociationTypes[i] + "\" or ";
      }
    }
    if (this.commonService.isPol) {
      this.IsPOL = true;
      this.POLOrg = true;
      this.frm_AddUsergroup.controls.frmArruserAccounts['controls'].forEach(va => va.patchValue({
        frmchk_Location: false,
        frmDrp_Works: false
      }))
    }

    queryVariable = { "associationtype": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    await this.vaservices.GetData(queryResult, depKey).toPromise().then(async res => {
      if (!res.errors) {

        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          this.rolesdata = [];
          this.rolesdata = res.data.vwGetRolesBasedOnAccountAssociation_list; //unsorted
          //to get unique roles from array
          let rolesarray = [];
          this.rolesdata.forEach(element => {
            if (rolesarray.length > 0) {
              let foundRoleDuplicate = false;
              for (let kk = 0; kk < rolesarray.length; kk++) {
                let role: string = element.rolename;
                if (rolesarray[kk].rolename === role) {
                  foundRoleDuplicate = true;
                  break;
                }
              }
              if (foundRoleDuplicate == false) {
                //to get unique roles from array
                rolesarray.push(element);
              }
            }
            else {
              rolesarray.push(element);
            }
          });
          this.rolesdata = rolesarray;

          (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).clear();

          let k;
          for (k = 0; k < this.rolesdata.length; k++) {
            let frm;
            frm as FormGroup;
            frm = "userRole_" + k;
            let IsPathologistActive: boolean = false;
            if (this.rolesdata[k].rolename == "Medical Staff")
              this.rolesdata[k].rolename = "Medical Assistant";
            else if (this.rolesdata[k].rolename == "Transcriptionist")
              this.rolesdata[k].rolename = "Pathologist Assistant";
            if (this.rolesdata[k].rolename == "Pathologist") {
              this.ShowMedicalDirector = true;
              this.ShoHoldReleaseDuration = false;
              frm = this._fb.group({
                frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: false }),
                frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
                frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
                frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
                frmChk_IsMedicalDirector: false,
                frmLbl_userID: new FormControl({ value: '', disabled: true }),
                frmLbl_usrStatus: new FormControl({ value: false, disabled: true }),
                frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
                frmInp_RoleHoldreleaseDuration: '0',
                frm_RoleType: this.rolesdata[k].roletype
              });
            }
            else if (this.rolesdata[k].rolename.toLowerCase() == "lab manager") {
              this.ShowMedicalDirector = false;
              this.ShoHoldReleaseDuration = true;
              frm = this._fb.group({
                frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: false }),
                frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
                frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
                frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
                frmInp_RoleHoldreleaseDuration: '0',
                frmLbl_userID: new FormControl({ value: '', disabled: true }),
                frmLbl_usrStatus: new FormControl({ value: false, disabled: true }),
                frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
                frmChk_IsMedicalDirector: false,
                frm_RoleType: this.rolesdata[k].roletype
              });
            }
            else {
              this.ShowMedicalDirector = false;
              this.ShoHoldReleaseDuration = false;
              frm = this._fb.group({
                frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: (this.rolesdata[k].rolename.toLowerCase() == "physician") ? this.checkAscPhysician(this.organizationList) : false }),
                frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
                frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
                frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
                frmLbl_userID: new FormControl({ value: '', disabled: true }),
                frmLbl_usrStatus: new FormControl({ value: false, disabled: true }),
                frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
                frmInp_RoleHoldreleaseDuration: '0',
                frmChk_IsMedicalDirector: false,
                frm_RoleType: this.rolesdata[k].roletype
              });
            }

            this.ArrSavedRoles.forEach((e, row_index) => {
              if (frm.controls.frmlbl_DisplayName.value == e.rolename) {
                frm.controls.frmChk_UserRoles_RoleName.value = true;
                frm.controls.frmChk_UserRoles_RoleName.disable();
                frm.controls.frmRd_UserRoles_RoleName.value = '';
                frm.controls.frmRd_UserRoles_RoleName.enable();

                if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "cytotechnologist") {
                  this.hidIsCytoSelected = true
                }
                if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "cytotechnologist supervisor") {
                  this.hidIsCytoSupervisiorSelected = true
                }
                if ((e.HoldReleaseDuration.toString() != "" && e.HoldReleaseDuration.toString() != null)) {
                  if (frm.controls.frmChk_UserRoles_RoleName.value == true) {
                    if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "lab manager" || frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "pathologist") {
                    }
                  }
                }
                if (e.IsPrimary == true) {
                  this.hidPrimaryRole = e.rolename;
                  frm.controls.frmRd_UserRoles_RoleName.value = frm.controls.frmLblChk_UserRoles_RoleName.value;
                }
                if (frm.controls.frmChk_UserRoles_RoleName.value == true) {
                  frm.controls.frmLbl_userID.value = e.userid;
                  if (e.Status == true) {
                    frm.controls.frmLbl_usrStatus.value = e.usrStatus && e.usrStatus.toString() == 'active' ? true : false;
                    frm.controls.frmImg_UserIDnUserStatus.src = 'activestatus';
                    frm.controls.frmImg_UserIDnUserStatus.title = 'Active';
                  }
                  else {
                    frm.controls.frmLbl_usrStatus.value = e.usrStatus && e.usrStatus.toString() == 'active' ? true : false;
                    frm.controls.frmImg_UserIDnUserStatus.src = 'inactivestatus';
                    frm.controls.frmImg_UserIDnUserStatus.title = 'InActive';
                  }
                }
              }
              if (e.rolename == "Pathologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
                this.frm_AddUsergroup.patchValue(
                  {
                    frmInp_AllowtoeditAccession: e.IsModifyTechnicalDataAccession as boolean,
                    frmInp_EditSite: e.ModifyTechnicalData as boolean,
                    frmInp_AllowReviewPathologist: e.ReviewPathCanSignout as boolean
                  });

                if (e.DiagICDcodeReminder == null) {
                  // if (this.UserType == "Laboratories" && this.IsOrderingFacility as string != "True") {
                  this.frm_AddUsergroup.patchValue(
                    {
                      frmInp_Notifyusers: this.DiagICDcodeReminder as boolean
                    }
                  );
                }
                else {
                  this.frm_AddUsergroup.patchValue(
                    {
                      frmInp_Notifyusers: e.DiagICDcodeReminder as boolean
                    }
                  );
                }
              }
              if ((e.rolename == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) || e.rolename == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
                if (e.DiagICDcodeReminder as string != "" && e.DiagICDcodeReminder as string != null) {
                  // if (this.UserType == "Laboratories" && this.IsOrderingFacility != "True") {
                  this.frm_AddUsergroup.patchValue(
                    {
                      frmInp_Notifyusers: this.DiagICDcodeReminder as boolean
                    }
                  );
                }
                else {
                  this.frm_AddUsergroup.patchValue(
                    {
                      frmInp_Notifyusers: e.DiagICDcodeReminder as boolean
                    }
                  );
                }
              }
              if (e.rolename == "Pathologist") {
                frm.controls.IsPathologistActive = e.STATUS as boolean;
                frm.controls.frmChk_IsMedicalDirector.value = e.isMedicalDirector as boolean;

              }
            });

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.hidIsCytoSelected = true;
              this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
            }

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.hidIsCytoSupervisiorSelected = true;
              this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
            }
            let isPatholigistEnabled = false;

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.hidIsPathlogistSelected = true;
              isPatholigistEnabled = true;
              this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].enable();
              this.showfrmInp_AllowReviewPathologist = true;
              this.ShowLicensureDetails = true;

              this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].enable();
              this.showfrmInp_AllowtoeditAccession = true;
              this.showfrmInp_Threshold = true;

              this.frm_AddUsergroup.controls['frmInp_EditSite'].enable();
              this.showfrmInp_EditSite = true;
              this.showfrmDrp_ThresholdDuration = true;

              this.frm_AddUsergroup.controls['frmChk_IsMedicalDirector'].enable();

              this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].enable();
              this.showfrmInp_AllowPathologistDiagnosisTemplate = true;
              this.showfrmInp_PopulateDiag = true;

              this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
            }
            /*Flag as Medical Director*/
            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist") {
              if (this.ShowMedicalDirector == true && this.IsMedDirectorExistsForCommon == false)
                this.ShowMedicalDirector = true;
              if ((this.ShowMedicalDirector == true && this.IsMedDirectorExistsForCommon == false) || !IsPathologistActive) {
                frm.controls.frmChk_IsMedicalDirector.disable();
              }
              else {
                frm.controls.frmChk_IsMedicalDirector.enable();
              }
            }
            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist" || frm.controls.frmLblChk_UserRoles_RoleName.value == "Physician") {

              this.frm_AddUsergroup.controls['frmInp_NPI'].enable();
            }
            if (isPatholigistEnabled) {
              let k;
              for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {

                (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].enable();
              }
              this.ShowSendforReview(true);
            }
            (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).push(frm);
          }
          this.ShowSendforReview(false);

          //to display hold release duration and  medical director
          this.MD_KValue = -1;
          this.HRD_KValue = -1;
          for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; k++) {
            if (this.HRD_KValue == -1 && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmInp_RoleHoldreleaseDuration').enabled == true) {
              this.HRD_KValue = k;
            }
            if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'lab manager') {
              this.rolelablistcount = k

            }
            if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'cytotechnologist') {
              this.rolelablistcytcount = k

            }
            if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'cytotechnologist supervisor') {
              this.rolelablistcyscount = k

            }

            if (this.MD_KValue == -1 && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_IsMedicalDirector').enabled == true) {
              this.MD_KValue = k;
            }

            if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value == 'Pathologist') {
              this.rolelistcount = k;
              if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
                this.MD_KValue = k;
                if (this.EPGorgType == 'Pathologists') {
                  this.ShowLicensureDetails = true;
                }
                else {
                  this.ShowLicensureDetails = false;
                }
              } else {
                this.ShowLicensureDetails = false;
              }
            } else {
              this.ShowLicensureDetails = false;
            }
          }

          // this.showRoles = true;
        }
      }
      else {
        this.rolesdata = [];
        let k;
        for (k = 0; k < this.rolesdata.length; k++) {
          this.ShowMedicalDirector = false;
          this.ShoHoldReleaseDuration = false;
          let frm;
          frm as FormGroup;
          frm = "userRole_" + k;
          frm = this._fb.group({
            frmChk_UserRoles_RoleName: [{ value: false, disabled: false }],
            frmLblChk_UserRoles_RoleName: new FormControl(''),
            frmRd_UserRoles_RoleName: [{ value: '', disabled: false }],
            frmlbl_DisplayName: ''
          });
          (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).clear();
          (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).push(frm);
        }
        // this.showAccounts = false;
        // this.showRoles = false;
      }
      await this.getIsMedicalDirectorvalue();
      //this.getIsMedicalRolePresent(OrgId);
    }, error => {
      console.error(error);
    });

  }

  //check the ASC locations in organization.
  checkAscPhysician(organizationList) {
    this.ascPresent = organizationList.some(va => va.IsSharedSurgicenter == true)
    if (this.ascPresent) {
      return true
    } else {
      return false
    }
  }

  ShowSendforReview(show: boolean) {
    if (show) {
      this.frm_AddUsergroup.controls['frmDrp_OrgSendforReview'].enable();
      let k;
      for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {

        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].enable();
      }
    }
    else {
      this.frm_AddUsergroup.controls['frmDrp_OrgSendforReview'].disable();
      let k;
      for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].disable();
      }
    }
  }

  async getIsMedicalDirectorvalue() {
    var IsMedicalDirectorExists_isMedicalDirector = false;
    var IsMedicalDirectorExists_status;
    var IsMedicalDirectorExists_userName;
    var IsMedicalDirectorExists_commonKey;
    var IsMedicalDirectorExists_userID;
    await this.vaservices.GetIsMedicalDirectorExists(this.SelectedOrgID.toString(), "0").toPromise().then(async data => {
      // var jsonResult: any = JSON.stringify(data);
      // jsonResult = JSON.parse(jsonResult);
      var jsonResult: any = data;
      if (jsonResult != undefined && jsonResult != null) {
        if (jsonResult.length > 0) {
          IsMedicalDirectorExists_isMedicalDirector = jsonResult[0].IsMedicalDirector;
          IsMedicalDirectorExists_status = jsonResult[0].Status;
          IsMedicalDirectorExists_userID = jsonResult[0].UserID;
          IsMedicalDirectorExists_userName = jsonResult[0].UserName;
          IsMedicalDirectorExists_commonKey = jsonResult[0].CommonKey;
        }
        else {
          IsMedicalDirectorExists_isMedicalDirector = false;
          IsMedicalDirectorExists_status = "";
          IsMedicalDirectorExists_userID = "";
          IsMedicalDirectorExists_userName = "";
          IsMedicalDirectorExists_commonKey = "";
        }
      }
      else {
        IsMedicalDirectorExists_isMedicalDirector = false;
        IsMedicalDirectorExists_status = "";
        IsMedicalDirectorExists_userID = "";
        IsMedicalDirectorExists_userName = "";
        IsMedicalDirectorExists_commonKey = "";
      }
      this.IsMedicalDirectorExists = IsMedicalDirectorExists_isMedicalDirector;
      if (IsMedicalDirectorExists_isMedicalDirector == true) {
        this.hidIsChecked = true;
      }
      else {
        this.hidIsChecked = false;
      }

      await this.getLicensureDetails();
    }, error => {
      console.error(error);
    });
  }


  async editGetIsMedicalDirectorvalue() {
    var IsMedicalDirectorExists_isMedicalDirector = false;
    var IsMedicalDirectorExists_status;
    var IsMedicalDirectorExists_userName;
    var IsMedicalDirectorExists_commonKey;
    var IsMedicalDirectorExists_userID;
    await this.vaservices.GetIsMedicalDirectorExists(this.userData.Organizationid, this.userData.userid).toPromise().then(async data => {
      // var jsonResult: any = JSON.stringify(data);
      // jsonResult = JSON.parse(jsonResult);
      var jsonResult: any = data;
      if (jsonResult != undefined && jsonResult != null) {
        if (jsonResult.length > 0) {
          IsMedicalDirectorExists_isMedicalDirector = jsonResult[0].IsMedicalDirector;
          IsMedicalDirectorExists_status = jsonResult[0].Status;
          IsMedicalDirectorExists_userID = jsonResult[0].UserID;
          IsMedicalDirectorExists_userName = jsonResult[0].UserName;
          IsMedicalDirectorExists_commonKey = jsonResult[0].CommonKey;
        }
        else {
          IsMedicalDirectorExists_isMedicalDirector = false;
          IsMedicalDirectorExists_status = "";
          IsMedicalDirectorExists_userID = "";
          IsMedicalDirectorExists_userName = "";
          IsMedicalDirectorExists_commonKey = "";
        }
      }
      else {
        IsMedicalDirectorExists_isMedicalDirector = false;
        IsMedicalDirectorExists_status = "";
        IsMedicalDirectorExists_userID = "";
        IsMedicalDirectorExists_userName = "";
        IsMedicalDirectorExists_commonKey = "";
      }

      this.IsMedicalDirectorExists = IsMedicalDirectorExists_isMedicalDirector;
      if (IsMedicalDirectorExists_isMedicalDirector == true) {
        this.hidIsChecked = true;
      }
      else {
        this.hidIsChecked = false;
      }
    }, error => {
      console.error(error);
    });
  }

  enableRadioBtnForRoleNameChk(userRl, i, event) {
    i = this.frm_AddUsergroup.controls.frmArruserRoles.value.findIndex((va: any) => va.frmLblChk_UserRoles_RoleName == userRl.value.frmLblChk_UserRoles_RoleName);
    if (!this.editMode && this.commonService.isPol) {
      this.checkRoleType(userRl, this.frm_AddUsergroup.controls.frmArruserRoles);
    }
    else if(this.editMode && this.commonService.isPol){
      this.checkRoleTypeForEdit(userRl, this.frm_AddUsergroup.controls.frmArruserRoles);
    }
    this.doEnableRadioBtnForRoleNameChk(i, event);
    this.RoleCheckboxValidation();
    // if (this.ClickFrom === 'UI') {
    this.funcToggle(userRl, i);
    this.enableDisableHDMD(userRl, i);

    // }
  }
  checkRoleTypeForEdit(userRl, roleForm){
    let checkedRoles = roleForm.controls.filter(va=>  va.controls.frmChk_UserRoles_RoleName.value == true )  
    if (userRl.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() != 'pathologist') {
      if (!userRl.controls['frmChk_UserRoles_RoleName'].value) {
        if (userRl.controls['frm_RoleType'] && userRl.controls['frm_RoleType'].value.toString().toLowerCase().trim() == 'of') {
          this.enabledRoles.forEach(va => {
            if (va.controls.frm_RoleType.value.toString().toLowerCase().trim() == 'lab') {
              va.controls.frmChk_UserRoles_RoleName.disable()
            }
            else {
              if (checkedRoles.find(ele => ele.value.frmLblChk_UserRoles_RoleName != va.controls.rolename)) {
                va.controls.frmChk_UserRoles_RoleName.enable();
              }
            }
            this.hideLocations('of');
          })
        }
        else if (userRl.controls['frm_RoleType'] && userRl.controls['frm_RoleType'].value.toString().toLowerCase().trim() == 'lab') {
          this.enabledRoles.forEach(va => {
            if (va.controls.frm_RoleType.value.toString().toLowerCase().trim() == 'of') {
              va.controls.frmChk_UserRoles_RoleName.disable()
            }
            else {
              if (checkedRoles.find(ele => ele.value.frmLblChk_UserRoles_RoleName != va.controls.rolename)) {
                va.controls.frmChk_UserRoles_RoleName.enable()
              }
            }
          })
          this.hideLocations('lab');
        }
        else {
          this.hideLocations();
        }

      }
      else {
        let roleData = this.enabledRoles.filter(va => va.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() != userRl.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() && va.controls['frmChk_UserRoles_RoleName'].value)
        if (roleData && roleData.length == 0) {
          this.searchContext = null;
          this.enabledRoles.forEach(va => {           
              va.controls.frmChk_UserRoles_RoleName.enable();
            
          })
        }
      }
    }
  }
  checkRoleType(userRl, roleForm) {
    let pathFilter = roleForm.controls.filter(va => va.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() != 'pathologist')

    if (userRl.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() != 'pathologist') {
      if (!userRl.controls['frmChk_UserRoles_RoleName'].value) {
        if (userRl.controls['frm_RoleType'] && userRl.controls['frm_RoleType'].value.toString().toLowerCase().trim() == 'of') {
          pathFilter.forEach(va => {
            if (va.controls.frm_RoleType.value.toString().toLowerCase().trim() == 'lab') {
              va.controls.frmChk_UserRoles_RoleName.disable()
            }
            else {
              va.controls.frmChk_UserRoles_RoleName.enable();
            }
            this.hideLocations('of');
          })
        }
        else if (userRl.controls['frm_RoleType'] && userRl.controls['frm_RoleType'].value.toString().toLowerCase().trim() == 'lab') {
          pathFilter.forEach(va => {
            va.controls.frm_RoleType.value.toString().toLowerCase().trim() == 'of' ? va.controls.frmChk_UserRoles_RoleName.disable() : va.controls.frmChk_UserRoles_RoleName.enable();
          })
          this.hideLocations('lab');
        }
        else {
          this.hideLocations();
        }

      }
      else {
        let roleData = pathFilter.filter(va => va.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() != userRl.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() && va.controls['frmChk_UserRoles_RoleName'].value)
        if (roleData && roleData.length == 0) {
          this.searchContext = null;
          roleForm.controls.forEach(va => {
            va.controls.frmChk_UserRoles_RoleName.enable();
          })
        }
      }
    }


  }

  hideLocations(context?) {
    this.searchContext = context
    context ? this.checkValidLocations(context) : null
  }

  checkValidLocations(context: string) {
    if (context == 'of') {
      this.frm_AddUsergroup.controls.frmArruserAccounts['controls'].forEach((va: any) => {
        if (va.controls.frmLbl_LocationContext.value.toString().toLowerCase().trim() != 'of') {
          va.patchValue({
            frmchk_Location: false
          })
        }
      })
    }
    else {
      this.frm_AddUsergroup.controls.frmArruserAccounts['controls'].forEach((va: any) => {
        if (va.controls.frmLbl_LocationContext.value.toString().toLowerCase().trim() == 'of') {
          va.patchValue({
            frmchk_Location: false
          })
        }
      })
    }
  }

  // Enable or Disable Medical Director and Hold Duration 
  enableDisableHDMD(userRl, index) {
    if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'lab manager' &&
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
      this.HRD_KValue = this.rolelablistcount;
      this.HRD_value = -1;
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
    }
    else if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'cytotechnologist') {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
        this.HRD_KValue = this.rolelablistcytcount;
        this.HRD_value = -1;

        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
      }
      else {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
        this.HRD_value = 0;
      }
    }
    else if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'cytotechnologist supervisor') {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
        this.HRD_KValue = this.rolelablistcyscount;
        this.HRD_value = -1;
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
      }
      else {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
        this.HRD_value = 0;
      }
    }
    else if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'pathologist') {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
        if (this.hidIsMedicalDirector == true) {
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_IsMedicalDirector'].disable();
        }
        else {
          this.MD_KValue = this.rolelistcount;
          this.HRD_KValue = this.rolelistcount;
          this.HRD_value = -1;
          if (this.IsMedicalDirectorExists == true) {
            this.hidIsChecked = false;
            userRl.controls['frmChk_IsMedicalDirector'].disable();
            (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
              {
                frmChk_IsMedicalDirector: false,
                frmInp_RoleHoldreleaseDuration: '0'
              });
          }
          else {
            userRl.controls['frmChk_IsMedicalDirector'].enable();
            (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
              {
                frmChk_IsMedicalDirector: this.IsMedicalDirectorExists,
                frmInp_RoleHoldreleaseDuration: '0'
              });
          }
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();

          //  (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_IsMedicalDirector'].enable();
          // (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmRd_UserRoles_RoleName'].enable();
          // (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
          //   {
          //     //frmRd_UserRoles_RoleName: 'Pathologists',
          //     // frmChk_UserRoles_RoleName: true,
          //     frmChk_IsMedicalDirector: !this.IsMedicalDirectorExists,
          //     frmInp_RoleHoldreleaseDuration: '0'
          //   });

          //(<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].enable();
          // //(<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();

          // // this.ClickFrom = "TS";
          // // let el: HTMLElement = this.role_Chk_click.nativeElement;
          // // el.click();
          // // this.ClickFrom = "UI";
          // this.RoleCheckboxValidation();
        }
      }
      else {
        this.HRD_value = 0;

        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();

      }
    }
  }
  // doEnableRadioBtnForRoleNameChk(userRl, i, event) {
  //   if (!this.frm_AddUsergroup.controls.frmArruserRoles.value.every(va => va.frmRd_UserRoles_RoleName)) {
  //     if (event.target.checked == true) {
  //       userRl.controls['frmRd_UserRoles_RoleName'].enable();
  //       userRl.patchValue(
  //         {
  //           frmRd_UserRoles_RoleName: true,
  //           frmChk_UserRoles_RoleName: event.target.checked
  //         });
  //     }
  //   }
  //   else {
  //     event.target.checked ? userRl.controls['frmRd_UserRoles_RoleName'].enable() : userRl.controls['frmRd_UserRoles_RoleName'].disable();
  //     userRl.patchValue(
  //       {    i = this.frm_AddUsergroup.controls.frmArruserRoles.value.findIndex(va => va.frmLblChk_UserRoles_RoleName == userRl.value.frmLblChk_UserRoles_RoleName);
  //         frmRd_UserRoles_RoleName: '',
  //         frmChk_UserRoles_RoleName: event.target.checked
  //       });
  //   }
  // }
  doEnableRadioBtnForRoleNameChk(i, event) {
    if (this.frm_AddUsergroup.controls.frmArruserRoles['controls'].every((va: any) => va.controls.frmChk_UserRoles_RoleName.value == false)) {
      if (event.target.checked == true) {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].enable();
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
          {
            frmRd_UserRoles_RoleName: (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value,
            frmChk_UserRoles_RoleName: event.target.checked
          });
      }
    }
    else if (event.target.checked == true) {
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].enable();
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
        {
          frmRd_UserRoles_RoleName: '',
          frmChk_UserRoles_RoleName: event.target.checked
        });
    }

    // let index = -1;
    // index = this.frm_AddUsergroup.controls.frmArruserRoles.value.findIndex(va => va.frmRd_UserRoles_RoleName == true);
    // if (index == i) {
    if (event.target.checked == false) {
      // this.counts = -1;
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].disable();
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
        {
          frmRd_UserRoles_RoleName: '',
          frmChk_UserRoles_RoleName: event.target.checked
        });

    }
    // else if (event.target.checked == false) {
    //   (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].disable();
    //   (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
    //     {
    //       frmRd_UserRoles_RoleName: '',
    //       frmChk_UserRoles_RoleName: event.target.checked
    //     });
    // }
    // }
    // else if (event.target.checked == false) {
    //   (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].disable();
    //   (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
    //     {
    //       frmRd_UserRoles_RoleName: '',
    //       frmChk_UserRoles_RoleName: event.target.checked
    //     });
    // }
  }



  RoleCheckboxValidation() {
    let m, count_true: number = 0, count_false: number = 0;
    let count: number = (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length;

    for (m = 0; m < count; m++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmChk_UserRoles_RoleName'].value == false) {
        count_false++;
      }
      else if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmChk_UserRoles_RoleName'].value == true) {
        count_true++;
      }
    }
    if (count_true === count) {
      // this.RoleCheckboxValidator = true;
      this.frm_AddUsergroup.patchValue({
        frmChk_RoleCheckboxValidator: true
      });
    }
    else if (count_false === count) {
      // this.RoleCheckboxValidator = false;
      this.frm_AddUsergroup.patchValue({
        frmChk_RoleCheckboxValidator: false
      });
    }
    else {
      // this.RoleCheckboxValidator = false;
      this.frm_AddUsergroup.patchValue({
        frmChk_RoleCheckboxValidator: false
      });
    }
  }

  funcToggle(userRl, i) {
    if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_UserRoles_RoleName'].value) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value == 'Pathologist') {
        this.hidIsPathlogistSelected = true;
      }
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value == 'Cytotechnologist') {
        this.hidIsCytoSelected = true;
      }
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value == 'Cytotechnologist Supervisor') {
        this.hidIsCytoSupervisiorSelected = true;
      }
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value == 'Physician') {
        this.hidIsPhysicianSelected = true;
      }
      else {
        this.hidIsPhysicianSelected = false;
      }
    }
    // }
    if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'lab manager') {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_UserRoles_RoleName'].value == true) {
        this.HRD_KValue = this.rolelablistcount;
        this.HRD_value = -1;
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
          {
            frmInp_RoleHoldreleaseDuration: '0'
          });
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].enable();
      }
      else {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].disable();
      }


    }

    if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'pathologist') {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_UserRoles_RoleName'].value == true) {
        if (this.EPGorgType == 'Pathologists') {
          this.ShowLicensureDetails = true;
        }
        else {
          this.ShowLicensureDetails = false;
        }
        this.hidIsPathlogistSelected = true;
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_IsMedicalDirector'].enable();
        if (this.hidIsMedicalDirector == true) {
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_IsMedicalDirector'].disable();
        }
        else {
          this.MD_KValue = this.rolelistcount;
          this.HRD_KValue = this.rolelistcount;
          this.HRD_value = -1;
          if (this.IsMedicalDirectorExists == true) {
            this.hidIsChecked = false;
            (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_IsMedicalDirector'].disable();
          }
          else {
            this.hidIsChecked = true;
            (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_IsMedicalDirector'].enable();
          }
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].enable();

          //(<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].enable();
          // //(<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();

          // // this.ClickFrom = "TS";
          // // let el: HTMLElement = this.role_Chk_click.nativeElement;
          // // el.click();
          // // this.ClickFrom = "UI";

          // (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmRd_UserRoles_RoleName'].enable();
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
            {
              //frmRd_UserRoles_RoleName: 'Pathologists',
              // frmChk_UserRoles_RoleName: true,
              frmChk_IsMedicalDirector: this.hidIsChecked,
              frmInp_RoleHoldreleaseDuration: '0'
            });

          // this.RoleCheckboxValidation();
        }
      }
      else {
        this.ShowLicensureDetails = false;
        this.hidIsPathlogistSelected = false;
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_UserRoles_RoleName'].enable();
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_IsMedicalDirector'].disable();
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].disable();

        this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].disable();
        this.ShowLicensureDetails = false;
        this.showfrmInp_AllowReviewPathologist = false;

        this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].disable();
        this.showfrmInp_AllowtoeditAccession = false;
        this.showfrmInp_Threshold = false;

        this.frm_AddUsergroup.controls['frmInp_EditSite'].disable();
        this.showfrmInp_EditSite = false;
        this.showfrmDrp_ThresholdDuration = false;

        this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].disable();
        this.showfrmInp_AllowPathologistDiagnosisTemplate = false;
        this.showfrmInp_PopulateDiag = false;

        if (this.hidIsCytoSelected == false || this.hidIsCytoSupervisiorSelected == false) {
          this.frm_AddUsergroup.controls['frmInp_Notifyusers'].disable();
          this.showfrmInp_Notifyusers = false;
        }

        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].disable();
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
          {
            frmRd_UserRoles_RoleName: '',
            frmChk_UserRoles_RoleName: false
          });

        return;
      }
      this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].enable();
      this.showfrmInp_AllowReviewPathologist = true;
      this.ShowLicensureDetails = true;
      this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].enable();
      this.showfrmInp_AllowtoeditAccession = true;
      this.showfrmInp_Threshold = true;
      this.frm_AddUsergroup.controls['frmInp_EditSite'].enable();
      this.showfrmInp_EditSite = true;
      this.showfrmDrp_ThresholdDuration = true;
      this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
      this.showfrmInp_Notifyusers = true;
      if (this.UserType == "Laboratories" && this.IsOrderingFacility == true) {
        this.frm_AddUsergroup.patchValue({
          frmInp_AllowtoeditAccession: true,
          frmInp_EditSite: true
        });
      }
    }
    else if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'cytotechnologist') {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_UserRoles_RoleName'].value == true) {
        this.HRD_KValue = this.rolelablistcytcount;
        this.HRD_value = -1;
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
          {
            frmInp_RoleHoldreleaseDuration: '0'
          });
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].enable();
        this.hidIsCytoSelected = true;
        this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
        this.showfrmInp_Notifyusers = true;
      }
      else {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].disable();
        this.hidIsCytoSelected = false;
        if (this.hidIsPathlogistSelected == false && this.hidIsCytoSelected == false && this.hidIsCytoSupervisiorSelected == false) {
          this.frm_AddUsergroup.controls['frmInp_Notifyusers'].disable();
          this.showfrmInp_Notifyusers = false;
        }
        return;
      }
    }
    else if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'cytotechnologist supervisor') {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_UserRoles_RoleName'].value == true) {
        this.HRD_KValue = this.rolelablistcyscount;
        this.HRD_value = -1;
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
          {
            frmInp_RoleHoldreleaseDuration: '0'
          });
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].enable();
        this.hidIsCytoSupervisiorSelected = true;
        this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
        this.showfrmInp_Notifyusers = true;
      }
      else {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].disable();
        this.hidIsCytoSupervisiorSelected = false;
        if (this.hidIsPathlogistSelected == false && this.hidIsCytoSelected == false && this.hidIsCytoSupervisiorSelected == false) {
          this.frm_AddUsergroup.controls['frmInp_Notifyusers'].disable();
          this.showfrmInp_Notifyusers = false;
        }
        return;
      }
    }
    else {
      if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'physician'
        || ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'pathologist') {
        this.frm_AddUsergroup.controls['frmInp_NPI'].enable();
      }
      // this.HRD_value=0;
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].disable();
      this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].disable();
      this.showfrmInp_AllowReviewPathologist = false;
      this.ShowLicensureDetails = false;
      this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].disable();
      this.showfrmInp_AllowtoeditAccession = false;
      this.showfrmInp_Threshold = false;
      this.frm_AddUsergroup.controls['frmInp_EditSite'].disable();
      this.showfrmInp_EditSite = false;
      this.showfrmDrp_ThresholdDuration = false;
      this.frm_AddUsergroup.controls['frmInp_Notifyusers'].disable();
      this.showfrmInp_Notifyusers = false;
      this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].disable();
      this.showfrmInp_AllowPathologistDiagnosisTemplate = false;
      this.showfrmInp_PopulateDiag = false;
      if (this.hidIsPathlogistSelected == true) {
        this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].enable();
        this.showfrmInp_AllowReviewPathologist = true;
        this.ShowLicensureDetails = true;
        this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].enable();
        this.showfrmInp_AllowtoeditAccession = true;
        this.showfrmInp_Threshold = true;
        this.frm_AddUsergroup.controls['frmInp_EditSite'].enable();
        this.showfrmInp_EditSite = true;
        this.showfrmDrp_ThresholdDuration = true;
        this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
        this.showfrmInp_Notifyusers = true;
      }
      this.showfrmInp_Notifyusers = true;
      this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].enable();
      this.showfrmInp_AllowPathologistDiagnosisTemplate = true;
      this.showfrmInp_PopulateDiag = true;
    }
    if (this.hidIsPathlogistSelected == true) {
      this.showfrmInp_DisplayFormatReports = true;
      this.frm_AddUsergroup.controls['frmInp_DisplayFormatReports'].enable();
    }
    else {
      this.showfrmInp_DisplayFormatReports = true;
      this.frm_AddUsergroup.controls['frmInp_DisplayFormatReports'].disable();
    }

  }

  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9 -.]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }


  donotAllowSpecialCharacter(event: KeyboardEvent) {
    const pattern = /^[@]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }


  MedicalDirectorCheck(event, data) {
    if (event.target.checked == true) {
      this.hidIsChecked = true;
      this.medicaldirectorChecked= true;
      data.patchValue({
        frmChk_IsMedicalDirector: true
      })
    }
    else {
      data.patchValue({
        frmChk_IsMedicalDirector: false
      })
      this.hidIsChecked = false;
      this.medicaldirectorChecked= false;
    }
  }

  TogglePrimaryRole(event, data) {
    let k;
    if (this.editMode && !data.controls.frmLbl_usrStatus.value) {
      data.patchValue({
        frmLbl_usrStatus: true
      })
    }
    for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; k++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == true) {
        if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
            {

              frmRd_UserRoles_RoleName: ''
            });
        }
      }
      else if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == false && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
        if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
            {
              frmRd_UserRoles_RoleName: ''
            });
        }
      }
    }
    event.target.checked = true;
  }

  onChangefrmchk_Location(value, userRl) {
    let m, count_true: number = 0, count_false: number = 0;
    let count: number = (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length;
    if (!value) {
      userRl.patchValue(
        {
          frmDrp_Works: false
        });
    }
    else {
      if (this.frm_AddUsergroup.controls.frmArruserAccounts.value.every((va: any) => !va.frmDrp_Works)) {
        userRl.patchValue(
          {
            frmDrp_Works: true
          });
      }
      else {
        userRl.patchValue(
          {
            frmDrp_Works: false
          });
      }
    }

  }

  onChangefrmDrp_OrgWorks(event, userRl) {
    for (let m = 0; m < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; m++) {
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).patchValue(
        {
          frmDrp_Works: false
        });
    }
    <FormGroup>userRl.patchValue(
      {
        frmchk_Location: true,
        frmDrp_Works: true
      })
  }



  filteredAccounts(control) {
    if (!this.accountSearch.toString().trim()) {
      if (this.searchContext) {
        return control.filter(va => va.value.frmLbl_LocationContext.toString().toLowerCase().trim() == this.searchContext.toString().toLowerCase().trim());
      }
      else {
        return control;
      }
    }
    else {
      if (!this.searchContext) {
        return control.filter(va => va.value.frmLbl_Location.toString().toLowerCase().trim().includes(this.accountSearch.toString().toLowerCase().trim()));
      }
      else {
        return control.filter(va => va.value.frmLbl_Location.toString().toLowerCase().trim().includes(this.accountSearch.toString().toLowerCase().trim()) && va.value.frmLbl_LocationContext.toString().toLowerCase().trim() == this.searchContext.toString().toLowerCase().trim());
      }
    }
  }

  filteredRoles(control) {
    if (!this.roleSearch.toString().trim()) {
      return control;
    }
    else {
      return control.filter(va => va.value.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim().includes(this.roleSearch.toString().toLowerCase().trim()));
    }
  }

  showHoldDuration(userRl) {
    let index = this.frm_AddUsergroup.get('frmArruserRoles').value.findIndex((va: any) => (va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == userRl.value.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim()));
    let roles = ['lab manager', 'cytotechnologist', 'cytotechnologist supervisor', 'pathologist']

    if (roles.some(va => va.toString().toLowerCase().trim() == ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value.toString().toLowerCase().trim())) &&
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
      return true;
    }
    return false;
  }

  showMedicalDirector(userRl) {
    let i = this.frm_AddUsergroup.controls.frmArruserRoles.value.findIndex((va: any) => va.frmLblChk_UserRoles_RoleName == userRl.value.frmLblChk_UserRoles_RoleName);
    if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value.toString().toLowerCase().trim() == 'pathologist' && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_UserRoles_RoleName'].value)) {
      return true;
    }
    return false;
  }


  openAlert(userTabs: MatTabGroup, direction) {
    if (userTabs.selectedIndex == 0) { //Users tab
      this.frm_AddUsergroup.controls['frmInp_Email'].setErrors(null);
      if (direction == 'left') {
        this.manageUserClose();
      }
      else {
        if (this.detectUserDetailChange(this.clonedUserForm, this.frm_AddUsergroup)) {
          this.alertPopUp = true;
        }
        else {
          userTabs.selectedIndex = 1;
          this.enableTabs(false, true, false, false, false);
        }
      }
    }
    else if (userTabs.selectedIndex == 1) { //Roles Tab
      this.roleSearch = "";
      if (direction == 'left') {
        if (this.detectRoleDetailChange(this.clonedUserForm, this.frm_AddUsergroup)) {
          this.moveTo = 'previous'
          this.alertPopUp = true;
        }
        else {
          userTabs.selectedIndex = 0;
          this.enableTabs(true, false, false, false, false);
          if (!this.editMode && this.POLOrg)
            this.setValidLocations(this.frm_AddUsergroup.controls.frmArruserRoles);
        }
      }
      else {
        if (this.detectRoleDetailChange(this.clonedUserForm, this.frm_AddUsergroup)) {
          this.moveTo = 'next';
          this.alertPopUp = true;
        }
        else {
          userTabs.selectedIndex = 2;
          this.enableTabs(false, false, true, false, false);
          if (!this.editMode && this.POLOrg)
            this.setValidLocations(this.frm_AddUsergroup.controls.frmArruserRoles);
        }
      }
    }
    else if (userTabs.selectedIndex == 2) { //Locations Tab
      this.accountSearch = ""
      if (direction == 'left') {
        if (this.detectLocationDetailChange(this.clonedUserForm, this.frm_AddUsergroup)) {
          this.moveTo = 'previous'
          this.alertPopUp = true;
        }
        else {
          userTabs.selectedIndex = 1;
          this.enableTabs(false, true, false, false, false);
        }
      }
      else {
        if (this.detectLocationDetailChange(this.clonedUserForm, this.frm_AddUsergroup)) {
          this.moveTo = 'next';
          this.alertPopUp = true;
        }
        else {
          userTabs.selectedIndex = 3;
          this.enableTabs(false, false, false, true, false);
          this.setPrimaryLocation(this.frm_AddUsergroup.value.frmArruserAccounts)
          this.fetchUserAssociations();
        }
      }
    }
    else if (userTabs.selectedIndex == 3) { //Configurations Tab
      if (direction == 'left') {
        if (this.detectConfigDetailChange(this.clonedUserForm, this.frm_AddUsergroup)) {
          this.moveTo = 'previous'
          this.alertPopUp = true;
        }
        else {
          userTabs.selectedIndex = 2;
          this.enableTabs(false, false, true, false, false);
        }
      }
      else {
        this.moveTo = 'next';
        this.alertPopUp = true;
      }
    }
  }
  setValidLocations(roleForm) {
    for (let data of roleForm.controls) {
      if (data.controls.frmChk_UserRoles_RoleName.value == true) {
        if (data.controls.frm_RoleType.value.toString().toLowerCase().trim() == 'of') {
          this.searchContext = 'of'
        }
        else if (data.controls.frm_RoleType.value.toString().toLowerCase().trim() == 'lab') {
          this.searchContext = 'lab'
        }
        else {
          this.searchContext = null
        }
        break
      }
    }
  }

  async fetchUserAssociations() {
    var queryList = this.vaservices.mainQueryList.filter(x => x.Keyword == "getuserassociatedroles");
    // let queryString = ''
    let queryVariable = {}
    let result;
    let accountSelection = ''
    this.frm_AddUsergroup.controls.frmArruserAccounts.value.forEach((va: any) => {
      if (va.frmchk_Location) {
        accountSelection += 'accountid = \"' + va.frmLblchk_Location.toString() + '\" or '
        // accountSelection +=   va.frmLblchk_Location.toString();
        // accountSelection +=  '\" or '  
      }
    })
    if (!accountSelection) {
      this.userAssocFlag = false;
      return;
    }
    accountSelection = accountSelection.slice(0, accountSelection.length - 4);

    // queryString = "accountid in \"" + accountSelection + "\"";
    queryVariable = { "filterParam": accountSelection, "orgid": this.organizationID ? this.organizationID.toString() : sessionStorage.getItem('org_id'), "extrname": 'Enable.UserAssociation' };

    let drpqueryRequest = {
      "OperationName": null,
      "Query": queryList[0].Query
    };

    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    if (this.checkSelectedRole('medical assistant')) {
      queryVariable['role'] = 'physician'
      drpqueryRequest["Variables"] = queryVariable;
      try {
        result = await this.vaservices.GetData(drpqueryRequest, depKey).toPromise();
        if (result.errors) {
          this._snackBar.open('Something went wrong! Please try again.', 'Close')
          return;
        }
        if (result.data.submenuData1 && result.data.submenuData1.length > 0) {
          this.userAssocFlag = result.data.submenuData1[0].ExtAttrValue ? result.data.submenuData1[0].ExtAttrValue == 'true' ? true : false : null;
        }
        if (this.userAssocFlag) {
          if (this.editMode) {
            this.existingAssociations = await this.fetchExistingUserAssociations()
            this.assignUserAssociations(result.data.submenuData, queryVariable['role'], this.existingAssociations);
          }
          else {
            this.assignUserAssociations(result.data.submenuData, queryVariable['role']);
          }
        }
      }
      catch {
        this._snackBar.open('Something went wrong! Please try again.', 'Close');
      }
    }
    if (this.checkSelectedRole('pathologist assistant')) {
      queryVariable['role'] = 'pathologist'
      drpqueryRequest["Variables"] = queryVariable;
      try {
        result = await this.vaservices.GetData(drpqueryRequest, depKey).toPromise();
        if (result.errors) {
          this._snackBar.open('Something went wrong! Please try again.', 'Close')
          return;
        }
        if (result.data.submenuData1 && result.data.submenuData1.length > 0) {
          this.userAssocFlag = result.data.submenuData1[0].ExtAttrValue ? result.data.submenuData1[0].ExtAttrValue == 'true' ? true : false : null;
        }
        if (this.userAssocFlag) {
          if (this.editMode) {
            this.existingAssociations = await this.fetchExistingUserAssociations()
            this.assignUserAssociations(result.data.submenuData, queryVariable['role'], this.existingAssociations);
          }
          else {
            this.assignUserAssociations(result.data.submenuData, queryVariable['role']);

          }
        }
      }
      catch {
        this._snackBar.open('Something went wrong! Please try again.', 'Close');
      }

    }


  }

  assignUserAssociations(data, role, existingData?) {
    if (!existingData) {
      existingData = [];
    }
    let uniqueData: any = [...new Map(data.map(item => [item['userid'], item])).values()];
    if (role.toString().trim() == 'physician') {
      (<FormArray>this.frm_AddUsergroup.get('frmPhysician')).clear();
      for (let m = 0; m < uniqueData.length; m++) {
        let frmPhysician;
        frmPhysician as FormGroup;
        let setvalue = existingData.some(va => va.associationtype.toString().toLowerCase() == 'physician' && va.associateduserid == uniqueData[m].userid) ? new FormControl(true) : new FormControl(false)
        //let templocation=checkedLoction[j];
        frmPhysician = this._fb.group({
          frmchk_user: setvalue,
          frmAssocID: this.checkExistingData(existingData, uniqueData[m].userid),
          frmlbl_username: new FormControl(uniqueData[m].username),
          frmlbl_userid: new FormControl(uniqueData[m].userid),
          frmlbl_rolename: new FormControl(uniqueData[m].rolename)
        });
        if (!(this.frm_AddUsergroup.get('frmPhysician').value.some((va: any) => va.frmlbl_userid == uniqueData[m].userid))) {
          (<FormArray>this.frm_AddUsergroup.get('frmPhysician')).push(frmPhysician);
        }
      }
    }

    if (role.toString().trim() == 'pathologist') {
      (<FormArray>this.frm_AddUsergroup.get('frmPathologist')).clear();
      for (let m = 0; m < uniqueData.length; m++) {
        let frmPathologist;
        frmPathologist as FormGroup;
        //let templocation=checkedLoction[j];

        frmPathologist = this._fb.group({
          frmchk_user: existingData.some(va => va.associationtype.toString().toLowerCase() == 'pathologist' && va.associateduserid == uniqueData[m].userid) ? new FormControl(true) : new FormControl(false),
          frmAssocID: this.checkExistingData(existingData, uniqueData[m].userid),
          frmlbl_username: new FormControl(uniqueData[m].username),
          frmlbl_userid: new FormControl(uniqueData[m].userid),
          frmlbl_rolename: new FormControl(uniqueData[m].rolename)
        });

        if (!(this.frm_AddUsergroup.get('frmPathologist').value.some((va: any) => va.frmlbl_userid == uniqueData[m].userid))) {
          (<FormArray>this.frm_AddUsergroup.get('frmPathologist')).push(frmPathologist);
        }
      }
    }
  }


  checkExistingData(existingData, userid) {
    let data = existingData.filter(va => va.associateduserid == userid)
    if (data.length > 0) {
      return data[0].associationid
    }
    else {
      return null
    }
  }


  savePhysicanAssociation() {
    let savedAssociations: any = this.frm_AddUsergroup.get('frmPhysician').value.filter((va: any) => va.frmchk_user || va.frmunchk_user)
    let physiciandataTable = []
    // for(let i = 0;i < savedAssociations.length ;i++){
    let i = 0
    savedAssociations.forEach(va => {
      let physicianObj = {
        ID: i,
        AssociationID: va.frmAssocID,
        UserID: this.userData && this.userData.userid ? this.userData.userid : null,
        AssociationType: 'Physician',
        AssociatedUserid: va.frmlbl_userid,
        OrganizationId: Number(this.organizationID ? this.organizationID.toString() : sessionStorage.getItem('org_id')),
        DeleteAssociation: va.frmunchk_user,
        CreatedBy: sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : '-100',
        ModifiedBy: this.editMethod ? sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : '-100' : null
      }
      i++
      physiciandataTable.push(physicianObj)
    });
    return physiciandataTable;
  }


  onChangePhysician(checked, userid) {
    let savedAssociations: any = this.frm_AddUsergroup.get('frmPhysician').value.filter((va: any) => va.frmlbl_userid == userid)
    if (!checked && this.existingAssociations && this.existingAssociations.some(va => va.associationtype.toString().toLowerCase() == 'physician' && va.associateduserid == userid)) {
      savedAssociations[0].frmunchk_user = true;
    }
    else if (checked && savedAssociations[0].frmunchk_user) {
      delete savedAssociations[0].frmunchk_user
    }
  }


  onChangePathologist(checked, userid) {
    // let savedAssociations: any = <FormArray>this.frm_AddUsergroup.get('frmPathologist').value.filter(va => va.frmlbl_userid == userid);  
    if (!checked && this.existingAssociations && this.existingAssociations.some(va => va.associationtype.toString().toLowerCase() == 'pathologist' && va.associateduserid == userid)) {
      this.frm_AddUsergroup.get('frmPathologist').value.forEach((va: any) => {
        if (va.frmlbl_userid == userid) {
          va.frmunchk_user = true;
        }
      })
      // .push({frmunchk_user : new Control(true)});
    }
    else if (checked) {
      this.frm_AddUsergroup.get('frmPathologist').value.forEach((va: any) => {
        if (va.frmlbl_userid == userid) {
          delete va.frmunchk_user;
        }
      })
    }
  }


  savePathologistAssociation() {
    let savedAssociations: any = this.frm_AddUsergroup.get('frmPathologist').value.filter((va: any) => va.frmchk_user || va.frmunchk_user)
    let pathologistdataTable = []
    // for(let i = 0;i < savedAssociations.length ;i++){
    let i = 0;
    savedAssociations.forEach(va => {
      let pathologistObj = {
        ID: i,
        AssociationID: va.frmAssocID,
        UserID: this.userData && this.userData.userid ? this.userData.userid : null,
        AssociationType: 'Pathologist',
        AssociatedUserid: va.frmlbl_userid,
        OrganizationId: Number(this.organizationID ? this.organizationID.toString() : sessionStorage.getItem('org_id')),
        DeleteAssociation: va.frmunchk_user,
        CreatedBy: sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : '-100',
        ModifiedBy: this.editMethod ? sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : '-100' : null
      }
      i++;
      pathologistdataTable.push(pathologistObj)
    });
    return pathologistdataTable;
  }


  checkSelectedRole(role) {
    return this.frm_AddUsergroup.value.frmArruserRoles.some((va: any) => va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == role && (va.frmChk_UserRoles_RoleName != false))
  }


  async saveAndOrProceed(action, userTabs) {
    this.alertPopUp = false;
    if (userTabs.selectedIndex == 0) { //Users tab
      if (action == 'save') {
        this.userTabValidation(userTabs, 'next');
      }
      else {
        this.frm_AddUsergroup = lodash.cloneDeep(this.clonedUserForm);
        this.frm_sign = lodash.cloneDeep(this.clone_sign);
        userTabs.selectedIndex = 1;
        this.enableTabs(false, true, false, false, false);
      }
    }
    else if (userTabs.selectedIndex == 1) { //Roles tab
      if (action == 'save') {
        // this.userTabValidation(userTabs,'next');
        this.rolesTabValidation(userTabs, 'next');
      }
      else {
        this.frm_AddUsergroup.controls.frmArruserRoles = lodash.cloneDeep(this.clonedUserForm.controls.frmArruserRoles);
        this.frm_AddUsergroup.value.frmArruserRoles = lodash.cloneDeep(this.clonedUserForm.value.frmArruserRoles);
        // this.frm_sign = lodash.cloneDeep(this.clone_sign);
        if (this.moveTo == 'previous') {
          userTabs.selectedIndex = 0;
          this.enableTabs(true, false, false, false, false);
        }
        else {
          userTabs.selectedIndex = 2;
          this.enableTabs(false, false, true, false, false);
        }
        if (!this.editMode && this.POLOrg)
          this.setValidLocations(this.frm_AddUsergroup.controls.frmArruserRoles);
      }
    }
    else if (userTabs.selectedIndex == 2) { //Locations tab
      if (action == 'save') {
        await this.fetchUserAssociations();
        this.locationsTabValidation(userTabs, 'next');
      }
      else {
        this.frm_AddUsergroup.controls.frmArruserAccounts = lodash.cloneDeep(this.clonedUserForm.controls.frmArruserAccounts);
        this.frm_AddUsergroup.value.frmArruserAccounts = lodash.cloneDeep(this.clonedUserForm.value.frmArruserAccounts);
        if (this.moveTo == 'previous') {
          userTabs.selectedIndex = 1;
          this.enableTabs(false, true, false, false, false);
        }
        else {
          userTabs.selectedIndex = 3;
          this.enableTabs(false, false, false, true, false);
        }
      }
    }
    else {
      if (action == 'save') {
        if (this.moveTo == 'previous') {
          this.configTabValidation(userTabs);
        }
        else {
          this.saveUser(userTabs);
        }
      }
      else {
        // this.frm_AddUsergroup.controls = lodash.cloneDeep(this.clonedUserForm.controls);
        this.frm_AddUsergroup.patchValue(this.clonedUserForm.value)
        this.surgiCentersList = lodash.cloneDeep(this.clonedsurgicenterList);
        this.selectedSurgiCenters = lodash.cloneDeep(this.clonedselectedSurgiCenters);
        this.primaryLocation = lodash.cloneDeep(this.clonedPrimaryLocation);
        this.assocChecked = lodash.cloneDeep(this.clonedAssocChecked)
        userTabs.selectedIndex = 2;
        this.enableTabs(false, false, true, false, false);
      }
    }
  }

  detectUserDetailChange(clone, form) {
    const { frmInp_NPI, frmInp_title, frmInp_FirstName, frmInp_LastName,
      frmInp_MiddleInitial, frmInp_Gender, frmInp_Qualification, frmInp_UPIN
      , frmInp_SSN, frmInp_UserCode, frmInp_LoginName, frmInp_AllowLogin
      , frmInp_Address1, frmInp_Address2, frmInp_City, frmInp_State
      , frmInp_Country, frmInp_Zip, frmInp_Email, frmInp_Phone } = form.value
    return !(frmInp_NPI == clone.value.frmInp_NPI && frmInp_title == clone.value.frmInp_title &&
      frmInp_FirstName == clone.value.frmInp_FirstName && frmInp_LastName == clone.value.frmInp_LastName &&
      frmInp_MiddleInitial == clone.value.frmInp_MiddleInitial && frmInp_Gender == clone.value.frmInp_Gender &&
      frmInp_Qualification == clone.value.frmInp_Qualification && frmInp_UPIN == clone.value.frmInp_UPIN &&
      frmInp_SSN == clone.value.frmInp_SSN && frmInp_UserCode == clone.value.frmInp_UserCode &&
      frmInp_LoginName == clone.value.frmInp_LoginName && frmInp_AllowLogin == clone.value.frmInp_AllowLogin &&
      frmInp_Address1 == clone.value.frmInp_Address1 && frmInp_Address2 == clone.value.frmInp_Address2
      && frmInp_City == clone.value.frmInp_City && frmInp_State == clone.value.frmInp_State &&
      frmInp_Country == clone.value.frmInp_Country && frmInp_Zip == clone.value.frmInp_Zip &&
      frmInp_Email == clone.value.frmInp_Email && frmInp_Phone == clone.value.frmInp_Phone && this.clone_sign == this.frm_sign)
  }

  detectRoleDetailChange(clone, form) {
    return !lodash.isEqual(clone.value.frmArruserRoles, form.value.frmArruserRoles);
  }

  detectLocationDetailChange(clone, form) {
    return !lodash.isEqual(clone.value.frmArruserAccounts, form.value.frmArruserAccounts);
  }

  detectConfigDetailChange(clone, form) {
    return !(lodash.isEqual(clone.value, form.value) && lodash.isEqual(this.selectedSurgiCenters, this.clonedselectedSurgiCenters) && this.primaryLocation == this.clonedPrimaryLocation);
  }

  manageUserClose() {
    this.finishUserConfig();
  }

  async userTabValidation(userTabs, move?, final?) {
    // this.clearAlertsOnSave();
    this.ngxService.start();
    let form = this.frm_AddUsergroup.value;
    // });
    let message

    //*Mandatory fields: First Name, Last Name, Login Name, Region, Email !!
    if (this.organizationLoginType === 'LoginName') {
      if (form.frmInp_FirstName == "" || form.frmInp_LastName == "" || form.frmInp_LoginName == "" || form.frmInp_NPI == "") {
        // this.alertOnSave1 = true;
        message = 'Please enter all the mandatory fields';
      }
    }
    else {
      // || (form.frmInp_ST == false && form.frmInp_US == false)
      this.frm_AddUsergroup.controls.frmInp_LoginName.clearValidators();
      if (form.frmInp_FirstName == "" || form.frmInp_LastName == "" || form.frmInp_NPI == "") {
        // this.alertOnSave1 = true;
        !message ? message = 'Please enter all the mandatory fields' : null;
      }
    }


    if (this.organizationLoginType.toString().toLowerCase() == 'email' && this.frm_AddUsergroup.value.frmInp_AllowLogin) {
      if (form.frmInp_Email == "") {
        // this.alertOnSave1 = true;
        this.frm_AddUsergroup.controls['frmInp_Email'].setValidators([Validators.required]);
        this.frm_AddUsergroup.controls['frmInp_Email'].setErrors({ 'incorrect': true });
        !message ? message = 'Please enter all the mandatory fields' : null;
      }
    }



    //*Check if mandatory fields are entered to set controls as touched 
    if (message && this.frm_AddUsergroup) {
      Object.keys(this.frm_AddUsergroup.controls).forEach(key => {
        this.frm_AddUsergroup.get(key).markAsTouched();
      });
    }

    //LoginName Availability check
    // this.CheckLoginNameAvailability(this.frm_AddUsergroup.value.frmInp_LoginName);
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusercheckloginname");
    // this.addloginname = form.frmInp_LoginName;
    let drpqueryRequest = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "LoginName": form.frmInp_LoginName }
    };
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    let result: any = await this.vaservices.GetData(drpqueryRequest, depKey).toPromise();
    if (!result.errors && !message) {
      if (result.data.submenuData != undefined && result.data.submenuData != null) {
        if (result.data.submenuData.length > 0) {
          this.loginNamesList = result.data.submenuData;
          // this.loginNameExists = false;
          // this.alertOnSave7 = false;

          let num: number;
          for (num = 0; num < this.loginNamesList.length; num++) {
            if (this.loginNamesList[num].userid != null && this.loginNamesList[num].userid != "" && this.loginNamesList[num].userid != 0) {
              // this.loginNameExists = true;
              // this.alertOnSave7 = true;
              // (<HTMLElement>document.getElementById('commonAlertOnSave')).innerHTML = "*Login Name Exists! Enter new Login Name!!";
              if (!this.editMode)
                !message ? message = 'Login Name Exists! Enter new Login Name!!' : null;
            }
          }
        }
      }
    }

    if (form.frmInp_Email !=
      "" && !message) {
      var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (re.test(form.frmInp_Email) == false) {
        // this.alertOnSave4 = true;
        !message ? message = 'Please enter valid Email ID' : null;
        this.frm_AddUsergroup.patchValue(
          {
            frmInp_Email: ""
          });
      }
      else {
        if (this.organizationLoginType.toString().toLowerCase().trim() == 'email') {
          let data
          let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
          let associationType = sessionStorage.getItem("context")
          if (!this.IsPOL) {
            data = {
              "orgid": this.organizationID,
              "email": form.frmInp_Email,
              "ispol": 0
            };
          } else if (this.IsPOL) {
            data = {
              "orgid": this.organizationID,
              "email": form.frmInp_Email,
              "accid": sessionStorage.getItem("AccountID"),
              "associationtype": associationType,
              "ispol": 1
            }
          }
          this.ngxService.start();
          await this.vaservices.CheckDuplicateEmail(data, depKey).toPromise().then(res => {
            if (!res.errors) {
              if (res[0].Message == "true") {
                if (!this.editMode) {
                  !message ? message = "A user with the same email already exists. Try again with another email id." : null;
                }
                else if (res[0].commonkey != this.EditCommonKey) {
                  !message ? message = "A user with the same email already exists. Try again with another email id." : null;
                }
              }
            }
            this.ngxService.stop();
          }, error => {
            this.ngxService.stop();
            console.error(error);
          })
        }
      }
    }


    if (form.frmInp_Zip && !message) {
      var zip = /^\d{5}([\-]\d{4})?$/;
      if (zip.test(form.frmInp_Zip) == false) {
        // this.alertOnSave3 = true;
        this.frm_AddUsergroup.patchValue(
          {
            frmInp_Zip: ""
          });
        !message ? message = 'Please enter valid zip' : null
      }
    }

    if (form.frmInp_Phone && !message) {
      var phoneExp = /^[0-9+. -]+$/;
      if (phoneExp.test(form.frmInp_Phone) == false || form.frmInp_Phone.length > 10) {
        !message ? message = 'Please enter valid phone number' : null
      }
    }


    if (form.frmInp_NPI && form.frmInp_NPI.toString().toLowerCase() != "unknown" && !message) {
      if (form.frmInp_NPI.length < 10) {
        !message ? message = 'Please enter valid NPI' : null;
        this.frm_AddUsergroup.controls['frmInp_NPI'].setErrors({ 'incorrect': true });
      }
    }
    this.ngxService.stop();
    if (message) {
      this._snackBar.open(message, 'Close');
      return message;
    }
    else {
      this.clonedUserForm = lodash.cloneDeep(this.frm_AddUsergroup);
      this.clone_sign = lodash.cloneDeep(this.frm_sign);
      move && !final ? userTabs.selectedIndex = 1 : null;
      move && !final ? this.enableTabs(false, true, false, false, false) : null;
      !final ? this._snackBar.open('User details saved successfully!', 'Close') : null;
    }
  }


  async rolesTabValidation(userTabs, move?, final?) {
    this.ngxService.start();
    let form = this.frm_AddUsergroup.value

    var fnValidate_RoleSelected = false;
    var fnValidate_primarySelected = false;
    var fnValidate_HoldDurationTime = "0";
    var fnValidate_isPrimarybelongsToSelectedRole = false;
    var fnValidate_isLabManager = false;
    var fnValidate_iscytotechnologist = false;
    var fnValidate_iscyt = false;
    var fnValidate_isPathologist = false;
    let message
    let mn;
    for (mn = 0; mn < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; mn++) {
      var fnValidate_chkrole = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value;
      var fnValidate_rdnPrimaryRole = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmRd_UserRoles_RoleName'].value ? (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value : '';

      var fnValidate_txtHoldduration = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmInp_RoleHoldreleaseDuration'].value;

      if (fnValidate_chkrole == true) {
        fnValidate_RoleSelected = true;
      }
      if (fnValidate_rdnPrimaryRole != "") {
        fnValidate_primarySelected = true;
      }
      if (fnValidate_chkrole == true && fnValidate_rdnPrimaryRole != "") {
        fnValidate_isPrimarybelongsToSelectedRole = true;
      }
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Lab Manager'
        && fnValidate_chkrole == true) {
        fnValidate_isLabManager = true;
        fnValidate_HoldDurationTime = fnValidate_txtHoldduration?.toString();
      }
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Pathologist'
        && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
        fnValidate_isPathologist = true;
        fnValidate_HoldDurationTime = fnValidate_txtHoldduration?.toString();
      }
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist supervisor"
        && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
        fnValidate_iscyt = true;
        fnValidate_HoldDurationTime = fnValidate_txtHoldduration?.toString();
      }
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist"
        && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
        fnValidate_iscytotechnologist = true;
        fnValidate_HoldDurationTime = fnValidate_txtHoldduration?.toString();
      }
    }

    if ((fnValidate_isLabManager == true && fnValidate_HoldDurationTime?.toString() == '') || (fnValidate_isPathologist == true && fnValidate_HoldDurationTime?.toString() == '')
      || (fnValidate_iscytotechnologist == true && fnValidate_HoldDurationTime?.toString() == '') || (fnValidate_iscyt == true && fnValidate_HoldDurationTime?.toString() == '')) {
      // this.alertOnSaveCommon = true;
      // (<HTMLElement>document.getElementById('commonAlertOnSave')).innerHTML = "*Please enter Lab Manager Hold duration time in minutes (0-99)!!";
      message = "Please enter hold duration time in minutes (0-60)";
      // return false;
    }

    if (fnValidate_RoleSelected == false) {
      // this.alertOnSaveCommon = true;
      !message ? message = 'Please select at least one Role!' : null;
      // return false;
    }

    if (fnValidate_primarySelected == false) {
      // this.alertOnSaveCommon = true;
      !message ? message = 'Please select primary role!' : null;
    }

    if (fnValidate_isPrimarybelongsToSelectedRole == false) {
      // this.alertOnSaveCommon = true;
      !message ? message = "Primary role selected is invalid!" : null;
      // return false;
    }

    if (this.hidIsPathlogistSelected == true) {
      var IsMedicalDirectorExists_isMedicalDirector = false;
      var IsMedicalDirectorExists_status;
      var IsMedicalDirectorExists_userName;
      var IsMedicalDirectorExists_commonKey;
      var IsMedicalDirectorExists_userID;

      //IsMedicalDirectorExists
      let data = await this.vaservices.GetIsMedicalDirectorExists(this.SelectedOrgID.toString(), "0").toPromise();
      // var jsonResult: any = JSON.stringify(data);
      // jsonResult = JSON.parse(jsonResult);
      var jsonResult: any = data;
      if (jsonResult != undefined && jsonResult != null) {
        if (jsonResult.length > 0) {
          IsMedicalDirectorExists_isMedicalDirector = jsonResult[0].IsMedicalDirector;
          IsMedicalDirectorExists_status = jsonResult[0].Status;
          IsMedicalDirectorExists_userID = jsonResult[0].UserID;
          IsMedicalDirectorExists_userName = jsonResult[0].UserName;
          IsMedicalDirectorExists_commonKey = jsonResult[0].CommonKey;
        }
        else {
          IsMedicalDirectorExists_isMedicalDirector = false;
          IsMedicalDirectorExists_status = "";
          IsMedicalDirectorExists_userID = "";
          IsMedicalDirectorExists_userName = "";
          IsMedicalDirectorExists_commonKey = "";
        }
      }
      else {
        IsMedicalDirectorExists_isMedicalDirector = false;
        IsMedicalDirectorExists_status = "";
        IsMedicalDirectorExists_userID = "";
        IsMedicalDirectorExists_userName = "";
        IsMedicalDirectorExists_commonKey = "";
      }

      if (IsMedicalDirectorExists_isMedicalDirector == true && this.hidIsChecked == true && this.medicaldirectorChecked == true) {
        // this.alertOnSaveCommon = true;
        !message ? message = "Medical Director already exist!" : null;
        // return false;
      }
      if (IsMedicalDirectorExists_isMedicalDirector && this.hidIsChecked == true && this.medicaldirectorChecked == true
        && IsMedicalDirectorExists_commonKey != this.CommonKey) {
        if (IsMedicalDirectorExists_status == "active") {
          // this.alertOnSaveCommon = true;
          !message ? message = "Medical Director already exist!!" : null;
          // return false;
        }
        else if (IsMedicalDirectorExists_status == "inactive") {
          // this.alertOnSaveCommon = true;
          !message ? message = "There can be only one Medical Director for an organization!. " + IsMedicalDirectorExists_userName + " is already associated as Medical Director for your Organization. Please unselect the Medical Director option to proceed further!" : null;
          // return false;
        }
      }
    }
    if (!final) {
      if (message) {
        this._snackBar.open(message, 'Close');
      }
      else {
        this.clonedUserForm.controls.frmArruserRoles = lodash.cloneDeep(this.frm_AddUsergroup.controls.frmArruserRoles);
        this.clonedUserForm.value.frmArruserRoles = lodash.cloneDeep(this.frm_AddUsergroup.value.frmArruserRoles);

        if (move) {
          if (this.moveTo == 'previous') {
            userTabs.selectedIndex = 0;
            this.enableTabs(true, false, false, false, false);

          }
          else {
            userTabs.selectedIndex = 2;
            this.enableTabs(false, false, true, false, false);
          }
        }
        this._snackBar.open('Roles saved successfully!', 'Close');
      }
      this.ngxService.stop();
    }
    else {
      this.ngxService.stop();
      if (message) {
        return message;
      }
      return message;
    }
  }

  captureHoldDuration(data, event) {
    data.patchValue({
      frmInp_RoleHoldreleaseDuration: event.target.value
    })
  }

  checkRolesSelected(data) {
    if (this.editMode) {
      return data && data.some(va => (va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'cytotechnologist supervisor' || va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'cytotechnologist' || va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'pathologist') && (va.frmChk_UserRoles_RoleName != false));
    }
    else {
      return data && data.some(va => (va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'cytotechnologist supervisor' || va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'cytotechnologist' || va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'pathologist') && (va.frmChk_UserRoles_RoleName == true));
    }
  }

  saveTabData(userTabs) {
    if (userTabs.selectedIndex == 0) { //Users Tab
      this.userTabValidation(userTabs)
    }
    else if (userTabs.selectedIndex == 1) { // Roles Tab
      this.rolesTabValidation(userTabs)
    }
    else if (userTabs.selectedIndex == 2) { // Locations Tab
      this.locationsTabValidation(userTabs);
    }
    else { //Config Tab
      this.moveTo = 'next';
      this.alertPopUp = true;
    }
  }


  locationsTabValidation(userTabs, move?, final?) {
    this.ngxService.start();
    var fnValidate_isPathologist = false;
    var fnValidate_chkAccountcount: number = 0;
    var fnValidate_cnt: number = 0;
    let k
    let message
    fnValidate_isPathologist = this.frm_AddUsergroup.value.frmArruserRoles.some((va: any) => va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'pathologist' && va.frmChk_UserRoles_RoleName != false)
    for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true) {
        fnValidate_chkAccountcount++;
        var fnValidate_drpwrktext = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_Works'].value;
        if (fnValidate_drpwrktext.toString() == "true" && fnValidate_isPathologist == true) {
          fnValidate_cnt++;
        }
      }
    }
    if (this.hidIsPathlogistSelected == true || this.hidIsCytoSelected == true || this.hidIsCytoSupervisiorSelected == true) {
      var fnValidate_drpWorksSelected = 'false';
      for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
        if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true) {
          fnValidate_drpWorksSelected = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_Works'].value.toString();
        }
        if (fnValidate_drpWorksSelected == 'true') {
          break;
        }
      }
      if (fnValidate_drpWorksSelected == 'false') {
        !message ? message = "Select at least one reading location!" : null;
      }
    }

    if (fnValidate_chkAccountcount == 0) {
      !message ? message = "Please select at least one Location!" : null;

    }
    if (fnValidate_cnt > 1) {
      !message ? message = "Only one account can be marked as true!" : null;
    }

    if (!final) {
      if (message) {
        this._snackBar.open(message, 'Close');
      }
      else {
        this.clonedUserForm.controls.frmArruserAccounts = lodash.cloneDeep(this.frm_AddUsergroup.controls.frmArruserAccounts);
        this.clonedUserForm.value.frmArruserAccounts = lodash.cloneDeep(this.frm_AddUsergroup.value.frmArruserAccounts);

        if (move) {
          if (this.moveTo == 'previous') {
            userTabs.selectedIndex = 1;
            this.enableTabs(false, true, false, false, false);
          }
          else {
            userTabs.selectedIndex = 3;
            this.enableTabs(false, false, false, true, false);
          }
        }
        this.locationsList = [];
        for (let k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
          if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true &&
            (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmLbl_LocationContext'].value.toString().toLowerCase().trim() == 'of') {
            this.locationsList.push({ accountid: (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmLblchk_Location'].value, accountname: (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmLbl_Location'].value })
          }
        }
        this.setPrimaryLocation(this.frm_AddUsergroup.value.frmArruserAccounts);
        this._snackBar.open('Locations saved successfully!', 'Close');
      }
      this.ngxService.stop();
    }
    else {
      this.ngxService.stop();
      if (message) {
        return message;
      }
      return message;
    }
  }

  enableTabs(userTab, roleTab, locationTab, configTab, summaryTab) {
    this.userTabDisable = !userTab
    this.roleTabDisable = !roleTab
    this.LocTabDisable = !locationTab;
    this.configTabDisable = !configTab;
    this.summaryTabDisable = !summaryTab;
  }


  licensure(value: string, index: number) {
    if (value == "add") {
      const frm = this._fb.group({
        frmDrp_LicsenceStates: new FormControl("Select"),
        frmDrp_LicsenceDate: ""
      });

      (<FormArray>this.frm_AddUsergroup.get('formLicensure')).push(frm);
    }
    else if (value == "delete") {
      if ((<FormArray>this.frm_AddUsergroup.get('formLicensure')).length > 1) {
        (<FormArray>this.frm_AddUsergroup.get('formLicensure')).removeAt(index);
      }
    }
  }


  checkForNotifUsers(data) {
    return data && data.some(va => (va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'cytotechnologist supervisor' || va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'cytotechnologist') && (va.frmChk_UserRoles_RoleName != false));
  }

  checkForPopulateDiag(data) {
    return data && data.some(va => (va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'physician' || va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'pathologist') && va.frmChk_UserRoles_RoleName != false);
  }

  checkallowPathologistDiagnosisTemplate(data) {
    return this.checkForEditSite(data);
  }

  checkForEditSite(data) {
    if (!this.editMode) {
      return data && data.some(va => (va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'pathologist') && (va.frmChk_UserRoles_RoleName == true));
    }
    return data && data.some(va => (va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'pathologist') && (va.frmChk_UserRoles_RoleName != false));
  }

  checkCytotechSelection(data) {
    return data && data.some(va => (va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'cytotechnologist') && (va.frmChk_UserRoles_RoleName != false));
  }

  checkCytotechSuperSelection(data) {
    return data && data.some(va => (va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'cytotechnologist supervisor') && (va.frmChk_UserRoles_RoleName != false));
  }

  checkForPhysicianSelection(data, locations) {
    // return false;
    let flag = this.surgicenterList &&
      this.surgicenterList.length > 0 &&
      data && data.length > 0 &&
      locations && locations.some(va => va.frmLbl_LocationContext.toString().toLowerCase().trim() == 'of' && va.frmchk_Location);
    if (this.editMode) {
      return data.some(va => (va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'physician')
        && (va.frmChk_UserRoles_RoleName != false)) && flag;
    }
    else {
      return data.some(va => (va.frmLblChk_UserRoles_RoleName.toString().toLowerCase().trim() == 'physician')
        && (va.frmChk_UserRoles_RoleName != false)) && flag;
    }
  }

  checkForPhysician(data) {
    if (this.editMode) {
      return data && this.frm_AddUsergroup.controls.frmPhysician['controls'].length > 0 && data.some(va => (va.controls && va.controls.frmLblChk_UserRoles_RoleName.value && va.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() == 'medical assistant') && va.controls.frmChk_UserRoles_RoleName.value == true && ((va.controls.frmChk_UserRoles_RoleName.disabled == true && va.controls.frmLbl_usrStatus.value == true) || (va.controls.frmChk_UserRoles_RoleName.disabled == false)));
    }
    return data && this.frm_AddUsergroup.controls.frmPhysician['controls'].length > 0 && data.some(va => (va.controls && va.controls.frmLblChk_UserRoles_RoleName.value && va.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() == 'medical assistant') && va.controls.frmChk_UserRoles_RoleName.value == true);

  }


  physicianSelectedCheck(data) {
    if (this.editMode) {
      return data && data.some(va => (va.controls && va.controls.frmLblChk_UserRoles_RoleName.value && va.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() == 'physician') && va.controls.frmChk_UserRoles_RoleName.value == true && ((va.controls.frmChk_UserRoles_RoleName.disabled == true && va.controls.frmLbl_usrStatus.value == true) || (va.controls.frmChk_UserRoles_RoleName.disabled == false)));
    }
    return data && data.some(va => (va.controls && va.controls.frmLblChk_UserRoles_RoleName.value && va.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() == 'physician') && va.controls.frmChk_UserRoles_RoleName.value == true);

  }

  checkForPathologist(data) {
    if (this.editMode) {
      return data && this.frm_AddUsergroup.controls.frmPathologist['controls'].length > 0 && data.some(va => (va.controls && va.controls.frmLblChk_UserRoles_RoleName.value && va.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() == 'pathologist assistant') && va.controls.frmChk_UserRoles_RoleName.value == true && ((va.controls.frmChk_UserRoles_RoleName.disabled == true && va.controls.frmLbl_usrStatus.value == true) || (va.controls.frmChk_UserRoles_RoleName.disabled == false)));
    }
    return data && this.frm_AddUsergroup.controls.frmPathologist['controls'].length > 0 && data.some(va => (va.controls && va.controls.frmLblChk_UserRoles_RoleName.value && va.controls.frmLblChk_UserRoles_RoleName.value.toString().toLowerCase().trim() == 'pathologist assistant') && va.controls.frmChk_UserRoles_RoleName.value == true);
  }

  checkAllowReviewPathologist(data) {
    return this.checkForEditSite(data);
  }

  checkAllowtoeditAccession(data) {
    return this.checkForEditSite(data);
  }

  checkLicensure(data) {
    return this.checkForEditSite(data);
  }

  checkThreshold(data) {
    return this.checkForEditSite(data);
  }

  checkSelectedTab() {
    return !(this.userTabs.selectedIndex == 3 && this.moveTo == 'next');
  }


  async saveUser(userTabs) {
    this.ngxService.start('1');
    let message = this.configTabValidation(userTabs);
    if (message) {
      this._snackBar.open(message, 'Close');
      this.ngxService.stop('1');
      return;
    }
    else {
      let message
      message = await this.userTabValidation(userTabs, 'next', 'final');
      if (message) {
        this._snackBar.open(message, 'Close');
        userTabs.selectedIndex = 0;
        this.enableTabs(true, false, false, false, false);
        this.ngxService.stop('1');
        return;
      }
      message = await this.rolesTabValidation(userTabs, 'next', 'final');
      if (message) {
        this._snackBar.open(message, 'Close');
        userTabs.selectedIndex = 1;
        this.enableTabs(false, true, false, false, false);
        this.ngxService.stop('1');
        return;
      }
      message = await this.locationsTabValidation(userTabs, 'next', 'final');
      if (message) {
        this._snackBar.open(message, 'Close');
        userTabs.selectedIndex = 2;
        this.enableTabs(false, false, true, false, false);
        this.ngxService.stop('1');
        return;
      }
      // this.createUser()
      let userJson

      if (this.editMode) {
        userJson = await this.editgenerateSaveJson()
      }
      else {
        userJson = await this.generateSaveJson();
      }

      this.saveOutOfOfficeData(userJson);
      this.saveThresholdDetails(userJson);
      this.saveSurgiCenterDetails(userJson);
      this.ngxService.stop('1');
      try {
        this.saveUserData(userJson, userTabs);
      }
      catch {
        this.ngxService.stop('1');
        this._snackBar.open('An error occured while processing your request. Please try again!', 'Close');
      }
    }
  }

  saveSurgiCenterDetails(form) {
    let surgicenterData = lodash.cloneDeep(this.clonedselectedSurgiCenters)

    if (!this.assocChecked && !this.editMode) {
      surgicenterData = [];
    }
    if (this.editMode) {
      let differenceArray = this.selectDeletedSurgiCenters(surgicenterData);
      differenceArray = differenceArray.map((va => ({ ...va, isdelete: true })));

      surgicenterData = [...surgicenterData, ...differenceArray]
      if (!this.assocChecked && surgicenterData && surgicenterData.length > 0) {
        surgicenterData = surgicenterData.filter(va => Object.keys(va).includes('isprimarylocation'))
        surgicenterData = surgicenterData.map(((va: any) => ({ ...va, isdelete: true })));
      }
    }
    if (surgicenterData.length == 0) {
      this.setPrimaryLocation(this.frm_AddUsergroup.value.frmArruserAccounts)
      if (!this.assocChecked && this.physicianSelectedCheck(this.frm_AddUsergroup.controls.frmArruserRoles['controls'])) {
      }
      surgicenterData.push({ primarylocation: this.primaryLocation, "modifiedby": sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid').toString() : '-100' })
    }

    surgicenterData = this.convertObjKeysToLower(surgicenterData);
    surgicenterData = surgicenterData.map((va => ({ ...va, "commonkey": this.editMode ? this.EditCommonKey : form["commonKey"], "createdby": sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid').toString() : '-100', "modifiedby": sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid').toString() : '-100', "primarylocation": this.primaryLocation })));

    this.surgiAssociationData = surgicenterData;

  }

  setPrimaryLocation(formControlArray) {
    this.ngxService.stop('1');
    let selectedAccounts = lodash.cloneDeep(formControlArray.filter(va =>
      va.frmchk_Location == true && va.frmLbl_LocationContext.toString().toLowerCase().trim() == 'of'
    ))
    selectedAccounts.sort((a, b) => {
      const nameA = a.frmLbl_Location.toUpperCase(); // ignore upper and lowercase
      const nameB = b.frmLbl_Location.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    })
    if (!this.primaryLocation)
      this.primaryLocation = selectedAccounts[0] ? String(selectedAccounts[0].frmLblchk_Location) : '';
  }



  selectDeletedSurgiCenters(surgicenterData) {
    if (surgicenterData && surgicenterData.length > 0) {
      return this.backupselectedSurgiCenters.filter((va: any) => !surgicenterData.some((val: any) => val.accountid == va.accountid));
    }
    return this.backupselectedSurgiCenters;
  }

  saveThresholdDetails(form) {
    this.thresholdDetails = {}
    if (form.threshold) {
      this.thresholdDetails = {
        "threshold": form.threshold,
        "thresholdduration": form.thresholdDuration.toLowerCase() !='select'?form.thresholdDuration:null,
        "commonkey": this.editMode ? this.EditCommonKey : form.commonKey,
        "readcomputeon": form.readcomputeon
      }
    }
    if (form.reviewthreshold) {
      this.thresholdDetails["reviewthreshold"] = form.reviewthreshold,
        this.thresholdDetails["reviewduration"] = form.reviewduration.toLowerCase() !='select'?form.reviewduration:null,
        this.thresholdDetails["reviewcomputeon"] = form.reviewcomputeon,
        this.thresholdDetails["commonkey"] = this.editMode ? this.EditCommonKey : form.commonKey
    }

    if (form.screeningthreshold) {
      this.thresholdDetails["screeningthreshold"] = form.screeningthreshold,
        this.thresholdDetails["screeningduration"] = form.screeningduration.toLowerCase() !='select'?form.screeningduration:null,
        this.thresholdDetails["screeningcomputeon"] = form.screeningcomputeon,
        this.thresholdDetails["commonkey"] = this.editMode ? this.EditCommonKey : form.commonKey
    }
  }

  async editgenerateSaveJson() {
    let form = this.frm_AddUsergroup.value;
    this.userData.loginname = form.frmInp_LoginName;
    //get logged-in user id
    let loggedIn_userID = sessionStorage.getItem('Userid');
    let roleList = "";
    let selected_primaryrole = "";
    let holdReleaseDurationvalue = "";
    let holdselected_primaryrole = "";
    //"Lab Manager,Claim tracker";
    for (let i = 0; i < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; i++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_UserRoles_RoleName'].value == true) {
        roleList += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value + ",";
        holdReleaseDurationvalue += ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].value || "0") + '|' + (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value + ",";
      }
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].value) {
        selected_primaryrole = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value;
        holdselected_primaryrole = ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].value || 0) + '|' + (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value;
      }
    }

    //holdduration list
    selected_primaryrole = selected_primaryrole || this.selectedPrimaryRole + ',0'
    holdselected_primaryrole = holdselected_primaryrole || this.selectedPrimaryRole + ',0'
    let arraylist1: string[];
    arraylist1 = holdReleaseDurationvalue.split(',');
    let listrole1: string[];
    listrole1 = arraylist1.filter(item => item !== holdselected_primaryrole)
    let role1 = String(listrole1);
    holdReleaseDurationvalue = holdselected_primaryrole + ',' + role1;
    holdReleaseDurationvalue = holdReleaseDurationvalue.substring(0, holdReleaseDurationvalue.length - 1);
    //rolelist ;
    let arraylist: string[];
    arraylist = roleList.split(',');
    let listrole: string[];
    listrole = arraylist.filter(item => item !== selected_primaryrole)
    let role = String(listrole);
    roleList = selected_primaryrole + ',' + role;
    roleList = roleList.substring(0, roleList.length - 1);
    let review_data = [];
    let accountList = "";
    // "5:1,6:1,20029:0,26112:0,26275:0";
    for (let j = 0; j < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; j++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmchk_Location'].value as boolean == true) {
        accountList += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value + ":1,";
      }
      else {
        accountList += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value + ":0,";
      }
      //review data
      let obj = {
        "userID": this.userData.userid,
        "AccountID": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value,
        "sendforreview": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmDrp_SendforReview'].value == "true" ? true : false,
        "works": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmDrp_Works'].value == true ? true : false,
        "manages": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmDrp_Manages'].value == "true" ? true : false,
        "RoleName": selected_primaryrole,
        "primaryrole": selected_primaryrole != "" ? true : false,
        "CanPathologistModifyAccession": form.frmInp_AllowtoeditAccession,
        "CanPathologistModify": form.frmInp_EditSite,
        "ModifiedBy": loggedIn_userID ? loggedIn_userID : "-100"
      };
      review_data.push(obj);
    }
    accountList = accountList.substring(0, accountList.length - 1);

    //check if user is medical director
    let MD_val = false;

    if (this.frm_AddUsergroup.controls.frmArruserRoles.value.some((va: any) => va.frmChk_UserRoles_RoleName != false &&
      va.frmLblChk_UserRoles_RoleName.toString().match(/pathologist/i) && va.frmChk_IsMedicalDirector == true)) {
      MD_val = true;
    }


    //check for hold release duration value
    let HD_val: number = 0;
    if (this.HRD_KValue != -1) {
      let user_hd_val = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(this.HRD_KValue)).controls['frmInp_RoleHoldreleaseDuration'].value;
      if (user_hd_val != null && user_hd_val != "") {
        HD_val = parseInt(user_hd_val);
      }
    }

    //convert threshold value to number
    let threshold_value: number = 0;
    let reviewThreshold_value: number = 0;
    if (form.frmInp_Threshold != null && form.frmInp_Threshold != "") {
      threshold_value = parseInt(form.frmInp_Threshold);
    }

    if (form.frmInp_ReviewThreshold != null && form.frmInp_ReviewThreshold != "") {
      reviewThreshold_value = parseInt(form.frmInp_ReviewThreshold);
    }

    //check if primary role has changed
    let isPrimaryRoleChanged = false;
    if (this.hidPrimaryRole != "" && selected_primaryrole != "") {
      isPrimaryRoleChanged = true;
    }

    //get licensure details
    let lic_details: string = "";
    if (this.hidIsPathlogistSelected) {
      lic_details = "<ArrayOfPathologistLicensure>";
      for (let a = 0; a < (<FormArray>this.frm_AddUsergroup.get('formLicensure')).length; a++) {
        var PathLic_Statefullname: string = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(a)).controls['frmDrp_LicsenceStates'].value && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(a)).controls['frmDrp_LicsenceStates'].value != 'Select' ? (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(a)).controls['frmDrp_LicsenceStates'].value : '';
        var PathLic_Expirydate = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(a)).controls['frmDrp_LicsenceDate'].value;
        var PathLic_State: string = PathLic_Statefullname.substring(0, PathLic_Statefullname.indexOf(' '));
        PathLic_State = PathLic_State === 'Select' ? 'null' : PathLic_State;
        PathLic_Expirydate = this.datepipe.transform(PathLic_Expirydate, 'MM/dd/yyyy');
        if (!PathLic_Statefullname || !PathLic_Expirydate) {
          continue;
        }

        //build xml string
        lic_details += "  <PathologistLicensure>";
        lic_details += "  <id>" + (a + 1) + "</id>";
        lic_details += "  <state>" + PathLic_State + "</state>";
        lic_details += "  <Expirydate>" + PathLic_Expirydate + "</Expirydate>";
        lic_details += "  <Statefullname>" + PathLic_Statefullname + "</Statefullname>";
        lic_details += "  </PathologistLicensure>";
      }
      lic_details += "  </ArrayOfPathologistLicensure>";
    }
    let orgid = this.userData.Organizationid;
    var reg = null;
    if (form.frmInp_US && form.frmInp_ST) {
      reg = 'US,ST'
    }
    else if (form.frmInp_ST) {
      reg = 'ST'
    }
    else if (form.frmInp_US) {
      reg = 'US'
    }
    let imageSrcString
    if (this.frm_sign) {
      imageSrcString = await this.toDataURL(this.frm_sign)
      imageSrcString = imageSrcString.toString().split(",")[1];
    }


    let roleStatusData = [];
    if (this.editMode) {
      for (let j = 0; j < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; j++) {
        //review data
        if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(j)).controls['frmLbl_userID'].value) {
          let obj = {
            "userID": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(j)).controls['frmLbl_userID'].value,
            "status": form.frmInp_Status ? (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(j)).controls['frmLbl_usrStatus'].value : false,
            "RoleName": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(j)).controls['frmLblChk_UserRoles_RoleName'].value
          };
          roleStatusData.push(obj);
        }
      }
    }

    this.worksAccount = this.frm_AddUsergroup.controls.frmArruserAccounts.value.filter((va: any) => this.checkRolesSelected(this.frm_AddUsergroup.value.frmArruserRoles) && va.frmchk_Location && va.frmDrp_Works)
    let sendJson = {
      "title": form.frmInp_title,
      "fname": form.frmInp_FirstName,
      "lname": form.frmInp_LastName,
      "midInitial": form.frmInp_MiddleInitial,
      "loginName": form.frmInp_LoginName,
      "eduQual": form.frmInp_Qualification,
      "gender": form.frmInp_Gender == "1" ? true : false,
      "npi": form.frmInp_NPI,
      "address1": form.frmInp_Address1,
      "address2": form.frmInp_Address2,
      "street": form.frmInp_Street,
      "city": form.frmInp_City,
    //  "state": form.frmEditInp_State,
    "state":form.frmInp_State,
      "zip": form.frmInp_Zip,
      "country": form.frmInp_Country,
      "phone": form.frmInp_Phone,
      "fax": form.frmInp_Fax,
      "email": form.frmInp_Email,
      "status": form.frmInp_Status ? 'Active' : 'InActive',
      "rolename": this.rolename,
      "userNamedisplayFormat": form.frmInp_DisplayFormatWebpage,
      "userNamedescriptionFormat": form.frmInp_DisplayFormatDescription,
      "usernameReportformat": form.frmInp_DisplayFormatReports,
      "password": this.Editpassword,
      "defaultPage": "VAPreferences.aspx",
      "ssn": form.frmInp_SSN,
      "upin": form.frmInp_UPIN,
      "userCode": form.frmInp_UserCode,
      "sign": imageSrcString ? imageSrcString : null,
      "commonKey": this.EditCommonKey,
      "isAccLockedOut": false,
      "allowPathologistReview": !form.frmInp_AllowReviewPathologist ? false : form.frmInp_AllowReviewPathologist as boolean,
      "isModifyTechnicalDataAccession": !form.frmInp_AllowtoeditAccession ? false : form.frmInp_AllowtoeditAccession as boolean,
      "modifyTechnicalData": !form.frmInp_EditSite ? false : form.frmInp_EditSite as boolean,
      "roleList": roleList,
      "isPrimaryRoleChanged": isPrimaryRoleChanged,
      "works": this.worksAccount && this.worksAccount.length > 0 ? this.worksAccount[0].frmLblchk_Location : null,
      "manages": form.frmDrp_OrgManages == 'true' ? true : false,
      "sendForreview": form.frmDrp_OrgSendforReview == 'true' ? true : false,
      "accountList": accountList,
      "pathReadingLoc": "5",
      "newRolesAdded": false,
      "medicalDirector": MD_val as boolean,
      "associateAccID": null,
      "deassociateAccID": null,
      "organizationID": orgid,
      "primaryLocation": 0,
      "showMyCaseOnly": !form.frmInp_ShowMyCaseOnly ? false : form.frmInp_ShowMyCaseOnly as boolean,
      "alowPathologistDiagnosisTemplate": !form.frmInp_AllowPathologistDiagnosisTemplate ? false : form.frmInp_AllowPathologistDiagnosisTemplate as boolean,
      "diagICDcodeReminder": !form.frmInp_Notifyusers ? false : form.frmInp_Notifyusers as boolean,
      "autoPopulateDiagICD9Codes": !form.frmInp_PopulateDiag ? false : form.frmInp_PopulateDiag as boolean,
      "EnableSignOutCorrection": !form.frmChk_EnableSignOutCorrection ? false : true,
      "IsPathologistPrivileges": !form.frmChk_IsPathologistPrivileges ? false : true,
      "region": reg,
      "associationType": null,
      "externalUserGUID": form.frmInp_ExternalUserGUID,
      "userlicensure": lic_details,
      "HoldReleaseDurationInfo": holdReleaseDurationvalue,
      "holdReleaseDuration": HD_val,
      "threshold": threshold_value,
      "thresholdDuration": form.frmDrp_ThresholdDuration.toLowerCase()!='select'?form.frmDrp_ThresholdDuration:null,
      "readcomputeon": form.frmDrp_ReadComputeOn,
      "reviewthreshold": form.frmInp_ReviewThreshold,
      "reviewduration": form.frmDrp_ReviewDuration,
      "reviewcomputeon": form.frmDrp_ReviewComputeOn,
      "screeningthreshold": form.frmInp_ScreeningThreshold,
      "screeningduration": form.frmDrp_ScreeningDuration,
      "screeningcomputeon": form.frmDrp_ScreeningComputeOn,
      "createdby": sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid').toString() : '-100',
      "modifiedby": loggedIn_userID ? loggedIn_userID : "-100",
      "userid": this.userData.userid,
      "loggedInUserId": loggedIn_userID ? loggedIn_userID : "-100",
      "entityType": "EditUser",
      "entityID": "",
      "actionperformed": "Edit User",
      "actiondetails": "",
      "auditDate": new Date(),
      "EntityData": null,
      "reviewData": review_data,
      "RoleData": roleStatusData
    };
    sendJson["allowLogin"] = this.organizationLoginType == 'Email' ? form.frmInp_AllowLogin : false;
    return sendJson;
  }



  compareIntervals(index, startDate, stopDate) {
    for (let i = 0; i < this.frm_AddUsergroup.value.frmOutOfOffice.length; i++) {
      if (index != i) {
        if (areIntervalsOverlapping({ start: startDate, end: stopDate }, { start: (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).at(i)).controls['frm_startdate'].value, end: (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).at(i)).controls['frm_enddate'].value })) {
          return true;
        }
      }
    }
    return false;
  }
  saveOutOfOfficeData(data) {
    let outOfOfficeArray = [];
    for (let k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).length; k++) {
      var id = null;
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).at(k)).controls['frm_id']) {
        id = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).at(k)).controls['frm_id'].value;
      }
      var startDate = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).at(k)).controls['frm_startdate'].value;
      var stopDate = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).at(k)).controls['frm_enddate'].value;

      if (!startDate && !stopDate) {
        break;
      }

      let numberofdays = Math.floor((stopDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24) + 1;

      startDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
      stopDate = this.datepipe.transform(stopDate, 'MM/dd/yyyy');


      let dataObject = {
        "id": id ? id : null,
        "commonkey": this.editMode ? this.EditCommonKey : data.commonKey,
        "actionby": Number(data.loggedInUserId),
        "startdate": startDate,
        "stopdate": stopDate,
        "numberofdays": numberofdays
      }

      outOfOfficeArray.push(dataObject);
    }
    this.outOfOfficeArray = [];
    if (this.editMode) {
      let deletedOutOfOfficeArray = this.existingOutOfOfficeDetails.filter(va => !(outOfOfficeArray.some(val => val.id == va.id)))
      deletedOutOfOfficeArray.forEach(va => {

        startDate = this.datepipe.transform(va.startdate, 'MM/dd/yyyy');
        stopDate = this.datepipe.transform(va.enddate, 'MM/dd/yyyy');

        let dataObject = {
          "id": va.id,
          "startdate": startDate,
          "commonkey": this.editMode ? this.EditCommonKey : data.commonKey,
          "actionby": Number(data.loggedInUserId),
          "stopdate": stopDate,
          "numberofdays": va.numberofdays,
          "isdeleted": 1
        }
        outOfOfficeArray.push(dataObject);
      })
    }
    this.outOfOfficeArray = outOfOfficeArray;


  }


  async saveUserData(data, userTabs) {
    this.ngxService.start("1");
    // this.getUserDetails(data, userTabs);
    // this.ngxService.stop("1");
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    // data.roleList = 'Accessioner,Lab Administrator'
    this.vaservices.SaveAddUserToOrg(data, depKey).subscribe(async result => {
      if (!result.errors) {
        // this.counts = -1;
        if (result.Success == false) {
          if (data.actionperformed == 'Edit User') {
            this._snackBar.open("User updation failed", 'Close');
          }
          else {
            this._snackBar.open("User creation failed", 'Close');
          }
          console.error(result.Message);
        }
        else {
          if (this.userAssocFlag) {
            await this.associateUsers(data, depKey);
          }
          if (this.outOfOfficeArray.length > 0) {
            await this.userOutOfOffice(this.outOfOfficeArray, depKey);
          }
          if (this.thresholdDetails && (this.thresholdDetails.threshold || this.thresholdDetails.reviewthreshold || this.thresholdDetails.screeningthreshold))
            await this.userThresholdDetails(this.thresholdDetails, depKey);
           await this.saveSurgi(this.surgiAssociationData, depKey);
          if (this.editMode) {
            this.actionBtn.allowLogin = data.allowLogin
            if (this.statuslist.length > 0) {
              this.UpdateUserStatus();
            }

            this.getUserDetails(data, userTabs);

            if (this.fromComponent != "allusers") {

              this.actionBtn.refresh();

            }
            //audit
            this.commonService.createActivityTracker('User', this.userData.userid, 'User', 'Audit', data, this.usersdeatils);
            this._snackBar.open("User updated Successfully", 'Success');
            // this.ngxService.stop("1");

          }
          else {
            if (this.LoginTypesflag == true) {
              this._snackBar.open(result.Message, 'Success');
              this.enableTabs(false, false, false, false, true);
              // if (this.saveandcreateflag == true) {
              //   this.ngxService.stop("1");
              //   this.resetAddUserDetails();
              //   this.SelectedModal("AddUser");
              // }
              // else {
              // this.refreshAddData();
              this.getUserDetails(data, userTabs);
              // this.ngxService.stop("1");

              // this.loginDeatilsData();

              // }


            }
            else {
              this.enableTabs(false, false, false, false, true);
              this.organizationLoginType.toString().match(/email/i) && data.email ? this._snackBar.open("Invitation Sent Successfully", 'Success') : this._snackBar.open("User created successfully", "Close");
              // this.refreshAddData();
              this.getUserDetails(data, userTabs);
            }
            // this.ngxService.stop("1");
          }
        }
        this.ngxService.stop("1");
      }
    }, error => {
      this._snackBar.open("An error occurred while processing your request", 'Failed');
      console.info(error);
      this.ngxService.stop("1");
    });
  }

  async saveSurgi(surgiAssociationData, depKey) {
    if (surgiAssociationData && surgiAssociationData.length > 0) {
      try {
        let result = await this.vaservices.associateSurgi(surgiAssociationData, depKey).toPromise();
        if (!result.Success) {
          console.error("SurgiCenter association failed");
        }
      }
      catch {
        console.error("SurgiCenter association failed");
      }
    }
  }

  async associateUsers(data, depKey) {
    let physAssoc = this.checkForPhysician(this.frm_AddUsergroup.controls.frmArruserRoles['controls']) ? this.savePhysicanAssociation() : [];
    let pathAssoc = this.checkForPathologist(this.frm_AddUsergroup.controls.frmArruserRoles['controls']) ? this.savePathologistAssociation() : [];
    let associationJson: any = {};
    // "92B5C895-FE7C-47C4-A6B3-700F78D8803D"
    associationJson.commonKey = this.editMode ? this.EditCommonKey : data.commonKey
    associationJson.physicianAssociation = physAssoc;
    associationJson.pathologistAssociation = pathAssoc;
    if (physAssoc.length > 0 || pathAssoc.length > 0) {
      try {
        let result = await this.vaservices.userAssociation(associationJson, depKey).toPromise();
        if (result.Success) {
          console.info("User Associated");
        }
      }
      catch {
        console.error("User Association failed");
      }
      this.ngxService.stop("1");
    }
  }

  async userOutOfOffice(outOfOfficeArray, depKey) {
    await this.vaservices.userOutOfOffice(outOfOfficeArray, depKey).toPromise().then(va => {
      console.info("Out of office details saved");
      this.ngxService.stop("1");
    },
      error => {
        console.error("Out of office details save failed");
        console.error(error);
        this.ngxService.stop("1");
      }
    );
    // if(result.Success){
    //   console.info("User Associated");
    // }

    this.ngxService.stop("1");

  }


  async userThresholdDetails(data, depKey) {
    let result = await this.vaservices.userThresholdDetails(data, depKey).toPromise();
    if (result.Success) {
      console.info("Threshold details saved");
      this.ngxService.stop("1");
    }
    else {
      console.error("Threshold details save failed");
      this.ngxService.stop("1");
    }

    // if(result.Success){
    //   console.info("User Associated");
    // }
  }


  async getUserDetails(data, userTabs) {
    this.enableTabs(false, false, false, false, true);
    userTabs.selectedIndex = 4;
    this.summaryData = data;
    this.roleList = this.summaryData.roleList.split(',');
    let list = this.summaryData.accountList.split(',')
    // let accounts = []
    this.actionBtn.cardtype = 'users'
    this.datashare.userCard = { email: data.email, userid: data.userid, allowLogin: data.allowLogin, userStatus: data.status };

    // { email : this.email,userid : this.userid , allowLogin : this.allowLogin, userStatus : this.userStatus}
    list.forEach(va => {
      if (va.split(':')[1] == '1') {
        this.accounts.push({ accountid: va.split(':')[0] });
      }
    })
    let accountList = this.frm_AddUsergroup.controls.frmArruserAccounts.value.filter((va: any) => this.accounts.some((val: any) => val.accountid == va.frmLblchk_Location));
    this.accounts = accountList;
    // .forEach(va => {
    //   for (let i = 0; i < .length; i++) {
    //     if (va.accountid = this.frm_AddUsergroup.controls.frmArruserAccounts.value[i].frmchk_Location) {
    //       va.accountname = this.frm_AddUsergroup.controls.frmArruserAccounts.value[i].frmLbl_Location;
    //       break;
    //     }
    //   }
    // })
    // this.usersdetails = Resdata.data.submenuData[0];
    // this.usersdetails.AllowLogin = this.allowLogin == "True" ? true : false;
    // this.editloginname = this.usersdeatils.loginname
    this.ngxService.stop("1");

  }

  async generateSaveJson() {
    let form = this.frm_AddUsergroup.value;
    //get logged-in user id
    let loggedIn_userID = sessionStorage.getItem('Userid');
    let roleList = "";
    let selected_primaryrole = "";
    let holdReleaseDurationvalue = "";
    let holdselected_primaryrole = "";
    //"Lab Manager,Claim tracker";
    for (let i = 0; i < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; i++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_UserRoles_RoleName'].value == true) {
        roleList += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value + ",";
        holdReleaseDurationvalue += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].value + '|' + (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value + ",";
      }
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].value != "") {
        selected_primaryrole = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value;
        holdselected_primaryrole = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].value + '|' + (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value;
      }
    }
    //holdduration list
    let arraylist1: string[];
    arraylist1 = holdReleaseDurationvalue.split(',');
    let listrole1: string[];
    listrole1 = arraylist1.filter(item => item !== holdselected_primaryrole)
    let role1 = String(listrole1);
    holdReleaseDurationvalue = holdselected_primaryrole + ',' + role1;
    holdReleaseDurationvalue = holdReleaseDurationvalue.substring(0, holdReleaseDurationvalue.length - 1);
    //role list
    let arrayrole: string[];
    arrayrole = roleList.split(',');
    let listrole: string[];
    listrole = arrayrole.filter(item => item !== selected_primaryrole)
    let role = String(listrole);
    roleList = selected_primaryrole + ',' + role;
    roleList = roleList.substring(0, roleList.length - 1);
    let review_data = [];
    let accountList = "";
    // "5:1,6:1,20029:0,26112:0,26275:0";
    for (let j = 0; j < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; j++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmchk_Location'].value as boolean == true) {
        accountList += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value + ":1,";
      }
      else {
        accountList += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value + ":0,";
      }
      // accountList += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value + ":" +
      //   (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmchk_Location'].value + ",";

      //review data
      let obj = {
        "userID": loggedIn_userID ? loggedIn_userID : "-100",
        "AccountID": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value,
        "sendforreview": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmDrp_SendforReview'].value == "true" ? true : false,
        "works": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmDrp_Works'].value == true ? true : false,
        "manages": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmDrp_Manages'].value == "true" ? true : false,
        "RoleName": selected_primaryrole,
        "primaryrole": selected_primaryrole != "" ? true : false,
        "CanPathologistModifyAccession": form.frmInp_AllowtoeditAccession,
        "CanPathologistModify": form.frmInp_EditSite,
        "ModifiedBy": loggedIn_userID ? loggedIn_userID : "-100"
      };
      review_data.push(obj);
    }
    accountList = accountList.substring(0, accountList.length - 1);

    //check if user is medical director
    let MD_val = false;

    if (this.frm_AddUsergroup.controls.frmArruserRoles.value.some((va: any) => va.frmChk_UserRoles_RoleName != false &&
      va.frmLblChk_UserRoles_RoleName.toString().match(/pathologist/i) && va.frmChk_IsMedicalDirector == true)) {
      MD_val = true;
    }

    //check for hold release duration value
    let HD_val: number = 0;
    if (this.HRD_KValue != -1) {
      let user_hd_val = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(this.HRD_KValue)).controls['frmInp_RoleHoldreleaseDuration'].value;
      if (user_hd_val != null && user_hd_val != "") {
        HD_val = parseInt(user_hd_val);
      }
    }

    //convert threshold value to number
    let threshold_value: number = 0;
    if (form.frmInp_Threshold != null && form.frmInp_Threshold != "") {
      threshold_value = parseInt(form.frmInp_Threshold);
    }

    //check if primary role has changed
    let isPrimaryRoleChanged = false;
    if (this.hidPrimaryRole != "" && selected_primaryrole != "") {
      isPrimaryRoleChanged = true;
    }

    //get licensure details
    let lic_details: string = "";
    if (this.hidIsPathlogistSelected) {
      lic_details = "<ArrayOfPathologistLicensure>";
      for (let a = 0; a < (<FormArray>this.frm_AddUsergroup.get('formLicensure')).length; a++) {
        var PathLic_Statefullname: string = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(a)).controls['frmDrp_LicsenceStates'].value && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(a)).controls['frmDrp_LicsenceStates'].value != 'Select' ? (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(a)).controls['frmDrp_LicsenceStates'].value : '';
        var PathLic_Expirydate = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(a)).controls['frmDrp_LicsenceDate'].value;
        var PathLic_State: string = PathLic_Statefullname.substring(0, PathLic_Statefullname.indexOf(' '));
        PathLic_State = PathLic_State === 'Select' ? 'null' : PathLic_State;
        PathLic_Expirydate = this.datepipe.transform(PathLic_Expirydate, 'MM/dd/yyyy');
        if (!PathLic_Statefullname || !PathLic_Expirydate) {
          continue;
        }
        //build xml string
        lic_details += "  <PathologistLicensure>";
        lic_details += "  <id>" + (a + 1) + "</id>";
        lic_details += "  <state>" + PathLic_State + "</state>";
        lic_details += "  <Expirydate>" + PathLic_Expirydate + "</Expirydate>";
        lic_details += "  <Statefullname>" + PathLic_Statefullname + "</Statefullname>";
        lic_details += "  </PathologistLicensure>";
      }
      lic_details += "  </ArrayOfPathologistLicensure>";
    }
    let orgid = this.organizationID
    var reg = null;
    if (form.frmInp_US && form.frmInp_ST) {
      reg = 'US,ST'
    }
    else if (form.frmInp_ST) {
      reg = 'ST'
    }
    else if (form.frmInp_US) {
      reg = 'US'
    }
    this.valueloginname = form.frmInp_LoginName;
    // this.passwordvalue = "va2010"

    let imageSrcString
    if (this.frm_sign) {
      imageSrcString = await this.toDataURL(this.frm_sign)
      imageSrcString = imageSrcString.toString().split(",")[1];
    }


    this.worksAccount = this.frm_AddUsergroup.controls.frmArruserAccounts.value.filter((va: any) => this.checkRolesSelected(this.frm_AddUsergroup.value.frmArruserRoles) && va.frmchk_Location && va.frmDrp_Works)

    var sendJson = {
      "title": form.frmInp_title,
      "fname": form.frmInp_FirstName,
      "lname": form.frmInp_LastName,
      "midInitial": form.frmInp_MiddleInitial,
      "loginName": form.frmInp_LoginName,
      "eduQual": form.frmInp_Qualification,
      "gender": form.frmInp_Gender == "1" ? true : false,
      "npi": form.frmInp_NPI,
      "address1": form.frmInp_Address1,
      "address2": form.frmInp_Address2,
      "street": form.frmInp_Street,
      "city": form.frmInp_City,
      "state": form.frmInp_State === "Select" ? '' : form.frmInp_State,
      "zip": form.frmInp_Zip,
      "sign": imageSrcString ? imageSrcString : null,
      "country": form.frmInp_Country,
      "phone": form.frmInp_Phone,
      "fax": form.frmInp_Fax,
      "email": form.frmInp_Email,
      "status": form.frmInp_Status ? 'Active' : 'InActive',
      "rolename": "",
      "userNamedisplayFormat": form.frmInp_DisplayFormatWebpage,
      "userNamedescriptionFormat": form.frmInp_DisplayFormatDescription,
      "usernameReportformat": !form.frmInp_DisplayFormatReports ? form.frmInp_DisplayFormatReports = 'S. F L D' : form.frmInp_DisplayFormatReports = 'S. F L D',
      "password": "8clpNzfFAebd6l/nd2R0BQ==",
      "defaultPage": "VAPreferences.aspx",
      "ssn": form.frmInp_SSN,
      "upin": form.frmInp_UPIN,
      "userCode": form.frmInp_UserCode,
      "commonKey": form.frmInp_ExternalUserGUID,
      "isAccLockedOut": false,
      "allowPathologistReview": !form.frmInp_AllowReviewPathologist ? false : form.frmInp_AllowReviewPathologist as boolean,
      "isModifyTechnicalDataAccession": !form.frmInp_AllowtoeditAccession ? false : form.frmInp_AllowtoeditAccession as boolean,
      "modifyTechnicalData": !form.frmInp_EditSite ? false : form.frmInp_EditSite as boolean,
      "roleList": roleList,
      "isPrimaryRoleChanged": isPrimaryRoleChanged,
      "works": this.worksAccount && this.worksAccount.length > 0 ? this.worksAccount[0].frmLblchk_Location : null,
      "manages": form.frmDrp_OrgManages == 'true' ? true : false,
      "sendForreview": form.frmDrp_OrgSendforReview == 'true' ? true : false,
      "accountList": accountList,
      "pathReadingLoc": "5",
      "newRolesAdded": false,
      "medicalDirector": MD_val as boolean,
      "associateAccID": null,
      "deassociateAccID": null,
      "organizationID": orgid,
      "primaryLocation": this.primaryLocation ? this.primaryLocation : 0,
      "showMyCaseOnly": !form.frmInp_ShowMyCaseOnly ? false : form.frmInp_ShowMyCaseOnly as boolean,
      "alowPathologistDiagnosisTemplate": !form.frmInp_AllowPathologistDiagnosisTemplate ? false : form.frmInp_AllowPathologistDiagnosisTemplate as boolean,
      "diagICDcodeReminder": !form.frmInp_Notifyusers ? false : form.frmInp_Notifyusers as boolean,
      "autoPopulateDiagICD9Codes": !form.frmInp_PopulateDiag ? false : form.frmInp_PopulateDiag as boolean,
      "EnableSignOutCorrection": !form.frmChk_EnableSignOutCorrection ? false : true,
      "IsPathologistPrivileges": !form.frmChk_IsPathologistPrivileges ? false : true,
      "region": reg,
      "associationType": null,
      "externalUserGUID": form.frmInp_ExternalUserGUID,
      "userlicensure": lic_details,
      "holdReleaseDuration": HD_val,
      "threshold": threshold_value,
      "HoldReleaseDurationInfo": holdReleaseDurationvalue,
      "allowLogin": form.frmInp_AllowLogin == null ? true : form.frmInp_AllowLogin,
      "thresholdDuration": form.frmDrp_ThresholdDuration.toLowerCase()!='select'?form.frmDrp_ThresholdDuration:null,
      "readcomputeon": form.frmDrp_ReadComputeOn,
      "reviewthreshold": form.frmInp_ReviewThreshold,
      "reviewduration": form.frmDrp_ReviewDuration,
      "reviewcomputeon": form.frmDrp_ReviewComputeOn,
      "screeningthreshold": form.frmInp_ScreeningThreshold,
      "screeningduration": form.frmDrp_ScreeningDuration,
      "screeningcomputeon": form.frmDrp_ScreeningComputeOn,
      "createdby": sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid').toString() : '-100',
      "userid": "0",
      "modifiedby": "0",
      "loggedInUserId": loggedIn_userID ? loggedIn_userID : "-100",
      "entityType": "AddNewUser",
      "entityID": "",
      "actionperformed": "New User Created",
      "actiondetails": "",
      "auditDate": new Date(),
      "EntityData": null,
      "reviewData": review_data,
      "RoleData": []
    };
    if (this.organizationLoginType.toString().toLowerCase().trim() != 'email') {
      sendJson["allowLogin"] = true;
    }
    return sendJson;

  }



  configTabValidation(userTabs): any {
    this.ngxService.start();
    let message;
    let form = this.frm_AddUsergroup.value;
    if (form.frmInp_ST == false && form.frmInp_US == false) {
      !message ? message = 'Please select at least one region' : null;
    }
    else {
      var stateID;
      // var licensure1;
      var confirmexpdate;
      var alldata = [];
      var StateLists = [];
      var DuplicateStateCount = 0;
      var i = 0;
      // var stateval = "";
      var DateValueCount = 0;
      let k
      for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('formLicensure')).length; k++) {
        var PathLic_Statefullname: string = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(k)).controls['frmDrp_LicsenceStates'].value ? (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(k)).controls['frmDrp_LicsenceStates'].value : '';
        var PathLic_Expirydate = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(k)).controls['frmDrp_LicsenceDate'].value;
        var PathLic_State: string = PathLic_Statefullname.substring(0, PathLic_Statefullname.indexOf(' '));

        if (PathLic_Statefullname == "" && PathLic_Expirydate == "") {
          continue;
        }

        if ((PathLic_Statefullname == "" && PathLic_Expirydate == "") || (PathLic_Statefullname == "" && PathLic_Expirydate != "")) {
          return;
        }
        if (alldata.length == i) {
          // alldata[i] = this.pathLicesData();
          alldata[i] = {
            id: "",
            State: "",
            Expirydate: "",
            Statefullname: "",
          };
          alldata[i].id = JSON.stringify(i + 1);
          alldata[i].State = PathLic_State;
          alldata[i].Expirydate = PathLic_Expirydate;
          alldata[i].Statefullname = PathLic_Statefullname;
          if (alldata[i].Expirydate == "") {
            confirmexpdate = true;
          }
          if (alldata[i].State != "") {
            if (StateLists.indexOf(alldata[i].State) === -1) {
              StateLists.push(alldata[i].State);
            }
            else {
              DuplicateStateCount++;

              // return false;
            }
          }
        }
        i++;
      }
      // this.hidlicensure = JSON.stringify(alldata);
      if (DuplicateStateCount > 0) {
        !message ? message = "The state has been selected multiple times. Please select some other state or delete the state." : null;
        document.getElementById(stateID).focus();
        // return false;
      }
      if (DateValueCount > 0) {
        // this.alertOnSaveCommon = true;
        !message ? message = "Please enter a valid Expirydate!" : null;
        // return false;
      }

      if (!message) {
        for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).length; k++) {

          var startDate = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).at(k)).controls['frm_startdate'].value;
          var stopDate = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).at(k)).controls['frm_enddate'].value;
          if (!startDate && !stopDate) {
            break;
          }

          if (this.compareIntervals(k, startDate, stopDate)) {
            message = "Leave dates are overlapping";
            break;
          }

          try {
            let numberofdays = Math.floor((stopDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24) + 1;

            if (numberofdays <= 0) {
              message = "From date cannot be more than to date"
              break
            }
          }
          catch {
            message = "Please Enter valid Dates"
          }

          try {
            startDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
            stopDate = this.datepipe.transform(stopDate, 'MM/dd/yyyy');
          }
          catch {
            message = "Please enter valid date for Out of Office"
            break;
          }
          // let dataObject = {
          //   "id" : id,
          //   "startDate" : startDate,
          //   "stopDate" : stopDate
          // }

        }
      }
      if (!message) {

        var frmDrp_ThresholdDuration = (this.frm_AddUsergroup.get('frmDrp_ThresholdDuration')).value;
        var frmInp_ReviewThreshold = (this.frm_AddUsergroup.get('frmInp_ReviewThreshold')).value;
        var frmDrp_ReviewDuration = (this.frm_AddUsergroup.get('frmDrp_ReviewDuration')).value;
        var frmInp_Threshold = (this.frm_AddUsergroup.get('frmInp_Threshold')).value;
        var frmInp_ScreeningThreshold = (this.frm_AddUsergroup.get('frmInp_ScreeningThreshold')).value;
        var frmDrp_ScreeningDuration = (this.frm_AddUsergroup.get('frmDrp_ScreeningDuration')).value;

        if (frmInp_Threshold && frmDrp_ThresholdDuration.toString().toLowerCase() == 'select') {
          message = 'Please select a valid read threshold duration!'
        }

        if (frmInp_ReviewThreshold && frmDrp_ReviewDuration.toString().toLowerCase() == 'select' && !message) {
          message = 'Please select a valid review threshold duration!'
        }

        if (frmInp_ScreeningThreshold && frmDrp_ScreeningDuration.toString().toLowerCase() == 'select' && !message) {
          message = 'Please select a valid screening threshold duration!'
        }
        // let dataObject = {
        //   "id" : id,
        //   "startDate" : startDate,
        //   "stopDate" : stopDate
        // }

      }

      if (!this.primaryLocation && this.associateSurgiCenter && this.selectedSurgiCenters && this.selectedSurgiCenters.length > 0) {
        message = 'Please select a primary location';
      }
    }
    if (message) {
      if (this.moveTo != 'previous') {
        this.ngxService.stop();
        return message;
      }
      else {
        this._snackBar.open(message, 'Close');
      }
    }
    else {
      if (this.moveTo != 'previous') {
        this.clonedUserForm = lodash.cloneDeep(this.frm_AddUsergroup);
        this.clonedsurgicenterList = lodash.cloneDeep(this.surgiCentersList);
        this.clonedselectedSurgiCenters = lodash.cloneDeep(this.selectedSurgiCenters);
        // userTabs.selectedIndex = 2;
        // this.enableTabs(false, false, true, false, false);
        // if (this.moveTo == 'previous')
        //   this._snackBar. 
      }
      else {
        this.clonedUserForm = lodash.cloneDeep(this.frm_AddUsergroup);
        this.clonedsurgicenterList = lodash.cloneDeep(this.surgiCentersList);
        this.clonedselectedSurgiCenters = lodash.cloneDeep(this.selectedSurgiCenters);
        userTabs.selectedIndex = 2;
        this.enableTabs(false, false, true, false, false);
        if (this.moveTo == 'previous')
          this._snackBar.open('Configuration details saved successfully', 'Close');
      }
    }

    // }
    this.ngxService.stop();
  }

  toDataURL = async (url) => {
    var res = await fetch(url);
    var blob = await res.blob();
    const result = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
    return result
  };

  activateOrInactivate(obj, UserID_value) {
    let value;
    if (UserID_value.controls.frmRd_UserRoles_RoleName.value && UserID_value.controls.frmLbl_usrStatus.value) {
      this._snackBar.open('Primary role cannot be inactive', 'Close');
      event.preventDefault();
      return;
    }
    if (obj.target.checked == false) {
      value = 'Inactive';
    }
    else {
      value = 'active';
    }
    this.userstatus = ((UserID_value.controls.frmLbl_userID.value) + '|' + (value));
    this.statuslist.push(this.userstatus);

  }

  UpdateUserStatus() {
    this.ngxService.start("1");
    let list = "";
    for (var i = 0; i < this.statuslist.length; i++) {
      list += this.statuslist[i] + ',';

    }
    var obj = {
      "User": list,
      "modifiedBy": sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100
    }
    this.vaservices.Updateuserstatus(obj).subscribe(result => {
      this.ngxService.stop("1");
      if (!result.errors) {
        console.log("result");
      }
    }, error => {
      this.ngxService.stop("1");
      this._snackBar.open("An error occurred while processing your request", 'Failed');
      console.info(error);
    });

  }

  async finishUserConfig() {
    // sessionStorage.setItem('DeploymentKey',this.deploymentKey);
    // sessionStorage.setItem('deploymentKey',this.deploymentKey);
    // this.vaservices.DeploymentKeys = this.deploymentKey
    if (this.fromComponent == 'homepage') {
      if (this.LoginTypesflag == false) {
        // this._snackBar.open("Invitation Sent Successfully", 'Success');
        this.homepage.AddUsergroupClose(true);
      }
      else {
        if (!this.summaryTabDisable) {
          this.loginDeatilsData('homepage');
        }
        else {
          this.homepage.AddUsergroupClose(true);
        }
      }
    }
    else if (this.fromComponent == 'userpersonalinfo') {
      this.UserPersonalInfoComponent.update(this.EditCommonKey);
    }
    else if (this.fromComponent == 'allusers') {
      if (this.editMode) {
        this.sendCommonKey.emit({ key: this.summaryData.commonKey, editMode: this.editMode });
      }
      else {
        if (!this.summaryTabDisable) {
          if (this.organizationLoginType.toString().toLowerCase().trim() != 'email') {
            await this.loginDeatilsData('adduser');
          }
          this.sendCommonKey.emit({ key: this.summaryData.commonKey, editMode: this.editMode });
        }
        else {
          this.user.back();
        }
      }
    }
    else {
      this.homepage.AddUserPopUpClose();
    }
  }


  async loginDeatilsData(context) {
    this.ngxService.start();
    // let deploykey=this.vaservices.deploymentKey
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');
    let modifiedBy = sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100

    //this.vaservices.deploymentKey=this.deploymentvalue
    // sessionStorage.setItem('deploymentKey', depKey.toLowerCase());
    await this.vaservices.GetResetPassword(this.valueloginname, modifiedBy, depKey).toPromise().then(data => {
      // sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);

      if (data != 'null') {
        //this.AddUsergroupClose();
        if (context.match(/home/)) {
          this.homepage.passworddata = data[0];
          this.homepage.valueloginname = this.summaryData.loginName;
          this.homepage.iflogindeatils = true;
        }
        else {
          this.AllUsersComponent.passworddata = data[0];
          this.AllUsersComponent.valueloginname = this.summaryData.loginName;
          this.AllUsersComponent.statusloginname = this.summaryData.loginName;
          this.AllUsersComponent.iflogindeatils = true;
        }

        this.count = -1;
        this.ngxService.stop();

      }
      else {
        // this.resetAddUserDetails();
        // sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
    }, error => {
      // sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
      // this.resetAddUserDetails();
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error);
    });
  }


  filteredUsers(userType, data) {
    if (userType == 'physician') {
      if (!this.physFilter) {
        return data;
      }
      return data.filter(va => va.value.frmlbl_username.toString().toLowerCase().trim().includes(this.physFilter.toString().toLowerCase().trim()))
    }
    else {
      if (!this.pathFilter) {
        return data;
      }
      return data.filter(va => va.value.frmlbl_username.toString().toLowerCase().trim().includes(this.pathFilter.toString().toLowerCase().trim()))
    }
  }


  addOutOfOffice() {
    let frm;
    frm as FormGroup;
    frm = this._fb.group({
      frm_startdate: '',
      frm_enddate: '',
      frm_isdeleted: 0
    });

    let values = this.frm_AddUsergroup.get('frmOutOfOffice').value
    if (!(values.some((va: any) => !va.frm_startdate || !va.frm_enddate))) {
      (<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).push(frm);
    }
    else {
      this._snackBar.open('Please fill the existing rows before adding another row', 'Close');
    }
  }

  deleteOutOfOffice(index) {
    if ((<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).length > 1) {
      (<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).removeAt(index);
    }
    else {
      let frm;
      frm as FormGroup;
      frm = this._fb.group({
        frm_startdate: '',
        frm_enddate: '',
        frm_isdeleted: 0
      });
      (<FormArray>this.frm_AddUsergroup.get('frmOutOfOffice')).controls[index] = frm;
    }
  }

  // allowLoginChecked(event) {
  //   if (this.organizationLoginType.toString().toLowerCase().trim() == 'email') {
  //     if (event.target.checked) {
  //       this.frm_AddUsergroup.controls.frmInp_Email = new FormControl(this.editMode ? this.usersdeatils.email : '', Validators.required);
  //       this.frm_AddUsergroup.patchValue({
  //         frmInp_Email : ''
  //       })
  //     }
  //     else {
  //       this.frm_AddUsergroup.controls.frmInp_Email.setErrors(null);
  //       this.frm_AddUsergroup.controls.frmInp_Email.clearValidators()
  //     }
  //   }
  // }




  checkPrimaryRole(role) {
    return this.summaryData && this.summaryData.reviewData.some(va => va.RoleName == role && va.primaryrole)
  }

  selectAllPathologists(checked) {
    for (let i = 0; i < (<FormArray>this.frm_AddUsergroup.get('frmPathologist')).length; i++) {
      (<FormArray>this.frm_AddUsergroup.get('frmPathologist')).at(i).patchValue({
        frmchk_user: checked
      })
    }
  }


  selectAllPhysicians(checked) {
    for (let i = 0; i < (<FormArray>this.frm_AddUsergroup.get('frmPhysician')).length; i++) {
      (<FormArray>this.frm_AddUsergroup.get('frmPhysician')).at(i).patchValue({
        frmchk_user: checked
      })
    }
  }

  allChecked(roleForm) {
    let allChecked = roleForm.every(va => va.frmchk_user == true)
    return allChecked;
  }

  someChecked(roleForm) {
    let someChecked = roleForm.some(va => va.frmchk_user == true)
    return someChecked;
  }


  currentTab() {
    let tabs = ['User Details', 'Roles', 'Locations', 'Configuration']
    return tabs[this.userTabs.selectedIndex];
  }

  triggerRoleChecked(controls) {
    return controls.every(va => va.value.frmChk_UserRoles_RoleName != false);
  }

  checkSomeRoles(controls) {
    return controls.some(va => va.value.frmChk_UserRoles_RoleName != false);
  }

  checkLocIndeter(allSelected, controls) {
    return !allSelected && controls.some(va => va.value.frmchk_Location);
  }

  checkLocChecked(controls) {
    if (controls.every(va => va.value.frmchk_Location)) {
      this.frm_AddUsergroup.value.frmchk_OrgLocation = true
    }
    else {
      this.frm_AddUsergroup.value.frmchk_OrgLocation = false
    }
    return this.frm_AddUsergroup.value.frmchk_OrgLocation;
  }

  async associateSurgi(checked) {
    if (checked) {
      this.assocChecked = true
      this.associateSurgiCenter = true
      this.locationsList = [];
      for (let k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
        if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true &&
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmLbl_LocationContext'].value.toString().toLowerCase().trim() == 'of') {
          this.locationsList.push({ accountid: (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmLblchk_Location'].value, accountname: (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmLbl_Location'].value })
        }
      }
      await this.fetchSurgicenters(checked);
    }
    else {
      this.assocChecked = false
      this.associateSurgiCenter = false
      this.searchSurgi = "";
    }
  }

  filteredSurgiCenters(searchSurgi: String) {
    if (!searchSurgi) {
      return this.surgicenterList
    }
    return this.surgicenterList.filter((va: any) => !va.ishidden && va.displayname.toString().toLowerCase().trim().includes(searchSurgi.toLowerCase().trim()));
  }

  selectedSurgiCenter(data) {
    data["selected"] = data["selected"] ? false : true;
  }


  selectUsers() {
    let selectedAccounts = this.surgicenterList.filter((va: any) => va.selected && !va.ishidden);
    let accounts = lodash.cloneDeep(selectedAccounts)
    accounts = accounts.map(({ selected, ishidden, ...rest }) => ({ ...rest }));
    this.selectedSurgiCenters = selectedAccounts && selectedAccounts.length > 0 ? [...this.selectedSurgiCenters, ...accounts] : [];
    selectedAccounts.forEach((va: any) => { va.ishidden = true, va.selected = false });
  }

  deselectUsers() {
    let selectedAccounts = this.selectedSurgiCenters.filter((va: any) => va.selected);
    selectedAccounts.forEach((va: any) => {
      this.surgicenterList.forEach((val: any) => {
        if (val.accountid == va.accountid) {
          val.selected = false;
          val.ishidden = false;
        }
      });
    });
    this.selectedSurgiCenters = this.selectedSurgiCenters.filter((va: any) => !va.selected);
  }


  checkDisabled(list, data) {
    if (list) {
      return !data.some(va => va.selected && !va.ishidden);
    }
    return !data.some(va => va.selected);
  }


  //#region Convert Obj to lower
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion


  checkThresholdDeployment() {
    return !this.DeploymentKeys.match(/thx/i)
  }


  statusChecked(checked) {
    for (let i = 0; i < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; i++) {
      if (checked) {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).get('frmLbl_usrStatus').enable();
      }
      else {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).get('frmLbl_usrStatus').disable();
      }
    }
  }

  checkReadingLocation(account) {
    return (this.worksAccount && this.worksAccount.length && account.frmLblchk_Location == this.worksAccount[0].frmLblchk_Location) ? true : false;
  }
  async getAccountType() {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "getOrganizationtype");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "organizationid": this.organizationID }
    };
    await this.vaservices.GetData(drpqueryRequest, sessionStorage.getItem('deploymentKey')).toPromise().then(result => {
      if (!result.errors) {
        if (result.data.submenuData != undefined && result.data.submenuData != null) {
          let obj = {
            contextId: this.organizationID,
            context: result.data.submenuData[0].Context,
            contexttype: 'organization'
          }
          let dbname = sessionStorage.getItem('deploymentKey');;
          this.vaservices.getAccountType(obj, dbname).subscribe(result => {
            if (result.length > 0) {
              this.commonService.isLiveAccount = result[0].isLive ? true : false;
            }
            else {
              this.commonService.isLiveAccount = false;
            }
          }, error => {
            console.error(error)
          })
        }
      }
    }
    )
  }
  async compareRoles(AssociationTypes) {
    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.GetQuery('getuserrolesforassociationtype');
    let i, Qstr = "";
    for (i = 0; i < AssociationTypes.length; i++) {
      if (i == AssociationTypes.length - 1) {
        Qstr += "associationtype = \"" + AssociationTypes[i] + "\"";
      }
      else {
        Qstr += "associationtype = \"" + AssociationTypes[i] + "\" or ";
      }
    }


    // let index = AssociationTypes.findIndex(va => va.toString().toLowerCase().trim() == 'both')
    // if (index == -1) {
    //   let polAssociations = ['laboratory', 'ordering facility']
    //   index = polAssociations.every(val => AssociationTypes.includes(val.toString().toLowerCase()));
    //   // AssociationTypes.some(va=> (va.toString().toLowerCase().trim() == 'laboratory' && va.toString().toLowerCase().trim() == 'ordering facility' ));
    //   index = index ? 0 : -1;
    // }
    // if (index != -1) {
   if (this.commonService.isPol) {
      this.IsPOL = true;
      this.POLOrg = true;
      this.frm_AddUsergroup.controls.frmArruserAccounts['controls'].forEach(va => va.patchValue({
        frmchk_Location: false,
        frmDrp_Works: false
      }))
    }

    queryVariable = { "associationtype": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    await this.vaservices.GetData(queryResult, depKey).toPromise().then(async res => {
      if (!res.errors) {
        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          let rolesdata = [];
          rolesdata = res.data.vwGetRolesBasedOnAccountAssociation_list; //unsorted
          //to get unique roles from array
          let rolesarray = [];
          rolesdata.forEach(element => {
            if (rolesarray.length > 0) {
              let foundRoleDuplicate = false;
              for (let kk = 0; kk < rolesarray.length; kk++) {
                let role: string = element.rolename;
                if (rolesarray[kk].rolename === role) {
                  foundRoleDuplicate = true;
                  break;
                }
              }
              if (foundRoleDuplicate == false) {
                //to get unique roles from array
                rolesarray.push(element);
              }
            }
            else {
              rolesarray.push(element);
            }
          });
          rolesdata = rolesarray;
          await this.getConfiguredRolesforLiveOrg(rolesdata);
        }
      }
    })
  }
}