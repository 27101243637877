<div *ngIf="gridPage && (filteredFliagList$|async)?.['data']" class="row">
    <div class="col-md-12 m-0 p-0" *ngIf="sourceApp != 'VitalDx'">
        <div class="modal-header admin-section-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong>Flags</strong>
            </h3>
        </div>
    </div>
    <!-- <div class="row col-sm-12 m-0 p-0" *ngIf="sourceApp == 'VitalDx'">
        <div class="col-sm-8 m-0 p-0 mt-2">
            <strong class="menu-title" style="padding-right: 17px;">Flags</strong>
        </div>
    </div> -->

</div>
<!-- <ng-container *ngIf=""> -->
    <mat-spinner class="labadmin-spinner" *ngIf="sourceApp && !(gridPage && (filteredFliagList$|async)?.['data'])  && !uploadClicked"
    style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50"></mat-spinner>

    <section class="ang-mat-section" *ngIf="gridPage && filteredFliagList$|async as flagsList">
        <div>
            <div *ngIf="!sequenceEditMode" class="d-flex align-items-center mt-2 mb-2 filters">
                <span class="not-selectable f-12" tabindex="0"
                [ngClass]="[filtercondition.value.status=='all' ? 'filterselected' : '',flagsList.isNoDataPresent || flagsSelectionModel.selected.length?'disabled':'']"
                (click)="!flagsList.isNoDataPresent || flagsSelectionModel.selected.length?filterStatus({status:'all'}):null">All
                ({{flagsList.statusCount.totalCount}})</span>
            <span id="seperator"></span>
            <div class="filters d-flex not-selectable flex-wrap">
                <span class="not-selectable f-12" tabindex="0"
                    [ngClass]="[filtercondition.value.status=='active'? 'filterselected' : '',flagsList.isNoDataPresent || flagsSelectionModel.selected.length?'disabled':'']"
                    (click)="!flagsList.isNoDataPresent || flagsSelectionModel.selected.length?filterStatus({status:'active'}):null">
                    Active ({{flagsList.statusCount.activeCount}})
                </span>
                <span class="not-selectable f-12" tabindex="0"
                    [ngClass]="[filtercondition.value.status=='inactive'? 'filterselected' : '',flagsList.isNoDataPresent || flagsSelectionModel.selected.length?'disabled':'']"
                    (click)="!flagsList.isNoDataPresent || flagsSelectionModel.selected.length?filterStatus({status:'inactive'}):null">
                    Inactive
                    ({{flagsList.statusCount.totalCount-flagsList.statusCount.activeCount}})
                </span>
                </div>
                <app-search-box placeHolder="Search Flag" (enteredValue)="filterStatus({searchText:$event})"
                    class="search-box-width ml-auto"  [isDisabled]="flagsList.isNoDataPresent || flagsSelectionModel.selected.length"></app-search-box>

                <app-button matTooltip="Copy" [matTooltipDisabled]="!copyBtn || flagsSelectionModel.selected.length > 0" image="icons/Global_Icon.svg" (click)="copyOrg()" [isDisabled]="!copyBtn || flagsSelectionModel.selected.length" class="ml-3"></app-button>

                <app-button  matTooltip="Reorder" [matTooltipDisabled]="!editBtn || flagsSelectionModel.selected.length > 0 || flagsList.data.length === 0 || isFilterEnabledChanged()" image="icons/Reorder_Blue.svg" class="ml-3" [isDisabled]="flagsList.isNoDataPresent || !editBtn || flagsSelectionModel.selected.length || isFilterEnabledChanged()"  (click)="enableSortEdit()" ></app-button>

                <app-button multiple="true" buttonHoverText=":Bulk Upload" image=":icons/Excel-Bulk Upload_white.svg"  buttonText="Create" [isRightButtonDisabled]="!uploadBtn || flagsSelectionModel.selected.length > 0" [isDisabled]="!createBtn" (leftBtnClick)="[resetForm(),flagsSelectionModel.select('-1')]" (rightBtnClick)="loadUploadScreen()" class="ml-3"></app-button>
            </div>
            <div *ngIf="sequenceEditMode" class="mt-3 mb-2 d-flex">
                <app-button buttonclass="secondary" class = "ml-auto edit-page-button" (click)="returnToList()">Return</app-button>
                <app-button buttonclass="primary" class = "edit-page-button ml-2" [isDisabled]="!isSortDataChanged "(click)="saveSequence()" >Save</app-button>
            </div>
            <div [classflags-list-border]="flagsList.data.length > 0">
                <div class="content-slab">
                    <span *ngIf="sequenceEditMode " class="ml-3 p-2"></span>
                    <span> Flag Name</span>
                    <img *ngIf="sequenceEditMode && sortedOrder === 'DESC'"
                        class="ml-2 cursor-pointer" width="14px" matTooltip="Sort" matTooltipPosition="right" (click)="sequenceTracking('ASC', flagsList.data)"
                        src="/assets/icons/Reorder_Descending.svg" alt="Descending">

                    <img *ngIf="sequenceEditMode && sortedOrder === 'ASC'"
                        (click)="sequenceTracking('DESC', flagsList.data)" matTooltip="Sort" matTooltipPosition="right" class="ml-2 cursor-pointer" width="14px"
                        src="/assets/icons/Reorder_Asceding.svg" alt="Ascending">

                    <img *ngIf="sequenceEditMode && sortedOrder === 'UNS'" matTooltip="Sort" matTooltipPosition="right"
                        class="ml-2 cursor-pointer" width="14px" (click)="sequenceTracking('ASC', flagsList.data)"
                        src="/assets/icons/Mix - Sort.svg" alt="Un-Ordered">
                    <span *ngIf="!sequenceEditMode" class="right-elements pointer" (click)="!flagsList.isNoDataPresent && flagsList.data.length && !flagsSelectionModel.selected.length?openPreview():null" [class.disabled]="flagsList.isNoDataPresent || flagsList.data.length === 0 || flagsSelectionModel.selected.length > 0 > 0">Preview</span>

                    <img *ngIf="!sequenceEditMode" class="excel" matTooltip="Export" (click)="!flagsList.isNoDataPresent || flagsList.data.length || flagsSelectionModel.selected.length ? exportexcel(flagsList.data):null"  src="/assets/icons/Excel_Download.svg"  alt="Download As Excel" [class.disabled]="flagsList.isNoDataPresent || !flagsList.data.length || flagsSelectionModel.selected.length">
                </div>
                <div >
                    <div  class="mt-3" [formGroup]="formAddFlag">
                        <div class="mb-2 m-3 mr-4" *ngIf="flagsSelectionModel.isSelected('-1')">
                            <div class="heading p-1 px-3 f-12 d-flex align-items-center">
                                <span>Create Flag</span>
                                <app-toggle-switch class="ml-auto d-flex align-items-center" labelActivetext='Active' isChecked="true"
                                labelInactivetext="Inactive" (clicked)="isactive = $event"
                                type="secondary"></app-toggle-switch>
                            </div>
                            <div class="form-body pr-3 py-2">
                                <div class="mt-2">
                                    <app-input labelValue="Name" placeholder="Enter Name" formcontrolname="attrname"
                                        [required]="true" id="name"
                                        [isFormValid]="!((getters.attrname.touched || getters.attrname.dirty) && getters.attrname.invalid)"
                                        ></app-input>
                                </div>
                                <div class="d-flex">
                                    <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                                        (click)="flagsSelectionModel.clear()">Return</app-button>
                                    <app-button buttonclass="primary" class="edit-page-button ml-2"
                                    (click)="SaveFlag()">Save</app-button>
                                </div>
                                <!-- <div class="d-flex">
                                    <button type="button" class="ml-auto btn f-12" (click)="SaveFlag()">Save</button>
                                </div> -->
                            </div>
                        </div>
                        <div *ngIf="!flagsList.isNoDataPresent" >
                            <div *ngIf="flagsList.data.length == 0 && filtercondition.value.searchText.length > 0">
                                <div class="col-sm-12 text-center mt-4">
                                    <span class="w-50 text-center mt-4" class="nodata-design">
                                        No Results
                                    </span>
                                </div>
                            </div>
                            <div class="flag-main" *ngIf="flagsList.data.length > 0" [ngClass]="{'vitalDx': sourceApp === 'VitalDx'}">
                                <ng-container>
                                    <div #scrollableDiv cdkDropList [cdkDropListData]="flagsList.data" class="items-list"
                                        (cdkDropListDropped)="drop($event)" (scroll)="onScroll($event)">
                                        <div class="items" *ngFor="let item of flagsList.data; let i= index" cdkDragBoundary=".items-list"
                                            cdkDragLockAxis="y" cdkDrag
                                            [cdkDragDisabled]="!editBtn ||flagsSelectionModel.selected.length>0">
                                            <div (click)="!editBtn?[flagsSelectionModel.select(item.attrid),openEdit(item)]:null" [class.cursor-pointer]="!editBtn" [ngClass]="item.isactive.toLowerCase()"
                                                *ngIf="!flagsSelectionModel.isSelected(item.attrid); else editFlag">
                                                <div *ngIf="sequenceEditMode" class="drag-handle" cdkDragHandle (click)="!editBtn??null">
                                                <div class="drag-handle" cdkDragHandle>
                                                    <img src="/assets/icons/Drag_handle.svg" alt="drag" width="14px" />
                                                </div>
                                            </div>
                                                <span class="ml-1">{{ item.attrname }}</span>
                                                <div *ngIf="!sequenceEditMode " class="ml-auto d-flex align-items-center">
                                                    <img class="mr-2" src="../../../../assets/images/edit.png" width="20px" alt="edit"
                                                    [matTooltip]="editBtn? 'Edit' :''"
                                                    [class.disabled]="!editBtn"
                                                    [matTooltipDisabled]="!editBtn"
                                                        matTooltipPosition="left"
                                                        (click)="editBtn?[flagsSelectionModel.select(item.attrid),openEdit(item)] : null" />
                                                </div>
                                            </div>
                                            <ng-template #editFlag>
                                                <div class="mb-2">
                                                    <div class="heading p-1 px-3 f-12 d-flex align-items-center">
                                                        <span>{{editBtn ?'Edit Flag' :'View Flag'}}</span>
                                                        <app-toggle-switch  class="ml-auto d-flex align-items-center" *ngIf="editBtn" [isChecked]="item.isactive =='Active'" labelActivetext='Active'
                                                        labelInactivetext="Inactive" (clicked)="isactive = $event"
                                                        type="secondary"></app-toggle-switch>
                                                    </div>
                                                    <div *ngIf="editBtn" class="form-body  pr-3 py-2">
                                                        <div class="mt-2">
                                                            <app-input class="f-12" labelValue="Name" placeholder="Enter Name"
                                                                formcontrolname="attrname" [required]="true"
                                                                [isFormValid]="!((getters.attrname.touched || getters.attrname.dirty) && getters.attrname.invalid)"
                                                                [isDisabled]="!isactive"></app-input>

                                                            <app-input class="f-12" labelValue="Sequence" placeholder="Enter Sequence"
                                                                formcontrolname="attrorder" [isDisabled]="!isactive" [required]="true"
                                                                [isFormValid]="!((getters.attrorder.touched || getters.attrorder.dirty) && getters.attrorder.invalid)"
                                                                mask="0000000000"></app-input>

                                                        </div>
                                                        <div class="d-flex">
                                                            <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                                                                (click)="onEditReturn()">Return</app-button>
                                                            <app-button buttonclass="primary" class="edit-page-button ml-2"
                                                            (click)="EditFlag()">Save</app-button>
                                                        </div>
                                                        <!-- <div class="d-flex">
                                                            <button type="button" class="ml-auto btn f-12"
                                                                (click)="EditFlag()">Save</button>
                                                        </div> -->
                                                    </div>
                                                    <div class="flags-border" *ngIf="!editBtn">
                                                        <div class="m-3 p-1 d-flex group-gap">
                                                            <div>
                                                                <span class="sub-heading-border">
                                                                    Flag Name
                                                                </span> <br>
                                                                <p class="mt-1">{{formAddFlag.controls.attrname.value}}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <span class="sub-heading-border">
                                                                    Flag Sequence
                                                                </span> <br>
                                                                <p class="mt-1">{{formAddFlag.controls.attrorder.value}}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <span class="sub-heading-border">
                                                                    Status
                                                                </span> <br>
                                                                <p class="mt-1">{{item.isactive ?'Active' : 'Inactive'}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex m-3">
                                                            <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                                                                (click)="flagsSelectionModel.clear()">Return</app-button>
                                                        </div>

                                                    </div>

                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>

                        </div>
                    </div>
                    <div *ngIf="flagsList.isNoDataPresent&&!flagsSelectionModel.isSelected(-1)"
                    class="col-sm-12 text-center mt-4">
                    <span class="w-50 text-center mt-4" class="nodata-design">
                        No Data Available
                    </span>
                </div>
                </div>

            </div>
        </div>


    </section>

<section *ngIf="!gridPage">
    <app-export-upload-copy (uploadBack)=backClicked($event) [templateData]="templateData"
        [copyDataClicked]="copyDataClicked" [uploadClicked]="uploadClicked">{{templateData}}</app-export-upload-copy>
</section>
