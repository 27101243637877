import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { LabadminService } from '../../labadmin.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { Store } from '@ngrx/store';
import { LabadminSessionService } from '../../services/labadmin-session.service';
import { Observable, of, Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-infoassociation',
  templateUrl: './infoassociation.component.html',
  styleUrls: ['./infoassociation.component.scss'],
})
export class InfoassociationComponent implements OnInit, OnDestroy {
  panelOpenState = false;
  workGroupList = [];
  @Input() userRoles = [];
  @Input() userDetails = '';
  @Input() fromLocation = ''
  @Input() locationDetails: any = []
  facilityLocations = [];
  labLocations = [];
  locationsList = [];
  stateList = [];
  subcription: Subscription;
  userSessionDetails: any;
  noDataFoundFlag: boolean = false;
  associationTabData: any;
  associationTabPhyData: any;
  surgiCenterDetails: any = [];
  isProvidersMenu: boolean = false;
  casetypeList: any;
  caseTypeTitleArr: any;
  seUserList: any;
  courierUserList: any;
  casetypesCount: any;
  showToolTip: boolean = false;
  toolTipContent: any = [];
  LabOrganizations: any[];
  currentCategory: string;
  associateSurgicenterIntialData: any;
  associateSurgicenterSavedData: any;
  associatedSavedSurgicenterData: any;
  associatePhysicianInitialData: any;
  associatePhysiciaSavedData: any;
  associatedSavedPhysicianData: any;
  associateOFIntialdata: any;
  associateOFSavedData: any;
  associatedSavedOFData: any;

  constructor(
    private vitalHttpServices: VitalHttpServices,
    private labAdminservice: LabadminService,
    private commonService: CommonService,
    private store: Store<{ breadcrum: any }>,
    private labAdminSessionService: LabadminSessionService
  ) {
    store.select('breadcrum').subscribe((data) => {
      this.stateList = data;
      let currentMenu = data?.[data?.length - 1];
      this.isProvidersMenu = currentMenu['routerLink'] === '/labadmin/clients/orders';
    });
  }
  ngOnInit() {}
   

  ngOnChanges() {
    if (this.fromLocation == 'location') {
      let _this=this
       this.labAdminSessionService.getLabAdminSession.subscribe(async session => {
        if (Object.keys(session["userDetails"]).length > 1) {
          _this.userSessionDetails = session["userDetails"]
        }})
      this.getLabLocationList();
    }
    else {
      if (
        this.stateList.length > 0 &&
        this.userDetails['DepartmentIds'] !== null &&
        this.userDetails['DepartmentId'] !== ''
      ) {
        this.locationsList = this.userDetails['labLocationList']
        this.getworkGroup();
        this.getPathList();
        this.getAssociateSurgicenterData();
      }
      else {
        if (
          this.stateList.length > 0 &&
          this.userDetails['DepartmentIds'] !== null &&
          this.userDetails['DepartmentId'] !== ''
        ) {
          this.locationsList = this.userDetails['labLocationList']
          this.getworkGroup();
          this.getPathList();
        }

        if (this.userRoles?.length > 0) {
          let sales = this.userRoles.find((va) => va.Role === 'Sales Executive');
          if (sales !== undefined) {
            this.getFacilities(sales.UserID);
          }
          else if (this.isProvidersMenu) {
           return true
          }
          else
            this.noDataFoundFlag = true;
        }
        else
          this.noDataFoundFlag = true;
      }
    }


  }
  getLabLocationList() {
    this.labAdminservice.getLabLocations(0, this.userSessionDetails.userId, this.locationDetails.AccountID, this.labAdminservice.deploymentKey)
      .toPromise()
      .then((data: any) => {
        let faclicatity = JSON.parse(JSON.stringify(data));
        if (data.length > 0) {

          faclicatity.forEach((element) => {
            element.sublocation = data.filter(va => va.OrganizationName === element.OrganizationName)
          })
        }
        const seen = new Set();
        faclicatity = faclicatity.filter(item => {
          const duplicate = seen.has(item.OrganizationName);
          seen.add(item.OrganizationName);
          return !duplicate;
        });
        this.LabOrganizations = faclicatity;
        this.getFacilityCasetypes();
      })
      .catch(error => {
        // Handle error
        console.error('An error occurred:', error);
      });

  }

  checkInfoAssociation() {
    if (this.userRoles?.length)
      return this.facilityLocations?.length || this.locationsList?.length;
    return this.workGroupList?.length;

  }

  getFacilityCasetypes() {
    let accountids=[];
    if (this.userSessionDetails?.userAccountIDs?.length > 0) {
      this.userSessionDetails.userAccountIDs.forEach((element) => {
        accountids.push(element.accountId);
      });
    }
    let ids = accountids.join(',');
    this.labAdminservice.getCaseCategoryByAccId(this.locationDetails.AccountID, this.userSessionDetails.organizationId,ids ,this.labAdminservice.deploymentKey).toPromise().then(value => {
      this.casetypeList = value.map(category => ({...category,Cases: category.Cases.filter(caseItem => caseItem.IsSelected)})).filter(category => category.Cases.length > 0);
      console.log(this.casetypeList)
      this.casetypesCount = this.casetypeList.flatMap(category => category.Cases).length;
      let arr = structuredClone(this.casetypeList);
      let resultObj:any = {};
      arr.forEach(item => {
        resultObj[item.Category] = item.Cases.map(caseItem => caseItem.CaseTypeDisplayname)
      });
   
      this.caseTypeTitleArr = resultObj;
      this.getSalesExecutiveUsers(ids);
    })
      .catch(error =>
        console.error(error)
      )
  }
  getSalesExecutiveUsers(accountIds) {
    this.labAdminservice.getSalesExecutiveAndCourierData(this.locationDetails.OrganizationID, this.locationDetails.AccountID, accountIds, this.labAdminservice.deploymentKey).toPromise().then(result => {
      this.seUserList = result.OFUserList.filter(va => va.RoleName == 'Sales Executive')
      this.courierUserList = result.OFUserList.filter(va => va.RoleName == 'Courier')
      if (this.locationDetails.IsSharedSurgicenter) {
        this.getSurgicenter(accountIds);
      }
      else {
        this.getAssociatedSurgiCeneter(accountIds)
      }
    })
      .catch(error =>
        console.error(error)
      )
  }
  getSurgicenterPhy() {
    this.labAdminservice.getPhysicinData(this.locationDetails.AccountID, this.userSessionDetails.userId, this.labAdminservice.deploymentKey)
      .subscribe({
        next: (data) => {
          this.associatePhysicianInitialData = data.filter((i) => !i.IsActive);
          this.associatePhysiciaSavedData = data.filter((i) => i.IsActive);
          this.associatePhysiciaSavedData
            ? (this.associatedSavedPhysicianData =
              this.associatePhysiciaSavedData)
            : (this.associatedSavedPhysicianData = []);
          
        },
        error: (err) => console.error(err),
      });
  }
  getAssociatedSurgiCeneter(accountIds) {
    this.labAdminservice.getSharedSurgiCenterForOF(this.locationDetails.AccountID, accountIds, 4, this.labAdminservice.deploymentKey)
      .subscribe({
        next: (data) => {
          this.associateSurgicenterIntialData = data.filter(
            (i: any) => !i.IsActive
          );
          this.associateSurgicenterSavedData = data.filter(
            (i: any) => i.IsActive
          );
          this.associateSurgicenterSavedData ? (this.associatedSavedSurgicenterData =
            this.associateSurgicenterSavedData) : (this.associatedSavedSurgicenterData = []);
            this.getSurgicenterPhy();
        },
        error: (err) => {
          console.error(err);
        },
      });
  }
  getSurgicenter(accountIds) {
    this.labAdminservice.getSharedSurgiCenterForOF(this.locationDetails.AccountID, accountIds, 3, this.labAdminservice.deploymentKey)
    .subscribe({
      next: (data) => {
        this.associateOFIntialdata = data.filter((i: any) => !i.IsActive);
          this.associateOFSavedData = data.filter((i: any) => i.IsActive);
          this.associateOFSavedData
            ? (this.associatedSavedOFData = this.associateOFSavedData)
            : (this.associatedSavedOFData = []);
        },
      error: (err) => {
        console.error(err);
      },
    });
  }
  // pathologist list in chip
  getPathList() {
    this.labAdminSessionService.getLabAdminSession.subscribe(
      (session) => {
        let ofAccount = '';
        if (this.userDetails && Array.isArray(this.userDetails['labLocationList'])) {
          ofAccount = this.userDetails['labLocationList']
            .filter(location => location && location.AccountId != null)
            .map(location => location.AccountId)
            .join(',');
        }
        let executiveUserId = this.userRoles.find((va) => va.Role === 'Pathologist Assistant');
        let medexecexecutiveUserId = this.userRoles.find((va) => va.Role === 'Medical Assistant');
        if (executiveUserId != undefined || medexecexecutiveUserId != undefined) {
          if (executiveUserId != undefined) {
            this.labAdminservice
              .getPathList(this.labAdminservice.organizationId, executiveUserId.UserID, ofAccount, '', 'pathologist', this.labAdminservice.deploymentKey)
              .subscribe((data) => {
                if (data) {
                  if (medexecexecutiveUserId != undefined) {
                    this.labAdminservice
                      .getPathList(this.labAdminservice.organizationId, medexecexecutiveUserId.UserID, ofAccount, '', 'Physician', this.labAdminservice.deploymentKey)
                      .subscribe((medstafdata) => {
                        this.associationTabPhyData = medstafdata;
                        this.associationTabData = data;
                      });

                  }
                  else {
                    this.associationTabData = data;
                  }
                }
              });
          }
          else {
            if (medexecexecutiveUserId != undefined) {
              this.labAdminservice
                .getPathList(this.labAdminservice.organizationId, medexecexecutiveUserId.UserID, ofAccount, '', 'Physician', this.labAdminservice.deploymentKey)
                .subscribe((medstafdata) => {
                  this.associationTabPhyData = medstafdata;
                });
            }
          }
        }
      });

  }

  getworkGroup() {
    // this.userDetails['DepartmentIds']="3,4,5,6"
    let newQuery = this.vitalHttpServices.mainQueryList.filter(
      (x) => x.Keyword == 'organizationworkgroups'
    )[0].Query;
    let OrgId = this.labAdminservice.organizationId;
    // let queryString = "OrganizationId in \""+OrgId+"\""
    let newQueryVariable = {
      organizationId: this.labAdminservice.organizationId,
    };
    let newQueryResult = this.commonService.GetCardRequest(
      newQueryVariable,
      newQuery
    );
    this.vitalHttpServices
      .GetData(newQueryResult, this.labAdminservice.deploymentKey)
      .subscribe((newResData) => {
        this.workGroupList = []
        let data = newResData.data.Admin3_VwGetOrganizationDepartments_list;
        if (this.userDetails !== '') {
          let ids = this.userDetails['DepartmentIds']?.split(',');
          if (ids.length > 0 && data.length > 0) {
            for (let i = 0; i < ids.length; i++) {
              let obj = data.find((va) => va.DepartmentId === parseInt(ids[i]));
              if (obj !== undefined) {
                this.workGroupList.push(obj);
              }
            }
          }
        }
      });
  }

  getFacilities(userid: string) {
    let accountids = [];
    this.facilityLocations = []
    this.subcription = this.labAdminSessionService.getLabAdminSession.subscribe(
      (session) => {
        this.userSessionDetails = session['userDetails'];
        if (this.userSessionDetails?.userAccountIDs?.length > 0) {
          this.userSessionDetails.userAccountIDs.forEach((element) => {
            accountids.push(element.accountId);
          });
        }
        let ids = accountids.join(',');
        this.labAdminservice
          .getOFAssociationForSE(ids, Number(userid), this.labAdminservice.deploymentKey)
          .subscribe((data) => {
            let faclicatity = JSON.parse(JSON.stringify(data));
            if (data.length > 0) {

              faclicatity.forEach((element) => {
                element.sublocation = data.filter(va => va.OrganizationName === element.OrganizationName)
                data = data.filter(va => va.OrganizationName !== element.OrganizationName)
              })
            }
            this.facilityLocations = faclicatity;
          });
      }
    );
  }

  ngOnDestroy() {
    if (this.subcription) {
      this.subcription.unsubscribe();
    }
  }
  getAssociateSurgicenterData() {
    let userData: any;
    this.labAdminSessionService.getLabAdminSession.subscribe(Response => userData = Response);
    this.labAdminservice.GetSharedSurgiAccountsData
      (this.userDetails['UserID'], userData.userDetails.userId, this.labAdminservice.deploymentKey)
      .subscribe({
        next: (data) => {
          this.surgiCenterDetails = data
        },
        error: (err) => {
          console.error(err);
        }
      });
  }

  //   this.subcription.unsubscribe();
  // }
  onMouseEnter(val) {
    this.currentCategory = val['Category'];
    this.toolTipContent = this.caseTypeTitleArr?.[val['Category']];
    this.showToolTip = true
  }
  onLeaveMouse() {
    this.currentCategory = '';
    this.toolTipContent = '';
    this.showToolTip = false
  }
}

