import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { saveAs as importedSaveAs } from "file-saver";
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Observable, catchError, map, of, shareReplay, tap } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-bulk-upload',
  templateUrl: './user-bulk-upload.component.html',
  styleUrls: ['./user-bulk-upload.component.scss']
})
export class UserBulkUploadComponent implements OnChanges, OnInit {

  @Input() type: 'users' | 'labusers' = 'users'
  @Input() file: any
  @Output() onReturn = new EventEmitter()
  @Input() sessionData: any

  headings: string[] = ['Upload Status', 'Npi', 'Login Name', 'Email', 'First Name', 'Last Name', 'Middle Initials', 'Title', 'Gender', 'Edu Qualification', 'Address 1', 'Address 2', 'Street', 'City', 'State', 'Zip', 'Phone Number', 'Fax', 'User Code', 'Role', 'SSN', 'Country', 'Upin', 'Account', 'Ordering Facility', 'Reading Location', 'Licensure']
  dataProperties: string[] = ['ValidationResponse', "NPI", 'LoginName', 'Email', "FirstName", "LastName", "MiddleInitials", "Title", "Gender", "EducationQualification", "Address1", "Address2", "Street", "City", "State", "Zip", "Phone", "Fax", "UserCode", "Role", "SSN", "Country", "Upin", "Account", "OrderingFacility", "ReadingLocation", "Licensure"]
  bulkUploadData$: Observable<bulkUploadUser[]>
  isDataInvalid: boolean = false
  userDataToUpload : bulkUploadUser[] = []
  bodyClass : 'bulk-user-body-loc' | 'bulk-user-body' = 'bulk-user-body'
  headerClass: 'bulk-user-header-loc' | 'bulk-user-header' = 'bulk-user-header'
  warningMessages : string
  reuploadPopupText: string = 'Facility'

  constructor(
    private _labAdminService: LabadminService,
    private _dailogService: DialogService,
    private _snackbar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    if(this.type === 'labusers'){
      this.headings.splice(24, 1)
      this.dataProperties.splice(24, 1)
      this.bodyClass = 'bulk-user-body-loc'
      this.headerClass = 'bulk-user-header-loc'
      this.reuploadPopupText = 'Lab'
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file) {
      this.reloadTable()
    }
  }

  private reloadTable() {
    this.isDataInvalid = false
    var formData: any = new FormData()
    formData.append('', this.file)
    this.bulkUploadData$ = this._labAdminService.ValidateUploadedData(this.type, this.sessionData.userDetails.organizationId, formData, this._labAdminService.deploymentKey).pipe(
      (
        catchError((err) =>{
          console.error(err)
          //this.warningMessages = "Unable to upload the file. Please check the template or try after some time."
          return of('error')
        }),
        map((res) => {    
          this.warningMessages = ''
          if(res === 'error'){
            this.warningMessages = "Unable to upload the file. Please check the template or try after some time."
            return []
          }
          else if(res.length === 0){
            this.isDataInvalid = res.length === 0
            this.warningMessages = "No Records Found"
            return []
          }
          else return this.setErrorObject(res)
        })
      )
    )
  }

  setErrorObject(usersData: bulkUploadUser[]) {
    this.userDataToUpload  = [...usersData]
    usersData.forEach(user => {
      if (user.ValidationResponse.toLowerCase() === 'failed') {
        var errorObject = this.getError(user.ValidationMessage)
        user.errorCount = Object.keys(errorObject).length
        this.isDataInvalid = true
        user.filteredValidationMessage = Object.values(errorObject).join('\n\r')

        Object.keys(errorObject).forEach(errorKey => {
          user[`is${errorKey}`] = user[errorKey] ? 'show-error-border' : 'show-error-empty'        
        })
        
      }
      else user.errorCount = 0
    });
    console.log(usersData)
    return usersData
  }

  onReupload() {
    this._dailogService.openUserBulkUploadDailog(`Bulk Upload ${this.reuploadPopupText} User(s)`,this.type).afterClosed().subscribe({
      next: (file) => {
        if (file) {
          this.file = file
          this.reloadTable()
        }
      }
    })
  }

  returnToList = () => this.onReturn.emit()

  onSave() {
    var bulkUserObj = {
      SessionData:{
        LoggedInUserId: this.sessionData.userDetails.userId,
        ApplicationDomain:this.sessionData.labAminSession.data.domainUrl,
        LoggedInUserOrgName: this.sessionData.userDetails.orgName,
        LoggedInUserOrgGuid: this.sessionData.userDetails.userOrgGUID,
        LoggedInUserOrdId: this.sessionData.userDetails.organizationId
    },
    userBulkUploads: this.userDataToUpload
    }
    
    this._labAdminService.saveUploadedUsers(this.type, bulkUserObj, this._labAdminService.deploymentKey).subscribe({
      next: (res) => {
        this._snackbar.open('Users Added Successfully', 'close')
        this.returnToList()
      },
      error: (err) => console.error(err)
    })
  }

 private getError(errors : string){
  var errorObject = {}
  var temperrors = errors.split(';\n\r')
  temperrors.forEach(err => {
    var keyvalue = err.split(':')
    if(errorObject.hasOwnProperty(keyvalue[0])) errorObject[keyvalue[0]] = `${errorObject[keyvalue[0]]}, ${keyvalue[1]}`
    else errorObject[keyvalue[0]] = keyvalue[1]
  })
  return errorObject
 }
}

export interface bulkUploadUser {
  ValidationMessage: string
  ValidationResponse: string
  LoginName: string
  Email: string
  Title: string
  FirstName: string
  LastName: string
  MiddleInitials: string
  Gender: string
  NPI: string
  EducationQualification: string
  Address1: string
  Address2: string
  Street: string
  City: string
  State: string
  Zip: string
  Phone: string
  Fax: string
  UserCode: string
  Role: string
  SSN: string
  Country: string
  Upin: string
  Account: string
  OrderingFacility: string
  ReadingLocation: string
  Licensure: string
  errorCount: number
  filteredValidationMessage: string
  isEmail: string
  isFirstName: string
  isLastName: string
  isTitle: string
  isNPI: string
  isUserCode: string
  isLoginName: string
  isRole: string
  isAccount: string
  isReadingLocation: string
  isLicensure: string
  isFax: string
}