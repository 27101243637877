import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroupDirective } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {  MatRadioGroup } from '@angular/material/radio';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import '@grapecity/wijmo.input';
//import '@grapecity/wijmo.styles/wijmo.css';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { CommonService } from '../../../core/services/commonservices';
import { DataShareService } from '../../../core/services/datashare.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
// import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

declare var $: any;

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-json-format',
  templateUrl: './json-format.component.html',
  styleUrls: ['./json-format.component.scss']
})
export class JsonFormatComponent implements OnInit {

  task: Task = {
    name: 'Indeterminate',
    completed: false,
    color: 'primary',
  };

  FormDataEntry: boolean;
  public SubMenuCardModel;
  RoleList: any = [];
  suggestionList: any;
  public RoleKeys = [];
  cardTypeSuggestionList: any;
  permissionSuggestionList: any;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @ViewChild(MatRadioGroup, { static: false }) radioGroup: MatRadioGroup;
  RoleAdding: Boolean = false;
  showGrid: Boolean = false;
  PermissionUpdating: Boolean = false;
  roleForm = new UntypedFormControl();
  parentNode: any;
  jsonValid: boolean = true;
  private _currentEditItem: any = null;
  @ViewChild('flex', { static: true }) flex: wjcGrid.FlexGrid;
  selectedType: any;
  templateData: any;
  noData: boolean;
  search: String = '';
  gridHeader: string[] = [
    'RoleId',
    'RoleName',
    'Description',
    'PriorityOrder',
    'Status',
    'IsAllowAllUsers'
  ];
  gridData: wjcCore.CollectionView<any>;
  showPaginationMainGrid: boolean;
  gridwidth: number;
  statusread: boolean = true;
  isAllowAllusersRead: boolean = true;
  arraydata: any = [];
  public DeploymentKeys = [];
  selectedCardType: any;
  selectedPermission: any;
  selectedRoleName: any;
  saveBtn: boolean = true;
  SetPermissions: any = null;
  RoleGrid: any = null;
  value: any = null;
  UserEnteredDataDiv: boolean = false;
  updateBtn: Boolean = false;

  constructor(
    private ngxService: NgxUiLoaderService,
    public vaservices: VitalHttpServices,
    private _fb: UntypedFormBuilder,
    private commonService: CommonService,
    private _snackbar: MatSnackBar,
    private datashare: DataShareService,
    private clipboard: Clipboard,
    public _snackBar: MatSnackBar,
    public datepipe: DatePipe
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vaservices,
      datashare
    );
  }


  JsonformatClose() {
    // this.value = null;
    // this.radioGroup.value = null;
    this.showGrid = true;
    this.PermissionUpdating = false;
    this.UserEnteredDataDiv = false;
    this.RoleAdding = false;
    this.JsonFormat.patchValue({
      RoleNameGroup: '',
      CardTypeGroup: '',
      permissionGroup: ''
    });
    // this.JsonFormat.reset();
    $('#genericModal').modal('hide');
    this.formGroupDirective.resetForm();
  }

  ngOnInit(): void {
    this.showGrid = true;
    this.UserEnteredDataDiv = true;
    this.getRoleGrid(this.value);
    this.RoleSuggestion();
  }

  JsonFormat = this._fb.group({
    RoleNameGroup: '',
    CardTypeGroup: '',
    permissionGroup: '',
    RoleNameAdd: '',
    DescriptionAdd: '',
    IsAllowAllUsersAdd: '',
    PriorityOrderAdd: '',
    StatusAdd: '',
    RoleName: '',
    CardType: '',
    permission: '',
    Permission : '',
    CreatedBy : '',
    ModifiedBy : '',
    IsAllowAllUsers: false,
    Description: '',
    PriorityOrder: '',
    Status: true
  });

  backSelect() {
    this.RoleAdding = false;
    this.showGrid = true;
    this.PermissionUpdating = false;
    this.UserEnteredDataDiv = false;
    this.JsonFormat.patchValue({
      RoleNameGroup: '',
      CardTypeGroup: '',
      permissionGroup: ''
    });
    // this.JsonFormat.reset();
    this.formGroupDirective.resetForm();
    this.getRoleGrid(this.value);
    this.RoleSuggestion();
  }

  //Handling 2 forms
  get formcontrol() {
    return this.JsonFormat.controls;
  }

  //Role suggestion for card type and updating the permission
  async RoleSuggestion() {
    let queryVariable = {};
    let query = this.SubMenuCardModel.GetQuery('GetAllVitalAdminUserRoles');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vaservices.GetData(queryResult, 'configdb').toPromise().then(
      data => {
        if (!data.errors) {
          if (data.data.submenuData) {
            this.suggestionList = data.data.submenuData;
            data.data.submenuData.forEach(element => {
                this.RoleList.push(element.RoleName);
            });
            this.RoleList = [...new Set(this.RoleList)].sort();
          }
        }
      },
      error => {
        console.error(error);
      }
    );
  }
  //Card type suggestion
  cardTypeSuggestion(value,resetCardType? : boolean) {
    if(resetCardType)
    this.JsonFormat.patchValue({
      CardTypeGroup : '',
      permissionGroup : ''
    });
    this.selectedRoleName = value;
    this.cardTypeSuggestionList = this.suggestionList.filter(
      va => va.RoleName == value
    );
    this.cardTypeSuggestionList = [...new Set(this.cardTypeSuggestionList)];
    this.cardTypeSuggestionList = this.cardTypeSuggestionList.sort((a, b) => a.CardType < b.CardType ? -1 : a.CardType > b.CardType ? 1 : 0)
  }
  //permission in json format
  async permissionSuggestion(event) {
    try{
      let value = event.value;
      this.ngxService.start('fetchPermissions');
      await this.RoleSuggestion();
      this.cardTypeSuggestion(this.selectedRoleName);
      this.selectedCardType = value;
      this.permissionSuggestionList = this.cardTypeSuggestionList.find(
        va => va.CardType == value
      );
      this.JsonFormat.patchValue({
        permissionGroup: this.permissionSuggestionList.Permission
      });
    }
    catch(error) {
      console.error(error);
    }
    finally{
      this.ngxService.stop('fetchPermissions');
    }
  }

  //Inline edit grid
  initializeGrid(flex: wjcGrid.FlexGrid) {
    flex.rows.defaultSize = 40;
    flex.formatItem.addHandler(
      (s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
        if (e.panel == s.cells) {
          let col = s.columns[e.col],
            item = s.rows[e.row].dataItem;
          if (item == this._currentEditItem) {
            // create editors and buttons for the item being edited
            if (this.JsonFormat.value) {
              switch (col.binding) {
                case 'buttons':
                  e.cell.innerHTML = document.getElementById(
                    'tplBtnEditMode'
                  ).innerHTML;
                  e.cell['dataItem'] = item;
                  break;
                case 'Id':
                case 'RoleName':
                case 'Description':
                case 'PriorityOrder':
                case 'Status':
                case 'IsAllowAllUsers':
                  break;
              }
            } else if (this.templateData) {
              switch (col.binding) {
                case 'buttons':
                  e.cell.innerHTML = document.getElementById(
                    'tplBtnEditMode'
                  ).innerHTML;
                  e.cell['dataItem'] = item;
                  break;
                case 'Id':
                case 'RoleName':
                case 'Description':
                case 'PriorityOrder':
                case 'Status':
                case 'IsAllowAllUsers':
                  break;
              }
            }
          } else {
            // create buttons for items not being edited
            switch (col.binding) {
              case 'buttons':
                e.cell.innerHTML = document.getElementById(
                  'tplBtnViewMode'
                ).innerHTML;
                e.cell['dataItem'] = item;
                break;
            }
          }
        }
      }
    ),
      // handle button clicks
      flex.addEventListener(flex.hostElement, 'click', (e: MouseEvent) => {
        let targetBtn: HTMLButtonElement;
        if (e.target instanceof HTMLButtonElement) {
          targetBtn = e.target;
        } else if (
          e.target instanceof HTMLElement &&
          e.target.classList.contains('fa')
        ) {
          targetBtn = e.target.parentElement as HTMLButtonElement;
        }
        if (targetBtn) {
          // get button's data item
          let item = wjcCore.closest(targetBtn, '.wj-cell')['dataItem'];
          // handle buttons
          switch (targetBtn.id) {
            // start editing this item
            case 'btnEdit':
              this.statusread = false;
              this.isAllowAllusersRead = false;
              this.flex = flex;
              this._editItem(item);
              break;
            // remove this item from the collection
            case 'btnDelete':
              (<wjcCore.CollectionView>flex.collectionView).remove(item);
              break;
            // commit edits
            case 'btnOK':
              this._commitEdit();
              break;
            // cancel edits
            case 'btnCancel':
              this._cancelEdit();
              break;
          }
        }
      });
    // exit edit mode when scrolling the grid or losing focus
    flex.scrollPositionChanged.addHandler(this._cancelEdit.bind(this));
    flex.lostFocus.addHandler(this._cancelEdit.bind(this));
  }
  //edit in grid
  private _editItem(item: any) {
    $('');
    this.statusread = false;
    this.isAllowAllusersRead = false;
    this._cancelEdit();
    this._currentEditItem = item;
    this.flex.invalidate();
  }
  //ok in grid
  private _commitEdit() {
    if (this._currentEditItem) {
      this.flex.columns.forEach((col: wjcGrid.Column) => {
        let input = <HTMLInputElement>(
          this.flex.hostElement.querySelector('#' + col.binding)
        );
        if (input) {
          let value = wjcCore.changeType(input.value, col.dataType, col.format);
          if (wjcCore.getType(value) == col.dataType) {
            this._currentEditItem[col.binding] = value;
          }
        }
      });
    }
    this.UpdateRole(this._currentEditItem);
    this._currentEditItem = null;
    this.flex.invalidate();
  }
  //Cancel in grid
  private _cancelEdit() {
    if (this._currentEditItem) {
      this.isAllowAllusersRead = true;
      this.statusread = true;
      this.getRoleGrid(this.value);
      this._currentEditItem = null;
      this.flex.invalidate();
    }
  }
  //flag to handle the permission
  showPermission(event) {
    this.UserEnteredDataDiv = true;
    this.PermissionUpdating = true;
    this.RoleAdding = false;
    this.showGrid = false;
    this.updateBtn = true;
    this.formGroupDirective.resetForm();
  }

  //Validating Json
  JsonValidate(value) {
    try {
      JSON.parse(value);
      this.jsonValid = true;
      this.updateBtn = false;
    } catch (e) {
      this.jsonValid = false;
      // this.disableSave(value);
      this.updateBtn = true;
    }
  }
  //flag for Update button
  disableSave(value) {
    if (
      JSON.stringify(value) === JSON.stringify(this.permissionSuggestionList.Permission)
      //!(value,this.permissionSuggestionList.Permission)
    ) {
      return true;
    } else {
      return false;
    }
  }
  //Role add form
  InsertRoleForm() {
    // if (this.radioGroup) {
    //   this.radioGroup.value = null;
    //   this.value = this.radioGroup;
    // }
    this.UserEnteredDataDiv = true;
    this.RoleAdding = true;
    this.showGrid = false;
    this.PermissionUpdating = false;
    this.JsonFormat.patchValue({
      RoleName: '',
      Description: '',
      IsAllowAllUsers: false
    });
  }
  //role grid
  getRoleGrid(value) {
    // this.value = value;
    this.showGrid = true;
    let query = '';
    this.RoleAdding = false;
    let queryVariable = {};
    let dbName = 'configdb';
    query = this.SubMenuCardModel.GetQuery('getAdminRoles');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vaservices.GetData(queryResult, dbName).subscribe(
      data => {
        if (!data.errors) {
          this.templateData = data.data;
          if (this.templateData.submenuData.length > 0) {
            this.addGridData(this.templateData);
            this.showGrid = true;
            this.PermissionUpdating = false;
            this.noData = false;
          } else {
            this.showGrid = false;
            this.PermissionUpdating = false;
            this.noData = true;
          }
        }
      },
      error => {
        this.showGrid = false;
        this.PermissionUpdating = false;
        this.noData = true;
        console.error(error);
      }
    );
  }
  //add role data to the grid
  addGridData(templateData: any): any {
    let primary = {};
    let gridArray = [];
    for (let i = 0; i < templateData.submenuData.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData.submenuData[i])) {
        for (let j = 0; j < this.gridHeader.length; j++) {
          if (key.toLowerCase() == this.gridHeader[j].toLowerCase()) {
            primary[key] = value;
          }
        }
      }
      gridArray.push(primary);
    }
    var grid = gridArray.sort((a, b) => a.RoleName < b.RoleName ? -1 : 1)
    if (grid.length > 0) {
      this.gridData = new wjcCore.CollectionView(grid, { pageSize: 10 });
      this.showGrid = true;
      this.PermissionUpdating = false;
      this.noData = false;
    } else {
      this.noData = true;
      this.showGrid = false;
      this.PermissionUpdating = false;
    }
    this.showPaginationMainGrid = grid.length > 10 ? true : false;
    this.gridwidth = (170 * this.gridHeader.length) + 37;
    if (this.gridwidth > 1300) {
      this.gridwidth = 1300;
    }
  }
  //update values of entity from grid
  PermissionUpdate() {
    let obj = this.JsonFormat.value;
    obj.RoleName = this.JsonFormat.value.RoleNameGroup;
    obj.CardType = this.JsonFormat.value.CardTypeGroup;
    obj.Permission = this.JsonFormat.value.permissionGroup;
    obj.CreatedBy = localStorage.getItem('UserEmail');
    obj.PriorityOrder = this.JsonFormat.value.PriorityOrder;
    if (!JSON.parse(obj.Permission)) {
      this._snackbar.open('Permission is not a valid json!', 'Close');
    } else {
      this.vaservices.UpdatePermission(obj, 'configdb').subscribe(
        result => {
          if (!result.errors) {
            this._snackbar.open('Permission Updated successfully', 'Close');
            this.RoleSuggestion();
          }
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  //inserting new role
  InsertNewRole() {
    let obj = this.JsonFormat.value;
    obj.RoleName = this.JsonFormat.value.RoleName;
    obj.CardType = this.JsonFormat.value.CardType;
    obj.Permission = this.JsonFormat.value.permission;
    obj.CreatedBy = localStorage.getItem('UserEmail');
    obj.ModifiedBy = localStorage.getItem('UserEmail');
    obj.IsAllowAllUsers = this.JsonFormat.value.IsAllowAllUsers;
    obj.PriorityOrder = this.JsonFormat.value.PriorityOrder;
    if (
      this.JsonFormat.value.RoleName == '' ||
      this.JsonFormat.value.Description == ''
    ) {
      this._snackbar.open(' Role Created values', 'Close');
      return;
    } else {
      this.vaservices.InsertNewRole(obj, 'configdb').subscribe(
        result => {
          if (!result.errors) {
            this._snackbar.open(result.Message, 'Close');
            this.RoleSuggestion();
            this.getRoleGrid(obj);
          }
        },
        error => {
          console.error(error);
        }
      );
    }
  }
  //Updating new role
  UpdateRole(editItem) {
    this.vaservices.UpdateRole(editItem, 'configdb').subscribe(
      result => {
        if (!result.errors) {
          this._snackbar.open('Role Updated successfully', 'Close');
          this.statusread = true;
          this.isAllowAllusersRead = true;
          // this.rolenameRead = true;
        }
      },
      error => {
        console.error(error);
      }
    );

  }
}
