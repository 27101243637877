<div class="input-container" *ngIf="!formarrayname">
  <div class="input-elements">
    <input #ref [placeholder]="placeholder" [mask]="mask" [type]="type" [formControlName]="formcontrolname"
      [prefix]="''" [class.cform-in-valid]="form.invalid && form.touched" [maxLength]="maxLength"
      [dropSpecialCharacters]="false" [specialCharacters]="['x','.','-','|','+','(',')',' ']" [validation]="false"
      [disabled]="isDisabled" [readonly]="isReadOnly" />
    <label>{{labelValue}}
      <ng-container *ngIf="required">
        <span class="text-danger fw-bold fs-6">*</span>
      </ng-container>
    </label>
    <b *ngIf="subscript!=''">({{subscript}})</b>
    <img class="side-image" [src]="img" *ngIf="imageType === 'side'" (click)="onImageClick.emit()" />
    <img class="whole-image" [src]="img" *ngIf="imageType === 'whole'" (click)="onImageClick.emit()" />
  </div>
</div>

<div class="input-container" *ngIf="formarrayname" [formArrayName]="formarrayname">
  <div class="input-elements" [formGroupName]="formgroupname">
    <input #ref [placeholder]="placeholder" [mask]="mask" [type]="type" [formControlName]="formcontrolname"
      [prefix]="''" [class.cform-in-valid]="form.invalid && form.touched" [maxLength]="maxLength"
      [dropSpecialCharacters]="false" [specialCharacters]="['x','.','-','|','+','(',')',' ']" [validation]="false"
      [disabled]="isDisabled" [readonly]="isReadOnly" />
    <label>{{labelValue}}
      <ng-container *ngIf="required">
        <span class="text-danger fw-bold fs-6">*</span>
      </ng-container>
    </label>
    <b *ngIf="subscript!=''">({{subscript}})</b>
  </div>
</div>