// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration=beta` replaces `environment.ts` with `environment.beta.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '0.0.88',
  mode: 'beta',
  envPrefix: 'VABeta',

  fusionauth: "https://oauthbeta.vitalaxis.com/oauth2/",
  clientid: "1208bc19-cfa4-4732-92a6-e6f28f3cae43",
  client: "https://bcmadminbeta.vitalaxis.com/",
  domain: ".vitalaxis.com",

  serverURL: 'https://bcmadminbetaapi.vitalaxis.com/api/graphql',
  baseUrl2: 'https://bcmadminbetaapi.vitalaxis.com/',
  ReportUrl: 'https://betareportspreviewapi.vitalaxis.com/',
  loginapiURL: "https://bcmadminbetaapi.vitalaxis.com/api/",
  elasticapiURL: "https://adminbetasearch.vitalaxis.com/api",
  dxTemplateUrl:"",
  hidCMSCATURl1: 'https://cdn.contentful.com/spaces/v18w5t57iphz',
  hidCMSCATCSKTKN: '0eabd5543f3ebb75e5349a2338cd1568a8f9384ae65d01216efbe2ab46f97eae',
  
  IPAddressURL: 'https://api.ipify.org/?format=json',
  IpRegionCheck: 'https://whatismyipaddress.com/ip/',
  VitalBridgeApiURL: 'https://vbadmin1.vitalaxis.net:856/',
  EnableimportButton: 1,
  sessionTimeout: 7080000, // 120 minutes  (60000*120)
  VitalBridgeRestApiURL: "https://vbfilemonitor.vitalaxis.net/api/getvbagentinfobyorgguid?orgguid=",
  NPIValidation: 'https://npilookup.azurewebsites.net/api/v1/lookup/bynpi',
  DefaultRole: 'Associate',

  OrgCompare_GroupByKeys: ['0.OrganizationAttributes.AttributeType'],
  OrgCompare_BlockLevel_Menu: [
    //syntax is menu_name:Selected_Json_Path:is_group_by:If(group by is true): group upon(optional)
    'Organizations:0.Organizations:false',
    'Organization Attributes:0.OrganizationAttributes:false',
    'Organization Password Policies:0.OrganizationPasswordPolicies:false',
    // 'Organization Password Policies -> Pwd Policy Name:0.OrganizationPasswordPolicies.PwdPolicyName:true',
    'GI Histology:0.OrganizationAttributes.AttributeContext:true:GI Histology',
    'GI Histology_PLUS:0.OrganizationAttributes.AttributeContext:true:GI Histology_PLUS',
    'RuleOut Options:0.OrganizationAttributes.AttributeType:true:RuleoutOptions'
  ],
  OrgCompare_sortByValue: [
    '0.Organizations.JSONSequence',
    '0.OrganizationAttributes.JSONSequence',
    '0.OrganizationPasswordPolicies.JSONSequence',
    '0.OrganizationTags.JSONSequence',
    '0.OrganizationTagValues.JSONSequence',
    '0.OrganizationRules.JSONSequence',
    '0.OrganizationAdequacyCodes.JSONSequence',
    '0.organizationcustomworklist.JSONSequence',
    '0.AccountCasetype.JSONSequence',
    '0.OrganizationSites.JSONSequence',
    '0.ExtractionProcedures.JSONSequence',
    '0.OrganizationDiagTemplates.JSONSequence',
    '0.IdentityFormat.JSONSequence',
    '0.OrganizationSitesOrder.JSONSequence',
    '0.OrganizationCommentstemplates.JSONSequence',
    '0.OrganizationReviseTemplates.JSONSequence',
    '0.OrganizationDeleteTemplates.JSONSequence',
    '0.OrganizationStatusDataDefinition.JSONSequence',
    '0.OrganizationOrderCodes.JSONSequence'
  ],
  OrgCompare_RemoveKeys: [
    '0.OrganizationAttributes.AttributeContextId',
    '0.OrganizationAttributes.IsActive'
  ],
  //'0.OrganizationAttributes.AttributeContextId'
  casetypes_list: [
    'dermatology',
    'gyn molecular',
    'urovysion fish',
    'urofish test',
    'pten fish',
    'promark',
    'pten / p53',
    'pro-fish',
    'prolaris',
    'terc fish',
    'urine fish',
    'urine fish only',
    'bladder biopsy',
    'bladder bx old',
    'bladder fish',
    'breast biopsy',
    'breast fna',
    'cervical fish',
    'colon fish',
    'cytology',
    'cytology & bfish',
    'cytology & bfish & urinalysis',
    'cytology & fish',
    'cytology & fish & urinalysis',
    'cytology & ht-fish',
    'cytology & imp-fish',
    'cytology & pro-fish',
    'cytology & urofish',
    'cytology & usc fish',
    'dermatology v2',
    'dermatology_sa',
    'gastrointestinal',
    'gastrointestinal_sa',
    'general',
    'general biopsy/cytology',
    'general cytology',
    'general histology',
    'gi cytology',
    'gi histology_plus',
    'gyn cytology',
    'gyn histology',
    'gyn histology_main',
    'gyn pap',
    'gyn pap cytology',
    'gyn pathvysion fish',
    'gyn urine cytology',
    'gyn urovysion fish',
    'lower gastrointestinal',
    'lower gi old',
    'male urine ct/ng',
    'microbiology',
    'microbiology_pnx',
    'next level fish',
    'next level fish & uc',
    'oral cytology',
    'oral cytology extended',
    'oral histology',
    'penile histology',
    'podiatric histology',
    'progensa pca3',
    'prostate fish',
    'prostate histology',
    'prostate histology lpa',
    'pten / tmprss erg',
    'stone analysis',
    'surgical podiatry',
    'testicular histology',
    'uc & reflex fish',
    'uc & reflex ht-fish',
    'uc & reflex imp-fish',
    'uc & reflex pro-fish',
    'uc & reflex urofish',
    'uc & reflex usc fish',
    'uc & urinalysis & reflex fish',
    'uc + fish',
    'uc + hematuria',
    'uc + reflex fish',
    'uc + reflex fish cp',
    'upper gastrointestinal',
    'upper gi old',
    'urinalysis',
    'urine cytology',
    'urine cytology & urinalysis',
    'urine cytology_sa',
    'urovysion fish_nju',
    'usc fish',
    'uvfish & uc',
    'vasectomy',
    'vasectomy lpa',
    'stain only',
    'gi',
    'ABL T315I Mutation Analysis in CML',
    'AFFIRM BV',
    'AML Mutation Panel (FLT-3 and NMP1) by PCR',
    'Anal PAP',
    'B Cell Clonality Assessment',
    'B&T Cell Gene Rearrangement PCR/Fragment Analysis',
    'urine fish',
    'urine fish only',
    'bladder biopsy',
    'BCR/ABL t(9;22) Quantification by RT-PCR',
    'BE FISH',
    'BE FISH Comprehensive Profile',
    'Bone Marrow Biopsy',
    'Bone Marrow Bx',
    'Breath Test',
    'CEPBA Analysis by PCR',
    'Chronic Lymphocytic Leukemia, IgVH Mutation Status',
    'Circulating Tumor Cells, Breast',
    'Circulating Tumor Cells, Colon',
    'Circulating Tumor Cells, Prostate',
    'C-Kit Mutation Analysis, Cell-based',
    'ClinicalChemistry_V2',
    'C-MYC',
    'Confirm MDx',
    'Culture, Fungus, Miscellaneous Source',
    'Cytogenetics',
    'Derm',
    'Fine Needle Aspirate',
    'Fine Needle Aspirate_HemOnc',
    'FISH',
    'Flow Cytometry',
    'Fixed Tissue (FFPE) Block',
    'FISH_HemOnc',
    'FLT-3 Mutuations (ITD and D835)',
    'Fresh Tissue',
    'Fresh Tissue (Others)',
    'General Surgical',
    'GYN',
    'HemOnc',
    'Her2neu',
    'High Risk',
    'High Risk NY',
    'High Risk Profile',
    'HR Pap Profile CFISH',
    'JAK2 Exons 12 and 13 Mutation Analysis ',
    'JAK2 V617F Quantification by RT-PCR',
    'Molecular',
    'Molecular Genetics',
    'Morphology',
    'MPL W515 and MPL S505 Mutation Analysis ',
    'MPM (Exon 12) Mutation Analysis',
    'Next Level Review',
    'NGS_IMP',
    'NIPT',
    'Non-gyn Cytology',
    'Other',
    'Pap Smear',
    'Pap Smear Conventional',
    'PathVysion FISH',
    'PCA-3',
    'Peripheral Blood',
    'Pleural Fluid',
    'PML/RARA t(15;17) Quantitation by RT-PCR',
    'Renal Biopsy',
    'T Cell Clonality Assessment',
    'T Cell Gene Rearrangement by PCR',
    'UC & Reflex BFISH',
    'Urology'
  ]
};
