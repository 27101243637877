import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep } from 'lodash';
import { Editor, Toolbar } from 'ngx-editor';
import { NgxUiLoaderService } from 'ngx-ui-loader';
// import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { CommonService } from '../../../core/services/commonservices';
import { DataShareService } from '../../../core/services/datashare.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { AccountsAssociationComponent } from '../accounts-association/accounts-association.component';
import { ArrayDataSource } from '@angular/cdk/collections';
import {  MatCheckbox } from '@angular/material/checkbox';
import { VitalAdminTabService } from '../../tab.service';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import {  MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../../core/services/dialog.service';

export interface Task {
  color: ThemePalette;
}

declare var $;

@Component({
  selector: 'app-setup-new-facility',
  templateUrl: './setup-new-facility.component.html',
  styleUrls: ['./setup-new-facility.component.scss']
})
export class SetupNewFacilityComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };

  @Input() shareData;
  @Input() ContextValue;
  @Input() editMode: boolean;
  @Input() editData: any;
  @Input() mainCard: any;
  editor1: Editor;
  editor: Editor;
  html = '';
  html1 = '';
  selectedTab = new FormControl(0);
  salesList = new FormControl();
  SubMenuCardModel: any;
  StateList: any;
  ClaimOptionsList: any;
  ServiceList: any;
  salesUsersList: any;
  CourierUsersList: any;
  submitted: boolean = false;
  zipValid: boolean = false;
  faxValid: boolean = false;
  phoneValid: boolean = false;
  primaryEmailValid: boolean = false;
  primaryPhoneValid: boolean = false;
  NPI: any = '';
  npiValidated: boolean = false;
  validationWarning: boolean = false;
  showInstructions: boolean = false;
  NPIWarning: boolean = false;
  imagePath: boolean = false;
  imageUploaded: boolean = false;
  imagePreview: string = '';
  files = [];
  frm_logo = null;
  disbaleCreateBtn = false;
  checkClientCode: boolean = false;
  checkAllLab: boolean = false;
  LabLocationList = [];
  orgName = '';
  frm_mnemonic = '';
  NoLabAssociated: boolean = false;
  allOFDetails = {};
  errorAlertDiv = '';
  errorAlertCasetype = '';
  errorAlertCasetypeOnNoLab = '';
  checkAllCaseType: boolean = false;
  categoryList: any = [];
  accessioninstruction: any;
  diagnosiscustominstruction: any;
  filteredCategoryList: any = [];
  showCasetype: boolean = false;
  page: any = 'facilityDetails';
  selectedActionButton: any = '';
  hideFacilityTab: boolean = false;
  hideFlagsTab: boolean = false;
  hideCaseTypeTab: boolean = false;
  hideSummaryTab: boolean = true;
  facilityDataSaved: boolean = false;
  flagsDataSaved: boolean = false;
  casetypeDataSaved: boolean = false;
  flagsTabData: any = {};
  hideLabLocList: boolean = false;
  selectedlabListObj: any = [];
  beingSaved: boolean = false;
  summaryData: any = {};
  ownerLabName: any;
  ownerLabId: any = sessionStorage.getItem('AccountID');
  fromComponent: any = "SetupNewFacility";
  OrgId: any = '';
  AccId: any = '';
  showAddUsers: boolean = false;
  savedfacilityData: any = {};
  savedflagData: any = {};
  updatedFlagData: any = {};
  selectedCasetypesList: any = [];
  popUpButton: string = "";
  flagsDataSavedNotFirstTime: boolean = false;
  filteredCategoryListAfterSaved: any = [];
  CasetypesDataSavedNotFirstTime: boolean = false;
  FacilityDataSavedNotFirstTime: boolean = false;
  filteredCategoryInitialList: any = [];
  SavecheckAllCaseTypeValue: boolean = false;
  facilityTabInitialValue: any = {};
  initialLabLocationList: any = {};
  facilityDataSavedToPatch: any = {};
  savedcheckAllLab: boolean = false;
  FacilityDataSavedSecondTimeOnwards: boolean = false;
  fromDepKey: string = "";
  existingCasetype: any = [];
  deSelectedCasetype: string;
  labAssociationList: any = [];
  previousOFDetails: {};
  searchAccount: string;
  OrganizationOptionList: any = [];
  defaulLabOwnOrg: string;
  isValidLabOwnOrg: boolean = false;
  hidecheckbtn: boolean = false;
  isownlaborgselected: boolean = false;
  CreateLiveUser: any;
  L2LData: any;
  IsPcEnabled: boolean;
  IsAutoSynchronized: boolean;
  IsAutoAssociated: boolean;
  AllowReportViewing: boolean;
  DisplayOFinLists: boolean;
  GenerateCoverSheet: boolean;
  newShortName: any;
  allOrglist: any=[];
  tempOrgName: any;
  initialFacData: any;
  setupNewFacilityModal: boolean = false;
  locationAlert: boolean = false;
  accountNames:any[];

  // savedcasetypesData: any = {};

  constructor(private _fb: FormBuilder, private AccountsAssociationComponent: AccountsAssociationComponent,
    private ngxService: NgxUiLoaderService, public _snackBar: MatSnackBar, private commonService: CommonService, public vaservices: VitalHttpServices, private datashare: DataShareService
    , public tabService: VitalAdminTabService, private dialog: MatDialog,private opendialog: DialogService) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, vaservices, datashare);
  }

  toolbar1: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  //Facility Form
  frm_FacilityData = this._fb.group({
    frm_NPI: ['', Validators.required],
    frm_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    frm_displayname: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    frm_shortname: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    frm_clia: '',
    frm_ein: '',
    frm_Address1: null,
    frm_Address2: null,
    frm_zip: '',//[Validators.pattern('\d{3}[\ \.\-]?\d{3}[\ \.\-]?\d{4}$')]
    frm_phone: '', // ['', [Validators.pattern('^[2-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$')]],
    frm_state: '',
    frm_city: null,
    frm_country: 'US',
    frm_fax: '',
    frm_salesExecutive: [],
    frm_DefaultServiceType: '',
    frm_ClaimOptions: 'Track Claim',
    frm_Courier: [],
    frm_AccountCode: '',
    frm_Location_Code: "",
    frm_Client_Code: '',
    frm_PrimaryName: ['', [Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    frm_PrimaryPhone: '',
    frm_PrimaryEmail: ''
    // frm_mnemonic: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    // frm_batchingmnemonic: null,
    // frm_taxonomycode: null,
    // frm_supportid: null,
    // frm_claimmnemonics: null,
    // frm_clpol: null,
    // frm_reportdata: '',
    // frm_enabledfax: 0,
    // frm_sharedsurgicalcenter: 0,
    // frm_enabledsendout: 0,
    // frm_statelicensenumber: null,
    // frm_usernameDisplayFormat: '',
    // frm_usernameReportDisplayFormat: '',
    // frm_collectiondateformat: '',
    // frm_packagedateformat: '',
    // frm_etadateformat: '',
    // frm_payaddress1: null,
    // frm_payaddress2: null,
    // frm_paycity: null,
    // frm_paystate: null,
    // frm_payzip: ''
  });

  async ngOnInit() {
    //console.log(this.shareData)
    this.editor1 = new Editor();
    this.editor = new Editor();
    //this.defaulLabOwnOrg=sessionStorage.getItem('Org_Name').toString() +"("+sessionStorage.getItem('Ord_id').toString()+")"
    this.setupInitialData();
    this.getOrgList();
    if (this.editMode) {
      this.isValidLabOwnOrg = true
      await this.getCustomorgAttribute();
      await this.getCustomAttrValues(sessionStorage.getItem('org_id').toString(), this.editData.OrganizationID.toString(), undefined)
    } else {
      await this.getCustomorgAttribute()
      //this.getcustomattrvalues()
    }
    this.getLabownorgAccess()
    this.allOrglist= await this.commonService.getAllOrganization();
  }

  //#region initial data setup
  setupInitialData() {
    //reset the facility form
    this.frm_FacilityData.reset();
    //set to initial values
    this.frm_FacilityData.patchValue({
      frm_NPI: 'UNKNOWN',
      frm_name: '',
      frm_displayname: '',
      frm_shortname: '',
      frm_clia: '',
      frm_ein: '',
      frm_Address1: null,
      frm_Address2: null,
      frm_zip: '',
      frm_phone: '',
      frm_state: '',
      frm_city: null,
      frm_country: 'US',
      frm_fax: '',
      frm_salesExecutive: '',
      frm_DefaultServiceType: '',
      frm_ClaimOptions: 'Track Claim',
      frm_Courier: [],
      frm_AccountCode: '',
      frm_Location_Code: "",
      frm_Client_Code: '',
      frm_PrimaryName: '',
      frm_PrimaryPhone: '',
      frm_PrimaryEmail: ''
    });
    //reset flags
    this.savedflagData = {
      emailLoginType: false,
      diagnosisReporting: (this.ContextValue == 'EPG') ? true : false,
      autoOpenOrdersCase: false,
      showReviewPathSign: false,
      autoPopulateDiagCodes: false,
      notifyToProvideDiagCodes: false,
      oFSetupCompleted: (this.ContextValue != 'EPG') ? true : false,
      GenerateCoverSheet: false,
      OwnerLabOrgId: false,
      AllowReportViewing: false,
      holdReleaseDuration: '0',
      Accessioninstructions: "",
      DiagnosisCustomInstructions: "",
      ispcenabled: false,
      isautosynchronized: false,
      isautoassociated: false,
      displayofinlists: false

    };
    this.flagsTabData = {
      emailLoginType: false,
      diagnosisReporting: (this.ContextValue == 'EPG') ? true : false,
      autoOpenOrdersCase: false,
      showReviewPathSign: false,
      autoPopulateDiagCodes: false,
      notifyToProvideDiagCodes: false,
      oFSetupCompleted: (this.ContextValue != 'EPG') ? true : false,
      GenerateCoverSheet: false,
      OwnerLabOrgId: false,
      AllowReportViewing: false,
      holdReleaseDuration: '0',
      EnableInstructions: false,
      Accessioninstructions: "",
      DiagnosisCustomInstructions: "",

      ispcenabled: false,
      // allowreportviewing: false,
      isautosynchronized: false,
      isautoassociated: false,
      displayofinlists: false

    };
    this.updatedFlagData = {
      emailLoginType: false,
      diagnosisReporting: (this.ContextValue == 'EPG') ? true : false,
      autoOpenOrdersCase: false,
      showReviewPathSign: false,
      autoPopulateDiagCodes: false,
      notifyToProvideDiagCodes: false,
      oFSetupCompleted: (this.ContextValue != 'EPG') ? true : false,
      GenerateCoverSheet: false,
      OwnerLabOrgId: false,
      AllowReportViewing: false,
      holdReleaseDuration: '0',
      Accessioninstructions: "",
      DiagnosisCustomInstructions: "",

      ispcenabled: false,
      // allowreportviewing: false,
      isautosynchronized: false,
      isautoassociated: false,
      displayofinlists: false

    }
    //reset casetypes


    // to check if enable instruction flag is set true or false
    this.getEntityAttributeFlag();
    //enable facility tab
    this.enableTab(true, false, false, false);
    this.selectedTab.setValue(0);
    //location Details
    this.getLabLocationList();
    //assign state list
    this.StateList = this.shareData.StateList;
    //assign claim options list
    this.ClaimOptionsList = this.shareData.ClaimOptions;
    //assign service list
    this.ServiceList = this.shareData.Service;
    this.CreateLiveUser = this.shareData.CreateLiveUser
    //assign Sales And Courier Users list
    this.getSalesAndCourierUsersList();
    // Case types moved to Case type configuration component
    // this.getCasetypeDetails();
    //set up error msg
    this.errorAlertCasetypeOnNoLab = 'Select atleast one Laboratory having Case Types associated !';
    this.errorAlertDiv = "";
    this.errorAlertCasetype = "";
    this.filteredCategoryList = [];
    this.savedfacilityData = {};
    this.savedflagData = {};
    this.selectedCasetypesList = [];
    this.popUpButton = "";
    this.flagsDataSavedNotFirstTime = false;
    this.CasetypesDataSavedNotFirstTime = false;
    this.filteredCategoryListAfterSaved = [];
    this.filteredCategoryInitialList = [];
    this.SavecheckAllCaseTypeValue = false;
    this.facilityDataSavedToPatch = {};

    //inital values for facility tab data
    // let logoObj = {
    //   files: [],
    //   frm_logo: null,
    //   imagePath: false
    // }
    this.facilityTabInitialValue = {
      OrganizationName: "",
      ShortName: "",
      LocDisplayName: "",
      Address1: null,
      Address2: null,
      City: null,
      Country: null,
      State: '',
      Zip: "",
      Phone: "",
      Npi: "",
      Ein: '',
      CLIA: '',
      Fax: "",
      ClientNumber: '',
      AccountCode: '',
      LocationCode: '',
      PrimaryContactname: "",
      PrimaryContactPhone: '',
      PrimaryContactEmail: '',
      ClaimOptions: 'Track Claim',
      ServiceType: '',
      sales: '',
      courier: '',
      IsClientCodeEnabled: false,
      LabAccountIDs: this.initialLabLocationList,
      Logo: null,
      files: [],
      imagePath: false
    }
    if (this.editMode) {
      this.getAccountData(this.editData);
    }
    this.captureNPI(this.frm_FacilityData.value.frm_NPI)
  }
  //Gets the data for the account based on accountid
  getAccountData(editData): any {
    let query = this.vaservices.GetQuery("getentitydetails");
    if (editData && editData.OrganizationID) {
      let queryVariable = { accid: editData.Associated_Account_Id.toString(), orgid: editData.OrganizationID.toString(), associatedaccountid: this.ownerLabId.toString() }
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.vaservices.GetData(queryResult).subscribe(data => {
        if (!data.errors) {
          let facilityDetails = (data.data.submenuDataAccount && data.data.submenuDataAccount.length>0) ? data.data.submenuDataAccount[0] : null;
          this.tempOrgName=facilityDetails.accountname;
          for (let i = 0; i < this.categoryList.length; i++) {
            for (let j = 0; j < this.categoryList[i].caseTypes.length; j++) {
              for (let k = 0; k < data.data.submenuDataCasetype.length; k++) {
                if (data.data.submenuDataCasetype[k].casetype.toString().toLowerCase() == this.categoryList[i].caseTypes[j].casetype.toString().toLowerCase()) {
                  this.categoryList[i].caseTypes[j].checked = true;
                  this.categoryList[i].checked = true;
                }
              }
            }
          }
          this.filteredCategoryList = this.categoryList
          this.existingCasetype = data.data.submenuDataCasetype.length > 0 ? data.data.submenuDataCasetype : [];

          let CourierUsersList = []
          let SalesExecutiveList = []
          if (data.data.submenuDataSalesCourier && data.data.submenuDataSalesCourier.length > 0) {
            data.data.submenuDataSalesCourier.find(e => {
              if (e.Role.toLowerCase().trim() == 'courier') {
                CourierUsersList.push(e.UserId)
              }
              else {
                SalesExecutiveList.push(e.UserId);
              }
            })
          }
          this.labAssociationList = [];
          if(this.ContextValue.toLowerCase() != 'epg')
          {
            this.labAssociationList = data.data.submenuDataAssociatedAccounts
          } else {
            this.labAssociationList = data.data.submenuDataEPGAssociatedAccounts
          }
          if (this.labAssociationList && this.labAssociationList.length > 0) {
            this.labAssociationList.forEach(e => {
              this.LabLocationList.forEach(r => {
                if (e.LabID == r.AccountID) {
                  r.Checked = true
                }
              })
            });
          }

          this.checkAllLab = this.LabLocationList.some(e => e.Checked == false) ? false : true;

          this.showCasetype = data.data.submenuDataCasetype.length > 0 ? true : false
          this.filteredCategoryList = cloneDeep(this.categoryList);
          let facilityFlags = (data.data.submenuDataFlags.length > 0) ? data.data.submenuDataFlags[0] : null;
          let InsObj
          if (data.data.submenuDataNotes && data.data.submenuDataNotes.length > 0) {
            InsObj = data.data.submenuDataNotes[0]
          }
          let Service, serviceTypeVal = "";
          if (data.data.submenuDataServices && data.data.submenuDataServices.length > 0) {
            Service = data.data.submenuDataServices.find(e => e.Services != null || e.Services == '')
          }
          this.L2LData =( data.data.submenuDataL2L.length > 0) ? data.data.submenuDataL2L[0].L2Lconfigid : null;
          this.DisplayOFinLists = (data.data.submenuDataOFdetails.length > 0) ? data.data.submenuDataOFdetails[0].DisplayOFinLists : null;
          this.IsPcEnabled = (data.data.submenuDataL2L.length > 0) ? data.data.submenuDataL2L[0].IsPcEnabled : null;
          this.IsAutoSynchronized = (data.data.submenuDataOFdetails.length > 0) ? data.data.submenuDataOFdetails[0].IsAutoSynchronized : null;
          this.IsAutoAssociated = (data.data.submenuDataOFdetails.length > 0) ? data.data.submenuDataOFdetails[0].IsAutoAssociated : null;
          this.AllowReportViewing = (data.data.submenuDataOFdetails.length > 0) ? data.data.submenuDataOFdetails[0].AllowReportViewing : null;
          this.GenerateCoverSheet = (data.data.submenuDataOFdetails.length > 0) ? data.data.submenuDataOFdetails[0].GenerateCoverSheet : null;
          if (this.ContextValue != 'EPG') {
            serviceTypeVal = (InsObj && InsObj.Services) ? InsObj.Services : ""
            this.IsPcEnabled = (data.data.submenuDataOFdetails && data.data.submenuDataOFdetails.length > 0) ? ((data.data.submenuDataOFdetails[0].IsPcEnabled && data.data.submenuDataOFdetails[0].IsPcEnabled.toString().toLowerCase() == "true") ? true : false) : true;
          }
          else {
            serviceTypeVal = (editData && editData.Services != "None") ? editData.Services : ""
          }
          let orgDetails = (data.data.submenuDataFlags.length > 0) ? data.data.submenuDataFlags[0] : null;
          this.checkClientCode = orgDetails.IsClientCodeEnabled ? orgDetails.IsClientCodeEnabled : false;
          if(facilityDetails)
          {
            if(!facilityDetails.ShortName){
              this.generateMnemonic(facilityDetails.accountname);
              this.newShortName=this.frm_FacilityData.value.frm_shortname
            }
            this.frm_FacilityData.patchValue({
              frm_NPI: facilityDetails.npi ? facilityDetails.npi : 'UNKNOWN',
              frm_name: facilityDetails.accountname ? facilityDetails.accountname : '',
              frm_displayname: facilityDetails.displayname ? facilityDetails.displayname : '',
              frm_shortname: facilityDetails.ShortName ? facilityDetails.ShortName : this.newShortName,
              frm_clia: facilityDetails.clia ? facilityDetails.clia : '',
              frm_ein: facilityDetails.ein ? facilityDetails.ein : '',
              frm_Address1: facilityDetails.address1 ? facilityDetails.address1 : null,
              frm_Address2: facilityDetails.address2 ? facilityDetails.address2 : null,
              frm_zip: facilityDetails.zip ? facilityDetails.zip : '',
              frm_phone: facilityDetails.phone ? facilityDetails.phone : '',
              frm_state: facilityDetails.state ? facilityDetails.state : '',
              frm_city: facilityDetails.city ? facilityDetails.city : null,
              frm_country: facilityDetails.country ? facilityDetails.country : null,
              frm_fax: facilityDetails.fax ? facilityDetails.fax : '',
              frm_salesExecutive: SalesExecutiveList,
              frm_DefaultServiceType: serviceTypeVal, //(Service && Service.Services) ? Service.Services : (editData && editData.Services != "None") ? editData.Services : "",
              frm_ClaimOptions: facilityDetails.claimoptions ? facilityDetails.claimoptions : '',
              frm_Courier: CourierUsersList,
              frm_AccountCode: facilityDetails.accountcode ? facilityDetails.accountcode : '',
              frm_Location_Code: (this.ContextValue && this.ContextValue.toLowerCase() == 'epg') ? (data.data.submenuDataL2L[0].Locationcode ? data.data.submenuDataL2L[0].Locationcode : "") : ((data.data.submenuDataOFdetails && data.data.submenuDataOFdetails.length>0) ? data.data.submenuDataOFdetails[0].Locationcode : ""),
              frm_Client_Code: (this.ContextValue && this.ContextValue.toLowerCase() == 'epg') ? (data.data.submenuDataL2L[0].ClientNumber ? data.data.submenuDataL2L[0].ClientNumber : '') : ((data.data.submenuDataOFdetails && data.data.submenuDataOFdetails.length>0) ? data.data.submenuDataOFdetails[0].ClientNumber : ""),
              frm_PrimaryName: facilityDetails.primarycontactname ? facilityDetails.primarycontactname : '',
              frm_PrimaryPhone: facilityDetails.primarycontactphone ? facilityDetails.primarycontactphone : '',
              frm_PrimaryEmail: facilityDetails.primarycontactemail ? facilityDetails.primarycontactemail : ''
            });
          }
          this.initialFacData=cloneDeep(this.frm_FacilityData);
          this.flagsTabData = {
            emailLoginType: (facilityFlags.LoginType && facilityFlags.LoginType.toString().toLowerCase() == "email") ? true : false,
            diagnosisReporting: this.IsPcEnabled ? false : true,
            autoOpenOrdersCase: (facilityFlags.loadByDefault && facilityFlags.loadByDefault.toString() == 'true') ? true : false,
            showReviewPathSign: (facilityFlags.ShowReviewPathSignature && facilityFlags.ShowReviewPathSignature.toString() == 'true') ? true : false, //associd:
            autoPopulateDiagCodes: (facilityFlags.AutoPopulateDiagICD9Codes && facilityFlags.AutoPopulateDiagICD9Codes.toString() == 'true') ? true : false,
            notifyToProvideDiagCodes: (facilityFlags.DiagICDcodeReminder && facilityFlags.DiagICDcodeReminder.toString() == 'true') ? true : false,
            oFSetupCompleted: (facilityFlags.isOrgSetupPending && facilityFlags.isOrgSetupPending.toString() == 'true') ? false : true,
            GenerateCoverSheet: this.GenerateCoverSheet == true ? true : false,
            // OwnerLabOrgId: (facilityFlags && facilityFlags) ? true : false,
            holdReleaseDuration: (facilityFlags.HoldReleaseDuration && facilityFlags.HoldReleaseDuration) ? facilityFlags.HoldReleaseDuration : "",
            // EnableInstructions: (facilityFlags && facilityFlags) ? true : false,
            Accessioninstructions: (InsObj && InsObj.Accessioninstruction) ? InsObj.Accessioninstruction : "",
            DiagnosisCustomInstructions: (InsObj && InsObj.DiagnosisCustomInstructions) ? InsObj.DiagnosisCustomInstructions : "",

            ispcenabled: this.IsPcEnabled == true ? false : true,
            isautosynchronized: this.IsAutoSynchronized == true ? true : false,
            isautoassociated: this.IsAutoAssociated == true ? true : false,
            displayofinlists: this.DisplayOFinLists == true ? true : false,
            AllowReportViewing: this.AllowReportViewing == true ? true : false,
          };
          this.previousOFDetails = {
            //facility data
            OrganizationID: parseInt(this.editData.OrganizationID),
            OrganizationName: facilityDetails.accountname ? facilityDetails.accountname : '',
            ShortName: facilityDetails.ShortName ? facilityDetails.ShortName : '',
            UsernameDisplayFormat: "S.L,F",
            UsernameReportFormat: "S.L,F",
            LocDisplayName: facilityDetails.displayname ? facilityDetails.displayname : '',
            Mnemonic: this.frm_mnemonic ?? "",
            Address1: facilityDetails.address1 ? facilityDetails.address1 : null,
            Address2: facilityDetails.address2 ? facilityDetails.address2 : null,
            City: facilityDetails.city ? facilityDetails.city : null,
            Country: facilityDetails.country ? facilityDetails.country : null,
            State: facilityDetails.state ? facilityDetails.state : '',
            Zip: facilityDetails.zip ? facilityDetails.zip : '',
            Phone: facilityDetails.phone ? facilityDetails.phone : '',
            Url: facilityDetails.Url,
            Supportid: facilityDetails.Supportid ? facilityDetails.Supportid : '',
            Npi: facilityDetails.npi,
            Ein: facilityDetails.ein ? facilityDetails.ein : '',
            CLIA: facilityDetails.clia ? facilityDetails.clia : '',
            Status: facilityDetails.status,
            Fax: facilityDetails.fax ? facilityDetails.fax : '',
            BillingPhoneNumber: facilityDetails.billingcontactphone,
            Logo: facilityDetails.logo,
            iUserID: SalesExecutiveList,
            Createdby: facilityDetails.createdby,
            vClientNumber: orgDetails.ClientNumber ? orgDetails.ClientNumber : '',
            vAccountCode: facilityDetails.accountcode ? facilityDetails.accountcode : '',
            vLocationCode: facilityDetails.accountcode,
            PrimaryContactname: facilityDetails.primarycontactname ? facilityDetails.primarycontactname : '',
            PrimaryContactPhone: facilityDetails.primarycontactphone ? facilityDetails.primarycontactphone : '',
            PrimaryContactEmail: facilityDetails.primarycontactemail ? facilityDetails.primarycontactemail : '',
            ClaimOptions: facilityDetails.claimoptions ? facilityDetails.claimoptions : '',
            vServices: Service,
            vSelectedUserID: SalesExecutiveList,
            courierUserID: CourierUsersList,
            LabAccountIDs: this.LabLocationList.toString(),
            StateDisplayName: facilityDetails.state,
            //flags data
            emailLoginType: (facilityFlags.LoginType && facilityFlags.LoginType.toString().toLowerCase() == "email") ? true : false,
            diagnosisReporting: this.IsPcEnabled == true ? false : true,
            autoOpenOrdersCase: (facilityFlags.loadByDefault && facilityFlags.loadByDefault.toString() == 'true') ? true : false,
            showReviewPathSign: (facilityFlags.ShowReviewPathSignature && facilityFlags.ShowReviewPathSignature.toString() == 'true') ? true : false, //associd:
            autoPopulateDiagCodes: (facilityFlags.AutoPopulateDiagICD9Codes && facilityFlags.AutoPopulateDiagICD9Codes.toString() == 'true') ? true : false,
            notifyToProvideDiagCodes: (facilityFlags.DiagICDcodeReminder && facilityFlags.DiagICDcodeReminder.toString() == 'true') ? true : false,
            oFSetupCompleted: (facilityFlags.isOrgSetupPending && facilityFlags.isOrgSetupPending.toString() == 'true') ? false : true,
            GenerateCoverSheet: this.GenerateCoverSheet == true ? true : false,
            holdReleaseDuration: (facilityFlags.HoldReleaseDuration && facilityFlags.HoldReleaseDuration) ? facilityFlags.HoldReleaseDuration : "",
            Accessioninstructions: (InsObj) ? InsObj.Accessioninstruction : "",
            DiagnosisCustomInstructions: (InsObj && InsObj.DiagnosisCustomInstructions) ? InsObj.DiagnosisCustomInstructions : "",

            ispcenabled: this.IsPcEnabled == true ? false : true,
            isautosynchronized: this.IsAutoSynchronized == true ? true : false,
            isautoassociated: this.IsAutoAssociated == true ? true : false,
            displayofinlists: this.DisplayOFinLists == true ? true : false,
            AllowReportViewing: this.AllowReportViewing == true ? true : false,
          }
          this.getexistinglogo(editData.Associated_Account_Id);

          this.html = (InsObj) ? InsObj.Accessioninstruction : ""
          this.html1 = (InsObj && InsObj.DiagnosisCustomInstructions) ? InsObj.DiagnosisCustomInstructions : ""
        }
      }, error => {
        console.error(error);
      });
    }
    else {
      console.log('OrganizationID is NULL in Locations');
    }
  }

  //#region get the location logo based on accid
  getexistinglogo(dataItemAccid) {
    let data = { accid: dataItemAccid };
    this.vaservices.GetLogoFile(data).subscribe(res => {
      this.ngxService.start("6");
      if (res.Success) {
        if (res.Message && res.Message != "") {
          this.frm_logo = "data:image/jpeg;base64," + res.Message;
          this.imagePath = true
        }
        else {
          this.frm_logo = "";
          this.imagePath = false;
        }
      }
      this.ngxService.stop("6");
    }, error => {
      this._snackBar.open("An error occurred while processing your request", "Failed");
      this.ngxService.stop("6");
    })
  }
  //#endregion

  getEntityAttributeFlag() {
    let query = this.vaservices.GetQuery('getentityextattributesFlag');
    let queryVariable = { orgid: sessionStorage.getItem('org_id').toString(), extrname: 'EnableInstructions' }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vaservices.GetData(queryResult).subscribe(data => {
      /*Null value handled */
      if (!data.errors) {
        let res = data.data.submenuData[0];
        if (res && res.ExtAttrValue.toLowerCase() == "true") {
          this.showInstructions = true;
        } else {
          this.showInstructions = false;
        }
      }
    }, error => {
      console.log(error);
    });
  }

  getSalesAndCourierUsersList() {
    this.salesUsersList = [];
    this.CourierUsersList = [];
    this.salesUsersList = this.shareData.serviceAndCourierUsersList.filter(e => e.Role.toLowerCase().trim() == 'sales executive');
    this.CourierUsersList = this.shareData.serviceAndCourierUsersList.filter(e => e.Role.toLowerCase().trim() == 'courier');
  }

  getLabLocationList() {
    this.hideLabLocList = true;
    if (this.shareData.labLocationList && this.shareData.labLocationList.length > 0) {
      //get org Name
      this.orgName = (this.shareData.labLocationList && this.shareData.labLocationList.length > 0) ? this.shareData.labLocationList[0].Organization_Name.toUpperCase() : '';
      //get lab locations
      this.LabLocationList = [];
      // let accID = sessionStorage.getItem('AccountID').toString()
      this.shareData.labLocationList.forEach(e => {
        // if (e.Account_ID == accID)
        if (e.Account_ID == this.ownerLabId) {
          this.ownerLabName = e.Account_Name;
        }
        // this.LabLocationList.push({ AccountID: e.Account_ID, AccountName: e.Account_Name, Checked: (e.Account_ID == accID) });
        // else
        this.LabLocationList.push({ AccountID: e.Account_ID, AccountName: e.Account_Name, Checked: false });
      });

      //keep a copy of initial lab location data
      this.initialLabLocationList = JSON.parse(JSON.stringify(this.LabLocationList));
      //set facility tab data initial value
      this.facilityTabInitialValue.LabAccountIDs = this.initialLabLocationList;
      this.hideLabLocList = !this.hideLabLocList;
    }
  }

  //to set the formcontrol to show respective validations
  get formcontrol() {
    return this.frm_FacilityData.controls;
  }
  //#endregion

  //#region NPI details
  captureNPI(NPI) {
    this.NPI = NPI.toString().trim();
    this.validationWarning = false;
    this.NPIWarning = false;
    this.npiValidated = false;
    if (this.NPI.toLowerCase() == 'unknown') {
      this.npiValidated = true
    }
  }

  ValidateNPIandgetData() {
    if (this.NPI != '' && this.NPI.toLowerCase() != 'unknown') {
      this.validationWarning = false;
      this.NPIWarning = false;
      this.ngxService.start();
      if(this.NPI.toLowerCase() != 'unknown' && this.NPI.length ==10){
        this.npiValidated = true;
        this.NPIWarning=false;
      }
      else{
        this.npiValidated = false;
        this.NPIWarning=true;
      }
      this.ngxService.stop();
      // this.vaservices.NpiValidation({ npi: this.NPI }).subscribe(
      //   Info => {
      //     this.ngxService.stop();
      //     this.NPIWarning = Info.result_count ? (Info.result_count == 0 ? true : false) : true;
      //     if (Info.results && Info.results.length > 0) {
      //       //this is to get only Group level NPI details and not individual(user) level details
      //       if (Info.results[0].enumeration_type == "NPI-2") {
      //         this.npiValidated = true;
      //         this.populateNPIDetails(Info.results[0]);
      //       }
      //       else {
      //         let dialogRef = this.dialog.open(ConfirmComponent, {
      //           disableClose: true,
      //           width: '360px',
      //           data: { header: "", message: "This is not a valid NPI for the location.", alert: "Do you want to continue?", continue: "yes", cancel: "no" }
      //         })
      //         return dialogRef.afterClosed().toPromise().then(result => {
      //           if (result) {
      //             this.npiValidated = true;
      //             if (!this.frm_FacilityData.controls.frm_name) {
      //               this.populateNPIDetails(Info.results[0]);
      //             }
      //           }
      //           else {
      //             this.NPIWarning = true;
      //             this.npiValidated = false;
      //           }
      //         })

      //       }
      //     }
      //     else {
      //       this.NPIWarning = true;
      //       this.npiValidated = false;
      //     }
      //   }, error => {

      //     this.ngxService.stop();
      //     console.error(error);
      //     this.NPIWarning = true;
      //     this.npiValidated = !this.NPIWarning;
      //   });
      return;
    }
    //when npi is empty or undefined or null
    if (this.NPI.toLowerCase() != 'unknown') this.validationWarning = true;
  }

  populateNPIDetails(result) {
    let addressObj: any;
    if (result.addresses && result.addresses.length > 0)
      addressObj = result.addresses.find(e => e.address_purpose.toUpperCase() == "LOCATION");
    if (addressObj) {
      this.frm_FacilityData.patchValue({
        frm_name: result.basic.organization_name ?? '',
        frm_country: addressObj.country_name ?? '',
        frm_Address1: addressObj.address_1 ?? '',
        frm_Address2: addressObj.address_2 ?? '',
        frm_city: addressObj.city ?? '',
        frm_state: addressObj.state ?? '',
        frm_zip: addressObj.postal_code ?? '',
        frm_phone: addressObj.telephone_number ?? '',
        frm_fax: addressObj.fax_number ?? ''
      });
      // if (this.frm_FacilityData.value.frm_name && this.frm_FacilityData.value.frm_name != "")
      //   this.generateMnemonic(this.frm_FacilityData.value.frm_name)
      // else
      //   this.frm_mnemonic = '';
      //this.frm_FacilityData.controls['frm_mnemonic'].setValue('');
    }
    this.submitted = false;
    this.errorAlertDiv = "";
  }
  //#endregion

  //#region Generate Mnemonic
  generateMnemonic(Name) {
    this.tempOrgName=Name
    if (Name.length > 0 && Name.trim() != '') {
      if (Name.trim().split(' ').length > 1) {
        var matches = Name.match(/\b(\w)/g);
        var acronym = matches[0] + matches[1];
        //this.frm_FacilityData.controls['frm_mnemonic'].setValue(acronym);
        this.frm_mnemonic = acronym.toString();
        this.frm_FacilityData.patchValue(
          {
            frm_shortname: acronym
          }
        )
      } else {
        //this.frm_FacilityData.controls['frm_mnemonic'].setValue(Name.trim().toUpperCase().substring(0, 2));
        this.frm_mnemonic = Name.trim().toUpperCase().substring(0, 2);
        this.frm_FacilityData.patchValue(
          {
            frm_shortname: Name.trim().toUpperCase().substring(0, 2)
          }
        )
      }
      this.frm_FacilityData.value.frm_displayname = Name;
      return;
    }
    // this.frm_FacilityData.controls['frm_mnemonic'].setValue('');
    this.frm_mnemonic = '';
  }
  //#endregion

  //#region Logo
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.frm_logo = null;
    this.imagePath = false;
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  prepareFilesList(event) {
    let reader = new FileReader();
    reader.onload = function () {
      let output: any = document.getElementById('blah');
      output.src = reader.result;
    }
    this.imagePath = false;
    if (event[0]) {
      reader.readAsDataURL(event[0]);
    }
  }

  onFileDropped($event) {
    this.onFileChange($event);
  }

  onFileChange(event) {
    this.onRemove(event);
    let tempArrData: any
    if (event.target) {
      tempArrData = event.target.files[0];
    } else {
      tempArrData = event[0];
    }
    if (tempArrData.type.toString().toLowerCase() == "image/png" || tempArrData.type == "image/jpeg" || tempArrData.type == "image/jpg") {
      event.addedFiles = [];
      event.addedFiles.push(tempArrData);
      this.files.push(...event.addedFiles);
      const reader = new FileReader();
      if (this.files && this.files.length) {
        const [logoUpload] = this.files;
        reader.readAsDataURL(logoUpload);
        reader.onload = () => {
          this.frm_logo = reader.result as string;
          this.imagePath = true;
        };
      } else {
        this._snackBar.open("An error occurred while processing your request !", "Failed");
      }
    } else {
      this._snackBar.open("Please upload image file only !", "Failed");
    }
  }

  toDataURL = async (url) => {
    var res = await fetch(url);
    var blob = await res.blob();
    const result = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
    return result
  };
  //#endregion

  //#region facility form
  checkClientCodeFunc() {
    this.checkClientCode = !this.checkClientCode;
  }
  //#endregion

  //#region Lab location association
  //all button click
  checkAllLabFunc() {
    this.checkAllLab = !this.checkAllLab;
    this.LabLocationList.forEach(e => e.Checked = this.checkAllLab);

    //get the filtered casetype list updated when lab location is clicked upon
    this.filterCasetypesOnLoc();
  }

  //below function is called when an account is selelected
  checkLabLocation(AccountID) {
    this.NoLabAssociated = false;
    for (let i = 0; i < this.LabLocationList.length; i++) {
      if (this.LabLocationList[i].AccountID == AccountID) {
        this.LabLocationList[i].Checked = !this.LabLocationList[i].Checked;
        break;
      }
    }
    this.checkAllLabLocSelected();
  }

  //below function checks if all the labs are selected
  checkAllLabLocSelected() {
    let flag = false;
    for (let i = 0; i < this.LabLocationList.length; i++) {
      //when first unchecked lab found break the loop
      if (!this.LabLocationList[i].Checked) {
        flag = true;
        break;
      }
    }
    this.checkAllLab = !flag;

    //get the filtered casetype list updated when lab location is clicked upon
    this.filterCasetypesOnLoc();
  }
  //#endregion

  //#region Casetype details
  getCasetypeDetails() {
    // sessionStorage.setItem('deploymentKey', 'LIS');
    this.ngxService.start();
    let accIDlist = [];
    this.LabLocationList.forEach(e => { accIDlist.push(e.AccountID) })
    this.vaservices.getNewFacilityCasetypeDetails(accIDlist).subscribe(
      // this.vaservices.getCasetypeDetails().subscribe(
      res => {
        // res = [];
        // res.push({ Category: 'Gi', CaseType: 'urology', AccountID: 55783 })
        // res.push({ Category: 'Gi', CaseType: 'urology', AccountID: 55782 })
        // res.push({ Category: 'Gi', CaseType: 'Prostate Histology', AccountID: 55782 })
        // res.push({ Category: 'Gi', CaseType: 'urology', AccountID: 55782 })
        // res.push({ Category: 'Gi', CaseType: 'Prostate', AccountID: 55782 })
        // res.push({ Category: 'Gi', CaseType: 'a Prostate Histology', AccountID: 55782 })
        // res.push({ Category: 'Gi', CaseType: 'ac Prostate Histology', AccountID: 55782 })
        // res.push({ Category: 'Gi', CaseType: 'avv Prostate Histology avv Prostate Histology', AccountID: 55782 })
        // res.push({ Category: 'Gi', CaseType: 'er Prostate Histology', AccountID: 55782 })
        // res.push({ Category: 'Gi', CaseType: 'aerere Prostate Histology', AccountID: 55782 })

        this.categoryList = [];
        if (res && res != "" && res.length > 0) {
          //format of categorylist is
          // [
          // {
          //   "Category": "Urology",
          //   "caseTypes": [
          //       {
          //           "casetype": "Prostate Histology",
          //           "checked": false
          //       },
          //       {
          //           "casetype": "Urine Cytology",
          //           "checked": false
          //       }],
          //   "checked": false
          // }
          // ]
          res.forEach(e => {
            if (!this.categoryList.find(element => element.Category == e.Category))
              this.categoryList.push({ Category: e.Category, caseTypes: [], checked: false })
            for (let i = 0; i < this.categoryList.length; i++) {
              if (this.categoryList[i].Category == e.Category) {
                this.categoryList[i].caseTypes.push({ casetype: e.CaseType, checked: false, accID: e.AccountID });
                break;
              }
            }
          });
          this.showCasetype = false;

          // set default  associated lab
          let accID = sessionStorage.getItem('AccountID').toString();
          if (accID) {
            this.checkLabLocation(accID);
          }
        }
        else {
          this.showCasetype = false;
          this._snackBar.open("An error occured while fetching Case Types. Please try again after some time or Contact Support Team !", "Close");
          this.back();
        }
        this.ngxService.stop();
      }, error => {

        this.ngxService.stop();
        this.categoryList = [];
        this.showCasetype = false;
        console.error(error);
        this._snackBar.open("An error occured while fetching Case Types. Please try again after some time or Contact Support Team !", "Close");
        this.back();
      });
  }

  filterCasetypesOnLoc() {
    //set all checkbox to unchecked
    this.checkAllCaseType = false;
    //get selected location list
    let accIdList = [];
    this.LabLocationList.forEach(e => {
      if (e.Checked)
        accIdList.push(e.AccountID);
    });

    //filter casetypes based on selected locations
    this.filteredCategoryList = [];
    //when the lab selection is changed user is allowed to make changes in casetypes tab
    this.casetypeDataSaved = false;
    this.facilityDataSaved = false;
    accIdList.forEach(ele => {
      this.categoryList.forEach(e => {
        e.caseTypes.forEach(elem => {
          if (elem.accID == ele) {
            if (!this.filteredCategoryList.find(element => element.Category == e.Category))
              this.filteredCategoryList.push({ Category: e.Category, caseTypes: [], checked: false })
            for (let i = 0; i < this.filteredCategoryList.length; i++) {
              if (this.filteredCategoryList[i].Category == e.Category) {
                //avoid duplicate casetypes
                let tempValue = this.filteredCategoryList[i].caseTypes.find(item => item.casetype == elem.casetype);
                // && tempValue['Category'].toLowerCase() != e.Category.toLowerCase()
                if (!tempValue)
                  this.filteredCategoryList[i].caseTypes.push({ casetype: elem.casetype, checked: false, accID: elem.accID });
                break;
              }
            }
          }
        });
      });
    });

    //initial values of casetypes filtered list is captured here
    this.filteredCategoryInitialList = JSON.parse(JSON.stringify(this.filteredCategoryList))

    if (!(this.filteredCategoryList.length > 0)) {
      this.showCasetype = false;
      this.errorAlertCasetypeOnNoLab = 'Select atleast one Laboratory having Case Types associated !';
    }
    else {
      this.showCasetype = true;
      this.errorAlertCasetypeOnNoLab = '';
    }
  }

  checkAllCasetypes() {
    this.casetypeDataSaved = false;
    //Checks all Category on all checkbox lick
    this.checkAllCaseType = !this.checkAllCaseType;
    this.filteredCategoryList.forEach(element => {
      element.checked = !this.checkAllCaseType;
      this.checkOnlyCategory(element.Category);
    });
    this.errorAlertCasetype = "";
  }

  checkCatergory(category) {
    //Checks all Casetypes
    this.checkOnlyCategory(category);
    this.categoryAllChecked();
  }

  checkOnlyCategory(category) {
    this.casetypeDataSaved = false;
    for (let i = 0; i < this.filteredCategoryList.length; i++) {
      if (this.filteredCategoryList[i].Category == category) {
        this.filteredCategoryList[i].checked = !this.filteredCategoryList[i].checked;
        let checkCategory = this.filteredCategoryList[i].checked;
        this.filteredCategoryList[i].caseTypes.forEach(element => {
          element.checked = checkCategory;
        });
        break;
      }
    }
    this.errorAlertCasetype = "";
  }

  checkCasetypes(category, casetype) {
    //Checks seleted Casetype
    for (let i = 0; i < this.filteredCategoryList.length; i++) {
      if (this.filteredCategoryList[i].Category == category) {
        for (let j = 0; j < this.filteredCategoryList[i].caseTypes.length; j++) {
          if (this.filteredCategoryList[i].caseTypes[j].casetype == casetype) {
            if (!this.filteredCategoryList[i].caseTypes[j].checked) {
              //when casetype is selected
              this.filteredCategoryList[i].caseTypes[j].checked = !this.filteredCategoryList[i].caseTypes[j].checked;
              this.filteredCategoryList[i].checked = true;
            }
            else {
              //when casetype is deselected
              this.filteredCategoryList[i].caseTypes[j].checked = !this.filteredCategoryList[i].caseTypes[j].checked;
              //check if other casetypes in the same category are selected
              this.filteredCategoryList[i].checked = false;
              for (let k = 0; k < this.filteredCategoryList[i].caseTypes.length; k++) {
                if (this.filteredCategoryList[i].caseTypes[k].checked) {
                  this.filteredCategoryList[i].checked = true;
                  break;
                }
              }
            }
            break;
          }
        }
        break;
      }
    }
    //this.casetypesAllChecked(category);
    this.categoryAllChecked();
  }

  categoryAllChecked() {
    this.casetypeDataSaved = false;
    //Find if all Categories are checked
    for (let i = 0; i < this.filteredCategoryList.length; i++) {
      if (!this.filteredCategoryList[i].checked) {
        this.checkAllCaseType = false;
        break;
      }
      else if (this.filteredCategoryList.length - 1 == i && !this.checkAllCaseType) {
        this.checkAllCaseType = !this.checkAllCaseType;
      }
    }
    this.errorAlertCasetype = "";
  }

  casetypesAllChecked(category) {
    //Find if all Casetypes are checked for a category
    for (let i = 0; i < this.filteredCategoryList.length; i++) {
      if (this.filteredCategoryList[i].Category == category) {
        for (let j = 0; j < this.filteredCategoryList[i].caseTypes.length; j++) {
          if (!this.filteredCategoryList[i].caseTypes[j].checked) {
            this.checkAllCaseType = false;
            this.filteredCategoryList[i].checked = false;
            break;
          }
          else if (this.filteredCategoryList[i].caseTypes.length - 1 == j && !this.checkAllCaseType) {
            this.filteredCategoryList[i].checked = true;
          }
        }
        break;
      }
    }
    this.categoryAllChecked();
  }
  //#endregion

  //#region Validation
  AllowOnlyNumberandSpclChar(event: KeyboardEvent) {
    const pattern = /^[0-9*#+. -]+$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  CheckIfOnlyNumberandSpclChar(value) {
    if (value && value != '') {
      const pattern = /^[0-9*#+. -]+$/;
      let flag = true;
      for (let i = 0; i < value.length; i++) {
        if (flag) {
          let charcode = value.charCodeAt(i)
          const inputChar = String.fromCharCode(charcode);
          if (!pattern.test(inputChar)) {
            flag = false;
          }
        }
        else {
          return false;
        }
      }
    }
    return true;
  }

  zipCodeValidation(zip) {
    if (zip && zip.trim() != '') {
      const zipValidator = /^(\d{5}([\-\ ]\d{4})?)$/;
      let tempValue = zipValidator.test(zip) ? true : false;
      if (!tempValue) {
        const newzipValidator = /^[0-9]{5}([0-9]{4})?$/;
        tempValue = newzipValidator.test(zip) ? true : false;
      }
      return tempValue;
    }
    else {
      return true;
    }
  }

  validateFax(value) {
    if (value.length > 0) {
      let faxRegEx = /\d{3}[\ \.\-]?\d{3}[\ \.\-]?\d{4}$/;
      if (!faxRegEx.test(value)) {
        return false;
      }
      else {
        return true;
      }
    }
    return true;
  }

  validatePhone(value) {
    if (value.length > 0 && value.length < 21) {
      let phoneRegEx = /^[0-9+. -]+$/;
      if (!phoneRegEx.test(value)) {
        return false;
      }
      else
        return true;
    }
    else if (value.length > 20)
      return false;
    else
      return true;
  }

  validateEmail(Email) {
    if (Email && Email.trim() != '') {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!reg.test(Email)) {
        return false
      }
      else
        return true;
    }
    return true;
  }

  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  //#endregion

  //#region pop up diaglog
  //code to open popUp
  openPopUp(page, button) {
    this.page = page;
    this.selectedActionButton = button;
    if (button != 'cancel') {
      if (page == 'facilityDetails' && this.facilityDataSaved && this.selectedActionButton != 'previous') {
        // this.proceedWithoutSave();
        this.setupNewFacilityModal = true;
      }
      else if (page == 'flagDetails' && this.flagsDataSaved) {
        this.proceedWithoutSave();
      }
      else if (page == 'casesTypeDetails' && this.casetypeDataSaved) {
        // && this.selectedActionButton != 'next'
        this.proceedWithoutSave();
      }
      else {
          if (this.formcontrol.frm_Location_Code.dirty && this.selectedActionButton=="next" && this.page=='facilityDetails') {
            this.validateLocations();
          }else{
          this.setupNewFacilityModal = true;
        }
      }
    }
    else {
        this.setupNewFacilityModal = true;
      
    }
  }

  // close Pop up and remove from body
  closeModal() {
    //close confirm pop up
    this.closePopUp();
    this.setupNewFacilityModal = false;

    //close summary pop up
    if (this.summaryData.AccDetails) {
      this.closeSummaryPopUpWithoutRefreshdata();
      $('#AddUsersToOFModal').remove();
    }
  }

  // close Pop up and remove from body and go to accounts association page ui
  back() {
    this.enableTab(true, false, false, false);
    this.selectedTab.setValue(0);
    this.closeModal();
    this.AccountsAssociationComponent.setupNewFacilityBtnClick = false;
    setTimeout(() => {
      this.AccountsAssociationComponent.closeSetupNewFacilityAndRefresh();
    })
  }
  //#endregion

  //#region functions of buttons inside popup
  //pop Up proceed Without Save
  proceedWithoutSave() {
    this.popUpButton = "proceedWithoutSave";
    let page = this.page;
    if (this.selectedActionButton == 'cancel' ||
      (page == 'facilityDetails' && this.selectedActionButton == 'previous')) {
      //back to accounts association
      this.back(); return;
    }
    else if (page == 'facilityDetails') {
      if (this.FacilityDataSavedNotFirstTime) {
        if (!this.editMode) {
          //saved value to be added here
          this.frm_FacilityData.patchValue({
            frm_NPI: this.facilityDataSavedToPatch.Npi,
            frm_name: this.facilityDataSavedToPatch.OrganizationName,
            frm_displayname: this.facilityDataSavedToPatch.LocDisplayName,
            frm_shortname: this.facilityDataSavedToPatch.ShortName,
            frm_clia: this.facilityDataSavedToPatch.CLIA,
            frm_ein: this.facilityDataSavedToPatch.Ein,
            frm_Address1: this.facilityDataSavedToPatch.Address1,
            frm_Address2: this.facilityDataSavedToPatch.Address2,
            frm_zip: this.facilityDataSavedToPatch.Zip,
            frm_phone: this.facilityDataSavedToPatch.Phone,
            frm_state: this.facilityDataSavedToPatch.State,
            frm_city: this.facilityDataSavedToPatch.City,
            frm_country: this.facilityDataSavedToPatch.Country,
            frm_fax: this.facilityDataSavedToPatch.Fax,
            frm_salesExecutive: this.facilityDataSavedToPatch.sales,
            frm_DefaultServiceType: this.facilityDataSavedToPatch.ServiceType,
            frm_ClaimOptions: this.facilityDataSavedToPatch.ClaimOptions,
            frm_Courier: this.facilityDataSavedToPatch.courier,
            frm_AccountCode: this.facilityDataSavedToPatch.AccountCode,
            frm_Location_Code: this.facilityDataSavedToPatch.LocationCode,
            frm_Client_Code: this.facilityDataSavedToPatch.ClientNumber,
            frm_PrimaryName: this.facilityDataSavedToPatch.PrimaryContactname,
            frm_PrimaryPhone: this.facilityDataSavedToPatch.PrimaryContactPhone,
            frm_PrimaryEmail: this.facilityDataSavedToPatch.PrimaryContactEmail
          });
          // update the logo, IsClientCodeEnabled checkbox,  LabAccountIDs, casetypes
          this.checkClientCode = this.facilityDataSavedToPatch.IsClientCodeEnabled;
          //set logo
          this.frm_logo = this.facilityDataSavedToPatch.Logo;
          this.files = this.facilityDataSavedToPatch.files;
          this.imagePath = this.facilityDataSavedToPatch.imagePath;
          //lab setting
          this.LabLocationList = JSON.parse(JSON.stringify(this.facilityDataSavedToPatch.LabAccountIDs))
          this.checkAllLab = this.savedcheckAllLab;

          this.filterCasetypesOnLoc();
        }
        // as the data was saved earlier set facilitydatasaved to true, and casetypedatasaved to false
        this.facilityDataSaved = true;
      }
      else {
        this.facilityDataSaved = false;
        // if (!this.editMode) {//patch previously saved value
        //   // this.frm_FacilityData.patchValue({
        //   //   frm_NPI: this.facilityTabInitialValue.Npi,
        //   //   frm_name: this.facilityTabInitialValue.OrganizationName,
        //   //   frm_displayname: this.facilityTabInitialValue.LocDisplayName,
        //   //   frm_shortname: this.facilityTabInitialValue.ShortName,
        //   //   frm_clia: this.facilityTabInitialValue.CLIA,
        //   //   frm_ein: this.facilityTabInitialValue.Ein,
        //   //   frm_Address1: this.facilityTabInitialValue.Address1,
        //   //   frm_Address2: this.facilityTabInitialValue.Address2,
        //   //   frm_zip: this.facilityTabInitialValue.Zip,
        //   //   frm_phone: this.facilityTabInitialValue.Phone,
        //   //   frm_state: this.facilityTabInitialValue.State,
        //   //   frm_city: this.facilityTabInitialValue.City,
        //   //   frm_country: this.facilityTabInitialValue.Country,
        //   //   frm_fax: this.facilityTabInitialValue.Fax,
        //   //   frm_salesExecutive: this.facilityTabInitialValue.sales,
        //   //   frm_DefaultServiceType: this.facilityTabInitialValue.ServiceType,
        //   //   frm_ClaimOptions: this.facilityTabInitialValue.ClaimOptions,
        //   //   frm_Courier: this.facilityTabInitialValue.courier,
        //   //   frm_AccountCode: this.facilityTabInitialValue.AccountCode,
        //   //   frm_Location_Code: this.facilityTabInitialValue.LocationCode,
        //   //   frm_Client_Code: this.facilityTabInitialValue.ClientNumber,
        //   //   frm_PrimaryName: this.facilityTabInitialValue.PrimaryContactname,
        //   //   frm_PrimaryPhone: this.facilityTabInitialValue.PrimaryContactPhone,
        //   //   frm_PrimaryEmail: this.facilityTabInitialValue.PrimaryContactEmail
        //   // });

        //   //set logo value
        //   this.files = this.facilityTabInitialValue.files;
        //   this.frm_logo = this.facilityTabInitialValue.Logo;
        //   this.imagePath = this.facilityTabInitialValue.imagePath;
        //   //set is client code enabled value
        //   this.checkClientCode = this.facilityTabInitialValue.IsClientCodeEnabled;
        //   //set lab location value
        //   this.LabLocationList = JSON.parse(JSON.stringify(this.facilityTabInitialValue.LabAccountIDs));
        //   //based on lab location set casetypes value also
        //   this.checkAllLab = false;
        //   this.filterCasetypesOnLoc();
        // }
        //this.captureNPI(this.frm_FacilityData.value.frm_NPI)
      }

      if (this.selectedActionButton == 'next') {
        //go to flags tab
        this.selectedTab.setValue(this.selectedTab.value + 1);//value must be 1
        this.enableTab(false, true, false, false);
        // this.selectedTab.setValue(1);
      }
    }
    else if (page == 'flagDetails') {
      //as flags is not mandatory field save automatically
      if (!this.flagsDataSaved && !this.flagsDataSavedNotFirstTime) {
        // if (!this.editMode) {
        //   this.flagsTabData = {
        //     emailLoginType: false,
        //     diagnosisReporting: false,
        //     autoOpenOrdersCase: false,
        //     showReviewPathSign: false,
        //     autoPopulateDiagCodes: false,
        //     notifyToProvideDiagCodes: false,
        //     oFSetupCompleted: false,
        //     GenerateCoverSheet: false,
        //     // OwnerLabOrgId:false,
        //     AllowReportViewing: false,
        //     holdReleaseDuration: '0',
        //     EnableInstructions: false,
        //     Accessioninstructions: "",
        //     DiagnosisCustomInstructions: ""

        //   };
        // }
        this.SaveOFDetails(page);

      }
      else {
        //this code is executed when data is already saved once and changes is made and not saved
        this.flagsDataSaved = true;
        this.flagsTabData = JSON.parse(JSON.stringify(this.updatedFlagData));
      }
      if (this.selectedActionButton == 'previous') {
        //go to facility tab
        this.selectedTab.setValue(this.selectedTab.value - 1);//value must be 0
        this.enableTab(true, false, false, false);
      }
      else if (this.selectedActionButton == 'next') {
        //go to casetypes tab
        this.selectedTab.setValue(this.selectedTab.value + 1);//value must be 2
        this.enableTab(false, false, true, false);
      }
    }
    else if (page == 'casesTypeDetails') {
      // if (this.FacilityDataSavedNotFirstTime && this.CasetypesDataSavedNotFirstTime && this.FacilityDataSavedSecondTimeOnwards) {
      // if (this.facilityDataSaved) {
      // if (!this.casetypeDataSaved) {
      //remove saved casetypes if facility lab selected is changed
      // this.filteredCategoryListAfterSaved = JSON.parse(JSON.stringify(this.filteredCategoryInitialList))
      //  [];
      // this.checkAllCaseType = false;
      // }
      // }
      // }

      // if (this.CasetypesDataSavedNotFirstTime) {
      // this.casetypeDataSaved = true;
      //since the data is not saved set it to old data as on when saved
      // this.filteredCategoryList = JSON.parse(JSON.stringify(this.filteredCategoryListAfterSaved));
      // this.checkAllCaseType = this.SavecheckAllCaseTypeValue;

      // this.casetypeDataSaved = false;
      // //since data is not saved set it to initial data for the first time
      // this.filteredCategoryList = JSON.parse(JSON.stringify(this.filteredCategoryInitialList));
      // this.checkAllCaseType = false;
      // }
      if (!this.CasetypesDataSavedNotFirstTime) {
        this.casetypeDataSaved = false;
        if (!this.editMode) {//since data is not saved set it to initial data for the first time
          this.filteredCategoryList = JSON.parse(JSON.stringify(this.filteredCategoryInitialList));
          this.checkAllCaseType = false;
        }
      }
      if (this.selectedActionButton == 'previous') {
        //go to flags tab
        this.selectedTab.setValue(this.selectedTab.value - 1);//value must be 1
        this.enableTab(false, true, false, false);
      }
      else if (this.selectedActionButton == 'next') {
        //save if all tab data are saved else show respective error msg
        this.proceedFromCasetype();

        //old working code
        // if (this.casetypeDataSaved) {
        //   if (this.facilityDataSaved && this.flagsDataSaved) {
        //     this.createOF();
        //   }
        //   else {
        //     if (!this.facilityDataSaved) {
        //       //go to facility tab
        //       this.selectedTab.setValue(0);
        //       this.enableTab(true, false, false, false);
        //     }
        //     else if (!this.flagsDataSaved) {
        //       //go to flags tab
        //       this.selectedTab.setValue(1);
        //       this.enableTab(false, true, false, false);
        //       this._snackBar.open("Save the Flag Details !", "Close")
        //     }
        //   }
        // } else {
        //   if (!(this.filteredCategoryList.length > 0)) {
        //     this._snackBar.open('Select atleast one Laboratory having Case Types associated !', 'Close');
        //     this.facilityDataSaved = false;
        //     // to go to facility tab
        //     this.selectedTab.setValue(0);
        //     this.enableTab(true, false, false, false);
        //   }
        //   else {
        //     this._snackBar.open('Select atleast one Case Type and Save !', 'Close');
        //   }
        // }
      }
    }
    this.closePopUp();
  }

  formHasChange() {
    return JSON.stringify(this.initialFacData.value) != JSON.stringify(this.frm_FacilityData.value)
  }

  validateOrgName(tempOrgName) {
    let duplicateFound = false;
    if (!this.editMode) {
      duplicateFound = this.allOrglist.some(va => va.organizationname.trim().toLowerCase() === tempOrgName.trim().toLowerCase());
    }else{
      if(this.formHasChange() && this.initialFacData.value.frm_name != this.frm_FacilityData.value.frm_name){
        duplicateFound = this.allOrglist.some(va => va.organizationname.trim().toLowerCase() === tempOrgName.trim().toLowerCase() && va.organizationid != this.editData.OrganizationID);
      }
    }
    
    return duplicateFound;
  }


  //pop Up save And Proceed
  saveAndProceed(isSave?) {
    this.popUpButton = "";
    let value = this.page;
    if (value == 'facilityDetails') {
      if (!this.validateOrgName(this.tempOrgName) || isSave) {
        this.SaveOFDetails(value);
        if (this.facilityDataSaved) {
          //go to flags tab
          this.selectedTab.setValue(this.selectedTab.value + 1);//value must be 1
          this.enableTab(false, true, false, false);
        }
      }else{
        const popUpHeader="Confirmation";
        const popUpMessage = `There is already another '${this.ContextValue}' with the same name. Do you wish to create again?`;
        const continueBtn="Yes"
        const dialogRef=this.opendialog.openDialog(popUpHeader,popUpMessage,'',continueBtn,"No")
        dialogRef.afterClosed().subscribe(res=>{
          if(res==true){
            this.saveAndProceed(true)
          }
        })
      }
    }
    else if (value == 'flagDetails') {
      if (this.selectedActionButton == 'previous') {
        this.SaveOFDetails(value);
        if (this.flagsDataSaved) {
          //go to facility tab
          this.selectedTab.setValue(this.selectedTab.value - 1);//value must be 0
          this.enableTab(true, false, false, false);
        }
      }
      else if (this.selectedActionButton == 'next' || this.selectedActionButton == 'cancel') {
        this.SaveOFDetails(value);
        if (this.flagsDataSaved) {
          //go to casetypes tab
          this.selectedTab.setValue(this.selectedTab.value + 1);//value must be 2
          this.enableTab(false, false, true, false);
        }
      }
    }
    else if (value == 'casesTypeDetails') {
      if (this.selectedActionButton == 'previous') {
        this.SaveOFDetails(value);
        if (this.casetypeDataSaved) {
          //go to flags tab
          this.selectedTab.setValue(this.selectedTab.value - 1);//value must be 1
          this.enableTab(false, true, false, false);
        }
        else {
          if (!this.editMode) {
            if (!(this.filteredCategoryList.length > 0)) {
              this._snackBar.open('Select atleast one Laboratory having Case Types associated !', 'Close');
              this.facilityDataSaved = false;
              // to go to facility tab
              this.selectedTab.setValue(0);
              this.enableTab(true, false, false, false);
              //to go to flags tab
              // this.selectedTab.setValue(this.selectedTab.value - 1);
              // this.enableTab(false, true, false, false);
            }
          }
        }
      }
      else if (this.selectedActionButton == 'next' || this.selectedActionButton == 'cancel') {
        //this.SaveOFDetails(value);
        if (!this.editMode) { this.saveCasetypeDetails(); }
        this.proceedFromCasetype();
      }
    }
    this.closePopUp();
  }

  async proceedFromCasetype() {
    this.errorAlertCasetypeOnNoLab = "";
    this.errorAlertCasetype = "";
    if (this.facilityDataSaved && this.flagsDataSaved) {
     return await this.createOF();
    }
    else {
      if (!this.facilityDataSaved) {
        //go to facility tab
        this.selectedTab.setValue(0);
        this.enableTab(true, false, false, false);
        this._snackBar.open('Save the Facility Details !', 'Close');
      }
      else if (!this.flagsDataSaved) {
        //go to flags tab
        this.selectedTab.setValue(1);
        this.enableTab(false, true, false, false);
        this._snackBar.open("Save the Flag Details !", "Close");
      }
    }

  }

  // close Pop up for stay on page button click
  closePopUp() {
    this.popUpButton = "";
    this.setupNewFacilityModal = false;
    // $('#setupNewFacilityModal').appendTo("")
  }
  closeLocationPopup(){
    if (!this.facilityDataSaved) {
      this.setupNewFacilityModal=true;
    }
    this.locationAlert=false;
  }
  //#endregion

  //#region Save flags data
  flagsDataChanges(flag: any) {
    this.flagsDataSaved = false;
    if (flag != 'holdReleaseDuration') {
      this.flagsTabData[flag] = !this.flagsTabData[flag];

      //old code not working
      // let objNames = Object.keys(this.flagsTabData);
      // objNames.forEach(e => {
      //   if (e.toString().toLocaleLowerCase() == flag.toLowerCase()) {
      //     this.flagsTabData[e] = !this.flagsTabData[e];
      //   }
      // })
      // this[flag] = this[flag]
      // flag.value = !flag.value;
      // this.flagsTabData = {
      //   emailLoginType: false,
      //   diagnosisReporting: false,
      //   autoOpenOrdersCase: false,
      //   showReviewPathSign: false,
      //   autoPopulateDiagCodes: false,
      //   notifyToProvideDiagCodes: false,
      //   oFSetupCompleted: false,
      //   holdReleaseDuration: '0'
      // };
    }
  }
  //#region

  //#region Save all the details of specified pages
  SaveOFDetails(page) {
    this.ValidateNPIandgetData();
    this.beingSaved = true;
    if (page == 'facilityDetails') {
      this.facilityDataSaved = false;
      this.savefacility();
    }
    else if (page == 'flagDetails') {
      this.ngxService.start("SetupNewFacility3");
      this.flagsDataSaved = true;
      this.saveflags();
      this.ngxService.stop("SetupNewFacility3");
      if (!this.editMode) {
        if (this.popUpButton != "proceedWithoutSave") {
          this._snackBar.open('Flags Saved !', 'Close');
        }
      }
    }
    else if (page == 'casesTypeDetails') {
      if (!this.editMode) {
        this.casetypeDataSaved = false;
        this.savecasetypes();
      }
    }
    this.beingSaved = false;
  }

  savefacility() {
    this.ngxService.start();
    this.submitted = true;
    let flag = true;
    //this.allOFDetails = {};
    let OFDetails = this.frm_FacilityData.value;

    //Validation
    this.errorAlertDiv = "";
    this.NoLabAssociated = false;

    //NPI validation
    if (OFDetails.frm_NPI != '' && OFDetails.frm_NPI.toLowerCase() != 'unknown') {
      this.validationWarning = !this.npiValidated;
      this.NPIWarning = false;
    }
    else if (OFDetails.frm_NPI.toLowerCase() == 'unknown') {
      this.validationWarning = false;
      this.NPIWarning = false;
      // this.npiValidated = true;
    }
    else if (OFDetails.frm_NPI != '') {
      this.validationWarning = true;
    }
    else {
      this.validationWarning = true;
    }

    //check npi if validated
    if (this.validationWarning || this.NPIWarning) {
      this.errorAlertDiv += "Enter Valid NPI details !"
      flag = false;
    }
    //mandatory fields check
    if ((!OFDetails.frm_name || OFDetails.frm_name == '') ||
      (!OFDetails.frm_displayname || OFDetails.frm_displayname == '') ||
      (!OFDetails.frm_ClaimOptions || OFDetails.frm_ClaimOptions == '') ||
      (!OFDetails.frm_shortname || OFDetails.frm_shortname == '')) {
      this.errorAlertDiv += 'Enter all mandatory fields !   ';
      flag = false;
    }

    //check if mnemonic is generated
    if (OFDetails.frm_name && OFDetails.frm_name != '' && (!OFDetails.frm_shortname || !(OFDetails.frm_shortname && OFDetails.frm_shortname.toString().trim())))   {
      this.generateMnemonic(OFDetails.frm_name);
      if (!this.frm_mnemonic || this.frm_mnemonic == '') {
        this.errorAlertDiv += 'Enter valid Name !   ';
        flag = false;
      }
    }

    //check if only numbers and charaters are allowed and
    //check if phone(2), zip and fax are numbers are are validated
    if (OFDetails.frm_zip && OFDetails.frm_zip != "") {
      if (!this.CheckIfOnlyNumberandSpclChar(OFDetails.frm_zip) || !this.zipCodeValidation(OFDetails.frm_zip)) {
        this.zipValid = false;
        flag = false;
        this.errorAlertDiv += 'Enter valid Zip !   ';
      }
      else {
        this.zipValid = true;
      }
    }
    else {
      this.zipValid = true;
    }

    if (OFDetails.frm_fax && OFDetails.frm_fax != "") {
      if (!this.CheckIfOnlyNumberandSpclChar(OFDetails.frm_fax) || !this.validateFax(OFDetails.frm_fax)) {
        this.faxValid = false;
        flag = false;
        this.errorAlertDiv += 'Enter valid Fax !   ';
      }
      else {
        this.faxValid = true;
      }
    }
    else {
      this.faxValid = true;
    }

    if (OFDetails.frm_phone && OFDetails.frm_phone != "") {
      if (!this.CheckIfOnlyNumberandSpclChar(OFDetails.frm_phone) || !this.validatePhone(OFDetails.frm_phone)) {
        this.phoneValid = false;
        flag = false;
        this.errorAlertDiv += 'Enter valid Phone # !   ';
      }
      else {
        this.phoneValid = true;
      }
    }
    else {
      this.phoneValid = true;
    }

    if (OFDetails.frm_PrimaryPhone && OFDetails.frm_PrimaryPhone != "") {
      if (!this.CheckIfOnlyNumberandSpclChar(OFDetails.frm_PrimaryPhone) || !this.validatePhone(OFDetails.frm_PrimaryPhone)) {
        this.primaryPhoneValid = false;
        flag = false;
        this.errorAlertDiv += 'Enter valid Primary Phone # !   ';
      }
      else {
        this.primaryPhoneValid = true;
      }
    }
    else {
      this.primaryPhoneValid = true;
    }

    if (OFDetails.frm_PrimaryEmail && OFDetails.frm_PrimaryEmail != "") {
      this.primaryEmailValid = this.validateEmail(OFDetails.frm_PrimaryEmail);
      if (!this.primaryEmailValid) {
        flag = false;
        this.errorAlertDiv += 'Enter email id !   ';
      }
    }
    else {
      this.primaryEmailValid = true;
    }

    //get list of labs associated
    this.selectedlabListObj = [];
    this.selectedlabListObj = this.LabLocationList.filter(e => e.Checked == true);

    //check if atleast one location is selected for association
    if (!(this.selectedlabListObj.length > 0)) {
      this.NoLabAssociated = true;
      this.errorAlertDiv += 'Atleast one Location must be associated !   ';
      flag = false;
    }

    //if all facility tab fields are valid
    if (flag) {
      this.facilityDataSaved = true;
      this.saveFacilityTabData(OFDetails);
      if (this.FacilityDataSavedNotFirstTime) {
        this.FacilityDataSavedSecondTimeOnwards = true
      }
      else {
        this.FacilityDataSavedSecondTimeOnwards = false;
      }
      if (this.ContextValue == 'EPG') {
        this.FacilityDataSavedNotFirstTime = true;
        this._snackBar.open('EPG Details Saved !', 'Close');
      }
      else {
        this.FacilityDataSavedNotFirstTime = true;
        if (!(this.selectedActionButton=="next" && this.page=='facilityDetails')) {
          this.validateLocations();
        }
        this._snackBar.open('Facility Details Saved !', 'Close');
      }
    }
    else {
      this.facilityDataSaved = false;
      this.savedfacilityData = {};
      this._snackBar.open('Enter Valid Data !', 'Close');
    }
    this.ngxService.stop();
  }

  saveflags() {
    this.flagsDataSavedNotFirstTime = true;
    //the save flag values are stored here
    // this.savedflagData = JSON.parse(JSON.stringify(this.flagsTabData));
    this.savedflagData = {
      LoginType: this.flagsTabData.emailLoginType == true ? "Email" : "LoginName",
      HoldRelease: (this.flagsTabData.holdReleaseDuration) ? this.flagsTabData.holdReleaseDuration : 0,
      ShowReviewPathSignature: this.flagsTabData.showReviewPathSign == true ? 1 : 0,
      AutoPopulateDiagICD9Codes: this.flagsTabData.autoPopulateDiagCodes == true ? 1 : 0,
      DiagICDcodeReminder: this.flagsTabData.notifyToProvideDiagCodes == true ? 1 : 0,
      isOrgSetupPending: this.flagsTabData.oFSetupCompleted == true ? 0 : 1,
      IsVitalOrdersEnabled: this.flagsTabData.diagnosisReporting == true ? 1 : 0,
      loadByDefault: this.flagsTabData.autoOpenOrdersCase == true ? 1 : 0,
      GenerateCoverSheet: this.flagsTabData.GenerateCoverSheet == true ? 1 : 0,
      //OwnerLabOrgId:this.flagsTabData.OwnerLabOrgId == true ? sessionStorage.getItem() : 0,
      AllowReportViewing: this.flagsTabData.AllowReportViewing == true ? 1 : 0,
      Accessioninstructions: this.html,
      DiagnosisCustomInstructions: this.html1,
      ispcenabled: this.flagsTabData.ispcenabled == true ? 1 : 0,
      isautosynchronized: this.flagsTabData.isautoassociated == true ? 1 : 0,
      isautoassociated: this.flagsTabData.isautoassociated == true ? 1 : 0,
      displayofinlists: this.flagsTabData.displayofinlists == true ? 1 : 0
    }
    //to patch for edit the copy is stored
    this.updatedFlagData = {
      emailLoginType: this.flagsTabData.emailLoginType,
      IsVitalOrdersEnabled: this.flagsTabData.diagnosisReporting,
      autoOpenOrdersCase: this.flagsTabData.autoOpenOrdersCase,
      showReviewPathSign: this.flagsTabData.showReviewPathSign,
      autoPopulateDiagCodes: this.flagsTabData.autoPopulateDiagCodes,
      notifyToProvideDiagCodes: this.flagsTabData.notifyToProvideDiagCodes,
      oFSetupCompleted: this.flagsTabData.oFSetupCompleted,
      holdReleaseDuration: this.flagsTabData.holdReleaseDuration,
      GenerateCoverSheet: this.flagsTabData.GenerateCoverSheet,
      OwnerLabOrgId: this.flagsTabData.OwnerLabOrgId,
      AllowReportViewing: this.flagsTabData.AllowReportViewing,
      Accessioninstructions: this.html,
      DiagnosisCustomInstructions: this.html1,
      ispcenabled: this.flagsTabData.ispcenabled == true ? 1 : 0,
      isautosynchronized: this.flagsTabData.isautoassociated == true ? 1 : 0,
      isautoassociated: this.flagsTabData.isautoassociated == true ? 1 : 0,
      displayofinlists: this.flagsTabData.displayofinlists == true ? 1 : 0
    }
  }
  //after the facilty tab details are validated, the data is saved
  async saveFacilityTabData(OFDetails: any) {
    //code to fetch the logo
    let logoValue = null; // this.logoExists();
    if (this.frm_logo && this.frm_logo != "") {
      let imageSrcString = await this.toDataURL(this.frm_logo)
      imageSrcString = imageSrcString.toString().split(",")[1];
      logoValue = imageSrcString;
    }

    //get list of account id's to send to api
    let labListObj: any = '';
    if (this.selectedlabListObj && this.selectedlabListObj != "" && this.selectedlabListObj.length > 0) {
      this.selectedlabListObj.forEach(e => {
        labListObj += e.AccountID + ','
      });
      //to remove last comma
      labListObj = labListObj.substring(0, labListObj.length - 1).trim();
    }

    //get select courier userid's
    let courierUserIds: any = '';
    if (OFDetails.frm_Courier && OFDetails.frm_Courier != "" && OFDetails.frm_Courier.length > 0) {
      OFDetails.frm_Courier.forEach(e => {
        courierUserIds += e + ','
      });
      //to remove last comma  and  if initial value is empty so substring starts from 1 else 0
      courierUserIds = courierUserIds.substring(0, courierUserIds.length - 1).trim();
    }

    //get select sales userid's and initial value is empty so substring starts from 1
    let salesUserIds: any = '';
    if (OFDetails.frm_salesExecutive && OFDetails.frm_salesExecutive != "" && OFDetails.frm_salesExecutive.length > 0) {
      OFDetails.frm_salesExecutive.forEach(e => {
        salesUserIds += e + ','
      });
      //to remove last comma and  if initial value is empty so substring starts from 1 else 0
      salesUserIds = salesUserIds.substring(0, salesUserIds.length - 1).trim();
    }

    this.savedfacilityData = {
      OrganizationName: OFDetails.frm_name ? OFDetails.frm_name.trim() : "",
      ShortName: OFDetails.frm_shortname ? OFDetails.frm_shortname.trim() : "",
      UsernameDisplayFormat: "S.L,F",
      UsernameReportFormat: "S.L,F",
      LocDisplayName: OFDetails.frm_displayname ? OFDetails.frm_displayname.trim() : "",
      Mnemonic: this.frm_mnemonic ?? "",
      Address1: OFDetails.frm_Address1 ?? "",
      Address2: OFDetails.frm_Address2 ?? "",
      City: OFDetails.frm_city ?? "",
      Country: OFDetails.frm_country ?? "",
      State: OFDetails.frm_state && OFDetails.frm_state != '' ? OFDetails.frm_state : null,
      Zip: OFDetails.frm_zip ?? "",
      Phone: OFDetails.frm_phone ?? "",
      Npi: OFDetails.frm_NPI && OFDetails.frm_NPI != '' ? OFDetails.frm_NPI : 'UNKNOWN',
      Statelic: null,
      Ein: OFDetails.frm_ein && OFDetails.frm_ein != "" ? OFDetails.frm_ein : null,
      CLIA: OFDetails.frm_clia && OFDetails.frm_clia != "" ? OFDetails.frm_clia : null,
      Taxonomycode: null,
      Status: "active",
      Fax: OFDetails.frm_fax ?? "",
      BillingPhoneNumber: "",
      Logo: logoValue ?? null,
      iUserID: sessionStorage.getItem('org_id').toString().trim() ?? null,
      Createdby: sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid') : -100,
      vClientNumber: OFDetails.frm_Client_Code ?? '',
      vAccountCode: OFDetails.frm_AccountCode ?? '',
      vLocationCode: OFDetails.frm_Location_Code ?? '',
      PrimaryContactname: OFDetails.frm_PrimaryName ?? "",
      PrimaryContactPhone: OFDetails.frm_PrimaryPhone ?? '',
      PrimaryContactEmail: OFDetails.frm_PrimaryEmail ?? '',
      ClaimOptions: OFDetails.frm_ClaimOptions && OFDetails.frm_ClaimOptions != "" ? OFDetails.frm_ClaimOptions : null,
      templateOrganizationID: 0,
      vServices: OFDetails.frm_DefaultServiceType ?? '',
      vSelectedUserID: salesUserIds && salesUserIds != "" ? salesUserIds : null,
      courierUserID: courierUserIds && courierUserIds != "" ? courierUserIds : null,
      vIsClientCodeEnabled: this.checkClientCode == true ? 1 : 0,
      LabAccountIDs: labListObj ?? null,
      StateDisplayName: OFDetails.frm_state && OFDetails.frm_state != '' ? OFDetails.frm_state : null,
    }

    this.facilityDataSavedToPatch = {
      OrganizationName: this.savedfacilityData.OrganizationName,
      ShortName: this.savedfacilityData.ShortName,
      LocDisplayName: this.savedfacilityData.LocDisplayName,
      Address1: this.savedfacilityData.Address1,
      Address2: this.savedfacilityData.Address2,
      City: this.savedfacilityData.City,
      Country: this.savedfacilityData.Country,
      State: this.savedfacilityData.State,
      Zip: this.savedfacilityData.Zip,
      Phone: this.savedfacilityData.Phone,
      Npi: this.savedfacilityData.Npi,
      Ein: this.savedfacilityData.Ein,
      CLIA: this.savedfacilityData.CLIA,
      Fax: this.savedfacilityData.Fax,
      ClientNumber: this.savedfacilityData.vClientNumber,
      AccountCode: this.savedfacilityData.vAccountCode,
      LocationCode: this.savedfacilityData.vLocationCode,
      PrimaryContactname: this.savedfacilityData.PrimaryContactname,
      PrimaryContactPhone: this.savedfacilityData.PrimaryContactPhone,
      PrimaryContactEmail: this.savedfacilityData.PrimaryContactEmail,
      ClaimOptions: this.savedfacilityData.ClaimOptions ?? '',
      ServiceType: this.savedfacilityData.vServices ?? '',
      sales: OFDetails.frm_salesExecutive,
      courier: OFDetails.frm_Courier,
      IsClientCodeEnabled: this.checkClientCode,
      LabAccountIDs: JSON.parse(JSON.stringify(this.LabLocationList)),
      Logo: this.frm_logo,
      files: this.files,
      imagePath: this.imagePath
    }
    this.savedcheckAllLab = this.checkAllLab;
  }

  savecasetypes() {
    this.ngxService.start("SetupNewFacility4");
    this.saveCasetypeDetails()
    this.ngxService.stop("SetupNewFacility4");
  }

  saveCasetypeDetails() {
    this.selectedCasetypesList = [];
    //check if casetypes are listed in the tab
    if (this.filteredCategoryList.length > 0) {
      this.filteredCategoryListAfterSaved = [];
      //save the copy of updated casetypes
      // this.filteredCategoryListAfterSaved = JSON.parse(JSON.stringify(this.filteredCategoryList));
      this.filteredCategoryList.forEach(element => {
        this.filteredCategoryListAfterSaved.push(element);
      });
      //keep a copy of all checkbox
      this.SavecheckAllCaseTypeValue = this.checkAllCaseType;

      //if casetypes are listed then do the following
      //forming casetype data to send to api
      let casetypeObj = [];
      this.filteredCategoryList.forEach(e => {
        e.caseTypes.forEach(ele => {
          if (ele.checked) {
            casetypeObj.push(
              {
                ID: casetypeObj.length + 1, CaseTypeName: ele.casetype,
                AccessionFormat: "", AlternateFormat: "", Category: e.Category
              });
          }
        });
      });

      this.selectedCasetypesList = casetypeObj;
      if (!(casetypeObj.length > 0)) {
        this.errorAlertCasetype = 'Select atleast one Case Type and Save !   ';
        //this.errorAlertDiv = this.errorAlertCasetype;
        this.casetypeDataSaved = false;
      }
      else {
        this.errorAlertCasetype = '';
        this.casetypeDataSaved = true;
        this.CasetypesDataSavedNotFirstTime = true;
        this._snackBar.open('Case Type Details Saved !', 'Close')
      }
    }
    else {
      this.casetypeDataSaved = false;
      // this._snackBar.open('Select atleast one Case Type and Save !', 'Close');
      this._snackBar.open('Select atleast one Laboratory having Case Types associated !', 'Close');
    }
  }

  async createOF() {
    //func to call the api
    let facilityTabDataSaved = this.savedfacilityData;
    //form the obj of details that is sent to api
    if (this.ContextValue == 'Ordering Facility') {
      this.allOFDetails = {
        //facility data
        OrganizationID: (this.editMode) ? parseInt(this.editData.OrganizationID) : 0,
        OrganizationName: facilityTabDataSaved.OrganizationName,
        ShortName: facilityTabDataSaved.ShortName,
        UsernameDisplayFormat: facilityTabDataSaved.UsernameDisplayFormat,
        UsernameReportFormat: facilityTabDataSaved.UsernameReportFormat,
        LocDisplayName: facilityTabDataSaved.LocDisplayName,
        Mnemonic: facilityTabDataSaved.Mnemonic,
        Address1: facilityTabDataSaved.Address1,
        Address2: facilityTabDataSaved.Address2,
        City: facilityTabDataSaved.City,
        Country: facilityTabDataSaved.Country,
        State: facilityTabDataSaved.State,
        Zip: facilityTabDataSaved.Zip,
        Phone: facilityTabDataSaved.Phone,
        Url: facilityTabDataSaved.Url,
        Supportid: facilityTabDataSaved.Supportid,
        Npi: facilityTabDataSaved.Npi,
        Statelic: facilityTabDataSaved.Statelic,
        Ein: facilityTabDataSaved.Ein,
        CLIA: facilityTabDataSaved.CLIA,
        Taxonomycode: facilityTabDataSaved.Taxonomycode,
        Status: facilityTabDataSaved.Status,
        Fax: facilityTabDataSaved.Fax,
        BillingPhoneNumber: facilityTabDataSaved.BillingPhoneNumber,
        Logo: facilityTabDataSaved.Logo,
        iUserID: facilityTabDataSaved.iUserID,
        Createdby: facilityTabDataSaved.Createdby,
        vClientNumber: facilityTabDataSaved.vClientNumber,
        vAccountCode: facilityTabDataSaved.vAccountCode,
        vLocationCode: facilityTabDataSaved.vLocationCode,
        PrimaryContactname: facilityTabDataSaved.PrimaryContactname,
        PrimaryContactPhone: facilityTabDataSaved.PrimaryContactPhone,
        PrimaryContactEmail: facilityTabDataSaved.PrimaryContactEmail,
        ClaimOptions: facilityTabDataSaved.ClaimOptions,
        templateOrganizationID: facilityTabDataSaved.templateOrganizationID,
        vServices: facilityTabDataSaved.vServices,
        vSelectedUserID: facilityTabDataSaved.vSelectedUserID,
        courierUserID: facilityTabDataSaved.courierUserID,
        vIsClientCodeEnabled: facilityTabDataSaved.vIsClientCodeEnabled,
        LabAccountIDs: facilityTabDataSaved.LabAccountIDs,
        StateDisplayName: facilityTabDataSaved.State,
        //casetypes data
        CaseTypes: this.datashare.savedCaseTypes,
        //flags data
        LoginType: this.savedflagData.LoginType ?? 0,
        HoldRelease: (this.savedflagData.HoldRelease) ? this.savedflagData.HoldRelease : 0,
        ShowReviewPathSignature: this.savedflagData.ShowReviewPathSignature,
        AutoPopulateDiagICD9Codes: this.savedflagData.AutoPopulateDiagICD9Codes,
        DiagICDcodeReminder: this.savedflagData.DiagICDcodeReminder,
        isOrgSetupPending: this.savedflagData.isOrgSetupPending,
        IsVitalOrdersEnabled: this.savedflagData.IsVitalOrdersEnabled,
        loadByDefault: this.savedflagData.loadByDefault,
        AllowReportViewing: this.savedflagData.AllowReportViewing,
        OwnerLabOrgId: this.savedflagData.OwnerLabOrgId,
        GenerateCoverSheet: this.savedflagData.GenerateCoverSheet,
        Accessioninstructions: this.savedflagData.Accessioninstructions,
        DiagnosisCustomInstructions: !(this.savedflagData.DiagnosisCustomInstructions) ? this.html1 : this.savedflagData.DiagnosisCustomInstructions,
        ispcenabled:this.flagsTabData.ispcenabled == true ? 1 :0,
        isautosynchronized:this.flagsTabData.isautoassociated == true ? 1 : 0,
        isautoassociated: this.flagsTabData.isautoassociated == true ? 1 : 0,
        displayofinlists: 1,
        PatientPortalAccountID: 0,
        PatientPortalPhysicianID: 0
      }
    }
    else if (this.ContextValue == 'EPG') {
      this.allOFDetails = {
        //facility data
        OrganizationID: (this.editMode) ? parseInt(this.editData.OrganizationID) : 0,
        OrganizationName: facilityTabDataSaved.OrganizationName,
        ShortName: facilityTabDataSaved.ShortName,
        UsernameDisplayFormat: facilityTabDataSaved.UsernameDisplayFormat,
        UsernameReportFormat: facilityTabDataSaved.UsernameReportFormat,
        LocDisplayName: facilityTabDataSaved.LocDisplayName,
        Mnemonic: facilityTabDataSaved.Mnemonic,
        Address1: facilityTabDataSaved.Address1,
        Address2: facilityTabDataSaved.Address2,
        City: facilityTabDataSaved.City,
        Country: facilityTabDataSaved.Country,
        State: facilityTabDataSaved.State,
        Zip: facilityTabDataSaved.Zip,
        Phone: facilityTabDataSaved.Phone,
        Url: facilityTabDataSaved.Url,
        Supportid: facilityTabDataSaved.Supportid ? facilityTabDataSaved.Supportid : '',
        Npi: facilityTabDataSaved.Npi,
        Statelic: facilityTabDataSaved.Statelic,
        Ein: facilityTabDataSaved.Ein,
        CLIA: facilityTabDataSaved.CLIA,
        Taxonomycode: facilityTabDataSaved.Taxonomycode,
        Status: facilityTabDataSaved.Status,
        Fax: facilityTabDataSaved.Fax,
        BillingPhoneNumber: facilityTabDataSaved.BillingPhoneNumber,
        Logo: facilityTabDataSaved.Logo,
        iUserID: facilityTabDataSaved.iUserID,
        Createdby: facilityTabDataSaved.Createdby,
        vClientNumber: facilityTabDataSaved.vClientNumber,
        vAccountCode: facilityTabDataSaved.vAccountCode,
        vLocationCode: facilityTabDataSaved.vLocationCode,
        PrimaryContactname: facilityTabDataSaved.PrimaryContactname,
        PrimaryContactPhone: facilityTabDataSaved.PrimaryContactPhone,
        PrimaryContactEmail: facilityTabDataSaved.PrimaryContactEmail,
        ClaimOptions: facilityTabDataSaved.ClaimOptions,
        templateOrganizationID: facilityTabDataSaved.templateOrganizationID,
        vServices: facilityTabDataSaved.vServices,
        vSelectedUserID: facilityTabDataSaved.vSelectedUserID,
        courierUserID: facilityTabDataSaved.courierUserID,
        vIsClientCodeEnabled: facilityTabDataSaved.vIsClientCodeEnabled,
        LabAccountIDs: facilityTabDataSaved.LabAccountIDs,
        StateDisplayName: facilityTabDataSaved.State,
        //casetypes data
        CaseTypes: this.datashare.savedCaseTypes,
        CaseTypeOrderCode: this.datashare.savedOrderCodes,
        Instruments: this.datashare.savedInstruments,
        //flags data
        LoginType: this.savedflagData.LoginType,
        HoldRelease: (this.savedflagData.HoldRelease) ? this.savedflagData.HoldRelease : 0,
        ShowReviewPathSignature: this.savedflagData.ShowReviewPathSignature,
        AutoPopulateDiagICD9Codes: this.savedflagData.AutoPopulateDiagICD9Codes,
        DiagICDcodeReminder: this.savedflagData.DiagICDcodeReminder,
        isOrgSetupPending: this.savedflagData.isOrgSetupPending,
        IsVitalOrdersEnabled: this.savedflagData.IsVitalOrdersEnabled,
        loadByDefault: this.savedflagData.loadByDefault,
        isautosynchronized: this.flagsTabData.isautoassociated == true ? 1 : 0,
        isautoassociated: this.flagsTabData.isautoassociated == true ? 1 : 0,
        displayofinlists: this.flagsTabData.displayofinlists == true ? 1 : 0,
        PatientPortalAccountID: 0,
        PatientPortalPhysicianID: 0,
        Context: this.ContextValue,
      }
    }

    if (this.editMode) {
      return await this.updateLocationData();
    }
    else {
      //call the api to create facility
      return await this.saveToDatabase();
    }
  }

  async validateLocations(){
     await this.vaservices.validateLocationCode(
      {
     "LocationCode":this.formcontrol.frm_Location_Code.value,
     "AssociatedAccountId":this.editMode ? parseInt(this.editData.Associated_Account_Id):0,
    },
     sessionStorage.getItem('deploymentKey').toUpperCase()).subscribe(
        result=>{
          if(!result.errors){
            this.locationAlert=result.LocationAlert;
            if(result.AccountName!=null){
              this.accountNames=result.AccountName.split(','); 
            }
          }

          if (this.locationAlert==false && !this.facilityDataSaved) {
            this.setupNewFacilityModal=true;
          }
        },error=>{
          console.error(error);
        });
  }

  async saveToDatabase() {
    let res : any;
    if (this.ContextValue == 'Ordering Facility') {
      try{
      res  = await this.vaservices.createNewFacility(this.allOFDetails).toPromise();
        if (!res.errors) {
            if (res && res.length > 0) {
              res = res[0];
              for (let i = 0; i < this.customorgattributrlist.length; i++) {
                this.customorgattributrlist[i].EntityType = 2;
                this.customorgattributrlist[i].EntityID = res.OrgDetails.organizationid;
              }
              this.saveCustomorgAttribute()
              if (res.StatusMessage == 'Success') {
                this._snackBar.open('New Facility created successfully !', 'Close');
                this.getSummaryDetails(res);
              }
              else if (!res.Success) {
                this._snackBar.open('An error occurred while processing your request !', 'Failed');
                this.enableTab(false, false, true, false);
                this.selectedTab.setValue(2);
              }
              else {
                this._snackBar.open('An error occurred while processing your request !', 'Failed');
                this.enableTab(false, false, true, false);
                this.selectedTab.setValue(2);
              }
            }
            else if (!res.Success) {
              this._snackBar.open('An error occurred while processing your request !', 'Failed');
              this.enableTab(false, false, true, false);
              this.selectedTab.setValue(2);
            }
            else {
              this._snackBar.open('An error occurred while processing your request !', 'Failed');
              this.enableTab(false, false, true, false);
              this.selectedTab.setValue(2);
            }
        }
        else {
          this._snackBar.open('An error occurred while processing your request !', 'Failed');
          this.selectedTab.setValue(2);
          this.enableTab(false, false, true, false);
        }
        this.ngxService.stop("SetupNewFacility5");
      }
    catch {
        this.ngxService.stop("SetupNewFacility5");
        this.selectedTab.setValue(2);
        this.enableTab(false, false, true, false);
        this._snackBar.open('An error occurred while processing your request !', 'Failed');
      }
    }
    else if (this.ContextValue == 'EPG') {
      try{
      res = await this.vaservices.setupNewEntities(this.allOFDetails).toPromise();
        if (!res.errors) {
            if (res && res.length > 0) {
              res = res[0];
              if (res.StatusMessage == 'Success') {

                this._snackBar.open('EPG created successfully !', 'Close');
                this.getSummaryDetails(res);

              }
              else if (!res.Success) {
                this._snackBar.open('An error occurred while processing your request !', 'Failed');
                this.enableTab(false, false, true, false);
                this.selectedTab.setValue(2);
              }
              else {
                this._snackBar.open('An error occurred while processing your request !', 'Failed');
                this.enableTab(false, false, true, false);
                this.selectedTab.setValue(2);
              }
            }
            else if (!res.Success) {
              this._snackBar.open('An error occurred while processing your request !', 'Failed');
              this.enableTab(false, false, true, false);
              this.selectedTab.setValue(2);
            }
            else {
              this._snackBar.open('An error occurred while processing your request !', 'Failed');
              this.enableTab(false, false, true, false);
              this.selectedTab.setValue(2);
            }
        }
        else {
          this._snackBar.open('An error occurred while processing your request !', 'Failed');
          this.selectedTab.setValue(2);
          this.enableTab(false, false, true, false);
        }
        this.ngxService.stop("SetupNewFacility5");
      }
      catch {
        this.ngxService.stop("SetupNewFacility5");
        this.selectedTab.setValue(2);
        this.enableTab(false, false, true, false);
        this._snackBar.open('An error occurred while processing your request !', 'Failed');
      }
    }
    return res;

  }
  //#endregion

  //#region enable/disable tabs
  enableTab(facilityTab: boolean, flagsTab: boolean, casetypesTab: boolean, summaryTab: boolean) {
    this.hideFacilityTab = !facilityTab;
    this.hideFlagsTab = !flagsTab;
    this.hideCaseTypeTab = !casetypesTab;
    this.hideSummaryTab = !summaryTab;
  }
  //#endregion

  //#region Export summary data
  exportData() {
    let accountTab = [];
    let casetypeTab = [];
    let userTab = [];
    let filename = this.ContextValue + '_';
    if(this.summaryData.AccDetails.accountname){
      filename = filename + this.summaryData.AccDetails.accountname.toString();
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
      let orgname = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
      if (orgname.length > 32) {
        fileName = filename.split('_')[0].replace(' ','') + '_';
        filename = fileName + orgname + '_';
      }
      else {
        filename = fileName + orgname + '_';
      }
    }
    else {
      filename = filename + '_';
    }
  }
    filename = filename +  this.summaryData.AccDetails.accountid.toString()+ '.xlsx';
    if(filename.length > 31){
      let fileName = filename.split('_')[0] + '_';
      let orgid = filename.split('_')[2];
      filename = fileName +orgid;
    }
    var sheet = XLSX.utils.book_new();

    //#region
    //account tab in the excel
    accountTab.push({
      "Account Name": this.summaryData.AccDetails.accountname,
      "Display Name": this.summaryData.AccDetails.displayname,
      "Short Name": this.summaryData.AccDetails.ShortName,
      "Mnemonic": this.summaryData.AccDetails.mnemonic,
      "Address1": this.summaryData.AccDetails.address1,
      "Address2": this.summaryData.AccDetails.address2,
      "City": this.summaryData.AccDetails.city,
      "State": this.summaryData.AccDetails.state,
      "Zip": this.summaryData.AccDetails.zip,
      "Fax": this.summaryData.AccDetails.fax,
      "Phone": this.summaryData.AccDetails.phone,
      "NPI": this.summaryData.AccDetails.npi,
      "GUID": this.summaryData.AccDetails.AccountGUID
    });

    var sheetData1 = XLSX.utils.json_to_sheet(accountTab);
    XLSX.utils.book_append_sheet(sheet, sheetData1, 'locations');

    //case tab in the excel;
    this.summaryData.CasetypeData.forEach(
      va => {
        casetypeTab.push({
          "CaseType": va.casetype,
          "Display Name": va.CaseTypeDisplayname,
          "Category": va.Category
        })
      })

    if (casetypeTab.length > 0) {
      var sheetData2 = XLSX.utils.json_to_sheet(casetypeTab);
      XLSX.utils.book_append_sheet(sheet, sheetData2, 'casetype');
    }
    //user tab
    this.summaryData.UsersData.forEach(va => {
      userTab.push({
        "Login Name": va.loginname,
        "Role Name": va.rolename,
        "Display Name": va.FormattedDisplayName,
        "Email": va.email
      })
    })

    if (userTab.length > 0) {
      var sheetData3 = XLSX.utils.json_to_sheet(userTab);
      XLSX.utils.book_append_sheet(sheet, sheetData3, 'users');
    }
    XLSX.writeFile(sheet, filename);
  }
  //#endregion


  //#region summary
  getSummaryDetails(data) {
    //get and set summary data
    this.ownerLabName = this.ownerLabName ?? 'Not Specified';
    this.summaryData.OrgLabAccData = data.OrgDetails ?? [];
    this.summaryData.AccDetails = data.AccDetails ?? [];
    this.summaryData.CasetypeData = data.CasetypeDetails ?? [];
    this.summaryData.UsersData = data.UserDetails ?? [];
    if (this.summaryData && Object.keys(this.summaryData).length !== 0) {
      this.enableTab(false, false, false, true);
      this.selectedTab.setValue(3);
      // //scroll to top
      // document.getElementById("setupCwc").scroll({
      //   top: 0,
      //   behavior: 'smooth'
      // });
    }
    else {
      this._snackBar.open('An error occurred while processing your request !', 'Failed');
      this.enableTab(false, false, true, false);
      this.selectedTab.setValue(2);
    }

    //call audit for activity tracking
    this.sendAuditDetails();
  }

  finishSetup() {
    this.back();
  }
  //#endregion

  //#region Download pdf
  downloadPDF() {

  }
  //#endregion

  //#region to add users to summary page
  addUsers() {
    this.ngxService.start("setupnewFacility1");
    // check if modal exists
    var elementExists = document.getElementById("AddUsersToOFModal");
    //set org id and acc id to send to all users component
    this.OrgId = {
      organizationid: this.summaryData.OrgLabAccData.organizationid ?? '',
      organizationname: this.summaryData.OrgLabAccData.organizationname ?? ''
    }

    this.AccId = this.summaryData.AccDetails.accountid ?? '';
    //open summary pop up to add users
    this.showAddUsers = true
    $('#AddUsersToOFModal').appendTo("body").modal('show');
    setTimeout(() => {
      this.openSummaryPopUp(elementExists);
    });
    this.ngxService.stop('setupnewFacility1');
  }

  refreshUsersData() {
    this.ngxService.start("setupnewFacility2");
    // getUsersByAccountId
    let queryVariable = { accId: this.summaryData.AccDetails.accountid ?? '' };
    let query = this.SubMenuCardModel.GetQuery('getUsersByAccountId');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vaservices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {
        if (res && res.data.subData && res.data.subData.length > 0) {
          this.summaryData.UsersData = res.data.subData ? res.data.subData : [];
          this._snackBar.open("Users List updated successfully", "Close")
        }
        else {
          this.summaryData.UsersData = [];
        }
      }
      else {
        this.summaryData.UsersData = [];
        this._snackBar.open("error found while refreshing the data !", "Close")
      }
      this.ngxService.stop('setupnewFacility2');
    }, error => {
      console.error(error)
      this.ngxService.stop('setupnewFacility2');
      this.summaryData.UsersData = [];
      this._snackBar.open("error found while refreshing the data !", "Close")
    });
  }
  //#endregion

  //#region add users at summary pop up
  //code to open add users pop up screen
  openSummaryPopUp(elementExists) {
    this.fromDepKey = sessionStorage.getItem('deploymentKey');
    if (elementExists == null || elementExists == undefined) {
      $('#AddUsersToOFModal').appendTo("body").modal('show')
    }
    else {
      // $('.AddUsersToOFModallast').remove();
      // $('.AddUsersToOFModal:last').modal('show');
      $('#AddUsersToOFModal').appendTo("body").modal('show');
    }
  }

  closeSummaryPopUp() {
    this.closeSummaryPopUpWithoutRefreshdata();
  }

  closeSummaryPopUpWithoutRefreshdata() {
    // if (!this.editMode) {
      this.refreshUsersData();
    // }
    // $('#AddUsersToOFModal').remove();
    // return
    $('.AddUsersToOFModal').modal('hide');
    this.OrgId = '';
    this.AccId = '';
    this.showAddUsers = false;
    return;
  }
  //#endregion

  //#region To send audit details
  sendAuditDetails() {
    this.ngxService.start("SetupNewFacility6");
    if (!this.editMode) {
      this.commonService.createActivityTracker('Setup New Facility Created', this.summaryData.OrgLabAccData.organizationid, 'Clients', 'Audit', this.allOFDetails, {});
    }
    else {
      this.commonService.createActivityTracker('Facility location updated', this.summaryData.OrgLabAccData.organizationid, 'Clients', 'Audit', this.allOFDetails, this.previousOFDetails);
    }
    this.ngxService.stop("SetupNewFacility6");
  }
  //#endregion

  async updateLocationData() {
    let deSelectedAccountIDs = [];
    if (this.labAssociationList && this.labAssociationList.length > 0) {
      this.labAssociationList.forEach(e => {
        this.LabLocationList.forEach(r => {
          if (e.LabID == r.AccountID && r.Checked != true) {
            deSelectedAccountIDs.push(r.AccountID)
          }
        })
      });
    }
    this.allOFDetails["Accountid"] = parseInt(this.editData.Associated_Account_Id);
    this.allOFDetails["id"] = parseInt(this.editData.ID);
    this.allOFDetails["Context"] = this.ContextValue;
    this.allOFDetails["displayofinlists"] = this.flagsTabData.displayofinlists ? 1 : 0;
    this.allOFDetails["isautoassociated"] = this.flagsTabData.isautoassociated ? 1 : 0;
    this.allOFDetails["isautosynchronized"] = this.flagsTabData.isautosynchronized ? 1 : 0;
    this.allOFDetails["ispcenabled"] = this.flagsTabData.ispcenabled ? 1 : 0;
    this.allOFDetails["selectaccountcasetype"] = this.allOFDetails['vSpeciality'];
    this.allOFDetails["DeslectLabAccountIDs"] = deSelectedAccountIDs.toString();
    this.allOFDetails["AllowReportViewing"] = this.flagsTabData.AllowReportViewing ? 1 : 0;
    this.allOFDetails["GenerateCoverSheet"] = (this.flagsTabData.GenerateCoverSheet) ? 1 : 0;
    this.allOFDetails["AccessionInstruction"] = this.flagsTabData.Accessioninstructions ? this.flagsTabData.Accessioninstructions : "";
    this.allOFDetails["DiagnosisCustomInstructions"] = this.allOFDetails["DiagnosisCustomInstructions"] ? this.allOFDetails["DiagnosisCustomInstructions"] : (this.flagsTabData.DiagnosisCustomInstructions ? this.flagsTabData.DiagnosisCustomInstructions : "");

    if (this.isownlaborgselected) {
      this.allOFDetails["OwnerLabOrgId"] = sessionStorage.getItem('org_id');
    }
    let res : any;
   try{
    res = await this.vaservices.UpdateEntities(this.allOFDetails).toPromise();
      if (!res.errors) {
          if (res && res.length > 0) {
            for (let i = 0; i < this.customorgattributrlist.length; i++) {
              this.customorgattributrlist[i].EntityType = 2;
              this.customorgattributrlist[i].EntityID = res[0].OrgDetails.organizationid;
            }
            this.saveCustomorgAttribute()
            if (res[0].StatusMessage && res[0].StatusMessage === 'Success') {
              this.getSummaryDetails(res[0]);
              this._snackBar.open("Updated Successfully", "Close")
            }
            else {
              this._snackBar.open("Update failed", "Close")
            }
          }
          else {
            this._snackBar.open("Update failed", "Close")
          }
      }
    }
    catch {
      this._snackBar.open('An error occurred while processing your request !', 'Failed');
    }
    return res;
  }

  //on destroy remove the modals that has been appended on the body
  ngOnDestroy() {
    //code to remove the modals in the component appended to the body
    this.setupNewFacilityModal = false;
    $('#AddUsersToOFModal').remove();
  }

  nextFromCasetype() {
    this.selectedTab.setValue(this.selectedTab.value + 1);
    this.enableTab(false, false, false, false);
  }

  prevFromCasetype() {
    this.selectedTab.setValue(this.selectedTab.value - 1);
    this.enableTab(false, false, false, false);
  }

  async saveCaseTypes() {
    this.selectedCasetypesList = this.datashare.savedCaseTypes;
    return await this.proceedFromCasetype();
  }


  filterAccounts(account: string) {
    this.searchAccount = account
    // return this.filterCaseTypeData
  }


  getOrgList() {
    this.defaulLabOwnOrg = this.tabService.tabs[0].tabData.mainCard.Organization + "(" + this.tabService.tabs[0].tabData.mainCard.Org_ID + ")";
    const query = this.vaservices.GetQuery('getAssociateOwnerLabOrg');
    this.ngxService.start();
    const orgArr = [];
    const keys = ['organizationname', 'orgid'];

    const queryVariable = { accid: sessionStorage.getItem('AccountID').toString() };
    const queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vaservices.GetData(queryResult).subscribe(res => {
      this.ngxService.stop();
      const result = res.data.Admin3_vwGetAssociateOwnerLabOrg_list;
      for (let i = 0; i < result.length; i++) {
        let arr = {
          organizationname: result[i].organizationname,
          orgid: result[i].parentOrganization
        };
        orgArr.push(arr);
      }

      this.OrganizationOptionList = [this.defaulLabOwnOrg]; // Initialize with default

      const filtered = orgArr.filter((currentOrg, index, self) =>
        index === self.findIndex(org => {
          if (org.organizationname !== sessionStorage.getItem('Org_Name').toString() && org.orgid !== sessionStorage.getItem('org_id').toString()) {
            org.organizationname === currentOrg.organizationname && org.orgid === currentOrg.orgid
          }
        }


        )
      );
      this.OrganizationOptionList.push(...filtered.map(org => org.organizationname + "(" + org.orgid + ")"));
    });
  }

  customorgval: any = [];
  updateCustomorgAttributeArray(arrtdata: any, event) {
    arrtdata.Value = event.currentTarget.value;
    arrtdata.EntityID = "";
    arrtdata.EntityType = "";
    if (arrtdata.Value != '' || arrtdata.Value != null) {
      if (this.customorgval.length > 0) {
        const exists = this.customorgval.some(item => item.Attribute === arrtdata.Attribute &&
          item.LaborgId === arrtdata.LaborgId &&
          item.CAID === arrtdata.CAID &&
          item.EntityID === arrtdata.EntityID &&
          item.EntityType === arrtdata.EntityType &&
          item.Value === arrtdata.Value);
        // console.log(exists)
        if (!exists) {
          this.customorgval.push(arrtdata);
        }
      } else {
        this.customorgval.push(arrtdata);
      }
    }
  }

  saveCustomorgAttribute() {
    let dbName = sessionStorage.getItem('deploymentKey');
    this.vaservices.saveCustomOrgvalues(this.customorgattributrlist, dbName).subscribe(res => {
      //alert("added");
    }, error => {
      console.error(error);
      //this.ngxService.stop();
    });
  }

  customorgattributrlist: any = [];
  customorgvalueslist: any = [];
  async getCustomorgAttribute() {
    let query = this.vaservices.GetQuery('getCustomOrgAttributes');
    this.ngxService.start();
    let orgArr = [];
    let queryVariable = { id: sessionStorage.getItem('org_id').toString() }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    try {
      let res = await this.vaservices.GetData(queryResult).toPromise();
      let result = res.data.CustomOrgAttributes_list;
      this.customorgattributrlist = result;
    }
    catch {
      this._snackBar.open('Error processing your request!', 'Close');
    }
  }


  async getCustomAttrValues(orgid?: any, EntityID?, EntityType?) {
    let orgidt = orgid ? orgid : sessionStorage.getItem('org_id').toString();
    let EntityIDt = EntityID ? EntityID : null;
    let EntityTypet = EntityType;
    let query = this.vaservices.GetQuery('getCustomOrgvalues');
    this.ngxService.start();
    let orgArr = [];
    let queryVariable = { laborgid: orgidt, entityid: EntityIDt }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let res = await this.vaservices.GetData(queryResult).toPromise();
    this.customorgvalueslist = res
    if (this.customorgvalueslist.data.values && this.customorgvalueslist.data.values.length > 0) {
      this.mapCustumAttributes(this.customorgvalueslist)
    }
  }

  mapCustumAttributes(recevedata: any) {
    const joinedData = [];
    recevedata.data.values.forEach(value => {
      let matchingAttribute = recevedata.data.attributes.find(attribute =>
        value.LaborgId === attribute.LaborgId
        && value.CAID === attribute.CAID
      );

      if (matchingAttribute) {
        const joinedEntry = {
          LaborgId: value.LaborgId,
          CAID: value.CAID,
          EntityType: value.EntityType,
          EntityID: value.EntityID,
          Value: value.Value,
          Attribute: matchingAttribute.Attribute
        };
        joinedData.push(joinedEntry);
      }
    });
    this.finalMapAttributes(joinedData, this.customorgattributrlist)
  }

  finalMapAttributes(joinedData: any, customorgattributrlist: any) {
    if (this.customorgattributrlist.length > 0 && joinedData.length > 0) {
      for (let i = 0; i < customorgattributrlist.length; i++) {
        customorgattributrlist[i].EntityID = '';
        customorgattributrlist[i].EntityType = '';
        customorgattributrlist[i].Value = '';
      }
      let newwarr = [];

      for (let i = 0; i < customorgattributrlist.length; i++) {
        let foundMatch = false;

        for (let j = 0; j < joinedData.length; j++) {
          if (customorgattributrlist[i].CAID === joinedData[j].CAID &&
            customorgattributrlist[i].LaborgId === joinedData[j].LaborgId &&
            customorgattributrlist[i].Attribute === joinedData[j].Attribute) {

            if (customorgattributrlist[i].Value !== '' &&
              customorgattributrlist[i].EntityID !== '' &&
              customorgattributrlist[i].EntityType !== '') {
              newwarr.push(joinedData[j]);
            } else {
              customorgattributrlist[i].EntityID = joinedData[j].EntityID;
              customorgattributrlist[i].EntityType = joinedData[j].EntityType;
              customorgattributrlist[i].Value = joinedData[j].Value;
              newwarr.push(customorgattributrlist[i]);
            }

            foundMatch = true;
            break; // No need to continue searching once a match is found
          }
        }

        // If no match was found in joinedData, push the current item from customorgattributrlist
        if (!foundMatch) {
          newwarr.push(customorgattributrlist[i]);
        }
        this.customorgattributrlist = newwarr
      }
    }

  }

  getLabownorgAccess() {
    this.hidecheckbtn = sessionStorage.getItem('RoleName').includes('ImplementationManager') ? true : false;
  }

  updateCheckboxValue(isValidLabOwnOrg: any, event: MatCheckbox) {
    this.isValidLabOwnOrg = event.checked;
  }

  extractContentInsideBraces(inputString) {
    let extractedValues = [];
    const regex = /\(([^)]*)\)/g;
    const matches = inputString.match(regex);

    if (matches) {
      for (const match of matches) {
        const extractedValue = match.slice(1, -1); // Remove the parentheses
        extractedValues.push(extractedValue);
      }
    }
    return extractedValues;
  }

  ownerlaborgid: any;
  updateLabownOrgid(val: any, event) {
    this.isownlaborgselected = true
    let ownlabid = this.extractContentInsideBraces(val);
    this.ownerlaborgid = ownlabid[0];
  }
}
