import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { Store } from '@ngrx/store';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Subscription } from 'rxjs';

declare var $: any;

export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-adequacy-codes',
  templateUrl: './adequacy-codes.component.html',
  styleUrls: ['./adequacy-codes.component.scss']
})

export class AdequacyCodesComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };

  adequacyGridData: wjcCore.CollectionView<any>;
  gridDisplay: boolean = false;
  excelDataArray = [];
  gridArray = [];
  gridheader: any = [];
  gridWidth: number = 0;
  gridwidth: number = 0;
  selectedrowData: any = {};
  sheetHeader: string[];
  @Input()
  templateData: any;
  uploadClicked: boolean = false;
  addEditScreen: boolean = false;
  gridPage: boolean = false;
  workBook: any;
  sheetsToSelect: any;
  gridData: CollectionView;
  public SubMenuCardModel;
  gridarray: any[] = [];
  gridHeader = ["IsActive", "IsUserLevelEnabled", "Abnormal", "Sequence", "Description", "DisplayName", "Name", "Id", "CaseType", "UserId", "CreatedBy", "CreatedDate", "ModifiedBy", "ModifiedDate"];
  selectedOption = 'Group';
  showPaginationMainGrid: boolean = false;
  invalidColumns: string = "";
  showInvalidColumns: boolean = false;
  @ViewChild('txtInput') txtInput: ElementRef;
  @ViewChild('selectedUser') selectedUser!: ElementRef;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  showDelete: boolean = true;
  postDownload: boolean = false;
  postUpload: boolean = false;
  userList: any;
  dataListArray: any = [];
  inData: any;
  editEnabled: boolean = false;
  userListArray: any;
  userCardFlag: boolean = false;
  addHeader: string;
  adequacyType: string = "Group";
  public DeploymentKeys = [];
  searchInput: String = '';
  searchUserInput: String = '';
  usertoggle: boolean = false;
  copyDataClicked: boolean = false;
  destDeployment: string;
  searchuserlist: any[];
  srcDeployment: any;
  orgList: any[];
  public searchResult: Array<any> = [];
  srcOrgid: any;
  srcUserid: string;
  srcOrgName: any;
  srcUserName: any;
  selector: Selector;
  selectedItems: any = [];
  checkStatus = false;
  copyData: any = [];
  caseTypeArray: any[];
  repeatedNames: any = [];
  invalidCasetypeFlag: boolean;
  backBtn: boolean = true;
  userchanged: any;
  srcOrgType: any;
  orgFilterList: any[];
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');
  userRoles: any[] = [];
  noRole: boolean = false;
  selectedRole: any;
  organizationId: any;
  hideManageAdequacybtn: boolean = false;
  hideMigratebtn: boolean = false;
  gridHeaderList: any = [];
  ListedGridHeader: any = [];
  listHeaderbackup: any;
  SendGridData: wjcCore.CollectionView<any>;
  ViewScreen: boolean = false;
  saveOrEditCompleted: number;
  auditableColumns: any;
  activityEntity: any;
  dbName: string;
  deployment: void;
  selectedData:any = { CaseType: '', UserId: '' };
  gridArr: any = [];



  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, private templateHeaders: TemplateHeadersService, public activityService: ActivityTrackerService, private labAdminService: LabadminService, private labAdminSessionService: LabadminSessionService,
    private store: Store<{ breadcrum: [] }>, ) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
  }

  configname = new FormControl();

  adequacyForm = this._fb.group({
    frmname: ["", Validators.required],
    frmdisplayname: ["", Validators.required],
    frmsequanceorder: [""],
    frmusername: ["", Validators.required],
    frmdescription: ["", Validators.required],
    frmabnormal: true,
    frmstatus: true
  })

  copyEntityForm = this._fb.group({
    frmOrganization: ["", Validators.required],
    frmUser: [""],
    frmDepKey: ["", Validators.required],
    frmOrgType: ["", Validators.required],
  })

  AllFields = [{}]
  MandatoryFields = [{}]
  uploadBtn: boolean = true;
  createBtn: boolean = true;
  editBtn: boolean = true;
  exportBtn: boolean = true;
  copyBtn: boolean = true;
  sourceApp: string = "";
  userSessionDetails: any;
  @ViewChild('inputAutoComplete') inputAutoComplete: any;
  storeSub: Subscription = new Subscription();

  async ngOnInit() {

    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName.trim() == 'AdequacyCodes') {
        this.ListedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.ListedGridHeader))
      }

    }
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      let dbName = this.labAdminService.deploymentKey;
      await this.labAdminSessionService.getLabAdminSession.subscribe(async (session: any) => {
        this.storeSub = this.store.select("breadcrum").subscribe(async result => {
          let menuObj = result[result.length - 1];
          this.storeSub.unsubscribe()
          if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
            this.userSessionDetails = session["userDetails"]
            await this.commonService.getCommmonTemplateData(menuObj, this.userSessionDetails);
            this.sourceApp = 'VitalDx';
            this.templateData = this.labAdminService.templateData;
            dbName = this.labAdminService.deploymentKey;
            sessionStorage.setItem('deploymentKey', dbName);
            sessionStorage.setItem('org_id', this.templateData.secondarykeys.OrganizationId);
            this.adequacyType = 'Group';
            this.getListUsers(null, dbName);
            this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.adequacyType) : null;
            this.gridPage = true;
            this.getAuditableDetails(this.templateData.menuURL)
            this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Pathology Management','value':this.templateData['menuURL']}] })
            this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
            this.activityEntity.entityId = '';
            this.activityService.setActivitySession(this.activityEntity);
            this.GetButtonAccess(this.labAdminService.subMenuPermissions)
          }
        })
      })
    }
    else {
    if ((this.templateData.cardtype.toLowerCase() == 'users') || (this.templateData.cardtype.toLowerCase() == 'pathologist') || sessionStorage.getItem("search_context").toLowerCase() == "userid" || sessionStorage.getItem("search_context").toLowerCase() == 'physician') {
      this.userCardFlag = true;
        this.userRoles = await this.commonService.userRoles(this.templateData.menuURL.replace(/\s/g, ''))
      if (this.userRoles && this.userRoles.length > 0) {
        this.inData = this.userRoles[0].userid.toString();
        this.selectedRole = this.userRoles[0].RoleName.toString();
        this.getOrgCasetype();
      }
      else {
        this.noRole = true;
        return
      }
    } else {
      this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
      this.getListUsers(null, this.destDeployment);
    }
    this.gridPage = true;
      !this.inData ? this.addGridData(this.adequacyType) : this.addGridData(this.inData);
    // this.addGridData(this.adequacyType);
    this.GetButtondetails();
    let actcontextKey=this.userCardFlag?'User':'parentMenu';
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': actcontextKey, 'value': this.templateData.menuURL }] })
    this.getAuditableDetails(this.templateData.menuURL)
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
  }
  }

  setUserRole(event, data) {
    if (event.source.selected) {
      this.inData = data.userid.toString();
      this.selectedRole = data.RoleName.toString();
      this.commonService.selectedUserForAC = data.userid.toString();
      this.refreshUserGrid();
      // this.addGridData(this.inData)
    }
  }

  fnToggleFilter() {
    this.selectedOption = '';
    this.inData = null;
    this.adequacyType = 'Group';
  }

  clearOrganization() {
    this.gridwidth = 0;
    this.copyEntityForm.patchValue({
      frmOrganization: '',
      frmUser: ''
    })
  }

  clearUser() {
    this.gridwidth = 0;
    this.copyEntityForm.patchValue({
      frmUser: ''
    })
    this.srcUserid = ''
  }

  //#region main grid: get selected userlevel/orglevel value from Dropdown
  onSelectedType(e?, u?) {
    if(e){
    if (e.source.selected) {
      this.inData = null;
      this.inData = u.username.match(/Group/i) ? u.username : u.userid
      if (this.inData.toString().toLowerCase() != 'group') {
        this.adequacyType = 'User'
      }
      else {
        this.adequacyType = 'Group';
      }
      this.addGridData(this.inData);

    }
  }
  else{
    if(this.selectedData['CaseType']){
      this.adequacyType = 'Group';
    }
    this.addGridData(this.adequacyType);
  }
  }
  //#endregion

  //#region Users dropdown under userlevel add/edit form
  onSelectedUser(e, obj) {
    if (e.source.selected) {
      this.userchanged = (this.selectedrowData.UserId != obj.userid) ? true : false;
      this.selectedrowData.UserId = obj.userid
    }
  }
  //#endregion

  //#region
  removeGrid() {
    // this.gridWidth = this.copyDataClicked ? -1 : 0;
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.backBtn = true;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
  }
  //#endregion

  //#region on copy button click
  copyOrg() {
    this.DeploymentKeys = this.DeploymentKeys.map(element => element.toUpperCase());
    this.srcDeployment = this.destDeployment
    this.copyDataClicked = true;
    this.editEnabled = false;
    this.gridPage = false;
    this.uploadClicked = false;
    this.addEditScreen = false;
    this.postUpload = false;
    this.backBtn = true;
    this.srcOrgid = ''
    this.copyEntityForm.reset()
    this.copyEntityForm.patchValue({
      frmOrganization: "",
      frmUser: "",
      frmDepKey: sessionStorage.getItem('deploymentKey'),
      frmOrgType: "Laboratory"
    })
    this.getListOrg()
  }
  //#endregion

  //#region to get the data in the select Group in copy-org page
  // getListOrg() {
  //   this.orgList = [];
  //   let dbName = this.srcDeployment;
  //   let queryVariable = {};
  //   let query = this.SubMenuCardModel.GetQuery("orgtype");
  //   let queryResult = this.commonService.GetCardRequest(queryVariable, query);
  //   this.VitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
  //     if (!data.errors) {
  //       if (data) {
  //         if (data.data.submenuData.length > 0) {
  //           this.orgFilterList = data.data.submenuData
  //           // this.orgList = data.data.submenuData
  //           // this.orgFilterList = this.orgList.filter(o => o.orgtype.toLowerCase() != 'ordering facilities');
  //         }
  //       }
  //     }
  //   }, error => {
  //   });
  // }
  //#endregion

  public GetQuery(keyword: string) {
    let query: string = "";
    let mainQueryIndex: number = this.VitalHttpServices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
    if (mainQueryIndex > -1)
      query = this.VitalHttpServices.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  getListOrg() {
    this.orgList = [];
    let dbName;
    let query = this.GetQuery('adduserfilterorg');
    let queryString = null
    dbName = this.srcDeployment;

    let queryVariable = { "filterParam": queryString };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (res.data.Organizations_list && res.data.Organizations_list.length > 0) {
        this.orgList = res.data.Organizations_list;
      }
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  //#region Delete Row (soft-delete)
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }
  //#endregion

  //#region Validate the form
  getErrorMessage(fieldValue, fieldName) {
    if (fieldName == '') {
      if (fieldValue.toString().replace(/\s/g, '').length == 0) {
        return 'Please enter a value';
      }
    }
    if (fieldName == 'sequence') {
      if (fieldValue.toString().replace(/\s+/g, '').length == 0) {
        return 'Please enter a value';
      }
      else if (!fieldValue.toString().match(/^[0-9]+$/g)) {
        return 'Enter only numbers';
      }
    }
  }
  //#endregion

  //#region copy displayname as name field
  setDisplayName(value) {
    this.adequacyForm.patchValue({
      frmdisplayname: value
    })
  }
  //#endregion

  //#region validate empty data
  checkEmpties(data) {
    if (this.adequacyType.toLowerCase() != 'group') {
      let val = this.userList.find(e => e.username == data.frmusername || e.userid == data.frmusername)
      if ((data.frmusername == "") || (!val)) {
        return true
      }
    }
    if (data.frmname == "" || data.frmdisplayname == "" || data.frmdescription == "") {
      return true;


    }
    return false;
  }
  //#endregion

  //#region validate data based on action
  saveAdequacyCodes(action: string, data: any) {
    const { frmsequanceorder } = data;
    if (this.editEnabled) {
      let flag = this.isFormModified();
      if ((flag && !this.userchanged) || (this.userchanged)) {
        if (this.checkEmpties(data)) {
          this._snackbar.open('Please enter all the required fields before saving!', 'Close');
          return;
        }
        if (frmsequanceorder && !frmsequanceorder.toString().match(/^[0-9]+$/g)) {
          this._snackbar.open('Sequence accepts only numbers as input', 'Close');
          return;
        }
        this.validateDisplayname(action, data);
      }
      else {
        this._snackbar.open('Please change at least one field before updating', 'Close');
        return;
      }
    } else {
      if (this.checkEmpties(data)) {
        this._snackbar.open('Please enter all the required fields before saving!', 'Close');
        return;
      }
      if (frmsequanceorder && !frmsequanceorder.toString().match(/^[0-9]+$/g)) {
        this._snackbar.open('Sequence accepts only numbers as input', 'Close');
        return;
      }
      this.validateDisplayname(action, data);
    }
  }
  //#endregion

  //#region check if displayname already exists if yes, show warning
  validateDisplayname(action, parseData) {
    let Check1 = false
    let Check2 = false
    if (!this.editEnabled) {
      if (this.adequacyType.toLowerCase() == 'group') {
        if (!this.templateData.submenuData || this.templateData.submenuData.length <= 0) {
          Check2 = false;
        } else {
          Check2 = (!this.templateData.submenuData.find(r => r.Display_Name?.toLowerCase() == parseData.frmdisplayname.toLowerCase())) ? false : true;
        }
      }
      else {
        if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
          if (!this.templateData.submenuData.submenuData1 || this.templateData.submenuData.submenuData1.length <= 0) {
            Check1 = false;
          } else {
            Check1 = (!this.templateData.submenuData.submenuData1.find(r => (r.UserID === this.selectedrowData.UserID)
              && (r.Display_Name?.toLowerCase() == parseData.frmdisplayname.toLowerCase())
            )) ? false : true;
          }
        }
        else {
          if (!this.templateData.submenuData1 || this.templateData.submenuData1.length <= 0) {
            Check1 = false;
          } else {
            Check1 = (!this.templateData.submenuData1.find(r => (r.UserID === this.selectedrowData.UserID)
              && (r.Display_Name?.toLowerCase() == parseData.frmdisplayname.toLowerCase())
            )) ? false : true;
          }
        }
      }
    }
    else {
      if (this.adequacyForm.value.frmdisplayname != this.selectedrowData.Display_Name) {
        if (this.adequacyType.toLowerCase() === 'group') {
          if (!this.templateData.submenuData || this.templateData.submenuData.length <= 0) {
            Check2 = false;
          }
          else {
            Check2 = (!this.templateData.submenuData.find(r => (r.Display_Name?.toLowerCase() == parseData.frmdisplayname.toLowerCase())
              && (r.ID !== this.selectedrowData.ID))) ? false : true;
          }
        }
        else {
          if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
            if (!this.templateData.submenuData.submenuData1 || this.templateData.submenuData.submenuData1.length <= 0) {
              Check1 = false;
            } else {
              Check1 = (!this.templateData.submenuData.submenuData1.find(r => (r.UserID === this.selectedrowData.UserID)
                && (r.Display_Name?.toLowerCase() == parseData.frmdisplayname.toLowerCase())
                && (r.ID !== this.selectedrowData.ID))) ? false : true;
            }
          }
          else {
            if (!this.templateData.submenuData1 || this.templateData.submenuData1.length <= 0) {
              Check1 = false;
            } else {
              Check1 = (!this.templateData.submenuData1.find(r => (r.UserID === this.selectedrowData.UserID)
                && (r.Display_Name?.toLowerCase() == parseData.frmdisplayname.toLowerCase())
                && (r.ID !== this.selectedrowData.ID))) ? false : true;
            }
          }
        }
      }
    }
    if (Check1 || Check2) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "", message: "", alert: "Adequacy Code with the same name already exists. Do you still want to continue adding the same?", continue: "Yes", cancel: "No" }
      });
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.saveData(action, parseData)
        }
        else {
          return
        }
      }, error => {
        console.log(error);
      });
    }
    this.saveData(action, parseData)
  }
  //#endregion

  disableApprovebtn() {
    let existsCount = 0;
    if (this.copyDataClicked) {
      return this.selectedItems.every(va => va._data['notes'] && ((va._data['notes'].toString().match(/mandatory/i)) || (va._data['notes'].toString().toLowerCase().match(/invalid/i)) || (va._data['notes'].toString().toLowerCase().match(/already exist/i)) || (((va._data['notes'].toString().match(/mandatory/i)) && (va._data['notes'].toString().toLowerCase().match(/invalid/i)))|| ((va._data['notes'].toString().match(/mandatory/i)) && (va._data['notes'].toString().toLowerCase().match(/invalid/i)) && (va._data['notes'].toString().toLowerCase().match(/already exist/i))))))
    }
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if ((this.gridArray[i]['notes'] && this.gridArray[i]['notes'].toString().match(/only takes/i) && this.gridArray[i]['notes'] && this.gridArray[i]['notes'].toString().match(/mandatory/i) && this.gridArray[i]['notes'] && this.gridArray[i]['notes'].toString().match(/already exist/i))) {
        existsCount++;
      }
      else if (this.gridArray[i]['notes'] && this.gridArray[i]['notes'].toString().match(/mandatory/i) ||  this.gridArray[i]['notes'] && this.gridArray[i]['notes'].toString().match(/already exist/i)) {
        existsCount++;
      }
    }
    if ((existsCount > 0 && existsCount == this.excelDataArray.length)) {
      return true;
    }
    else if (existsCount == this.excelDataArray.length) {
      return true;
    }
    return false;
  }

  //#region checks if the form is dirty
  isFormModified() {
    let formControls = this.adequacyForm.controls;
    if (formControls.frmname.dirty || (this.selectedrowData.Display_Name != formControls.frmdisplayname.value) || formControls.frmabnormal.dirty
      || formControls.frmdescription.dirty || formControls.frmsequanceorder.dirty || formControls.frmstatus.dirty) {
      return true
    }
    else {
      return false
    }
  }
  //#endregion

  //#region insert/update individual data
  saveData(action, data) {
    let createdby = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid");
    let ObjectArray = [{
      "id": (this.selectedrowData) ? this.selectedrowData.ID : null
      , "organizationid": sessionStorage.getItem('org_id')
      , "casetype": this.templateData.cardtype
      , "name": data.frmname
      , "displayname": data.frmdisplayname
      , "description": data.frmdescription
      , "isactive": data.frmstatus == true ? 1 : 0
      , "sequenceorder": data.frmsequanceorder
      , "abnormal": (data.frmabnormal == true) ? 1 : 0
      , "modifiedby": createdby
    }]

    let dataArray = (this.adequacyType.toLowerCase() != 'group') ? ObjectArray.map(v => ({ ...v, isuserleveladequacycodeenabled: true, userid: this.selectedrowData.UserId })) : ObjectArray;

    if (!this.editEnabled) {
      this.VitalHttpServices.BulkUploadAdequacyCodes(dataArray).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          if (action == 'new' && result.length > 0) {
            this.callResetForm();
            this.refreshGrid();
            this._snackbar.open('Data saved successfully', 'Close');
          } else if (action == '' && result.length > 0) {
            this.callResetForm();
            this._snackbar.open('Data saved successfully', 'Close');
            this.refreshGrid(true);
          }
          else {
            this._snackbar.open('Data saving failed!', 'Close');
          }
          // this.auditDetails({}, result, 'Added');
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong. Please try again', 'Close');
        console.error(error)
      })
    }
    else {
      this.VitalHttpServices.UpdateAdequacyCodes(dataArray).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          if (result.Success) {
            this.callResetForm();
            this._snackbar.open('Data updated successfully', 'Close');
            this.refreshGrid(true);
            // this.auditDetails(this.selectedrowData, dataArray, 'Edited');
          }
          else {
            this._snackbar.open('Update failed!', 'Close');
          }
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong. Please try again', 'Close');
        console.error(error)
      })
    }
  }
  //#endregion

  //#region reset the form
  callResetForm() {
    if (!this.userCardFlag) {
      let user = (this.inData != "User") ? this.userList.filter(res => res.userid == this.inData) : "";
      this.selectedrowData.UserId = this.inData
      this.adequacyForm.reset();
      this.adequacyForm.patchValue({
        frmname: ""
        , frmdisplayname: ""
        , frmsequanceorder: ""
        , frmusername: user != "" ? user[0].username : ""
        , frmdescription: ""
        , frmabnormal: false
        , frmstatus: true
      })
    }
    else {
      this.selectedrowData.UserId = sessionStorage.getItem('DxUserId')
      this.adequacyForm.reset();
      this.adequacyForm.patchValue({
        frmname: ""
        , frmdisplayname: ""
        , frmsequanceorder: ""
        , frmusername: ""
        , frmdescription: ""
        , frmabnormal: false
        , frmstatus: true
      })
    }
    Object.keys(this.adequacyForm.controls).forEach(key => {
      this.adequacyForm.controls[key].setErrors(null)
    });
  }
  //#endregion

  //#region set the data for edit form
  openEditScreen(grid, event) {
    let selectedrowData = grid;
    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      this.selectedrowData = this.templateData.submenuData.find(e => e.ID == selectedrowData.ID)
      if (!this.selectedrowData || Object.keys(this.selectedrowData).length < 0) {
        if (this.templateData.submenuData.submenuData1 && this.templateData.submenuData.submenuData1.length > 0) {
          this.selectedrowData = this.templateData.submenuData.submenuData1.find(e => e.ID == selectedrowData.ID)
        }
      }
    } else if (this.templateData.submenuData1 && this.templateData.submenuData1.length > 0) {
      this.selectedrowData = this.templateData.submenuData1.find(e => e.ID == selectedrowData.ID)
    }
    this.getInputData('edit');
  }
  //#endregion

  //#region patch the data in edit form
  getInputData(action) {
    if (action.toLowerCase() == 'edit') {
      this.editEnabled = true;
      let user = (this.selectedrowData.UserId != 'Not Specified') ? this.userList.filter(res => res.userid == this.selectedrowData.UserId) : "";
      this.adequacyForm.patchValue({
        frmname: this.selectedrowData.Name
        , frmdisplayname: this.selectedrowData.Display_Name
        , frmsequanceorder: this.selectedrowData.Sequence
        , frmusername: user != "" ? user[0].username : ""
        , frmdescription: this.selectedrowData.Description
        , frmabnormal: this.selectedrowData.Abnormal == 1 ? true : false
        , frmstatus: this.selectedrowData.Status == 1 ? true : false
      })
    } else {
      this.editEnabled = false;
      this.callResetForm();
    }
    this.addHeader = this.adequacyType == 'Group' ? 'Group Level' : 'User Level';
    this.gridPage = false;
    this.addEditScreen = true;
  }
  //#endregion
  groupBy(objectArray: any, Property1: string, Property2?: string) {
    return objectArray.reduce(function (
      acc: { [key: string]: any[] },
      obj: { [key: string]: any }
    ) {
      // Generate a unique key based on the combination of values from Property1 and Property2

      var compositeKey = obj[Property1] + (Property2 ? ('|' + obj[Property2]) : '');

      if (!acc[compositeKey]) {
        acc[compositeKey] = [];
      }

      acc[compositeKey].push(obj);
      return acc;
    },
      {});
  }
  //#region Export Grid data for download(uploaded data)
  ExportExcel(flex) {
    let excel = [];
    flex.rows.find(e => {
      delete e._data["notemessage"];
      delete e._data["notes"];
      delete e._data["modifiedby"];
      delete e._data["id"];
      excel.push(e._data);
    });
    if (!this.templateData.secondarykeys) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Adequacy Codes_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Adequacy Codes');
    XLSX.writeFile(wb, filename);
  }
  //#endregion

  //#region export the data for main grid data
  ExportGridToExcel(flex) {
    let excel = [];
    const view: any = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate();
    flex.rows.find(e => {
      if (this.userCardFlag) {
        delete e._data['_path'];
        delete e._data['_items'];
        delete e._data['_groups'];
        delete e._data['_level'];
        delete e._data['_isBottomLevel'];
        delete e._data['_name'];
        delete e._data['_gd'];
      }
      if (e._data['ID']) {
        excel.push(e._data);
      }
    });
    if (!this.templateData.secondarykeys) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Adequacy Codes_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'organizationadequacycodes');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate();
  }
  //#endregion

  //#region filter user-level data based on userid
  getArray(input) {
    if (!this.userCardFlag) {
      if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
        return this.templateData.submenuData.submenuData1.filter(res => res.UserId?.toString() == input?.toString())
      }
      else {
        if (this.templateData.submenuData1 && this.templateData.submenuData1.length > 0) {
          return this.templateData.submenuData1.filter(res => res.UserId?.toString() == input?.toString())
        } else {
          return []
        }
      }
    }
    else {
      if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
        return this.templateData.submenuData.filter(res => res.UserId?.toString() == input?.toString())
      }
      else {
        return []
      }
    }
  }
  //#endregion

  //#region List page: get grid
  addGridData(input?) {
    this.gridArray = [];
    let primary = {};
    this.gridarray = [];
    this.dataListArray = [];
    this.dataListArray = isNaN(input) ? this.templateData.submenuData : this.getArray(input)
    if (this.dataListArray) {
      if (this.dataListArray.length > 0) {
        for (let i = 0; i < this.dataListArray.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(this.dataListArray[i])) {
            this.gridHeader.find((e): any => {
              if (key === e) {
                if (key == 'Abnormal' || key == 'IsActive' || key == 'IsUserLevelEnabled') {
                  if (value == 'true' || value == true) {
                    value = true
                  } else if (value == 'false' || value == false || !value) {
                    value = false
                  }
                }
                else if (value == null || value == '') {
                  value = 'Not Specified';
                }
                primary[key] = value;
              }
            });
          }
          this.gridarray.push(primary);
        }
        let groupedData = this.groupBy(this.gridarray, 'CaseType')
        let finalTemplate: any[] = [];
        if (groupedData !== undefined) {
          for (let [key, value] of Object.entries(groupedData)) {
            if (Array.isArray(value)) {
            }
            finalTemplate = finalTemplate.concat(value);
          }
        }
        this.gridArr = [];
        this.gridArr = finalTemplate.sort((a, b) => a?.CaseType?.localeCompare(b.CaseType) || a?.Sequence - b?.Sequence);
        if(this.sourceApp == 'VitalDx'){
          this.gridArr = this.gridArr.filter(item => {
            return (!this.selectedData.CaseType || item.CaseType.toLowerCase() === this.selectedData.CaseType.toLowerCase()) &&
                   (!this.selectedData.UserId || item.UserId === this.selectedData.UserId);
          });
        }
        this.SendGridData = new CollectionView(this.gridArr);
        this.gridwidth = (186 * this.gridHeader.length) + 37;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
        this.showPaginationMainGrid = this.gridarray.length > 10 ? true : false;
      }
      else {
        this.gridArr = [...[]];
        this.SendGridData = new CollectionView(this.gridArr);
        // this.gridwidth = 0;
      }
    }
    else {
      this.gridArr = [...[]];
      this.SendGridData = new CollectionView(this.gridArr);
      // this.gridwidth = 0;
    }
  }
  //#endregion

  //#region Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }
  //#endregion



  onFileDropped($event) {
    this.onFileChange($event);
  }

  //#region Method to hit once a file is dragged to or uploaded
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationadequacycodes')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  //#endregion

  //#region Method to validate sheet data.
  convertToJson(sheetname) {
    var worksheet;
    this.excelDataArray = [];
    let tempExcelArr = [];
    let primary = {}
    let tempArray = []
    this.sheetHeader = [];
    let temp = [];
    let i = 1;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded excel does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    for (let i = 0; i < tempExcelArr.length; i++) {
      // tempExcelArr.find((r): any => {
      primary = {}
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().toLowerCase().match(/empty/i)) {
          value = value && value.toString().trim();
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      // });
    }
    tempExcelArr = tempArray;
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    temp[0].push("Notes")
    temp[0].reverse()
    this.sheetHeader = temp[0].filter(va => !va.toString().toLowerCase().match(/empty/i));
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    this.excelDataArray.sort((a, b) => a.displayname < b.displayname ? -1 : a.displayname > b.displayname ? 1 : 0)
    this.excelDataArray.find((d): any => {
      Object.assign(d, {
        slno: Number(i), notes: '', tablename: 'organizationadequacycodes', organizationid: Number(sessionStorage.getItem('org_id')),
        casetype: this.templateData.cardtype.toString(), userid: !isNaN(this.inData) ? this.inData : null
      });
      i++;
    });
    this.validateExcelData(this.excelDataArray);
  }
  //#endregion

  //#region Upload screen
  loadUploadScreen() {
    this.uploadClicked = true;
    this.gridPage = false;
    let queryVariable = { tablename: 'OrganizationAdequacyCodes', identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          let allFields = {}
          for (let i = 0; i < data.data.allFields.length; i++) {
            allFields[data.data.allFields[i]["Column"]] = null
          }
          this.AllFields[0] = allFields
          delete this.AllFields[0]['CaseType']
          delete this.AllFields[0]['IsActive']
          delete this.AllFields[0]['OrganizationID']
          delete this.AllFields[0]['OrganizationAttributeGUID']
          delete this.AllFields[0]['Type']
          delete this.AllFields[0]['OrganizationAdequacyCodeGUID']
          delete this.AllFields[0]['CreatedBy']
          delete this.AllFields[0]['CreatedDate']
          delete this.AllFields[0]['ModifiedBy']
          delete this.AllFields[0]['ModifiedDate']
          delete this.AllFields[0]['IsUserLevelAdequacyCodeEnabled']
          delete this.AllFields[0]['UserID']
          delete this.AllFields[0]['AdminKey']
          this.MandatoryFields[0]['Name'] = null
          this.MandatoryFields[0]['DisplayName'] = null
          this.MandatoryFields[0]['Description'] = null
        }
      }
    }, error => {
      console.error(error);
    });
  }
  //#endregion

  //#regin to show notes
  formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field is missing' || value == 'Null' || (value.toString().includes('Null values exist!')) || (value.toString().includes('InValid CaseType')) || (value.toString().includes('already exist!'))) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('Successfully') || value == 'Success') {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if (value.toString().includes('Failed') || (value.toString().includes('Ignored'))) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }

  //#region Back to upload
  async refreshGrid(showGrid?) {
    this.getListUsers(null, this.destDeployment);
    if (showGrid) {
      this.editEnabled = false;
      this.gridPage = true;
      this.uploadClicked = false;
      this.addEditScreen = false;
      this.copyDataClicked = false;
      this.removeGrid();
    }

    let queryVariable = { casetype: this.templateData.secondarykeys.casetype?.toString() || null, orgid: sessionStorage.getItem('org_id')?.toString() };
    let query = this.SubMenuCardModel.GetQuery("OrgAdequacyCodes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult).toPromise().then(data => {
      if (!data.errors) {
        if (this.adequacyType.toLowerCase() == 'group') {
          this.templateData.submenuData = [...[]];
          this.templateData.submenuData = structuredClone(data.data.submenuData);
          if (data.data.submenuData.length > 0) {
            this.templateData.submenuData.submenuData1 = [];
            this.templateData.submenuData.submenuData1 = data.data.submenuData1;
          } else {
            this.templateData.submenuData1 = [...[]];
            this.templateData.submenuData1 =  structuredClone(data.data.submenuData1);
          }
          if (showGrid) {
            this.addGridData(this.adequacyType);
          }
        }
        else {
          if (data.data.submenuData.length > 0) {
            this.templateData.submenuData.submenuData1 = [...[]];
            this.templateData.submenuData.submenuData1 =  structuredClone(data.data.submenuData1);
          } else {
            this.templateData.submenuData1 = [...[]];
            this.templateData.submenuData1 =  structuredClone(data.data.submenuData1);
          }
          if (showGrid) {
            this.addGridData(this.inData);
          }
        }
      }
    }, error => {
      console.error(error);
    });
  }
  //#endregion

  //#region User/PAthologist Cardtype: back button
  refreshUserGrid() {
    this.editEnabled = false;
    this.gridPage = true;
    this.uploadClicked = false;
    this.addEditScreen = false;
    this.copyDataClicked = false;
    this.removeGrid();
    let queryVariable ;
    queryVariable = { casetype: null, orgid: sessionStorage.getItem('org_id').toString() };
    let query = this.SubMenuCardModel.GetQuery("UserAdequacyCodes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data.data.submenuData.length > 0) {
          this.templateData.submenuData = [];
          this.templateData.submenuData = data.data.submenuData;
        }
        this.addGridData(this.inData);
      }
    }, error => {
      console.error(error);
    });
  }
  //#endregion

  //#region Tooltip for Grid
  initLocationGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }
  //#endregion

  //#region Convert Obj to lower
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion

  //#region Validate Excel/(Copy from other entity data)
  validateExcelData(excelArr) {
    this.ngxService.start();
    if(this.userCardFlag){
      for (let i = 0; i < excelArr.length; i++) {
        excelArr[i]['isuserleveladequacycodeenabled'] = "true";
      }
    }
    this.VitalHttpServices.ValidateAdequacyData(excelArr).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          this.ngxService.stop();
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          excelArr.filter(e => {
            result.find(r => {
              if (r.slno == e.slno) {
                e.notes = r.notemessage;
              }
              if (this.uploadClicked) {
                if (((r.name && r.name.toLowerCase()) === (e.name && e.name.toLowerCase()) && (r.description && r.description.toLowerCase()) === (e.description && e.description.toLowerCase()) && (r.displayname && r.displayname.toLowerCase()) === (e.displayname && e.displayname.toLowerCase()) && r.slno != e.slno)) {
                  e.notes = 'Adequacy code already exist!.';
                }
              }
            });
          });
          for (let i = 0; i < excelArr.length; i++) {
            if (excelArr[i]["description"] == null || excelArr[i]["name"] == null || excelArr[i]["displayname"] == null) {
              excelArr[i]["notes"] = 'Mandatory field is missing';
            }
            // else {
            //   excelArr[i]["notes"] = excelArr[i]["notes"] == "" ? 'Valid' : excelArr[i]["notes"]
            // }
          }
          // excelArr = excelArr.filter(va=> !va.notes.toString().toLowerCase().match(/mandatory/i));
          this.ngxService.stop();
          this.excelGridData(excelArr);
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
  }
  //#endregion

  //#region Grid Data : uploaded data with Notes column
  excelGridData(data) {
    this.ngxService.stop();
    this.gridDisplay = true;
    this.gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          let mandatoryFieldEmpty = false
          let checkDataType = false
          let dataTypeCol;
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i)) {
              let flag = false;
              if (key.toString().toLowerCase() != 'name' && key.toString().toLowerCase() != 'displayname' && key.toString().toLowerCase() != 'description') {
                if (value != null) {
                  if (value.toString().toLowerCase().trim() == 'null') {
                    flag = true;
                  }
                  primary[key] = value;
                }
              }
              if (key.toLowerCase() == 'abnormal' || key.toLowerCase() == 'isactive' || key.toLowerCase() == 'isuserleveladequacycodeenabled') {
                if (value == 'true' || value == true) {
                  value = true
                } else if (value == 'false' || value == false || !value) {
                  value = false
                }
                primary[key] = value;
              }
              if (data[i]['sequenceorder'] && isNaN(data[i]['sequenceorder'])) {
                if (checkDataType) {
                  dataTypeCol = 'Abnormal field accepts only boolean, Sequenceorder field accepts only number'
                }
                else {
                  checkDataType = true;
                  dataTypeCol = 'Sequenceorder field accepts only number'
                }
              }
              if (key.toLowerCase() == 'abnormal') {
                if (typeof value != 'boolean') {
                  if (!this.checkBool(value)) {
                    if (checkDataType) {
                      dataTypeCol = 'Abnormal field accepts only boolean, Sequenceorder field accepts only number'
                    }
                    else {
                      checkDataType = true;
                      dataTypeCol = 'Abnormal field accepts only boolean'
                    }
                  }
                }
              }
              flag ? primary['notes'] = 'Null values exist!' : null
              if (key.toString().toLowerCase() == 'name' || key.toString().toLowerCase() == 'displayname' || key.toString().toLowerCase() == 'description') {
                if (value == null || value.toString().toLowerCase().trim() == 'null' || (!/\S/.test(value.toString()))) {
                  mandatoryFieldEmpty;
                }
                primary[key] = value;
              }

              if (checkDataType) {
                value = dataTypeCol;
                this.excelDataArray[i]["notes"] = value;
                primary['notes'] = value;
              }

              if (mandatoryFieldEmpty) {
                value = 'Mandatory field is missing';
                this.excelDataArray[i]["notes"] = value;
                primary['notes'] = value;
              }
            }
            primary[key] = value;
          }
          this.gridArray.push(primary);
        }
        if (this.copyDataClicked && this.postUpload) {
          this.selector.column.grid.headersVisibility = HeadersVisibility.Column
        }
        if(this.copyDataClicked && !this.postUpload){
          this.gridArray = this.gridArray.filter(ele=> ele.isactive === true);
        }
        this.excelDataArray = this.gridArray;
        this.gridData = new CollectionView(this.gridArray)
        this.gridWidth = (120 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
      else {
        this.gridWidth = 0;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }
  //#endregion

  checkBool(stringValue) {
    switch (stringValue?.toLowerCase()?.trim()) {
      case "true":
      case "1":
      case "false":
      case "0":
      case 0:
      case 1:
      case null:
      case undefined:
        return true;

      default:
        return false;
    }
  }

  //#region Upload ExcelData to Insert API
  uploadData() {
    let dataArray = [];
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["notes"]) {
          if (this.copyDataClicked) {
            this._snackbar.open('Copying data failed! Please check the data for datatype mismatch.', 'Close');
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
          }
        }
        else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/already exist/i) && !this.excelDataArray[i]["notes"].toString().match(/accepts only/i) && !this.excelDataArray[i]["notes"].toString().match(/valid/i) && !this.excelDataArray[i]["notes"].toString().match(/mandatory/i)  && !this.excelDataArray[i]["notes"].toString().toLowerCase().match(/invalid casetype/i)) {
          errorcount++
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.invalidColumns != "")) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value == "null" || value == "" || value == null) {
              this.excelDataArray[i][key] = null;
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            delete this.excelDataArray[i]["slno"]
            delete this.excelDataArray[i]["tablename"]
            // delete this.excelDataArray[i]["notes"]
            this.excelDataArray[i]["modifiedby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
            this.excelDataArray[i]["organizationid"] = sessionStorage.getItem('org_id').toString();
            this.excelDataArray[i]["casetype"] = !this.userCardFlag ? this.templateData.cardtype.toString() : this.excelDataArray[i]["casetype"];
            this.excelDataArray[i]["isactive"] = (this.copyDataClicked) ? (this.checkStatus ? false : true) : true
          }
        }
        if (!this.userCardFlag) {
          dataArray = (this.adequacyType.toLowerCase() != 'group') ? this.excelDataArray.map(v => ({ ...v, isuserleveladequacycodeenabled: true, userid: this.inData })) : this.excelDataArray;
        }
        else {
          dataArray = this.excelDataArray.map(v => ({ ...v, isuserleveladequacycodeenabled: true, userid: !isNaN(this.inData) ? this.inData : null, type: "Adequacy" }))
        }
      }

      let sendjson = this.excelDataArray.filter(va => !va.notes?.toString().toLowerCase().match(/mandatory/i) && !va.notes?.toString().toLowerCase().match(/invalid/i) && !va.notes?.toString().toLowerCase().match(/already exist/i));
      let invalidCasetype = this.excelDataArray.filter(va=> va.notes?.toString().toLowerCase().match(/invalid casetype/i))
      if (this.userCardFlag) {
        if(invalidCasetype.length > 0){
          this.repeatedNames = [];
          let str: any = []
          for (let i = 0; i < dataArray.length; i++) {
            if(dataArray[i]['notes'] && dataArray[i]['notes'].toString().toLowerCase().includes('invalid casetype')){
              str += dataArray[i]['casetype'] + ', ';
            }
          }
          this.repeatedNames = Array.from(new Set(str.split(',').map(value => value.trim()).filter(value => value !== '')));
          this.invalidCasetypeFlag =true;
        }
      }
      this.ngxService.start();
      this.VitalHttpServices.BulkUploadAdequacyCodes(sendjson).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          if (result.length > 0) {
            this.checkStatus = false;
            this.postUpload = true;
            this.postDownload = true;
            this.showDelete = false;
            this.excelDataArray = [];
            this.excelDataArray = this.convertObjKeysToLower(result);
            this.excelGridData(this.excelDataArray);
            if (this.copyDataClicked) {
              this.commonService.auditDetails('', '', [], sendjson, 'Copy', this.templateData, this.auditableColumns);
              this._snackbar.open('Data copied successfully', 'Close');
            }
            else {
              this.commonService.auditDetails('', '', [], sendjson, 'Upload', this.templateData, this.auditableColumns);
              this._snackbar.open('Data uploaded successfully', 'Close');
            }
            // this.auditDetails({}, result, 'Added');
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
          }
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong.Please try again', 'Close');
        console.error(error)
      })
    }
  }
  //#endregion

  //#region grapghqc call to get the data in the select User Dropdown
  getListUsers(org_id, dbName) {
    let orgid = org_id === null ? sessionStorage.getItem('org_id') : org_id;
    let query = this.SubMenuCardModel.GetQuery('getusersdiagnosistemplate');
    let queryVariable = { "orgid": orgid.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (!res.errors) {
        this.userList = res.data.Card;
        this.userListArray = res.data.Card;
        this.userList.sort((a, b) => a.username < b.username ? -1 : a.username > b.username ? 1 : 0)
        let obj = { "userid": "", "username": "Group", "rolename": "Group-Level" }
        this.userList.unshift(obj);
        this.ngxService.stop();
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }
  //#endregion

  //#region List Page: select org-level/User-level
  filterUsers(value) {
    if (value == '' || value.toLowerCase() == 'group') {
      this.adequacyType = 'Group';
      this.inData = "";
      this.addGridData(this.adequacyType);
    }
    else {
      this.adequacyType = (!this.userList.find(v => v.username == value)) ? 'Group' : 'User'
      if (this.adequacyType.toLowerCase() == 'group') {
        this.addGridData(this.adequacyType);
        this.inData = "";
      }
    }
  }

  //#region Userdropdown in ADD/EDIT form
  filterFormUsers(value) {
    if (value == '') {
      return this.userListArray
    }
    let users = this.userListArray
    return users.filter(va => va.username.toLowerCase().includes(value.toLowerCase()));
  }
  //#endregion

  //#region Download All Fields
  downloadAllFields() {
    if (!this.templateData.secondarykeys) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Adaquecy Codes_' + 'All Fields_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.AllFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Adequacy Codes');
    ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields)
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }
  //#endregion

  sampleDataAllFields = [{
    Name: 'Name'
    , DisplayName: 'DisplayName'
    , Description: 'Description'
    , SequenceOrder: '123'
    , Abnormal: '1'
  }]

  sampleDataMinFields = [{
    Name: 'Name'
    , DisplayName: 'DisplayName'
    , Description: 'Description'
  }]
  //#region Download Madatory Fields
  downloadMadatoryFields() {
    if (!this.templateData.secondarykeys) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Adaquecy Codes_' + 'Minimal Fields_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.MandatoryFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Adequacy Codes');
    ws = XLSX.utils.json_to_sheet(this.sampleDataMinFields)
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }
  //#endregion

  //#region RBAC
  GetButtondetails() {
    if ((this.templateData.cardtype.toLowerCase() == 'users') || (this.templateData.cardtype.toLowerCase() == 'pathologist')) {
      this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
    }
    else {
      this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
    }
  }
  //#endregion

  //#region get button access
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Templates").SubMenu.find(va => va.URL == this.labAdminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Migrate":
          this.hideMigratebtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "ManageAdequacyCodes":
          this.hideManageAdequacybtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Copy":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;

      }
    }
  }
  //#endregion

  //#region To send audit details
  // auditDetails(oldObj, newObjArray, value) {
  //   let oldObject = {}
  //   if (Object.keys(oldObj).length > 0) {
  //     //update record
  //     oldObject = {
  //       "id": (oldObj) ? oldObj.ID : null
  //       , "organizationid": oldObj.orgid
  //       , "casetype": oldObj.CaseType
  //       , "name": oldObj.Name
  //       , "displayname": oldObj.Display_Name
  //       , "description": oldObj.Description
  //       , "isactive": (oldObj.Status == true) ? 1 : 0
  //       , "sequenceorder": oldObj.Sequence
  //       , "abnormal": (oldObj.Abnormal == true) ? 1 : 0
  //       , "modifiedby": oldObj.ModifiedBy
  //     }
  //     if (newObjArray[0]["isuserleveladequacycodeenabled"] != undefined) {
  //       Object.assign(oldObject, { isuserleveladequacycodeenabled: oldObj.IsUserLevelAdequacyCodeEnabled, userid: oldObj.UserID });
  //       //if col isuserleveladequacycodeenabled found in obj
  //     }
  //     this.ngxService.start();
  //     this.commonService.createActivityTracker(value, oldObj.ID, 'AdequacyCodes', 'Audit', newObjArray[0], oldObject);
  //     this.ngxService.stop();
  //   } else {
  //     //new record
  //     this.ngxService.start();
  //     for (let i = 0; i < newObjArray.length; i++) {
  //       this.commonService.createActivityTracker(value, newObjArray[i].ID, 'AdequacyCodes', 'Audit', newObjArray[i], {});
  //     }
  //     this.ngxService.stop();
  //   }
  // }
  //#endregion

  //#region copy-from-entity: orglist
  fetchOrgSeries(value: string) {
    this.searchResult = []
    this.searchuserlist = [];
    this.searchUserInput = '';
    this.srcUserid = '';
    if (value === '') {
      this.searchuserlist = [];
      return (this.searchResult = []);
    }
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.orgList.find(r => {
          if (r.organizationname) {
            if (r.organizationname.toString().toLowerCase() === value.toLowerCase()) {
              this.srcOrgid = r.organizationid
              return r
            }
            else {
              this.srcOrgid = ''
            }
          }
        })
        this.searchResult = this.orgList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    }
    else {
      this.orgList.find(r => {
        if (r.organizationid.toString().toLowerCase() === value.toLowerCase()) {
          this.srcOrgid = r.organizationid
          return r
        }
        else {
          this.srcOrgid = ''
        }
      })
      this.searchResult = this.orgList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
  }
  //#endregion

  disableGetDataBtn() {
    return this.searchInput == '' ? true : (this.srcOrgid && this.srcOrgid) != '' ? false : true
  }

  //#region copy-from-entity: userlist
  fetchUserSeries(value: string) {
    // this.removeGrid();
    this.searchuserlist = []
    if (value === '') {
      return (this.searchuserlist = []);
    }
    this.usertoggle = false;
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.userListArray.find(r => {
          if (r.username.toString().toLowerCase() === value.toLowerCase() && r.username.toLowerCase() != 'group') {
            this.srcUserid = r.userid
            return r
          } else {
            this.srcUserid = ''
            this.usertoggle = true
          }
        })
        this.searchuserlist = this.userListArray.filter(function (series) {
          if (series && series.username != null) {
            return series.username.toString().toLowerCase().includes(value.toLowerCase()) && series.username.toLowerCase() != 'group';
          }
        });
        this.searchuserlist = this.searchuserlist.splice(0, 25);
      }
    }
    else {
      this.searchuserlist = this.userListArray.filter(function (series) {
        if (series && series.userid != null) {
          return series.userid.toString().toLowerCase().includes(value.toLowerCase()) && series.username.toLowerCase() != 'group';
        }
      });
      this.searchuserlist = this.searchuserlist.splice(0, 25);
    }
  }
  //#endregion

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.srcUserid = '';
      this.searchUserInput = '';
      this.searchuserlist = [];
      this.srcOrgid = data.organizationid;
      this.srcOrgName = data.organizationname;
      this.searchInput = data.organizationname;
      this.getListUsers(this.srcOrgid, this.srcDeployment);
    }
  }

  selectedCopyDataUser(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.usertoggle = false;
      this.srcUserid = data.userid;
      this.srcUserName = data.username;
      this.searchUserInput = data.username;
    }
  }

  onChangeOrgType(e, orgType) {
    if (e.source.selected) {
      this.searchInput = '';
      this.searchUserInput = '';
      this.gridWidth = -1
      this.srcOrgid = '';
      this.searchResult = []
      this.searchuserlist = []
      this.srcOrgType = orgType
      this.resetCopyForm();
    }
  }

  resetCopyForm() {
    this.copyEntityForm.patchValue({
      frmOrganization: "",
      frmUser: "",
      frmDepKey: this.srcDeployment,
      frmOrgType: this.srcOrgType
    })
  }

  onChangeDeployment(e, DeploymentKey) {
    if (e.source.selected) {
      this.srcDeployment = DeploymentKey;
      this.searchInput = '';
      this.searchUserInput = '';
      this.usertoggle = false;
      this.searchResult = []
      this.searchuserlist = []
      this.srcOrgType = "Laboratory"
      this.removeGrid();
      this.gridWidth = -1
      this.resetCopyForm();
      this.getListOrg();
    }
  }

  getOrgCasetype() {
    let query = this.SubMenuCardModel.GetQuery('getorgcasetypes');
    let queryVariable = { "orgid": sessionStorage.getItem('org_id') };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {
        this.caseTypeArray = [];
        this.caseTypeArray = res.data.submenuData;
      }
    }, error => {
      console.log(error)
    })
  }

  //#region User/Pathologist Cardtype: casetype validation
  validateCasetype() {
    let temp1 = []
    for (let i = 0; i < this.caseTypeArray.length; i++) {
      temp1.push(this.caseTypeArray[i].casetype)
    }
    let configuredCasetype = [];
    configuredCasetype = this.excelDataArray.filter(item => temp1.includes(item.casetype))
    let invalidCasetype = [];
    let temp = [];
    invalidCasetype = this.excelDataArray.filter(item => !temp1.includes(item.casetype))
    for (let i = 0; i < invalidCasetype.length; i++) {
      temp.push(invalidCasetype[i].casetype)
    }
    let displayInvalidCasetype = [...new Set(temp)];
    if (displayInvalidCasetype.length > 0) {
      this.repeatedNames = '';
      let str = '';
      for (let i = 0; i < displayInvalidCasetype.length; i++) {
        str += displayInvalidCasetype[i] + ', '
      }
      if (configuredCasetype.length > 0) {
        this.uploadData();
      }
      this.repeatedNames = str.substring(0, str.length - 2);
      this.invalidCasetypeFlag = true;
    }
    else {
      if (configuredCasetype.length > 0) {
        this.uploadData();
      }
    }
  }
  //#endregion

  //#region Get data to copy-from-other-entity
  getDataToCopy() {
    this.removeGrid()
    this.gridWidth = -1;
    this.editEnabled = false;
    this.gridPage = false;
    this.uploadClicked = false;
    this.addEditScreen = false;
    this.postUpload = false;
    this.backBtn = true;
    this.uploadClicked = false
    let queryVariable;
    if (this.userCardFlag) {
      queryVariable = { casetype: null, orgid: this.srcOrgid.toString() };
    }
    else {
      queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: this.srcOrgid.toString() };
    }
    let query = this.SubMenuCardModel.GetQuery("OrgAdequacyCodes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(data => {
      if (!data.errors) {
        if (!this.srcUserid && !this.searchUserInput) {
          if (data.data.submenuData.length > 0) {
            this.backBtn = false
            this.manageDataColumn(data.data.submenuData);
          } else {
            this.backBtn = true
            this.excelGridData([]);
          }
        }
        else {
          if (!this.usertoggle) {
            if (data.data.submenuData1.length > 0) {
              this.backBtn = false
              let userSpecificData = [];
              userSpecificData = data.data.submenuData1.filter(u => u.UserId == Number(this.srcUserid));
              if (userSpecificData.length > 0) {
                this.manageDataColumn(userSpecificData);
              }
              else {
                this.backBtn = true
                this.excelGridData([]);
              }
            }
            else {
              this.backBtn = true
              this.excelGridData([]);
            }
          } else {
            this._snackbar.open("Please enter valid user", "Close");
            return
          }
        }
      }
    })
  }
  //#endregion

  closeCasetypeModel() {
    this.invalidCasetypeFlag = false;
  }

  manageDataColumn(data) {
    var i;
    for (i = 0; i < data.length; i++) {
      data[i].displayname = data[i]['DisplayName'];
      data[i].isuserleveladequacycodeenabled = data[i]['IsUserLevelEnabled'];
      data[i].isactive = data[i]['IsActive'];
      data[i].abnormal = data[i]['Abnormal'];
      data[i].casetype = data[i]['CaseType'];
      data[i].description = data[i]['Description'];
      data[i].id = data[i]['Id'];
      data[i].name = data[i]['Name'];
      delete data[i].IsActive;
      delete data[i].IsUserLevelEnabled;
      delete data[i].DisplayName;
      delete data[i].Sequence;
      delete data[i].OrganizationId;
      delete data[i].CreatedBy;
      delete data[i].ModifiedBy;
      delete data[i].ModifiedDate;
      delete data[i].CreatedDate;
      delete data[i].Type;
    }
    this.sheetHeader = ["isactive", "isuserleveladequacycodeenabled", "abnormal","sequence", "description", "displayname", "name", "id", "casetype", "Notes"]//Object.keys(data[0]);
    this.excelDataArray = [];
    this.excelDataArray = this.convertObjKeysToLower(data);
    if (this.userCardFlag) {
      this.excelDataArray.find((d): any => {
        Object.assign(d, {
          slno: Number(i), notes: '', tablename: 'organizationadequacycodes', organizationid: Number(sessionStorage.getItem('org_id')),
          casetype: d.casetype, userid: !isNaN(this.inData) ? Number(this.inData) : null
        });
        i++;
      });
    }
    else {
      this.excelDataArray.find((d): any => {
        Object.assign(d, {
          slno: Number(i), notes: '', tablename: 'organizationadequacycodes', organizationid: Number(sessionStorage.getItem('org_id')),
          casetype: this.templateData.cardtype.toString(), userid: (!isNaN(this.inData)) ? this.inData : null
        });
        i++;
      });
    }
    this.validateExcelData(this.excelDataArray);
  }

  initializeGrid(flexgrid) {
    this.selectedItems = [];
    flexgrid.collapseGroupsToLevel(0);
    setTimeout(function () {
      if (this.flexgrid) this.flexgrid.refresh(true);
    });
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        var va = flexgrid.rows.filter((r) => r.isSelected);
        this.selectedItems = va;
      },
    });
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  uploadCopyData(flexgrid) {
    this.excelDataArray = [];
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        this.selectedItems = flexgrid.rows.filter(r => r.isSelected);
      }
    });
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.excelDataArray.push(this.selectedItems[i]._data);
    }
    if (this.adequacyType.toLowerCase() === 'group' && !this.userCardFlag) {
      for (let i = 0; i < this.excelDataArray.length; i++) {
        delete this.excelDataArray[i].isuserleveladequacycodeenabled;
        delete this.excelDataArray[i].isactive;
      }
    }
    this.uploadData();
  }

  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  clearInput(evt: any): void {
    evt.stopPropagation();
    this.selectedOption = '';
    this.adequacyType = 'Group'
    this.inputAutoComplete?.nativeElement.focus();
  }

  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }

  ngOnDestroy() {
    //code to remove the modals in the component appended to the body
    $('#selectSheetModal').remove();
  }

  handleListingScreen(val: any) {
    if (val == 'closeList') {
      this.ViewScreen = true
    } else if (val = 'openList') {
      this.ViewScreen = false
    }
  }

  #editStarting
  newEditMethod(editObj: any) {
    editObj.rowData.Abnormal == "Yes" ? editObj.rowData.Abnormal = 1 : editObj.rowData.Abnormal = 0;
    editObj.rowData.Status == "Active" ? editObj.rowData.Status = 1 : editObj.rowData.Status = 0;
    this.openEditScreen(editObj.rowData, editObj.event);
  }

  recieveObjFromListing(object: any) {
    object.newData.Abnormal == 1 ? true : false;
    object.newData.Status == 1 ? true : false;
    if(!this.userCardFlag){
      object.newData['isuserleveladequacycodeenabled'] = (object.newData['UserId'] && object.newData['UserId'] != 'Not Specified') ? 1 : 0;
    }
    // object.newData['Type'] = 'Adequacy';

    // if(!object?.newData?.['UserId']) {
    //     this.adequacyType = 'Group';
    // } else {
    //     this.adequacyType = 'User Level';
    // }

    if (object.newData.Action === 'Create') {
      object.oldData = null;
      delete object.newData.Action;
      // delete object.newData.IsUserLevelEnabled;
      delete object.newData.CreatedBy;
      // delete object.newData.Type;
      const newObj = {};
      Object.entries(object.newData).forEach(([key, value]) => {
        newObj[key.toLowerCase()] = value;
      });
      this.newSaveData('Create', newObj, '');
    }
    else if (object.newData.Action === 'Edit') {
      // delete object.newData.IsUserLevelEnabled;
      delete object.newData.CreatedBy;
      // delete object.newData.Type;
      delete object.newData.Action;
      object.newData['Id'] = object.oldData['Id'];
      if(this.userCardFlag){
        object.newData['isuserleveladequacycodeenabled'] = object.oldData['IsUserLevelEnabled']   == true ? 1 : 0;
        object.newData['UserId'] = object.oldData['UserId'];
      }
      // object.newData['sequenceorder'] = object.oldData['Sequence'];
      const newObj = {};
      Object.entries(object.newData).forEach(([key, value]) => {
        newObj[key.toLowerCase()] = value;
      });

      const oldObj = {};
      Object.entries(object.oldData).forEach(([key, value]) => {
        oldObj[key.toLowerCase()] = value;
      });
      this.newSaveData('Edit', newObj, oldObj);
    }
  }

  newSaveData(action: any, newObj: any, oldObj: any) {
    newObj.userid = newObj.userid == "Not Specified" ? null : newObj.userid
    if (action === 'Create') {
      this.VitalHttpServices.BulkUploadAdequacyCodes([newObj]).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          this.adequacyType = 'Group';
          if (result[0].Notes.includes('already exists')) {
            this._snackbar.open(result[0].Notes, 'Close');
            return;
          }
          newObj.IsActive = newObj.isactive == 1 ? 'Active' : 'Inactive'
          newObj.abnormal = newObj.abnormal == 1 ? 'Yes' : 'No'
          this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
          this.commonService.auditDetails('ID', 'name', result, [newObj], 'Create', this.templateData, this.auditableColumns);
          // this.callResetForm();
          if(this.sourceApp == 'VitalDx'){
            this.selectedData = {CaseType: '', UserId: ''}
          }
          if (!this.userCardFlag) {
            this.refreshGrid(true);
            }
            else {
              this.refreshUserGrid();
            }
          // this.addGridData(this.adequacyType);
          this._snackbar.open('Data saved successfully', 'Close');
        } else {
          this._snackbar.open('Data saving failed!', 'Close');
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong. Please try again', 'Close');
        console.error(error)
      })
    }
    else {
      this.VitalHttpServices.UpdateAdequacyCodes([newObj]).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          this.adequacyType = 'Group';
          if (result.Success) {
            newObj.isactive = newObj.isactive == 1 ? 'Active' : 'Inactive'
            newObj.abnormal = newObj.abnormal == 1 ? 'Yes' : 'No'
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            oldObj['sequenceorder'] = oldObj['sequence'];
            delete oldObj['sequence'];
            this._snackbar.open('Data updated successfully', 'Close');
            if (!this.userCardFlag) {
              this.refreshGrid(true);
            }
            else {
              this.refreshUserGrid();
            }
            this.commonService.auditDetails('id', 'name', [oldObj], [newObj], 'Edit', this.templateData, this.auditableColumns);
            // this.addGridData(this.adequacyType);
          }
          else {
            this._snackbar.open('Update failed!', 'Close');
          }
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong. Please try again', 'Close');
        console.error(error)
      })
    }
  }
  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  emitFilters($event: any) {
    if(this.sourceApp != 'VitalDx'){
        this.onSelectedType($event?.UserId?.event, $event ?.UserId?.object);
      }
      else{
        this.selectedData = {CaseType : $event.CaseType === 'All Casetypes' ? '' : $event.CaseType, UserId: $event?.UserId?.object?.userid}
        this.onSelectedType($event?.UserId?.event, $event?.UserId?.object);
    }
  }

  roleChanged($event: any) {
    this.setUserRole($event?.event, $event?.user)
  }
}
