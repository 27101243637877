<div mat-dialog-title class="fax-header">
    <span>{{data.header}}</span>
    <img class="ml-auto" mat-dialog-close src="/assets/icons/Close_black.svg" alt="cross" />
</div>
<mat-dialog-content class="fax-content">
    <div *ngIf="labAccountDetails.length" [formGroup]="faxForm">
        <app-custom-select labelValue="Laboratory" formcontrolname="laboratory" [required]="true" [dropDownValues]="labAccountDetails" dropDownKeyProp="DisplayName" dropDownValueProp="AccountId" defaultOption="Select Laboratory"></app-custom-select>
    </div>
    <div *ngIf="caseCategoriesList.length&&faxAdvanceAlert==''">
        <div id="DivPopupDetails">
            <div class="grid-popup">
                <div class="grid-container-popup">
                    <div class="header-popup d-flex">
                        <mat-checkbox #chkAllCaseType 
                        [checked]="isAllCasesCategorySelected(caseCategoriesList)"
                            (click)="$event.stopPropagation()"
                            (change)="$event?toggleAllCaseCategory(caseCategoriesList):null"
                             >
                            <b> Case Type </b>
                        </mat-checkbox>
                        
                    </div>
                    <div class="w-100">
                        <div class="font" *ngFor="let caseCategory of caseCategoriesList; let i = index">
                            <mat-checkbox class="mb-2"
                                [checked]="caseCategory.selectionModel.hasValue()&&isAllCaseSelected(caseCategory.Cases,caseCategory.selectionModel)"
                                (click)="$event.stopPropagation()"
                                (change)="$event?toggleAllCases(caseCategory,caseCategory.selectionModel):null">
                                
                                    <span> <b>{{caseCategory.Category}}</b></span>
            
                            </mat-checkbox>
                            <div class="grid-caselist mb-1">
                                <div class="item font" *ngFor="let caseTypes of caseCategory.Cases">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event?caseCategory.selectionModel.toggle(caseTypes.CaseTypeId):null"
                                        [checked]="caseCategory.selectionModel.isSelected(caseTypes.CaseTypeId)">
                                        <span>{{caseTypes.CaseTypeDisplayname}} </span>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid-container-popup" *ngIf="servicesList">
                    <div class="header-popup ">
                        <mat-checkbox (change)="$event ? toggleAllServices() : null"
                            [checked]="orderingServiceSelection.hasValue() && isSelectedService()">
                            <b> Service Type </b>
                        </mat-checkbox>
                    </div>
                    <div class="service d-flex" >
                        <div class="item font" *ngFor="let service of servicesList; let i = index">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? orderingServiceSelection.toggle(service) : null"
                                [checked]="orderingServiceSelection.isSelected(service)">
                                {{service}}
                            </mat-checkbox>

                        </div>
                    </div>
                </div>
                <div class="grid-container-popup" *ngIf="physicianList.length">
                    <div class="header-popup">
                        <mat-checkbox #chkAllOF color="#2177af" (change)="$event ? toggleAllPhysicians() : null"
                            [checked]="orderingPhysicianSelection.hasValue() && isSelectedPhysicains()">
                            <b> Physicians</b>
                        </mat-checkbox>
                    </div>
                    <div class="service" >
                        <div class="item font" *ngFor="let case of physicianList">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? orderingPhysicianSelection.toggle(case) : null"
                                [checked]="orderingPhysicianSelection.isSelected(case)">
                                {{case.Physician}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid-popupButton">
                <div class="row pl-5 align-items-center">
                    <app-button class="ml-auto col-1" buttonclass="secondary" (click)="onNoClick()">Return</app-button>
                    <app-button class="creation-btn-width" buttonclass="primary"
                        (click)="saveCaseType()">Save</app-button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="faxAdvanceAlert!=''">
        {{faxAdvanceAlert}}
    </div>
</mat-dialog-content>