<div mat-dialog-title class="npi-lookup-header">
    <span>{{config.header}}</span>
    <img class="ml-auto" mat-dialog-close src="/assets/icons/Close_black.svg" alt="cross" />
</div>
<mat-dialog-content class="npi-content">
    <div>
        <form [formGroup]="formNPIRegistry" autocomplete="off" *ngIf="config.mode === 'search'">
            <div *ngIf="config.NPIFor === 'location'" class="d-flex npi-search">
                <input type="text" placeholder="Search by NPI Number / Provider Name" [maxlength]="100"
                    formControlName="inputForLocation" />
                <app-button type="submit" buttonclass="primary" (click)="getNpiData()">Search</app-button>
            </div>
            <div *ngIf="config.NPIFor === 'user'" class="search-user-npi-feilds mt-3">
                <app-custom-input placeholder="Enter NPI" mask="0000000000" labelValue="NPI"  formcontrolname="npi"></app-custom-input>
                <app-custom-input placeholder="Enter First Name" labelValue="First Name" formcontrolname="firstName">
                </app-custom-input>
                <app-custom-input placeholder="Enter Last Name" labelValue="Last Name"  formcontrolname="lastName"></app-custom-input>
                <app-custom-input placeholder="Enter Zip Code" labelValue="Zip Code"  formcontrolname="zip"  mask="00000-0000" (input)="getStateAndCity()"></app-custom-input>
                <app-custom-input placeholder="Enter City" labelValue="City"  formcontrolname="city"></app-custom-input>
                    <app-custom-select id="state"  labelValue="State"
                    formcontrolname="state" defaultOption="Select State" dropDownKeyProp="Description"
                    dropDownValueProp="Description" [dropDownValues]="config.stateList"></app-custom-select>
                <div class="d-flex align-items-center">
                    <app-button type="submit" buttonclass="primary" class="npi-search-btn ml-auto mr-2" (click)="getNpiData()">Search</app-button>
                    <app-button  buttonclass="secondary" class="npi-search-btn" (click)="clearSearch()">Clear Search</app-button>
                </div>
            </div>
        </form>
        <ng-container *ngIf="NPIList$ |asyncloader| async as npiList">
            <ng-container *ngIf="!npiList.loading;else loading">
            <div *ngIf="npiList.data.length == 31">
                <p class="npi-errors">Only 30 records are displayed. Please filter using other
                    parameters</p>
            </div>
            <div class="mt-3" *ngIf="npiList.data.length>0 && errorMessage ==''">
                <div class="p-1 px-2" [class]="config.mode === 'search' ? 'npi-table-header pr-3' : 'npi-table-header-view'">
                    <span *ngIf="config.mode === 'search'"></span>
                    <span>NPI</span>
                    <span *ngIf="config.NPIFor === 'location'">PROVIDER</span>
                    <span *ngIf="config.NPIFor === 'user'">NAME</span>
                    <span>PRIMARY TAXONOMY</span>
                    <span>PRIMARY PRACTICE ADDRESS</span>
                    <span> STATE </span>
                </div>
                <div [class]="bodyClassName" >
                    <div *ngFor="let i of npiList.data; let j = index">
                        <div class="p-2 row-shadow npi-body-each-item"
                            (click)="[markRadio(j), selectedRadioButton($event,i)]" data-toggle="collapse"
                            role="button" [aria-expanded]="opencontent?'true':'false'" [attr.href]="'#col'+j" aria-controls="collapseExample"
                            style="cursor: pointer;" data-parent="#collapse-parent"
                            [ngClass]="{'table-body-background-color' :j%2==0 ,'table-body-white-background-color' :j%2!=0}">
                            <span *ngIf="config.mode === 'search'" >
                                <input type="radio" [id]="'rad'+j" name="npiRadio" (click)="[selectedRadioButton($event,i), $event.stopPropagation()]" />
                            </span>
                            <span class="npi-text-color"><b>{{i.npi}}</b></span>
                            <span *ngIf="config.NPIFor === 'location'"><b>{{i.basic.organizationname??''}} </b></span>
                            <span *ngIf="config.NPIFor === 'user'"><b>{{i.basic.firstname}} {{i.basic.lastname}} </b></span>
                            <span><b>{{i.primaryTaxonomy.desc}}</b> </span>
                            <span><b>{{i?.primaryPracticeAddress?.address1}} {{i?.primaryPracticeAddress?.city}}</b></span>
                            <span> <b>{{i?.primaryPracticeAddress?.state}}</b> </span>
                        </div>
                        <div [class.collapse]="config.mode === 'search'" [id]="config.mode === 'search'? 'col'+j : ''">
                            <div class="" style="margin: 20px 59px 10px 59px;">
                                <div class="row row-cols-5 justify-content-md-between">
                                    <div><b>Status :</b> <span *ngIf="i.basic.status === 'A' "> Active</span></div>
                                    <div><b> Last Updated :</b> <span> {{i.basic.lastupdateddate | formatDate:"MMM d, y"}}</span></div>
                                    <div><b>Certification Date :</b> <span> {{i.basic.certification_date | formatDate:"MMM d, y"}}</span></div>
                                    <div><b>Enumeration Date : </b><span> {{i.enumerationdate | formatDate:"MMM d, y"}}</span></div>
                                    <!-- <div><b>NPI Type :</b> <span> {{i.enumeration_type}}</span> </div> -->
                                </div>
                                <div class="" style="margin: 10px 0 ">
                                    <div class="">
                                        <b><label class="">Mailing Address :</label></b>
                                         {{i?.address[0]?.type}},
                                        {{i?.address[0]?.address1}}, {{i?.address[0]?.address2}},
                                        {{i?.address[0]?.city}}, {{i?.address[0]?.state}},
                                        {{i?.address[0]?.zip}}-{{i?.address[0]?.zip4}}, {{i?.address[0]?.telephonenumber}}
                                    </div>
                                </div>
                                <div class="" *ngIf="i?.address[1]">
                                    <p class="">
                                        <b> <label class="">Secondary Primary Practice Address :</label></b>
                                        {{i?.address[1]?.type}},
                                        {{i?.address[1]?.address1}}, {{i?.address[1]?.address2}},
                                        {{i?.address[1]?.city}}, {{i?.address[1]?.state}},
                                        {{i?.address[1]?.zip}}-{{i?.address[1]?.zip4}}, {{i?.address[1]?.telephonenumber}}
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex inner-table-heading">
                                <div class="col-2">
                                    <div class="col p-1 pl-2">TAXONOMY</div>
                                </div>
                                <div class="col-10 px-1 pb-1 inner-table-background">
                                    <ng-container *ngIf="i.taxonomies.length">
                                        <div class="row row-cols npi-border-bottom mb-1">
                                            <div class="col-4">Primary Taxonomy</div>
                                            <div class="col-4">Selected Taxonomy</div>
                                            <div class="col-2">State</div>
                                            <div class="col-2">License Number</div>
                                        </div>
                                        <div class="inner-table-body">
                                            <div class="row row-cols align-items-center" *ngFor="let add of i.taxonomies">
                                                <div class="col-4">{{add.primary}}</div>
                                                <div class="col-4">
                                                    <div>{{add?.taxonomy_group}}</div>
                                                    {{add.code}} - {{add.desc}}
                                                </div>
                                                <div class="col-2">{{add.state}}</div>
                                                <div class="col-2">{{add.license}}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div *ngIf="!i.taxonomies.length" class="pt-1 npi-no-records-found">
                                        <span >No Records Are Found.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex inner-table-heading ">
                                <div class="col-2">
                                    <div class="col p-1 pl-2"> OTHER IDENTIFIERS </div>
                                </div>
                                <div class="col-10 px-1 pb-1 inner-table-background" >
                                    <ng-container *ngIf="i.identifiers.length">
                                        <div class="row row-cols npi-border-bottom mb-1">
                                            <div class="col-8">Issuer</div>
                                            <div class="col-2">State</div>
                                            <div class="col-2"> Number</div>
                                        </div>
                                        <div class="inner-table-body">
                                            <div class="row row-cols align-items-center" *ngFor="let add of i.identifiers">
                                                <div class="col-8">{{add.issuer}}</div>
                                                <div class="col-2">{{add.state}}</div>
                                                <div class="col-2">{{add.identifier}}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div *ngIf="!i.identifiers.length" class="pt-1 npi-no-records-found">
                                        <span>No Records Are Found.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container #loading>
            <app-shimmerui [line]="4" *ngIf="npiList.loading"></app-shimmerui>
        </ng-container>
        </ng-container>
        <div class="row npi-errors" *ngIf="errorMessage!='' ">
            <p>{{errorMessage}}</p>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions allign="end" *ngIf="config.mode === 'search'">
    <div class="my-2 mx-4 d-flex align-items-center w-100">
        <ng-container *ngIf="selectedNpi">
            <div class="d-flex align-items-center align-self-end" *ngIf="!selectedNpi?.verified; else mdmlogo">
                <input class="mr-2" type="checkbox" [(ngModel)]="npiVerified" [ngModelOptions]="{standalone: true}"
                    [disabled]="!selectedNpi" aria-label="Checkbox for following text input">
                <b class="secondary-heading">I have verified the NPI details of this Organization and Authorize the
                    Validation</b>
            </div>
            <ng-template #mdmlogo>
                <img class="verified-by-logo" src="/assets/icons/Logo/VitalAxis.svg" alt="VitalAxis Logo">
            </ng-template>
        </ng-container>
        <app-button mat-dialog-close class="ml-auto npi-btn mr-3" buttonclass="secondary">Return</app-button>
        <app-button buttonclass="primary" class="npi-btn" (click)="checkOverdingValues()" [isDisabled]="!selectedNpi"
            >Import</app-button>
    </div>
</mat-dialog-actions>
