import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';
import { HomeLayoutComponent } from '../common/layout/home-layout.component';
import { VitalHttpServices } from '../core/services/VitalHttpServices';
import { CardQueries } from '../model/card-query';
import { CommonService } from '../core/services/commonservices';
import { LabadminService } from './labadmin.service';
import { addNabvTab, decrement } from './store/actions/navbar.actions';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { LabadminSessionService } from './services/labadmin-session.service';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmLabadminComponent } from '../base/popup/confirm-labadmin/confirm-labadmin.component';
import { ActivityTrackerService } from '../core/services/activity-tracker.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-labadmin',
  templateUrl: './labadmin.component.html',
  styleUrls: ['./labadmin.component.scss'],
})
export class LabadminComponent {
  selectedRoute: any;
  labAdminDomain: any;
  userInfo: any = {};
  userRoles: any[] = [];
  menuList = [];
  isCreationModeEnabled$ : Observable<boolean>
  queryvalue: string="";
  globalSearchComponent: boolean;
  userSessionDetails:any;
  globalSearchDetails: any;
  clientSearchCriteria:any;
  showCritria: boolean = false;
  searchCriteria: any[]=[];
  readonly separatorKeysCodes: number[] = [];
  @ViewChild("globalSearch")globalSearch : ElementRef
  isOver = false;
  openActivityTkr = false;
  trackerInput: any = {}
  activityPath: any[];
  trackerApiUrl:any;
  labAdminSessionData: any;

  tempClientSearchCriteria: any;
  cloneClientSearchCriteria: any;
  EntityList: any;
  dropdownValue: any;
  showQueryValue: string;
  sendObject: { queryvalue: string; dropdownValue: any; };
  toolTipText: any;
  constructor(
    private router: Router,
    private vitalHttpServices: VitalHttpServices,
    private commonService: CommonService,
    public labAdminService: LabadminService,
    public labAdminSession: LabadminSessionService,
    public cookieService: CookieService,
    private dialog: MatDialog,
    private store: Store<{ breadcrum: [], creationMode : boolean }>,
    private activitySession: ActivityTrackerService
  ) {
    this.router.navigate(['/labadmin'])
  }

  ngOnInit() {
    sessionStorage.setItem('deploymentKey', "configdb");
    sessionStorage.setItem('refresh',btoa(""));
    sessionStorage.setItem("page", btoa("login"));
    this.getRolesOnFusionAuth("-1");
    this.commonService.setTitle('VitalDx | Lab Admin', '../../assets/icons/DX.svg');
    this.isCreationModeEnabled$ = this.store.select('creationMode')
    this.getSearchCriteria()
    this.EntityList = ["Clients", "Client Users", "Lab Users", "Physician", "Pathologists", "Referring Physician"];
    this.dropdownValue = this.EntityList[0];
  }

  settingsObject : any =  {
      "Htext" : "Settings",
      "SubMenu" : [],
      "Template" : "",
      "icon" : "",
      "iconType" : "",
      "routerLink" : "/labadmin/settings"
    }

  decrement() {
    this.store.dispatch(decrement());
  }

  async GetHomeDetails() {
    if (
      this.vitalHttpServices.mainQueryList ||
      this.vitalHttpServices.mainQueryList.length <= 0
    ) {
      let query = new CardQueries().mainQuery;
      let OrgId = this.labAdminService.organizationId
      let queryRequest: any = {
        OperationName: null,
        Query: query,
        Variables: null,
      };
      let _this = this;
      let result = await _this.vitalHttpServices
        .GetData(queryRequest, 'configdb')
        .toPromise();
      if (!result.errors) {
        _this.vitalHttpServices.mainQueryList = result.data.Card;
        sessionStorage.setItem(
          'GraphqlMainQuery',
          btoa(JSON.stringify(_this.vitalHttpServices.mainQueryList))
        );
        let newQuery = _this.vitalHttpServices.mainQueryList.filter(
          (x) => x.Keyword == 'getVitalAdminMenusForDx'
        )[0].Query;

        let queryString = "Organizationid in \"" + OrgId + "\":string[] OR Organizationid in \"-1\":string[]"
        let newQueryVariable = {
          CardName: "LabAdministrator",
          filtername: queryString
        };
        let newQueryResult = _this.commonService.GetCardRequest(
          newQueryVariable,
          newQuery
        );
        _this.vitalHttpServices
          .GetData(newQueryResult, this.labAdminService.deploymentKey)
          .subscribe((newResData) => {

            let data = JSON.parse(newResData.data.submenuData[0].JSONMenu).MenuItems;
            _this.labAdminService.rbacPermissions = JSON.parse(newResData.data.submenuData1[0]?.Permission).MenuItems
            _this.menuList = data;
            _this.labAdminService.menuList = data;
            _this.selectedMenu(data[0]);
          });
      } else {
        this.logOut();
        console.error(result);
      }
    } else {
      this.logOut();
    }
  }

  async getRolesOnFusionAuth(userid) {
    this.vitalHttpServices.getADUserRoles(userid).subscribe(async (resData: any) => {
      if (resData) {
        //use this as local
      //    let resData = {
      //      "userinfo": "{\"aud\":\"8c8fe76f-455a-4103-94de-8bbe8365ee2f\",\"exp\":1711707066,\"iat\":1711706946,\"iss\":\"vitalaxis.com\",\"sub\":\"c01e818c-22b5-48ce-a668-ee206091b46e\",\"jti\":\"1d2f0d10-086f-4e0a-b1a2-dc3420f7d4bd\",\"authenticationType\":\"PASSWORD\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"email_verified\":true,\"preferred_username\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"scope\":\"offline_access\",\"auth_time\":1711706946,\"tid\":\"97ecda9b-e323-6684-68a9-d6f7b1f62e33\"}",
      //    "data": "{\"commonKey\":\"c01e818c-22b5-48ce-a668-ee206091b46e\",\"deploymentKey\":\"BCM\",\"orgGuid\":\"aced0092-e24e-41ef-8aad-6ec7a39b7baf\",\"orgId\":\"777\",\"userId\":22921921,\"domainUrl\":\"https://jupiterdevbcm.vitalaxis.com\",\"sourceApp\":\"VitalDx\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\"}"
      // };
        let sessionData = JSON.parse(JSON.stringify(resData));
        if ((sessionData.userinfo && Object.keys(sessionData.userinfo).length > 0) && (sessionData.data && Object.keys(sessionData.data).length > 0)) {       
          await this.vitalHttpServices.getURLs() 
          let user = JSON.parse(resData.userinfo)
           user.email=user.email=="" ? "1" : user.email
           localStorage.setItem("UserEmail", user.email);
           let domainData = JSON.parse(resData.data)
           sessionStorage.setItem("Userid",domainData?.userId);
           this.labAdminDomain = domainData;
         //to get logout when the session is destroyed
         sessionStorage.setItem("domainUrl",btoa(this.labAdminDomain.domainUrl));
           if(domainData.email.toLowerCase()==user.email.toLowerCase())
            {
          this.labAdminService.organizationId = domainData.orgId
          this.labAdminService.deploymentKey = domainData.deploymentKey;
          this.labAdminService.sourceApp = 'vitalDX';
          sessionStorage.setItem("DeploymentKey", domainData.deploymentKey);
          let _this = this;
          this.labAdminService.getUserRoles({
            // Dev & QC
             "userId": domainData.userId,
            "orgGuid": domainData.orgGuid
          }, domainData.deploymentKey).subscribe(async (res) => {
            // Local enable below cmd above 3 lines
          //  "userId": '22935343',
          //  "orgGuid" : 'ACED0093-B7BD-48D5-A5EC-B0F193E1263D'
          //  }, "LIS").subscribe((res) => {
            this.userInfo = res.content;
            this.labAdminService.formattedDisplayName = this.userInfo?.formattedDisplayName;
            localStorage.setItem("user_name", this.userInfo?.formattedDisplayName);
            this.userRoles = res.content.userRoles.sort((a) => a.roleDisplayName == this.userInfo.defaultRole? -1 : 0);
            this.labAdminSession.setLabAdminSession({ "labAminSession": sessionData, "userDetails": res.content });
            await this.GetHomeDetails();
          })

            }
            else{
              this.informationPopBeforeLogOut();
            }
        }
        else{
          this.logOut();
        }
      }
    }, error => {
      this.logOut();
    });
  }

  selectedMenu(route) {
    this.selectedRoute = route.routerLink;
    this.router.navigate([`${route.routerLink}`]);
    route = { ...route, Level: 0 }
    this.store.dispatch(addNabvTab({ value: route }));
  }

  changeRole(role) {
    window.location.href = atob(sessionStorage.getItem("domainUrl")) + "/VALogin.aspx?FormUserID=" + role.userId + "&from=switchRole";
  }

  logOut() { 
    this.clearStoreData();
    window.location.href = environment.fusionauth + 'logout?client_id=' + environment.clientid + '&post_logout_redirect_uri=' + atob(sessionStorage.getItem("domainUrl")) + "/VALogOut.aspx";
  }
 // clear the data when the logout is happend
   clearStoreData(){
    this.labAdminSession.setLabAdminSession('');
    localStorage.clear();
    this.cookieService.deleteAll('/', environment.domain);
    this.labAdminService.sourceApp = "";
    this.labAdminService.menuList = []
    this.labAdminService.baseUrl2 ='';
    this.labAdminService.selectedSubmenu = {}
    this.labAdminService.rbacPermissions = {}
    this.labAdminService.subMenuPermissions = {};
    this.labAdminService.organizationId = '';
    this.labAdminService.accountId = '';
    this.labAdminService.deploymentKey = '';
    this.labAdminService.templateData = {};
    this.labAdminService.detailsData={};
    this.labAdminService.subSideMenu=[]
    this.labAdminService.hidCMSCATURl1 = '';
    this.labAdminService.hidCMSCATCSKTKN = '';
    this.labAdminService.formattedDisplayName = '';
   }
  
  //Information that user has the session with the different authentication
  informationPopBeforeLogOut(){
    let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
      disableClose: true,
      width: '360px',
      data: { header: "", message: "A session is already active in the same browser. You will be logged out from here. Please try to login in a different browser OR Logout from the active session and Login here again.", continue: "Ok", cancel: "dontshow" }
        })
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.logOut();
      }
    })
  }
  openActivityTracker() {
    this.openActivityTkr = true;
    this.labAdminSession.getLabAdminSession.subscribe(labAdminSessionData => this.labAdminSessionData = labAdminSessionData);
    let context = {};
    let activitySearchParams: any;
    this.trackerApiUrl = environment.baseUrl2;
    this.activityPath = []
    this.activitySession.getActivitySession.subscribe(activityTab => activitySearchParams = activityTab);

    this.trackerInput = {
      'productId': atob(sessionStorage.getItem("domainUrl")),
      'customerId': sessionStorage.getItem('org_id'),
      'entityType': activitySearchParams.entityType,
      'actionType': 'audit',
      'entityId': activitySearchParams.entityId,
      'ticketId': '',
      'index': '',
      'locationId': this.labAdminSessionData?.userDetails?.organizationId,
      'context': activitySearchParams.context,
      'createdBy': '',
      'startDate': '',
      'endDate': ''
    }
    
  }
  onSearchInputChange(){
    if(this.queryvalue.length==0){
      return;
    }
    this.globalSearchComponent=true;
    this.getGlobalSearchedData()
  }
  clearSearch() {
    this.queryvalue = ""
    // this.fnChildSearchSubmit(false);
  }
  async getGlobalSearchedData() {
    // if(this.queryvalue.length==0){
    //   return;
    // }
    let AssociatedAccountList: string;
    let _this = this;
    let tempAccountsList = [];
    await this.labAdminSession.getLabAdminSession.subscribe(async session => {
      if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
        _this.userSessionDetails = session["userDetails"];
        _this.userSessionDetails.userAccountIDs.forEach(element => {
          tempAccountsList.push(element.accountId);
        });
        AssociatedAccountList = tempAccountsList.toString();
      }
    })
    // let obj:any = this.updateObjectFromArray(this.tempClientSearchCriteria.clients[0], this.searchCriteria);
    
    // if (!obj.hasOwnProperty()) {
    //    obj = {
    //     searchedinputvalue: this.searchCriteria[0]
    //   }
    // }
    let object = {
      OrgID: this.labAdminService.organizationId,
      AssociatedAccounts: AssociatedAccountList,
      entity: this.dropdownValue, 
      searchedinputvalue:this.queryvalue     
    }
    this.sendObject = {... this.sendObject ,queryvalue: this.queryvalue,dropdownValue: this.dropdownValue}
    this.showQueryValue=object.searchedinputvalue;
    // object = {...object, ...obj};
    await this.labAdminService.getGlobalSearchDetails(object, this.labAdminService.deploymentKey).toPromise().then(async result => {
      if (result.status == 'Success') {
        this.globalSearchDetails = result.content;
      }
      else{
        this.globalSearchDetails=[];
      }
    }), error => {
      this.globalSearchDetails=[];
      console.error(error)
    }
  }
  getSearchCriteria(){
   this.clientSearchCriteria= {"clients": [{"FacilityName": "Facility Name","LocationCode": "Location Code","NPI": "NPI"}]};
   this.tempClientSearchCriteria = structuredClone(this.clientSearchCriteria);
   this.clientSearchCriteria = Object.values(this.clientSearchCriteria.clients[0])
   this.cloneClientSearchCriteria=this.clientSearchCriteria;
  }

  //#region  mat-chip implementation
  // filterCriteria(event) {
  //   if(event.target.value.length >=1 ){
  //     this.showCritria=true;
  //   }
  //   else if (event.target.value.length ==0){
  //     this.showCritria=false;
  //   }
  //   if (event.target.value != '') {
  //     console.log(this.searchCriteria)
  //     const keysInJson = this.searchCriteria.map(item => item.split(':')[0]);

  //     // Filter arr1 based on keys present in json1
   
  //     this.clientSearchCriteria = this.cloneClientSearchCriteria
  //     .filter(item => !keysInJson.includes(item)) 
  //     .filter(va => va.toLowerCase().includes(event.target.value.toLowerCase())); // Filter by case-insensitive substring match
  // }
  //   else if (event.target.value == '') {
  //     this.clientSearchCriteria = this.cloneClientSearchCriteria;
  //   }
  // }

  // selectingCriteria(criteria) {
  //   this.searchCriteria=[]
  //   this.queryvalue =  criteria;
  //   //this.searchCriteria.push(criteria);
  // }
  // removeSearchCriteria(){
  //   this.searchCriteria=[];
  // }
  // remove(searchData): void {
  //   const index = this.searchCriteria.indexOf(searchData);

  //   if (index >= 0) {
  //     this.searchCriteria.splice(index, 1);
  //   }
  // }
  // add(event: MatChipInputEvent): void {
  //   const input = event.input;
  //   const value = event.value;

  //   // Add our fruit
  //   if ((value || '').trim()) {
  //     this.searchCriteria.push(value.trim());
  //   }

  //   // Reset the input value
  //   if (input) {
  //     input.value = '';
  //     this.globalSearch.nativeElement.focus();
  //   }
  // }
  
  // updateObjectFromArray(obj, arr) {
  //   let updatedObj = {};

  //   arr.forEach(item => {
  //     let [key, value] = item.split(':');
      
  //     // Trim spaces from the key
  //     key = key.replace(/\s+/g, '');
      
  //     // Check if the key exists in the original object
  //     if (obj.hasOwnProperty(key)) {
  //       updatedObj[key] = value;
  //     }
  //   });
  
  //   return updatedObj;
  // }
  //#endregion
  OnChangeEntry(DrpDwnEntityValue) {
    this.dropdownValue = DrpDwnEntityValue;
  }
  globalSearchClear() {
      this.globalSearchComponent = false;
      this.queryvalue = "";
      this.globalSearchDetails=[];
      this.selectedRoute = this.menuList[0].routerLink;
      let firstMenuItem = this.menuList[0].SubMenu[0];
      this.router.navigate([`${firstMenuItem.routerLink}`]);
      let obj : any = {...firstMenuItem , Level: 1}
      this.store.dispatch(addNabvTab({value: { ...this.menuList[0], Level : 0 }}));
      this.store.dispatch(addNabvTab({value: obj}));
  }

  enableToolTip(e) {
    this.toolTipText=e.scrollWidth > e.clientWidth?e.textContent:'';
  }
}
