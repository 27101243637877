import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TemplateViewService } from '../../../core/utility/template-view.service'
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { ShortenTextPipe } from '../../../core/utility/shortentext.pipe';
import { Editor, Toolbar } from 'ngx-editor';
import { StripHtmlPipe } from 'src/app/core/utility/striphtml.pipe';

@Component({
  selector: 'app-templates-view',
  templateUrl: './templates-view.component.html',
  styleUrls: ['./templates-view.component.scss'],
  providers:[ ShortenTextPipe, StripHtmlPipe ]
})
export class TemplatesViewComponent implements OnInit, OnChanges, OnDestroy {
  valueClone: any;
  editViewClicked: boolean = false;
  createViewClicked: boolean = false;
  SubMenuCardModel: SubMenuCardModel;
  formBuilderObj: any = {};
  finalFormData: any[] = [];
  dataToBeSaved: any = {};
  formValidationData: any[] = [];
  valuesForDropDown: any = [];
  chipValues: any = [];
  context: string = '';
  finalObj: any = {};
  dataForm: any = [{}];
  temp: any[] = [];
  isFormChanged = false;
  fieldPatterns = {
    number: '^[0-9]*$',
    alpha: '^[a-zA-Z]*$',
    alphaNumeric: '^[a-zA-Z0-9]*$'
  };
  auditableColumns: any;
  isSaveDisabled = false;
  editor: Editor;

  @Input() public Placeholders: any = [];
  @Input() public dynamicFormData: any = [];
  @Input("viewSites")
  @Output() closeViewTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Input() templateData: any;
  @Input() selectDropDown: any;
  @Input() createNewClicked: any;
  @Input() editExistingClicked: any;
  @Input() subMenuCardModel: any;
  @Input() selectedListOption: any;
  @Input() hideEdit = false;
  @Input() saveOrEditCompleted: any;
  @Input() HeaderName
  @Input() viewRecord
  @Input() isUserLevel = false;
  @Input() isAdmin3 = true;


  @Output() saveOrEditEventTriggered = new EventEmitter<any>();


  @ViewChild('myForm') myForm: NgForm;
  @ViewChildren('textareaElement') textAreas: QueryList<ElementRef>; // to get list of all textarea in view(HTML)
  activityEntity: any;

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  constructor(
    public tempService: TemplateViewService,
    public commonService: CommonService,
    public VitalHttpServices: VitalHttpServices,
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    private _snackbar: MatSnackBar,
    public activityService: ActivityTrackerService,
    private shortentext:ShortenTextPipe,
    private labAdminService: LabadminService) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }

  ngOnInit(): void {
    this.editor = new Editor();
  }

  resetDynamicForm() { // when we click on reset form based on edit or create we should call particular messages.
    this.editViewClicked ? this.editDynamicForm() : this.createDynamicForm();
  }

  defaultFormCreation() { // to create Default Dynamcim Form
    // this.dynamicFormData = this.tempService.objMapping(this.viewRecord, this.HeaderName);
    // this.dynamicFormData.forEach(element => {
    //   element.value = this.viewRecord[element['valueToBeFetchedBy']];
    // });
  }

  backView() { //method to go to previous page
    this.getActivityEntity(false);
    this.activityEntity['entityId'] = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.closeViewTemplate.emit('close');
  }

  returnBtn() { //button used to return back to listing page
    this.backView()
  }

  appendToTextarea(value: any, particularField: any) { // on click of chip we are appending the value to textarea.
    let selectedValue = `{{${value}}}`;

    const textarea = this.textAreas.find(textarea => // to get all the textarea and then from those textareas we will get the textarea which we want.
      textarea.nativeElement.getAttribute('name') === particularField?.properties?.source?.targetField)?.nativeElement as HTMLTextAreaElement;

    textarea.focus(); // to focus on particular textarea so that we can get proper postions.
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    let currentValue = textarea.value;

    // let descriptionField = this.dynamicFormData.find(item =>
    //   item.columnName === particularField?.properties?.source?.targetField);

    let descriptionField = particularField?.groupedField;

    if (descriptionField?.value.length < 4000 && (textarea.value.length + selectedValue.length < 4000)) {
      const description = currentValue.substring(0, startPos) + selectedValue + currentValue.substring(endPos);
      textarea.value = description;
      descriptionField.value = description;
      textarea.setSelectionRange(startPos + selectedValue.length, startPos + selectedValue.length);
      textarea.blur();
      this.onTextAreaChange(particularField);
    } else {
      this._snackbar.open('Character limit reached for description!', 'Close');
    }
  }

  onTextAreaChange(particularField: any) {
    let hiddenTextArea = this.dynamicFormData.find((item: any) => item.columnName === particularField?.properties?.source?.copyTo);
    hiddenTextArea['value'] = particularField?.groupedField?.value;
  }

  createDynamicForm() { // we are creating new dynamicForm here
    this.dynamicFormData.forEach(item => {
      item.value = ''; // making all value as '', so that if any value is present we will remove here

      // here we want the dropdown which is parent or whose value is dependent on options of other dropdowns
      if (item?.properties?.patchPredefinedValue) {
        item['value'] = this.tempService.globalMasterDataForQueries[item?.columnName] || '';
      }

      if (item.dataType === 'multiselectDropdown') {
        item['dummyValue'] = [...[]];
        item['value'] = item?.value ? Array.isArray(item?.value) ? item?.value : [item?.value] : null;
      }

      if (['Active', 'Status', 'IsActive'].includes(item.displayName)) {  // by default we need it as true so
        item.value = 1;
      }

      if (['Default', 'Abnormal', 'IsDefault'].includes(item.displayName)) { // by default we need it as false so
        item.value = 0;
      }
      if (item.dataType === 'autocomplete') {
        item['dummyValue'] = { ...{ itemName: '', itemId: '' } };
      }

      if (item?.dataType === 'groupedTextArea') {
        item['groupedField'].value = '';
      }

      if (item?.dataType === 'multiselectDropdown') {
        item.value = [];
      }
    });

    this.context = 'Create ';
    this.templateData = this.templateData || this.VitalHttpServices.Templatedata;
    this.editViewClicked = false;
    this.createViewClicked = true;
    this.getDependentDropdownsOnEdit();
  }


  editDynamicForm() {
    // this.defaultFormCreation();

    this.dynamicFormData.forEach(element => {
      element.value = this.viewRecord[element['valueToBeFetchedBy']];

      if (['Is_Default', 'Default', 'Abnormal', 'IsDefault'].includes(element.valueToBeFetchedBy)) {
        element.value = element.value === 'Yes' || element.value === true ? 1 : 0;
      }

      if (['IsActive', 'Active', 'Status'].includes(element.valueToBeFetchedBy)) {
        element.value = ['Yes', true, 'Active'].includes(element.value) ? 1 : 0;
      }

      if (element?.dataType === 'groupedTextArea') {
        let hiddenTextArea = this.dynamicFormData.find((item: any) => item.columnName === element?.properties?.source?.copyTo);
        element['groupedField'].value = hiddenTextArea?.['value'];
      }

      if (element?.dataType === 'multiselectDropdown' && element?.properties?.source?.separatedBy) {
        element.value = element.value.split('|');
        element['dummyValue'] = this.getValueToPatchForMultiSelect(element);
      } else if (element?.dataType === 'multiselectDropdown' && !element?.properties?.source?.separatedBy) {
        element.value = element?.value ? Array.isArray(element.value) ? element.value : [element.value] : null;
        if (element?.properties?.source?.dataType === 'number' && element.value) {
          element.value = element.value.map(str => parseInt(str));
        }
        element['dummyValue'] = this.getValueToPatchForMultiSelect(element);
      }

      if (element?.dataType === 'autocomplete') {
        let selectedObject = element?.properties?.source?.options.find((item: any) => item[element?.properties?.source?.keyToBeSentToBackend] === element?.value);
        this.getValueToPatchForAutoComplete(selectedObject, element);
      }

    });
    this.getDependentDropdownsOnEdit();
    this.context = 'Edit ';
    this.createViewClicked = false;
    this.editViewClicked = true;
  }


  getDependentDropdownsOnEdit() { // to get the options of dependent dropdowns
    this.dynamicFormData
      .filter(fieldObject => fieldObject.dataType === 'dropdown' && fieldObject?.properties?.source?.dependentFieldsList?.length)
      .forEach(fieldObject => this.tempService.getDependentValues(fieldObject, this.dynamicFormData, this.subMenuCardModel, this.templateData)
      );
  }


  onBadgeChange(event: any, columnName) { // on badge change value should be as 0 or 1
    let field = this.dynamicFormData.find((item: any) => item.columnName === columnName);
    field['value'] = event.target.checked == true ? 1 : 0;
  }

  markAllFieldsOfFormTouched() {
    // Mark all form controls as touched to trigger validation
    Object.values(this.myForm.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  saveDynamicForm() { //Passing the data to API side
    this.isSaveDisabled = true;
    this.ngxService.start();
    const defaultFields = {
      OrganizationId: sessionStorage?.getItem('org_id') && sessionStorage?.getItem('org_id') != '' ? sessionStorage?.getItem('org_id') : this.labAdminService.organizationId,
      CaseType: this.templateData?.secondarykeys?.casetype?.toString() || '',
      CreatedBy: sessionStorage?.getItem('Userid') ? sessionStorage?.getItem('Userid').toString() : '-100',
      ModifiedBy: sessionStorage?.getItem('Userid') ? sessionStorage?.getItem('Userid').toString() : '-100',
    };

    const action = {
      Action: this.createViewClicked ? "Create" : "Edit"
    };

    const dataToBeSaved: any = {};
    this.dynamicFormData.forEach(a => {
      if (a.columnName) {
        dataToBeSaved[a.columnName] = a.value;
      }
    });

    if (dataToBeSaved?.CaseType) {
      delete defaultFields.CaseType;
    }

    // console.log('dataToBeSaved', dataToBeSaved);
    const finalObj = { ...dataToBeSaved, ...action, ...defaultFields };

    if (this.checkRequiredFields() && this.myForm.valid) {
      this.saveOrEditEventTriggered.emit(finalObj);
    } else {
      this.ngxService.stop();
      this.isSaveDisabled = false;
      this.markAllFieldsOfFormTouched();
      this._snackbar.open("Mandatory field is missing", 'Close');
    }

    setTimeout(() => {
      this.isSaveDisabled = false;
    }, 300);
  }


  ngOnChanges(changes: SimpleChanges) {
    this.valueClone = this.viewRecord;
    this.temp = this.tempService.objMapping(this.viewRecord, this.HeaderName);

    if (this.createNewClicked && changes?.createNewClicked?.currentValue) {
      this.saveOrEditCompleted = undefined;
      this.createNewClicked = undefined;
      this.getActivityEntity();
      this.activityEntity.entityId = '';
      this.activityService.setActivitySession(this.activityEntity);
      this.createDynamicForm();
    } else if (this.editExistingClicked && changes?.editExistingClicked?.currentValue) {
      this.saveOrEditCompleted = undefined;
      this.editExistingClicked = undefined;
      this.getActivityEntity();
      let idField = this.tempService.getTemplateConfiguration(this.HeaderName)?.idKey;
      this.activityEntity.entityId = this.viewRecord?.[idField]?.toString();
      this.activityService.setActivitySession(this.activityEntity);
      this.editDynamicForm();
    }

    if (this.saveOrEditCompleted && changes?.saveOrEditCompleted?.currentValue !== changes?.saveOrEditCompleted?.previousValue) {
        this.backView();
      this.saveOrEditCompleted = undefined;
      this.isSaveDisabled = false;
    }
  }

  onBlurOfInput($event: any, fieldItem: any) {
    let replicateFields = fieldItem?.['properties']?.['replicateFields'];
    if (replicateFields?.length) {
      this.dynamicFormData.forEach(obj => {
        if (replicateFields.includes(obj.columnName)) {
          obj.value = $event.target.value;
        }
      });
    }
  }

  onSelectChange(selectedColumn) {
    if (selectedColumn?.['properties']['source']['dependentFieldsList']?.length) {
      // this.getDependentValues(selectedColumn);
      this.tempService.getDependentValues(selectedColumn, this.dynamicFormData, this.subMenuCardModel, this.templateData);
    }
  }

  // for autocomplete dropdown start.
  filterAutomCompleteOptions(autoCompleteInputboxValue: any, autoCompleteOptions: any, filterProperty: any) {
    if (!autoCompleteInputboxValue) {
      return autoCompleteOptions;
    } else {
      let filteredAutoCompleteOptions = autoCompleteOptions;
      return filteredAutoCompleteOptions.filter((option: any) => option[filterProperty].toLowerCase().includes(autoCompleteInputboxValue?.toLowerCase()));
    }
  }

  onAutoCompleteSelectionChange(e, field, particularObject) {
    if (e.source.selected) {
      field.value = particularObject[field['properties']?.['source']?.['keyToBeSentToBackend']];
      field.dummyValue['itemId'] = particularObject[field['properties']?.['source']?.['keyToBeSentToBackend']];
    }
  }

  onAutoCompleteInputChange($event: any, field: any) {
    field.value = null; // once we type something then we have to remove the data.
    field.dummyValue['itemId'] = null; // once we type something then we have to remove the data.
    this.getUserBasedOnTypedName($event.target.value, field); // to get the user Id from the name typed. 
  }

  getUserBasedOnTypedName(value: any, field: any) {
    let keyToBeSentToBackend = field['properties']?.['source']?.['keyToBeSentToBackend'];
    let selectedObject = field?.properties?.source?.options?.filter((item: any) => { // from the available options we will searc for the user and then assign the id.
      return item.username === value;
    });
    field.value = selectedObject[0]?.[keyToBeSentToBackend] ? selectedObject[0]?.[keyToBeSentToBackend] : null; // from the available options we will searc for the user and then assign the id.
    field.dummyValue['itemId'] = selectedObject[0]?.[keyToBeSentToBackend] ? selectedObject[0]?.[keyToBeSentToBackend] : null; // from the available options we will searc for the user and then assign the id.
  }
  // for autocomplete dropdown end.

  checkRequiredFields() {
    const missingFields = this.dynamicFormData.find(obj => obj.inputValidations?.required && !obj.value);
    return missingFields ? false : true;
  }

  getSettings(field: any) {
    return field['properties']?.['source']?.['dropdownSettings'];
  }


  // for multiple dropdown start.
  onMultiDropdownSelect(item: any, field: any) {
    const value = item[field['properties']?.['source']?.['keyToBeSentToBackend']];
    if (!field.value) {
      field.value = [];
    }
    field.value.push(value);
    this.isFormChanged = true;
  }

  onMultiDropdownSelectAll(items: any, field: any) {
    field.value = [];
    for (let itr = 0; itr < items.length; itr++) {
      field.value.push(items[itr][field['properties']?.['source']?.['keyToBeSentToBackend']]);
    }
    this.isFormChanged = true;
  }

  onMultiDropdownDeSelect(items: any, field: any) {
    const index: number = field.value.indexOf(items[field['properties']?.['source']?.['keyToBeSentToBackend']]);
    if (index !== -1) {
      field.value.splice(index, 1);
    }
    this.isFormChanged = true;
  }

  onMultiDropdownDeSelectAll(field: any) {
    field.value = [];
    this.isFormChanged = true;
  }
  // for multiple dropdown end.

  //Text fields trim the value
  trimField(field: any): void {
    field.value = field.value.trim();
  }

  //patch for autoComplete
  getValueToPatchForAutoComplete(selectedObject, fieldItem) {
    if (fieldItem?.properties?.source?.toDisplayInIputField && fieldItem?.value && fieldItem?.value !== 'Not Specified' && selectedObject) {
      const values = fieldItem?.properties?.source?.toDisplayInIputField.map((key: any) => selectedObject?.[key]).filter(Boolean);
      let fieldDisplayValue: any;
      if (values.length > 1) {
        fieldDisplayValue = values[0] + ' ( ' + values[1] + ' )';
      } else if (values.length === 1) {
        fieldDisplayValue = values[0];
      } else {
        fieldDisplayValue = '';
      }
      fieldItem.dummyValue = { itemName: fieldDisplayValue, itemId: fieldItem?.value };
    }
  }
  //patch end for autoComplete


  //patch for autoComplete
  getValueToPatchForMultiSelect(fieldItem) {
    let tempValue: any;
    if (fieldItem?.properties?.source?.dataValueToDisplay) {
      if (fieldItem.value) {
        tempValue = fieldItem.value.map(id => {
          const item = fieldItem?.properties?.source?.options.find((obj: any) => {
            return obj[fieldItem?.properties?.source?.keyToBeSentToBackend] == id;
          });
          return item ? item : null;
        }).filter(role => role !== null);
      }
    }
    console.log('tempValue', tempValue);
    return tempValue ? tempValue : [];
  }
  //patch end for autoComplete

  ngOnDestroy(): void {
    this.dynamicFormData = undefined;
  }

  async getActivityEntity(getAudit = true) {
    // this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
    if (getAudit) {
      this.getAuditableDetails(this.templateData.menuURL);
    }
    await this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
  }

  getAuditableDetails(location: any) {
    let locactionTable=location
    let obj = {
      "microscopic codes": 'Microscopic Notes',
      "correlation" :'Correlation Templates',
      "nomenclature" :'Nomenclature Templates'
    }
    if(obj[location.toLowerCase()]){
      locactionTable = obj[location.toLowerCase()]
    }      
    this.VitalHttpServices.getDisplayColumns({ "TableName": locactionTable }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }
}
