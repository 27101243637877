import { Component, Input } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalAdminTabService } from '../../tab.service';
import { CommonService } from 'src/app/core/services/commonservices';

import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcCore from '@grapecity/wijmo';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-diagnosis-references',
  templateUrl: './diagnosis-references.component.html',
  styleUrls: ['./diagnosis-references.component.scss']
})
export class DiagnosisReferencesComponent {
  SubMenuCardModel: SubMenuCardModel;
  @Input()
  templateData: any;
  public SubmenuCardModel;
  public DeploymentKeys = [];
  noDataFound: boolean;
  gridShow: boolean;
  gridheader: string[];
  listedGridHeader: any = [];
  gridData: wjcCore.CollectionView<any>;
  showPaginationMainGrid: boolean;
  gridHeaderList: any;
  listHeaderbackup: any;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  copyBtn: boolean;
  activityEntity: any;
  auditableColumns: any;
  saveOrEditCompleted: number;
  deleteBtn: boolean;
  destDeployment: string;
  userSessionDetails: any;
  sourceApp: string;
  storesub: Subscription = new Subscription();
  dbName: string


  constructor(
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    public tabService: VitalAdminTabService,
    public VitalHttpServices: VitalHttpServices,
    public commonService: CommonService,
    private templateHeaders: TemplateHeadersService,
    private _snackbar: MatSnackBar,
    public activityService: ActivityTrackerService,
    private dialog: MatDialog,
    private labadminService: LabadminService,
    private labAdminSessionService: LabadminSessionService,
    private store: Store<{ breadcrum: [] }>,
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }

  async ngOnInit() {
    this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      let _this = this;
      await this.labAdminSessionService.getLabAdminSession.subscribe(async session => {
        if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
          _this.userSessionDetails = session["userDetails"]
          _this.sourceApp = 'VitalDx'
          let menuObj = {}
          this.storesub = this.store.select("breadcrum").subscribe(async result => {
            menuObj = result[result.length - 1]
          })
          this.storesub.unsubscribe();
          await _this.commonService.getCommmonTemplateData(menuObj, _this.userSessionDetails);
          _this.templateData = _this.labadminService.templateData;
          _this.dbName = _this.labadminService.deploymentKey;
          sessionStorage.setItem('deploymentKey', this.dbName);
          this.addGridData(this.templateData.submenuData);
          this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Pathology Management','value':this.templateData['menuURL']}] })
          this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
          this.getAuditableDetails(this.templateData.menuURL)
          this.GetButtonAccess(this.labadminService.subMenuPermissions)

        }
      })
    }
    else {
      this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.noDataFound = true;
      this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
      this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
      this.getAuditableDetails(this.templateData.menuURL)
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);

    }

  }

  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL == this.labadminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    } 
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.deleteBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  getAllDiagReferences(caseType?: string) {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('getorganizationdiagreferencetemplates');
    queryVariable = {
      casetype: this.sourceApp == 'VitalDx' ? caseType : this.templateData.secondarykeys.casetype.toString()
      , orgid: sessionStorage.getItem('org_id')
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          this.templateData.submenuData = data.data.submenuData;
          this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.gridShow = true;
          if (!this.templateData?.submenuData?.length) this.gridData = new CollectionView(...[]);
          this.activityEntity.entityId = '';
          this.activityService.setActivitySession(this.activityEntity);
        }
        this.ngxService.stop();
      },
      error => {
        // this.gridShow = false;
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

  addGridData(templateData: any) {
    let primary = {};
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'DiagnosisReferences') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }

    }
    let gridarray = [];
    this.gridheader = [
      , 'ID'
      , 'OrganizationID'
      , 'CaseType'
      , 'TemplateName'
      , 'Description'
      , 'IsActive'
      , 'ReferenceType'
      , 'CreatedBy'
      , 'CreatedDate'
      , 'ModifiedBy'
      , 'ModifiedDate'
    ]
    for (let i = 0; i < templateData?.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        if (key == 'TemplateName' || key == 'Description') {
          if (value == '' || value == null) {
            value = 'Not Specified';
            primary[key] = value;
          }
          primary[key] = value;
        }
        for (let j = 0; j < this.gridheader.length; j++) {
          if (key === this.gridheader[j]) {
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary);
    }
    gridarray.sort((a, b) => a.TemplateName.localeCompare(b.TemplateName))
    this.gridData = new CollectionView(gridarray);
    this.gridShow = true;
    this.noDataFound = false;
    this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    return gridarray;
  }

  recieveObjFromListing(object: any) {
    object.newData = { ...object.newData, ...{ 'Context': 'Diagnosis References' } };
    if (object.newData.Action == 'Edit') {
      object.newData['Id'] = object.oldData['ID']
    }
    this.insertOrEditlDiagReferences(object)
  }


  insertOrEditlDiagReferences(data) {
    this.ngxService.start();
    // data.newData.TemplateName = data.newData.TemplateName.trim();
    this.VitalHttpServices.templateManagementCE(data.newData, this.destDeployment).subscribe(response => {
      if (response.status == "Success") {
        if (response.content.message.toString().includes('already exist')) {
          this._snackbar.open(response.content.message, "Close")
          this.ngxService.stop();
          return
        }
        else {
          data.newData.IsActive = data.newData.IsActive == 1 ? 'Active' : 'Inactive'
          if (data.newData.Action === 'Create') {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            // response.content['Id'] = response.content['userId']
            this.commonService.auditDetails('id', 'TemplateName', [response.content], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open(response.content.message, "Close")
            this.getAllDiagReferences();
          }
          else if (data.newData.Action === 'Edit') {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this.commonService.auditDetails('ID', 'TemplateName', [data.oldData], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open(response.content.message, "Close")
            this.getAllDiagReferences();
          }
          else {
            this._snackbar.open(response.content.message, "Close")
          }
          // this.addGridData(this.templateData.submenuData);
        }
      }
      else {
        this._snackbar.open("failed!", "Close")
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  deleteTemplate(value) {
    if (!this.deleteBtn) {
      value.rowData = { ...value.rowData, ...{ 'Context': 'Diagnosis References', 'Action': 'Delete' } };
      value.rowData['Id'] = value.rowData['ID']
      delete value.rowData['ID'];
      value.rowData.IsActive = value.rowData.IsActive == 'Active' ? 1 : 0;
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
        width: '400px',
        // data: { header: "", message: "This action will permanently delete the selected row.", alert: "Do you want to continue?", continue: "yes", cancel: "no" }
        data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }

      })
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.VitalHttpServices.templateManagementCE(value.rowData, this.destDeployment).subscribe(
            () => {
              value.rowData.IsActive = value.rowData.IsActive == 1 ? 'Active' : 'Inactive';

              this.commonService.auditDetails('Id', 'TemplateName', [value.rowData], [], 'Delete', this.templateData, this.auditableColumns);
              this._snackbar.open('Diagnosis template deleted successfully', 'Close');
              this.getAllDiagReferences();
            },
            error => {
              console.error('Error deleting item:', error);
            }
          );
        }
      })
    }
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }
  emitFilters($event: any) {
    let casetype=$event.CaseType !='All Casetypes'?$event.CaseType:null
    this.getAllDiagReferences(casetype)
  }
}
